/**
 * @generated SignedSource<<53e00efc5d0dc605dbd289df2c1a37d6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MovieMetainfoAngle = "back" | "front";
export type MovieMetainfoJobType = "AnnotateObject" | "LabelingSwing";
export type SwingFrameAngle = "back" | "front";
import { FragmentRefs } from "relay-runtime";
export type SwingPositionClassificationSummary$data = {
  readonly angle: MovieMetainfoAngle | null | undefined;
  readonly id: string;
  readonly movieClips: ReadonlyArray<{
    readonly id: string;
    readonly swingClassification: {
      readonly swingFrame: {
        readonly angle: SwingFrameAngle;
        readonly name: string;
      };
    } | null | undefined;
  }>;
  readonly movieMetainfoJobs: ReadonlyArray<{
    readonly appliedAt: any | null | undefined;
    readonly approvedAt: any | null | undefined;
    readonly id: string;
    readonly reviewer: {
      readonly avatar: {
        readonly signedUrl: string | null | undefined;
      } | null | undefined;
      readonly name: string;
    } | null | undefined;
    readonly type: MovieMetainfoJobType;
    readonly user: {
      readonly avatar: {
        readonly signedUrl: string | null | undefined;
      } | null | undefined;
      readonly name: string;
    };
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"useSwingFrameUrl">;
  readonly " $fragmentType": "SwingPositionClassificationSummary";
};
export type SwingPositionClassificationSummary$key = {
  readonly " $data"?: SwingPositionClassificationSummary$data;
  readonly " $fragmentSpreads": FragmentRefs<"SwingPositionClassificationSummary">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "angle",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "Avatar",
    "kind": "LinkedField",
    "name": "avatar",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "signedUrl",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SwingPositionClassificationSummary",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "MovieMetainfoJob",
      "kind": "LinkedField",
      "name": "movieMetainfoJobs",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "appliedAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "approvedAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "reviewer",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MovieClip",
      "kind": "LinkedField",
      "name": "movieClips",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "SwingClassification",
          "kind": "LinkedField",
          "name": "swingClassification",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SwingFrame",
              "kind": "LinkedField",
              "name": "swingFrame",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                (v1/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useSwingFrameUrl"
    }
  ],
  "type": "MovieMetainfo",
  "abstractKey": null
};
})();

(node as any).hash = "bc54ce86d585f20e276ee11ad97f31fc";

export default node;
