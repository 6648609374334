/**
 * @generated SignedSource<<ef370f2733faa2f882c04e0aa3d94997>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LabelingSwingWorkingQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"LabelingSwingItemQuery" | "SwingPositionClassificationMetaQuery">;
  readonly " $fragmentType": "LabelingSwingWorkingQuery";
};
export type LabelingSwingWorkingQuery$key = {
  readonly " $data"?: LabelingSwingWorkingQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"LabelingSwingWorkingQuery">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LabelingSwingWorkingQuery",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LabelingSwingItemQuery"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SwingPositionClassificationMetaQuery"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "4bffe51c89b97e0b20ff5de2151a59ad";

export default node;
