import React, { useCallback, useState } from "react";
import { ListItem, Divider, List, Stack, Button } from "@mui/material";
import Text from "@components/atoms/Text";
import graphql from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import Spacer from "@components/atoms/Spacer";
import Message from "@components/molecules/Message";
import Loading from "@components/atoms/Loading";
import { isNil } from "@lib/utils/commons";
import Colors from "@constants/Colors";
import {
  MovieMetainfoDetectionMutation,
  MovieMetainfoDetectionMutation$data,
} from "@generated/MovieMetainfoDetectionMutation.graphql";
import { MovieMetainfoDetection$key } from "@generated/MovieMetainfoDetection.graphql";
import ObjectDetectionItemSummery from "@components/molecules/ObjectDetectionItem/ObjectDetectionItemSummery";
import ObjectDetectionItemRow from "@components/molecules/ObjectDetectionItem/ObjectDetectionItemRow";
import ObjectDetectionChat from "@components/molecules/ObjectDetectionItem/ObjectDetectionChat";
import { useNavigate } from "react-router-dom";
import usePreventDoubleClick from "@lib/hooks/usePreventDoubleClick";
import useMessage from "@lib/hooks/useMessage";
import MovieMetainfoRemandButton from "@components/organisms/MovieMetainfo/MovieMetainfoRemandButton";

const query = graphql`
  fragment MovieMetainfoDetection on MovieMetainfo {
    id
    ...ObjectDetectionItemRowDirection
    ...ObjectDetectionChat
    movieClips {
      id
      ...ObjectDetectionItemRow
    }
    movieMetainfoJobs {
      id
      type
      ...ObjectDetectionItemSummery
      ...MovieMetainfoRemandButton
    }
  }
`;

const mutation = graphql`
  mutation MovieMetainfoDetectionMutation(
    $input: AssignAnnotationObjectMutationInput!
  ) {
    assignAnnotationObject(input: $input) {
      __typename
      ... on MovieMetainfoJob {
        id
      }
      ... on UserError {
        message
      }
    }
  }
`;

export default function MovieMetainfoDetection({
  movieMetainfoFragment,
}: {
  movieMetainfoFragment: MovieMetainfoDetection$key;
}) {
  const movieMetainfo = useFragment<MovieMetainfoDetection$key>(
    query,
    movieMetainfoFragment
  );
  const { processing, onClick, onRelease } = usePreventDoubleClick();
  const { id, movieClips, movieMetainfoJobs } = movieMetainfo;
  const annotationJob = movieMetainfoJobs.find(
    (row) => row.type === "AnnotateObject"
  );
  const [confirm, setConfirm] = useState<boolean>(false);
  const [, setMessage] = useMessage();
  const navigate = useNavigate();
  const [commit] = useMutation<MovieMetainfoDetectionMutation>(mutation);
  const handleAssign = useCallback(async () => {
    if (processing) {
      return;
    }
    onClick();
    const result = await new Promise<
      MovieMetainfoDetectionMutation$data["assignAnnotationObject"]
    >((resolve) => {
      commit({
        variables: {
          input: {
            movieMetainfoId: id,
          },
        },
        onCompleted: ({ assignAnnotationObject }) => {
          resolve(assignAnnotationObject);
        },
      });
    });
    if (result.__typename === "MovieMetainfoJob") {
      navigate("/annotate_objects");
      setMessage({
        mode: "error",
        title: "アサインしました",
      });
    } else {
      setMessage({
        mode: "error",
        title:
          result.__typename === "UserError"
            ? result.message
            : "アサインできませんでした",
      });
    }
    onRelease();
    setConfirm(false);
  }, [id, commit, setMessage, navigate, processing, onClick, onRelease]);

  if (isNil(annotationJob)) {
    return (
      <Stack direction="column" spacing={1}>
        <Text>
          アノテーションタスクがありません。アノテーションタスクを作成してください。
        </Text>
        <Stack direction="row" flex={1} justifyContent="center">
          <Button onClick={() => setConfirm(true)}>
            アノテーションタスクを作成
          </Button>
        </Stack>
        <Message
          closeText="キャンセル"
          confirmText="アサインする"
          onClose={() => setConfirm(false)}
          onConfirm={handleAssign}
          title="アノテーションタスクを自分にアサインします"
          visible={confirm}
        />
        {processing && <Loading color={Colors.white} mask />}
      </Stack>
    );
  }
  return (
    <>
      <MovieMetainfoRemandButton movieMetainfoJobFragment={annotationJob} />
      <ObjectDetectionItemSummery movieMetainfoJobFragment={annotationJob} />
      <Spacer height={16} />
      <Divider />
      {movieClips.length > 0 ? (
        <List>
          {movieClips.map((movieClip) => (
            <React.Fragment key={movieClip.id}>
              <ListItem>
                <ObjectDetectionItemRow
                  movieClipFragment={movieClip}
                  movieMetainfoFragment={movieMetainfo}
                />
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>
      ) : (
        <Text>
          スイング画像がありません。ラベリングが完了したら表示されます。
        </Text>
      )}
      <ObjectDetectionChat movieMetainfoFragment={movieMetainfo} />
    </>
  );
}
