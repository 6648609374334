import messageState from "@lib/hooks/useMessage";
import { Snackbar, IconButton } from "@mui/material";
import Message from "@components/molecules/Message";
import Icon from "@components/atoms/Icon";
import Colors from "@constants/Colors";

export default function MessageScreen() {
  const [state, set] = messageState();

  if (state === null) {
    return null;
  }
  switch (state.mode) {
    case "toast":
      return (
        <Snackbar
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={() => set(null)}
              size="small"
            >
              <Icon color={Colors.white} name="close" size={24} />
            </IconButton>
          }
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          autoHideDuration={2000}
          message={state.title}
          onClose={() => set(null)}
          open
        />
      );
    case "dialog":
    case "error":
      return (
        <Message
          closeIcon
          error={state.mode === "error"}
          message={state.subline}
          onClose={() => set(null)}
          title={state.title}
          visible
        />
      );
    case "confirm":
      return (
        <Message
          closeIcon
          message={state.subline}
          onClose={() => set(null)}
          onConfirm={() => {
            if (state.onConfirm !== undefined) {
              state.onConfirm();
            }
            set(null);
          }}
          title={state.title}
          visible
        />
      );
    default:
      return null;
  }
}
