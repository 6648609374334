/**
 * @generated SignedSource<<657b66063568a123d792729a703a00a1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type UserType = "Admin" | "Annotater";
import { FragmentRefs } from "relay-runtime";
export type MemberData$data = {
  readonly type: UserType;
  readonly " $fragmentSpreads": FragmentRefs<"MemberDisplay" | "MemberRemove">;
  readonly " $fragmentType": "MemberData";
};
export type MemberData$key = {
  readonly " $data"?: MemberData$data;
  readonly " $fragmentSpreads": FragmentRefs<"MemberData">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MemberData",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MemberDisplay"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MemberRemove"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "604dfa857ffd1904feb9aecf49d00444";

export default node;
