import { useEffect, Suspense } from "react";
import ContentLayout from "@navigation/ContentLayout";
import SwingFrameCreate from "@components/templates/SwingFrameCreate";
import graphql from "babel-plugin-relay/macro";
import {
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import { isNil } from "@lib/utils/commons";
import Loading from "@components/atoms/Loading";
import { SwingFrameCreateScreenQuery } from "@generated/SwingFrameCreateScreenQuery.graphql";

const query = graphql`
  query SwingFrameCreateScreenQuery {
    ...SwingFrameCreate
  }
`;

function ScreenContent({
  queryReference,
}: {
  queryReference: PreloadedQuery<SwingFrameCreateScreenQuery>;
}): JSX.Element {
  const data = usePreloadedQuery<SwingFrameCreateScreenQuery>(
    query,
    queryReference
  );
  return <SwingFrameCreate swingFragment={data} />;
}

export default function SwingFrameCreateScreen(): JSX.Element {
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<SwingFrameCreateScreenQuery>(query);
  useEffect(() => {
    loadQuery({});
    return () => {
      disposeQuery();
    };
  }, [loadQuery, disposeQuery]);
  return (
    <ContentLayout
      breadcrumbs={[
        {
          label: "スイングポジション定義一覧",
          path: "/swing_frames",
        },
      ]}
      title="スイングポジション定義 作成"
    >
      <Suspense fallback={<Loading />}>
        {!isNil(queryReference) && (
          <ScreenContent queryReference={queryReference} />
        )}
      </Suspense>
    </ContentLayout>
  );
}
