/**
 * @generated SignedSource<<b2cdf55c221b1bdef0511246263a5149>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LessonTemplateDrill$data = {
  readonly id: string;
  readonly swingPosition: {
    readonly name: string;
  };
  readonly title: string | null | undefined;
  readonly " $fragmentType": "LessonTemplateDrill";
};
export type LessonTemplateDrill$key = {
  readonly " $data"?: LessonTemplateDrill$data;
  readonly " $fragmentSpreads": FragmentRefs<"LessonTemplateDrill">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LessonTemplateDrill",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SwingPosition",
      "kind": "LinkedField",
      "name": "swingPosition",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Drill",
  "abstractKey": null
};

(node as any).hash = "f3aa25104eb3582fd69cf60bfc9e41c1";

export default node;
