import { useCallback } from "react";
import graphql from "babel-plugin-relay/macro";
import { useMutation } from "react-relay";
import {
  useMovieClipDownloadMutation,
  useMovieClipDownloadMutation$data,
} from "@generated/useMovieClipDownloadMutation.graphql";

const mutation = graphql`
  mutation useMovieClipDownloadMutation(
    $input: DownloadMovieMetainfoMutationInput!
  ) {
    downloadMovieMetainfo(input: $input) {
      __typename
      ... on DownloadFile {
        url
      }
      ... on UserError {
        message
      }
    }
  }
`;

type Params = {
  movieMetainfoId: string;
  onComplete?: () => void;
  onError: (message: string) => void;
};

export default function useMovieClipDownload() {
  const [commit] = useMutation<useMovieClipDownloadMutation>(mutation);
  const download = useCallback(
    async ({ movieMetainfoId, onComplete, onError }: Params) => {
      const result = await new Promise<
        useMovieClipDownloadMutation$data["downloadMovieMetainfo"]
      >((resolve) => {
        commit({
          variables: {
            input: {
              id: movieMetainfoId,
            },
          },
          onCompleted: ({ downloadMovieMetainfo }) => {
            resolve(downloadMovieMetainfo);
          },
        });
      });
      if (result.__typename === "DownloadFile") {
        window.open(result.url, "_blank");
        if (onComplete !== undefined) {
          onComplete();
        }
      } else {
        onError(
          result.__typename === "UserError"
            ? result.message
            : "エラーが発生しました"
        );
      }
    },
    [commit]
  );
  return { download };
}
