import { useCallback, useState } from "react";
import { Stack, Button } from "@mui/material";
import Message from "@components/molecules/Message";
import Loading from "@components/atoms/Loading";
import useMessage from "@lib/hooks/useMessage";
import usePreventDoubleClick from "@lib/hooks/usePreventDoubleClick";
import graphql from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import { MovieMetainfoRemandButton$key } from "@generated/MovieMetainfoRemandButton.graphql";
import {
  MovieMetainfoRemandButtonMutation,
  MovieMetainfoRemandButtonMutation$data,
} from "@generated/MovieMetainfoRemandButtonMutation.graphql";
import Colors from "@constants/Colors";

const query = graphql`
  fragment MovieMetainfoRemandButton on MovieMetainfoJob {
    id
  }
`;

const mutation = graphql`
  mutation MovieMetainfoRemandButtonMutation(
    $input: UpdateMovieMetainfoJobMutationInput!
  ) {
    updateMovieMetainfoJob(input: $input) {
      __typename
      ... on MovieMetainfoJob {
        id
      }
      ... on UserError {
        message
      }
    }
  }
`;

export default function MovieMetainfoRemandButton({
  movieMetainfoJobFragment,
}: {
  movieMetainfoJobFragment: MovieMetainfoRemandButton$key;
}) {
  const { processing, onClick, onRelease } = usePreventDoubleClick();
  const [, setMessage] = useMessage();
  const [confirm, setConfirm] = useState<boolean>(false);
  const { id } = useFragment<MovieMetainfoRemandButton$key>(
    query,
    movieMetainfoJobFragment
  );
  const [commit] = useMutation<MovieMetainfoRemandButtonMutation>(mutation);
  const handleRemand = useCallback(async () => {
    if (processing) {
      return;
    }
    onClick();
    const result = await new Promise<
      MovieMetainfoRemandButtonMutation$data["updateMovieMetainfoJob"]
    >((resolve) => {
      commit({
        variables: {
          input: {
            id,
            workflow: "remand",
          },
        },
        onCompleted: ({ updateMovieMetainfoJob }) => {
          resolve(updateMovieMetainfoJob);
        },
      });
    });
    if (result.__typename !== "MovieMetainfoJob") {
      setMessage({
        mode: "error",
        title: "処理に失敗しました",
        subline: "処理に失敗しました",
      });
      setConfirm(false);
      onRelease();
    } else {
      window.location.href = "/movie_metainfos";
    }
  }, [commit, id, onClick, onRelease, processing, setMessage]);
  return (
    <Stack
      alignItems="flex-end"
      direction="column"
      justifyContent="center"
      spacing={2}
    >
      <Button color="error" onClick={() => setConfirm(true)}>
        タスクを差し戻す
      </Button>
      <Message
        closeText="キャンセル"
        confirmText="理解して、差戻す"
        message="この操作は取り消せません。差戻しますか？"
        onClose={() => setConfirm(false)}
        onConfirm={handleRemand}
        title="スイング動画を差戻しますか？"
        visible={confirm}
      />
      {processing && <Loading color={Colors.white} mask />}
    </Stack>
  );
}
