/**
 * @generated SignedSource<<8511c227f8cea3588e39c2c9b22b0754>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChangeProfile$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ChangeProfileEmail">;
  readonly " $fragmentType": "ChangeProfile";
};
export type ChangeProfile$key = {
  readonly " $data"?: ChangeProfile$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChangeProfile">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChangeProfile",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ChangeProfileEmail"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "6fac6c26a5e8b22757876d4b46e8cbe8";

export default node;
