import React from "react";

const urlRegex = /(https?:\/\/[^\s]+)/g;
const newLineRegex = /\n/g;

const HtmlText = React.memo(({ text }: { text: string }) => {
  const newLines = text.split(newLineRegex);
  return (
    <>
      {newLines.map((line, index) =>
        index > 0
          ? [
              <br key={`br-${index + 1}`} />,
              line.split(urlRegex).map((part, cindex) => {
                if (urlRegex.test(part)) {
                  return (
                    <a
                      key={`link-${cindex + 1}`}
                      href={part}
                      rel="noreferrer"
                      target="_blank"
                    >
                      {part}
                    </a>
                  );
                }
                return part;
              }),
            ]
          : line.split(urlRegex).map((part, cindex) => {
              if (urlRegex.test(part)) {
                return (
                  <a
                    key={`link-${cindex + 1}`}
                    href={part}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {part}
                  </a>
                );
              }
              return part;
            })
      )}
    </>
  );
});

export default HtmlText;
