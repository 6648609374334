/**
 * @generated SignedSource<<77781ea9ce8df7ce43c620f874f8d47e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateMovieClipMutationInput = {
  ballTrajectory: boolean;
  clientMutationId?: string | null | undefined;
  id: string;
};
export type SwingPositionClassificationItemClipMutation$variables = {
  input: UpdateMovieClipMutationInput;
};
export type SwingPositionClassificationItemClipMutation$data = {
  readonly updateMovieClip: {
    readonly __typename: "MovieClip";
    readonly ballTrajectory: boolean;
    readonly id: string;
    readonly swingClassification: {
      readonly id: string;
      readonly needReview: boolean;
      readonly secondary: boolean;
      readonly swingFrame: {
        readonly id: string;
        readonly name: string;
      };
    } | null | undefined;
    readonly swingImage: {
      readonly id: string;
      readonly signedUrl: string | null | undefined;
    };
  } | {
    readonly __typename: "UserError";
    readonly message: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type SwingPositionClassificationItemClipMutation = {
  response: SwingPositionClassificationItemClipMutation$data;
  variables: SwingPositionClassificationItemClipMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "updateMovieClip",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ballTrajectory",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SwingImage",
            "kind": "LinkedField",
            "name": "swingImage",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "signedUrl",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SwingClassification",
            "kind": "LinkedField",
            "name": "swingClassification",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "needReview",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "secondary",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SwingFrame",
                "kind": "LinkedField",
                "name": "swingFrame",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "type": "MovieClip",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "type": "UserError",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SwingPositionClassificationItemClipMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SwingPositionClassificationItemClipMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "96ce3fb797178b6829762dc7fabb9545",
    "id": null,
    "metadata": {},
    "name": "SwingPositionClassificationItemClipMutation",
    "operationKind": "mutation",
    "text": "mutation SwingPositionClassificationItemClipMutation(\n  $input: UpdateMovieClipMutationInput!\n) {\n  updateMovieClip(input: $input) {\n    __typename\n    ... on MovieClip {\n      id\n      ballTrajectory\n      swingImage {\n        id\n        signedUrl\n      }\n      swingClassification {\n        id\n        needReview\n        secondary\n        swingFrame {\n          id\n          name\n        }\n      }\n    }\n    ... on UserError {\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6b1b7405ad60bcbf34a93a957dde43e9";

export default node;
