/* eslint-disable import/extensions */
import "@assets/styles/base.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "@navigation/App";
import { RelayEnvironmentProvider } from "react-relay/hooks";
import ErrorBoundary from "@components/molecules/ErrorBoundary";
import { RecoilRoot } from "recoil";
import { ExceptionalError } from "@lib/hooks/useExecptionalError";
import RelayEnvironment from "./RelayEnvironment";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <RelayEnvironmentProvider environment={RelayEnvironment}>
    <RecoilRoot>
      <React.StrictMode>
        <ErrorBoundary>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ErrorBoundary>
      </React.StrictMode>
      <ExceptionalError />
    </RecoilRoot>
  </RelayEnvironmentProvider>
);
