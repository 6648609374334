import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { isNil } from "@lib/utils/commons";

dayjs.extend(duration);

export function isExpired(date: string): boolean {
  return dayjs(date).endOf("day") < dayjs();
}

export function secondToDuration(second: number) {
  return dayjs(second * 1000).format("mm:ss");
}

export function dateFormat(
  date?: string | number | Date | dayjs.Dayjs | null | undefined,
  format = "YYYY-MM-DD"
) {
  if (isNil(date)) {
    return "";
  }
  return dayjs(date).format(format);
}
export { dayjs };
