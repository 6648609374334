import React from "react";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { CoachingData$key } from "@generated/CoachingData.graphql";
import CoachingMain from "@components/organisms/Coaching/CoachingMain";
import CoachingImage from "@components/organisms/Coaching/CoachingImage";
import { Stack, Divider } from "@mui/material";

const query = graphql`
  fragment CoachingData on Coaching {
    ...CoachingMain
    coachingImages {
      id
      ...CoachingImage
    }
  }
`;

export default function Coaching({
  coachingFragment,
}: {
  coachingFragment: CoachingData$key;
}): JSX.Element {
  const data = useFragment<CoachingData$key>(query, coachingFragment);
  return (
    <Stack direction="column" spacing={2}>
      <CoachingMain coachingFragment={data} />
      <Divider />
      {data.coachingImages.map((coachingImage) => (
        <React.Fragment key={coachingImage.id}>
          <CoachingImage coachingFragment={coachingImage} />
          <Divider />
        </React.Fragment>
      ))}
    </Stack>
  );
}
