/**
 * @generated SignedSource<<ac821b9671728cbebc9a699211c54c56>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LabelingSwing$data = {
  readonly " $fragmentSpreads": FragmentRefs<"LabelingSwingConfirm" | "LabelingSwingWorking">;
  readonly " $fragmentType": "LabelingSwing";
};
export type LabelingSwing$key = {
  readonly " $data"?: LabelingSwing$data;
  readonly " $fragmentSpreads": FragmentRefs<"LabelingSwing">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LabelingSwing",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LabelingSwingWorking"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LabelingSwingConfirm"
    }
  ],
  "type": "MovieJob",
  "abstractKey": null
};

(node as any).hash = "68e727a387b8152199cefa9ef2e2accc";

export default node;
