/**
 * @generated SignedSource<<08e4d5a34eb4eea389a9a720318e5b69>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ObjectDetectionObjectType = "ball" | "head" | "shaft";
import { FragmentRefs } from "relay-runtime";
export type ProgressObject$data = {
  readonly annotatedCount: number;
  readonly name: ObjectDetectionObjectType;
  readonly " $fragmentType": "ProgressObject";
};
export type ProgressObject$key = {
  readonly " $data"?: ProgressObject$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProgressObject">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProgressObject",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "annotatedCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "ProgressAnnotation",
  "abstractKey": null
};

(node as any).hash = "3c8e5a7779d0d265557d7894bd66ef17";

export default node;
