/**
 * @generated SignedSource<<90126967c87a79328280c05defe1b1d9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MovieMetainfoAngle = "back" | "front";
export type MovieMetainfoDirection = "landscape" | "portrait" | "square";
import { FragmentRefs } from "relay-runtime";
export type SwingPositionClassificationItemMeta$data = {
  readonly angle: MovieMetainfoAngle | null | undefined;
  readonly direction: MovieMetainfoDirection | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"SwingPositionClassificationMeta" | "useMovieMetainfo">;
  readonly " $fragmentType": "SwingPositionClassificationItemMeta";
};
export type SwingPositionClassificationItemMeta$key = {
  readonly " $data"?: SwingPositionClassificationItemMeta$data;
  readonly " $fragmentSpreads": FragmentRefs<"SwingPositionClassificationItemMeta">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SwingPositionClassificationItemMeta",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "direction",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "angle",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SwingPositionClassificationMeta"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useMovieMetainfo"
    }
  ],
  "type": "MovieMetainfo",
  "abstractKey": null
};

(node as any).hash = "d1ab36cbd5152b9a164ef227a8a32c83";

export default node;
