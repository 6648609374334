import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Breakpoint,
} from "@mui/material";
import Icon from "@components/atoms/Icon";
import Text from "@components/atoms/Text";
import styled from "styled-components";
import { isNil } from "@lib/utils/commons";

export default function Message({
  message,
  title,
  closeText = "閉じる",
  confirmText = "実行する",
  onClose,
  onConfirm,
  error,
  closeIcon = false,
  dialogContent,
  maxWidth = "sm",
  disableConfirm = false,
  visible = true,
}: {
  message?: string | null;
  title: string;
  closeText?: string;
  confirmText?: string;
  onClose: () => void;
  onConfirm?: () => void;
  error?: boolean;
  closeIcon?: boolean;
  dialogContent?: React.ReactNode;
  maxWidth?: Breakpoint;
  disableConfirm?: boolean;
  visible?: boolean;
}) {
  return (
    <Dialog maxWidth={maxWidth} onClose={onClose} open={visible}>
      <DialogTitle color={error === true ? "error" : "inherit"}>
        <Title>
          {title}
          {closeIcon && (
            <IconButton onClick={onClose}>
              <Icon name="close" size={24} />
            </IconButton>
          )}
        </Title>
      </DialogTitle>

      {!isNil(message) && (
        <DialogContent>
          <Text>{message}</Text>
        </DialogContent>
      )}
      {dialogContent !== undefined && (
        <DialogContent>{dialogContent}</DialogContent>
      )}

      <DialogActions>
        <Button color="secondary" onClick={onClose} variant="contained">
          {closeText}
        </Button>
        {onConfirm !== undefined && (
          <Button
            color="primary"
            disabled={disableConfirm}
            onClick={onConfirm}
            variant="contained"
          >
            {confirmText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
