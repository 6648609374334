/**
 * @generated SignedSource<<12edafdb5c1fea90e1b6195c51ea3e85>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MovieMetainfoJobType = "AnnotateObject" | "LabelingSwing";
import { FragmentRefs } from "relay-runtime";
export type AnnotateObject$data = {
  readonly id: string;
  readonly movieMetainfo: {
    readonly movieClips: ReadonlyArray<{
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"ObjectDetectionItemRow">;
    }>;
    readonly " $fragmentSpreads": FragmentRefs<"ObjectDetectionItemRowDirection">;
  };
  readonly type: MovieMetainfoJobType;
  readonly " $fragmentSpreads": FragmentRefs<"AnnotateObjectHead" | "ObjectDetectionItemSummery">;
  readonly " $fragmentType": "AnnotateObject";
};
export type AnnotateObject$key = {
  readonly " $data"?: AnnotateObject$data;
  readonly " $fragmentSpreads": FragmentRefs<"AnnotateObject">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AnnotateObject",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AnnotateObjectHead"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ObjectDetectionItemSummery"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MovieMetainfo",
      "kind": "LinkedField",
      "name": "movieMetainfo",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ObjectDetectionItemRowDirection"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "MovieClip",
          "kind": "LinkedField",
          "name": "movieClips",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ObjectDetectionItemRow"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "MovieMetainfoJob",
  "abstractKey": null
};
})();

(node as any).hash = "6f75bf8c9378a3164597049003155dc3";

export default node;
