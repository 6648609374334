import { useCallback } from "react";
import graphql from "babel-plugin-relay/macro";
import { useMutation, useFragment } from "react-relay";
import { Form } from "@components/molecules/TextInput";
import useMessage from "@lib/hooks/useMessage";
import { ChangeProfile$key } from "@generated/ChangeProfile.graphql";
import {
  ChangeProfileUpdateMutation,
  ChangeProfileUpdateMutation$data,
} from "@generated/ChangeProfileUpdateMutation.graphql";
import ChangeProfileEmail from "@components/organisms/ChangeProfile/ChangeProfileEmail";
import ChangeProfilePassword from "@components/organisms/ChangeProfile/ChangeProfilePassword";
import { UpdateParam } from "@components/organisms/ChangeProfile/type";

const query = graphql`
  fragment ChangeProfile on User {
    ...ChangeProfileEmail
  }
`;

const mutation = graphql`
  mutation ChangeProfileUpdateMutation(
    $input: CreateVerifyAdminMutationInput!
  ) {
    createVerifyAdmin(input: $input) {
      message
      success
    }
  }
`;

export type Mode = "password" | "email";

export default function ChangeProfile({
  mode,
  adminFragment,
}: {
  mode: Mode;
  adminFragment: ChangeProfile$key;
}) {
  const [commit] = useMutation<ChangeProfileUpdateMutation>(mutation);
  const data = useFragment(query, adminFragment);
  const [, setMessage] = useMessage();

  const handleUpdate = useCallback(
    async ({ changeType, changeValue }: UpdateParam) => {
      try {
        const result = await new Promise<
          ChangeProfileUpdateMutation$data["createVerifyAdmin"]
        >((resolve) => {
          commit({
            variables: {
              input: {
                changeType,
                changeValue,
              },
            },
            onCompleted({ createVerifyAdmin }) {
              resolve(createVerifyAdmin);
            },
          });
        });
        if (result.success === false) {
          setMessage({
            title: "変更できませんでした",
            subline: result.message ?? "もう一度やりなおしてください",
            mode: "error",
          });
        } else {
          setMessage({
            title:
              "認証メールを送信しました。認証URLから変更を完了させてください",
            mode: "toast",
          });
        }
      } catch (e: unknown) {
        setMessage({
          title: "変更できませんでした",
          mode: "error",
        });
      }
    },
    [commit, setMessage]
  );

  return (
    <Form>
      {mode === "password" && <ChangeProfilePassword onChange={handleUpdate} />}
      {mode === "email" && (
        <ChangeProfileEmail adminFragment={data} onChange={handleUpdate} />
      )}
    </Form>
  );
}
