/**
 * @generated SignedSource<<57e506c6d3fa7be66c155f846b34fdb9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SwingFrameAngle = "back" | "front";
import { FragmentRefs } from "relay-runtime";
export type SwingSceneData$data = {
  readonly correctForm: string;
  readonly draw: string;
  readonly id: string;
  readonly incorrectForm: string;
  readonly orderNumber: number;
  readonly point: string;
  readonly swingFrame: {
    readonly angle: SwingFrameAngle;
    readonly id: string;
    readonly name: string;
  };
  readonly thumbnail: {
    readonly signedUrl: string | null | undefined;
  } | null | undefined;
  readonly title: string;
  readonly video: {
    readonly signedUrl: string | null | undefined;
  } | null | undefined;
  readonly view: string;
  readonly " $fragmentSpreads": FragmentRefs<"SwingSceneDisplay" | "SwingSceneRemove">;
  readonly " $fragmentType": "SwingSceneData";
};
export type SwingSceneData$key = {
  readonly " $data"?: SwingSceneData$data;
  readonly " $fragmentSpreads": FragmentRefs<"SwingSceneData">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "signedUrl",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SwingSceneData",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "draw",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "correctForm",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "incorrectForm",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "point",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "view",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "orderNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Thumbnail",
      "kind": "LinkedField",
      "name": "thumbnail",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Thumbnail",
      "kind": "LinkedField",
      "name": "video",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SwingFrame",
      "kind": "LinkedField",
      "name": "swingFrame",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "angle",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SwingSceneDisplay"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SwingSceneRemove"
    }
  ],
  "type": "SwingScene",
  "abstractKey": null
};
})();

(node as any).hash = "83224cd6e0f54dcc9e9d8746dd1948e1";

export default node;
