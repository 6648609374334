import { useNavigate } from "react-router-dom";
import Text from "@components/atoms/Text";
import Icon from "@components/atoms/Icon";
import Spacer from "@components/atoms/Spacer";
/* eslint-disable import/extensions */
import logo from "@assets/images/logo-icon.png";
import styled from "styled-components";
import React, { useState } from "react";
import {
  IconButton,
  Button,
  Menu,
  ListItemButton,
  ListItem,
  ListItemText,
  Divider,
  Box,
  Link,
} from "@mui/material";
import onSignOut from "@lib/utils/signOut";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay/hooks";
import { NavigationHeaderAdmin$key } from "@generated/NavigationHeaderAdmin.graphql";
import Avatar from "@components/atoms/Avatar";
import { HeaderHeight, AppVersion } from "@constants/App";

const query = graphql`
  fragment NavigationHeaderAdmin on User {
    id
    name
    email
    avatar {
      signedUrl
    }
  }
`;

export default function NavigationHeader({
  adminFragment,
  toggleDrawer,
  drawer,
}: {
  adminFragment: NavigationHeaderAdmin$key;
  toggleDrawer: (open: boolean) => void;
  drawer: "open" | "close";
}) {
  const nav = useNavigate();
  const { name, email, avatar } = useFragment<NavigationHeaderAdmin$key>(
    query,
    adminFragment
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  function onNavigate(path: string) {
    nav(path);
    setAnchorEl(null);
  }

  return (
    <Container>
      <Box
        sx={(theme) => ({
          [theme.breakpoints.up("md")]: {
            display: "none",
          },
        })}
      >
        <Wrapper>
          <IconButton onClick={() => toggleDrawer(drawer !== "open")}>
            <Icon name={drawer === "open" ? "close" : "menu"} size={30} />
          </IconButton>
          <MinLogo>
            <Link href="/">
              <Logo src={logo} />
            </Link>
          </MinLogo>
          <MinAvatars>
            <Button
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                setAnchorEl(event.currentTarget);
              }}
            >
              <Avatar size={40} url={avatar?.signedUrl ?? null} />
            </Button>
          </MinAvatars>
        </Wrapper>
      </Box>

      <Box
        sx={(theme) => ({
          [theme.breakpoints.down("md")]: {
            display: "none",
          },
        })}
      >
        <Wrapper>
          <Link href="/">
            <Logo src={logo} />
          </Link>

          <Item>
            <Avatar size={40} url={avatar?.signedUrl ?? null} />
            <Spacer width={16} />
            <Divider flexItem orientation="vertical" />
            <Spacer width={16} />
            <Button
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                setAnchorEl(event.currentTarget);
              }}
              variant="text"
            >
              <Item>
                <Text>{name}</Text>
                <Spacer width={16} />
                <Divider flexItem orientation="vertical" />
                <Spacer width={16} />
                <Text>{email}</Text>
              </Item>
            </Button>
          </Item>
        </Wrapper>
      </Box>

      <Menu
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        open={anchorEl !== null}
      >
        <ListItem disablePadding>
          <ListItemButton onClick={() => onNavigate("profile")}>
            <IconWrapper>
              <Icon name="account" size={20} />
            </IconWrapper>
            <ListItemText>アカウント管理</ListItemText>
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem disablePadding>
          <ListItemButton onClick={() => onNavigate("change_email")}>
            <IconWrapper>
              <Icon name="email" size={20} />
            </IconWrapper>
            <ListItemText>Email変更</ListItemText>
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem disablePadding>
          <ListItemButton onClick={() => onNavigate("change_password")}>
            <IconWrapper>
              <Icon name="lock" size={20} />
            </IconWrapper>
            <ListItemText>パスワード変更</ListItemText>
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem disablePadding>
          <ListItemButton onClick={onSignOut}>
            <IconWrapper>
              <Icon name="logout" size={20} />
            </IconWrapper>
            <ListItemText>ログアウト</ListItemText>
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem>
          <IconWrapper />
          <ListItemText secondary={`ver${AppVersion}`} />
        </ListItem>
      </Menu>
    </Container>
  );
}

const Container = styled.div`
  padding: 0 24px;
`;

const Wrapper = styled.div`
  display: flex;
  height: ${HeaderHeight}px;
  justify-content: center;
  align-items: center;
`;

const MinLogo = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MinAvatars = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Item = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Logo = styled.img`
  height: 39px;
  width: 39px;
`;

const IconWrapper = styled.div`
  display: flex;
  width: 32px;
`;
