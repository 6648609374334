import Cookies from "js-cookie";

export default class Storage {
  mode: "cookie" | "localStorage";

  constructor(mode: "cookie" | "localStorage") {
    this.mode = mode;
    if (localStorage === undefined) {
      throw new Error("このブラウザではストレージを使用できません");
    }
  }

  static clearCookie(): void {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i += 1) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = `${name}=;max-age=0`;
    }
  }

  setItem(key: string, value: string): void {
    switch (this.mode) {
      case "cookie":
        Cookies.set(key, value);
        return;
      case "localStorage":
        localStorage.setItem(key, value);
        return;
      default:
        throw new Error("ストレージへの保存ができませんでした");
    }
  }

  getItem(key: string): string | null {
    switch (this.mode) {
      case "cookie":
        return Cookies.get(key) ?? null;
      case "localStorage":
        return localStorage.getItem(key) ?? null;
      default:
        throw new Error("ストレージの取得ができませんでした");
    }
  }

  removeItem(key: string): void {
    switch (this.mode) {
      case "cookie":
        Cookies.remove(key);
        return;
      case "localStorage":
        localStorage.removeItem(key);
        return;
      default:
        throw new Error("ストレージの削除ができませんでした");
    }
  }

  clear(): void {
    switch (this.mode) {
      case "cookie":
        Storage.clearCookie();
        return;
      case "localStorage":
        localStorage.clear();
        return;
      default:
        throw new Error("ストレージの削除ができませんでした");
    }
  }
}
