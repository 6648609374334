import { useCallback } from "react";
import { Button } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useMutation, useFragment } from "react-relay";
import Loading from "@components/atoms/Loading";
import useMessage from "@lib/hooks/useMessage";
import usePreventDoubleClick from "@lib/hooks/usePreventDoubleClick";
import { LabelingSwingGenerate$key } from "@generated/LabelingSwingGenerate.graphql";
import {
  LabelingSwingGenerateMutation,
  LabelingSwingGenerateMutation$data,
} from "@generated/LabelingSwingGenerateMutation.graphql";
import Colors from "@constants/Colors";

const query = graphql`
  fragment LabelingSwingGenerate on MovieJob {
    id
    generated
    status
  }
`;

const mutation = graphql`
  mutation LabelingSwingGenerateMutation(
    $input: CreateTmpImagesMutationInput!
  ) {
    createTmpImages(input: $input) {
      success
      message
    }
  }
`;

export default function LabelingSwingGenerate({
  movieJobFragment,
}: {
  movieJobFragment: LabelingSwingGenerate$key;
}) {
  const [, setMessage] = useMessage();
  const { id, generated, status } = useFragment<LabelingSwingGenerate$key>(
    query,
    movieJobFragment
  );
  const [commit] = useMutation<LabelingSwingGenerateMutation>(mutation);
  const { processing, onClick, onRelease } = usePreventDoubleClick();
  const buttonText =
    status === "generating"
      ? "画像生成中..."
      : `スイングポジション画像を${generated === true ? "再生成" : "生成"}する`;
  const handleGenerate = useCallback(async () => {
    if (processing || status === "generating") {
      return;
    }
    onClick();
    const result = await new Promise<
      LabelingSwingGenerateMutation$data["createTmpImages"]
    >((resolve) => {
      commit({
        variables: {
          input: {
            movieJobId: id,
          },
        },
        onCompleted: ({ createTmpImages }) => {
          resolve(createTmpImages);
        },
      });
    });
    if (result.success === false) {
      setMessage({
        mode: "error",
        title: "生成できませんでした",
        subline: result.message ?? "",
      });
      onRelease();
    } else {
      window.location.reload();
    }
  }, [id, status, commit, setMessage, processing, onClick, onRelease]);

  const onDialog = useCallback(() => {
    if (generated === false) {
      handleGenerate();
      return;
    }
    setMessage({
      mode: "confirm",
      title: "スイングポジション画像を再生成しますか？",
      subline:
        "再生成すると、以前の画像やラベルは全て削除されます。この操作は取り消せません。",
      onConfirm: handleGenerate,
    });
  }, [generated, handleGenerate, setMessage]);

  return (
    <>
      <Button
        disabled={processing || status === "generating"}
        onClick={onDialog}
        variant="contained"
      >
        {buttonText}
      </Button>
      {processing && <Loading color={Colors.white} mask />}
    </>
  );
}
