/**
 * @generated SignedSource<<57a4047e345f78f834b9635dccc785db>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LessonTemplateScreenQuery$variables = {
  id: string;
};
export type LessonTemplateScreenQuery$data = {
  readonly lessonTemplate: {
    readonly " $fragmentSpreads": FragmentRefs<"LessonTemplateData">;
  };
};
export type LessonTemplateScreenQuery = {
  response: LessonTemplateScreenQuery$data;
  variables: LessonTemplateScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "content",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "SwingPosition",
  "kind": "LinkedField",
  "name": "swingPosition",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    (v2/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LessonTemplateScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "lessonTemplate",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "LessonTemplateData"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LessonTemplateScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "lessonTemplate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "goal",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Cause",
                "kind": "LinkedField",
                "name": "cause",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "LessonTemplateForm",
                "kind": "LinkedField",
                "name": "lessonTemplateForms",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "formType",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Thumbnail",
                    "kind": "LinkedField",
                    "name": "thumbnail",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "signedUrl",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Drill",
                "kind": "LinkedField",
                "name": "drills",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "LessonTemplate",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6e35075fd0bea41619784e410ecf70d7",
    "id": null,
    "metadata": {},
    "name": "LessonTemplateScreenQuery",
    "operationKind": "query",
    "text": "query LessonTemplateScreenQuery(\n  $id: ID!\n) {\n  lessonTemplate(id: $id) {\n    __typename\n    ...LessonTemplateData\n    ... on LessonTemplate {\n      id\n    }\n  }\n}\n\nfragment LessonTemplateData on LessonTemplate {\n  ...LessonTemplateMain\n  lessonTemplateForms {\n    id\n    ...LessonTemplateFormData\n  }\n  drills {\n    id\n    ...LessonTemplateDrill\n  }\n}\n\nfragment LessonTemplateDrill on Drill {\n  id\n  title\n  swingPosition {\n    name\n    id\n  }\n}\n\nfragment LessonTemplateFormData on LessonTemplateForm {\n  id\n  formType\n  content\n  thumbnail {\n    signedUrl\n    id\n  }\n}\n\nfragment LessonTemplateMain on LessonTemplate {\n  id\n  goal\n  cause {\n    content\n    swingPosition {\n      name\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "e57f4a646aa9a997902861c286aa8a00";

export default node;
