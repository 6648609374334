/**
 * @generated SignedSource<<1ebf1ab17863bf4dba05eda64423dc2d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MovieMetainfoData$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"MovieMetainfoDetection" | "MovieMetainfoLabelsData">;
  readonly " $fragmentType": "MovieMetainfoData";
};
export type MovieMetainfoData$key = {
  readonly " $data"?: MovieMetainfoData$data;
  readonly " $fragmentSpreads": FragmentRefs<"MovieMetainfoData">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MovieMetainfoData",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MovieMetainfoDetection"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MovieMetainfoLabelsData"
    }
  ],
  "type": "MovieMetainfo",
  "abstractKey": null
};

(node as any).hash = "51112a53ca9a8684d96517c787dc9de7";

export default node;
