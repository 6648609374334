/**
 * @generated SignedSource<<91e5e92c626d45c9b26c3018a1232385>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AnnotateObjectScreenQuery$variables = {
  id: string;
};
export type AnnotateObjectScreenQuery$data = {
  readonly movieMetainfoJob: {
    readonly " $fragmentSpreads": FragmentRefs<"AnnotateObject">;
  };
};
export type AnnotateObjectScreenQuery = {
  response: AnnotateObjectScreenQuery$data;
  variables: AnnotateObjectScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "signedUrl",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Avatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v2/*: any*/)
  ],
  "storageKey": null
},
v6 = [
  (v2/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AnnotateObjectScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MovieMetainfoJob",
        "kind": "LinkedField",
        "name": "movieMetainfoJob",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AnnotateObject"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AnnotateObjectScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MovieMetainfoJob",
        "kind": "LinkedField",
        "name": "movieMetainfoJob",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "MovieMetainfo",
            "kind": "LinkedField",
            "name": "movieMetainfo",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "direction",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "MovieClip",
                "kind": "LinkedField",
                "name": "movieClips",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "width",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "height",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SwingImage",
                    "kind": "LinkedField",
                    "name": "swingImage",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ObjectDetection",
                    "kind": "LinkedField",
                    "name": "objectDetections",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "objectType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "xmin",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "ymin",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "xmax",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "ymax",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CommentContent",
                        "kind": "LinkedField",
                        "name": "commentContents",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "content",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "createdAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              (v5/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "reviewer",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "assignedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "appliedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "approvedAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "89e01e41644674c54bd2dc2ffdf7b7cf",
    "id": null,
    "metadata": {},
    "name": "AnnotateObjectScreenQuery",
    "operationKind": "query",
    "text": "query AnnotateObjectScreenQuery(\n  $id: ID!\n) {\n  movieMetainfoJob(id: $id) {\n    ...AnnotateObject\n    id\n  }\n}\n\nfragment AnnotateObject on MovieMetainfoJob {\n  id\n  type\n  ...AnnotateObjectHead\n  ...ObjectDetectionItemSummery\n  movieMetainfo {\n    ...ObjectDetectionItemRowDirection\n    movieClips {\n      id\n      ...ObjectDetectionItemRow\n    }\n    id\n  }\n}\n\nfragment AnnotateObjectHead on MovieMetainfoJob {\n  id\n  type\n  movieMetainfo {\n    id\n  }\n}\n\nfragment ObjectDetectionItemRow on MovieClip {\n  id\n  width\n  height\n  swingImage {\n    id\n    signedUrl\n  }\n  objectDetections {\n    id\n    objectType\n    xmin\n    ymin\n    xmax\n    ymax\n    commentContents {\n      id\n      content\n      createdAt\n      user {\n        name\n        avatar {\n          signedUrl\n          id\n        }\n        id\n      }\n    }\n  }\n}\n\nfragment ObjectDetectionItemRowDirection on MovieMetainfo {\n  direction\n}\n\nfragment ObjectDetectionItemSummery on MovieMetainfoJob {\n  id\n  status\n  user {\n    id\n    name\n    avatar {\n      signedUrl\n      id\n    }\n  }\n  reviewer {\n    id\n    name\n    avatar {\n      signedUrl\n      id\n    }\n  }\n  assignedAt\n  appliedAt\n  approvedAt\n}\n"
  }
};
})();

(node as any).hash = "f60274f227a48b9d9ba12ec48a2a4239";

export default node;
