import { Controller } from "react-hook-form";
import { CommonProps } from "@components/molecules/TextInput/type";
import { TextField } from "@mui/material";
import React from "react";

export default function NumberField({
  label,
  name,
  placeholder,
  disabled,
  required,
  control,
  helperText,
  style,
}: CommonProps) {
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { value, onBlur, onChange: onChangeText },
        fieldState: { invalid },
      }) => (
        <TextField
          disabled={disabled}
          error={invalid}
          helperText={helperText}
          label={label}
          onBlur={onBlur}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.value === "") {
              onChangeText(null);
            } else if (!Number.isNaN(Number(event.target.value))) {
              onChangeText(event.target.value);
            }
          }}
          placeholder={placeholder}
          required={required}
          style={{
            margin: "0px",
            ...style,
          }}
          type="numeric"
          value={value ?? ""}
        />
      )}
    />
  );
}
