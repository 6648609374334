import { useState } from "react";
import Image, { Props } from "@components/atoms/Image";
import Icon from "@components/atoms/Icon";
import { Stack, Box, IconButton, Dialog, DialogContent } from "@mui/material";
import Colors from "@constants/Colors";

export default function ExpandImage({ src, width, height, alt }: Props) {
  const [exapnd, setExpand] = useState<boolean>(false);
  return (
    <>
      <Box onClick={() => setExpand(true)} sx={{ position: "relative" }}>
        <Image alt={alt} height={200} src={src} />
        <IconButton
          sx={{
            position: "absolute",
            right: 8,
            bottom: 8,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <Icon color={Colors.white} name="expand" size={24} />
        </IconButton>
      </Box>
      <Dialog onClose={() => setExpand(false)} open={exapnd}>
        <Stack alignItems="flex-end" justifyContent="center">
          <IconButton onClick={() => setExpand(false)}>
            <Icon name="close" size={24} />
          </IconButton>
        </Stack>
        <DialogContent>
          <Image alt={alt} height={height} src={src} width={width} />
        </DialogContent>
      </Dialog>
    </>
  );
}
