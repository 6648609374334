import {
  ListItem,
  Divider,
  List,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import Avatar from "@components/atoms/Avatar";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { ObjectDetectionItemSummery$key } from "@generated/ObjectDetectionItemSummery.graphql";
import { dateFormat } from "@lib/utils/date";
import { isNil } from "@lib/utils/commons";

const query = graphql`
  fragment ObjectDetectionItemSummery on MovieMetainfoJob {
    id
    status
    user {
      id
      name
      avatar {
        signedUrl
      }
    }
    reviewer {
      id
      name
      avatar {
        signedUrl
      }
    }
    assignedAt
    appliedAt
    approvedAt
  }
`;

export default function ObjectDetectionItemSummery({
  movieMetainfoJobFragment,
}: {
  movieMetainfoJobFragment: ObjectDetectionItemSummery$key;
}) {
  const { user, reviewer, assignedAt, appliedAt, approvedAt } =
    useFragment<ObjectDetectionItemSummery$key>(
      query,
      movieMetainfoJobFragment
    );
  return (
    <List>
      <ListItem>
        <ListItemAvatar>
          <Avatar size={32} url={user?.avatar?.signedUrl} />
        </ListItemAvatar>
        <ListItemText
          primary={`${user?.name}さんが${appliedAt === null ? "アノテーション中です" : "アノテーションしました"}`}
          secondary={dateFormat(appliedAt ?? assignedAt, "M月D日 HH:mm")}
        />
      </ListItem>

      {!isNil(reviewer) && (
        <>
          <Divider />
          <ListItem>
            <ListItemAvatar>
              <Avatar size={32} url={reviewer.avatar?.signedUrl} />
            </ListItemAvatar>
            <ListItemText
              primary={`${reviewer.name}さんが${
                approvedAt === null ? "レビュー中です" : "承認しました"
              }`}
              secondary={
                approvedAt !== null
                  ? dateFormat(approvedAt, "M月D日 HH:mm")
                  : null
              }
            />
          </ListItem>
        </>
      )}
    </List>
  );
}
