import { Stack } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { LessonTemplateFormData$key } from "@generated/LessonTemplateFormData.graphql";
import Text from "@components/atoms/Text";
import { LessonTemplateFormFormType } from "@constants/App";
import Thumbnail from "@components/atoms/Thumbnail";

const query = graphql`
  fragment LessonTemplateFormData on LessonTemplateForm {
    id
    formType
    content
    thumbnail {
      signedUrl
    }
  }
`;

export default function LessonTemplateForm({
  templateFragment,
}: {
  templateFragment: LessonTemplateFormData$key;
}): JSX.Element {
  const data = useFragment<LessonTemplateFormData$key>(query, templateFragment);
  return (
    <Stack direction="column" spacing={1}>
      <Text bold>{LessonTemplateFormFormType[data.formType]}</Text>
      <Text>{data.content}</Text>
      <Thumbnail size={300} url={data.thumbnail.signedUrl} />
    </Stack>
  );
}
