import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import ProfileInfo from "@components/organisms/Profile/ProfileInfo";
import ProfileAvatar from "@components/organisms/Profile/ProfileAvatar";
import { ProfileData$key } from "@generated/ProfileData.graphql";
import Spacer from "@components/atoms/Spacer";

const query = graphql`
  fragment ProfileData on User {
    ...ProfileInfo
    ...ProfileAvatar
  }
`;

export default function Profile({
  adminFragment,
}: {
  adminFragment: ProfileData$key;
}): JSX.Element {
  const admin = useFragment<ProfileData$key>(query, adminFragment);
  return (
    <>
      <ProfileAvatar adminFragment={admin} />
      <Spacer height={24} />
      <ProfileInfo adminFragment={admin} />
    </>
  );
}
