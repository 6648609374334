import { useEffect, Suspense } from "react";
import ContentLayout from "@navigation/ContentLayout";
import SwingFrame from "@components/templates/SwingFrame";
import graphql from "babel-plugin-relay/macro";
import {
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import { isNil } from "@lib/utils/commons";
import Loading from "@components/atoms/Loading";
import { SwingFrameScreenQuery } from "@generated/SwingFrameScreenQuery.graphql";
import useQueryId from "@lib/hooks/useQueryId";

const query = graphql`
  query SwingFrameScreenQuery($id: ID!) {
    swingFrame(id: $id) {
      ...SwingFrameData
    }
    ...SwingFrameSwingPositions
  }
`;

function ScreenContent({
  queryReference,
}: {
  queryReference: PreloadedQuery<SwingFrameScreenQuery>;
}): JSX.Element {
  const data = usePreloadedQuery<SwingFrameScreenQuery>(query, queryReference);
  const { swingFrame } = data;
  return <SwingFrame definitionFragment={swingFrame} swingFragment={data} />;
}

export default function SwingFrameScreen(): JSX.Element {
  const id = useQueryId();
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<SwingFrameScreenQuery>(query);
  useEffect(() => {
    loadQuery({ id: id ?? null });
    return () => {
      disposeQuery();
    };
  }, [id, loadQuery, disposeQuery]);
  return (
    <ContentLayout
      breadcrumbs={[
        {
          label: "スイングフレーム定義一覧",
          path: "/swing_frames",
        },
      ]}
      title="スイングフレーム定義"
    >
      <Suspense fallback={<Loading />}>
        {!isNil(queryReference) && (
          <ScreenContent queryReference={queryReference} />
        )}
      </Suspense>
    </ContentLayout>
  );
}
