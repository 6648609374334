/**
 * @generated SignedSource<<2c3affdc9e72379c1e539fd98333677b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AssignAnnotationObjectMutationInput = {
  clientMutationId?: string | null | undefined;
  movieMetainfoId: string;
};
export type MovieMetainfoDetectionMutation$variables = {
  input: AssignAnnotationObjectMutationInput;
};
export type MovieMetainfoDetectionMutation$data = {
  readonly assignAnnotationObject: {
    readonly __typename: "MovieMetainfoJob";
    readonly id: string;
  } | {
    readonly __typename: "UserError";
    readonly message: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type MovieMetainfoDetectionMutation = {
  response: MovieMetainfoDetectionMutation$data;
  variables: MovieMetainfoDetectionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "assignAnnotationObject",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "type": "MovieMetainfoJob",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "type": "UserError",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MovieMetainfoDetectionMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MovieMetainfoDetectionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "720f4e3a2d6dc5dd56808da4ff39dc61",
    "id": null,
    "metadata": {},
    "name": "MovieMetainfoDetectionMutation",
    "operationKind": "mutation",
    "text": "mutation MovieMetainfoDetectionMutation(\n  $input: AssignAnnotationObjectMutationInput!\n) {\n  assignAnnotationObject(input: $input) {\n    __typename\n    ... on MovieMetainfoJob {\n      id\n    }\n    ... on UserError {\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0b0602fd3adb99d8dcd7a9800fd05ccc";

export default node;
