/**
 * @generated SignedSource<<191aefcd66bdb242fca47c4bc5baef04>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserInvites$data = {
  readonly userInvites: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"UserInvitesItem">;
  }>;
  readonly " $fragmentType": "UserInvites";
};
export type UserInvites$key = {
  readonly " $data"?: UserInvites$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserInvites">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserInvites",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserInvite",
      "kind": "LinkedField",
      "name": "userInvites",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UserInvitesItem"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "f0e63418de2da2977b27a091e2ca00b6";

export default node;
