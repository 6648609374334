/**
 * @generated SignedSource<<aa1f09620111365d59c8a55cfa9ae08c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NavigationHeaderAdmin$data = {
  readonly avatar: {
    readonly signedUrl: string | null | undefined;
  } | null | undefined;
  readonly email: string;
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "NavigationHeaderAdmin";
};
export type NavigationHeaderAdmin$key = {
  readonly " $data"?: NavigationHeaderAdmin$data;
  readonly " $fragmentSpreads": FragmentRefs<"NavigationHeaderAdmin">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NavigationHeaderAdmin",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Avatar",
      "kind": "LinkedField",
      "name": "avatar",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "signedUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "0879dd6cca839549219e82ca1836ce09";

export default node;
