import React, { useEffect, useRef } from "react";
import { Stack, Button, ListItem, Divider, List } from "@mui/material";
import { useNavigate } from "react-router-dom";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { MovieMetainfoLabelsData$key } from "@generated/MovieMetainfoLabelsData.graphql";
import { MovieMetainfoLabelsQuery$key } from "@generated/MovieMetainfoLabelsQuery.graphql";
import SwingPositionClassificationItem from "@components/molecules/SwingPositionClassification/SwingPositionClassificationItem";
import SwingPositionClassificationSummary from "@components/molecules/SwingPositionClassification/SwingPositionClassificationSummary";
import SwingPositionClassificationChat from "@components/molecules/SwingPositionClassification/SwingPositionClassificationChat";
import Spacer from "@components/atoms/Spacer";
import Text from "@components/atoms/Text";
import useQueryParams from "@lib/hooks/useQueryParams";
import useScroll from "@lib/hooks/useScroll";
import Colors from "@constants/Colors";
import { PageAnchorName } from "@constants/App";
import { isNil } from "@lib/utils/commons";
import MovieMetainfoRemandButton from "@components/organisms/MovieMetainfo/MovieMetainfoRemandButton";

const query = graphql`
  fragment MovieMetainfoLabelsData on MovieMetainfo {
    ...SwingPositionClassificationItemMeta
    ...SwingPositionClassificationSummary
    ...SwingPositionClassificationChat
    movieClips {
      id
      ...SwingPositionClassificationItemClip
    }
    movieMetainfoJobs {
      type
      ...MovieMetainfoRemandButton
    }
  }
`;

const masterQuery = graphql`
  fragment MovieMetainfoLabelsQuery on Query {
    ...SwingPositionClassificationItemQuery
    ...SwingPositionClassificationSummaryQuery
  }
`;

export default function MovieMetainfoLabels({
  movieMetainfoFragment,
  masterFragment,
}: {
  movieMetainfoFragment: MovieMetainfoLabelsData$key;
  masterFragment: MovieMetainfoLabelsQuery$key;
}) {
  const data = useFragment<MovieMetainfoLabelsData$key>(
    query,
    movieMetainfoFragment
  );
  const navigate = useNavigate();
  const listItemRef = useRef<HTMLLIElement | null>(null);
  const { movieClips, movieMetainfoJobs } = data;
  const master = useFragment<MovieMetainfoLabelsQuery$key>(
    masterQuery,
    masterFragment
  );
  const labelingJob = movieMetainfoJobs.find(
    (row) => row.type === "LabelingSwing"
  );
  const param = useQueryParams(PageAnchorName);
  const anchor = param[PageAnchorName];
  const { scrollTo } = useScroll();
  useEffect(() => {
    if (listItemRef.current !== null) {
      scrollTo(listItemRef);
    }
  }, [scrollTo]);
  if (isNil(labelingJob)) {
    return (
      <Stack>
        <Text>ラベリングタスクがありません。タスクを作成してください。</Text>
        <Button onClick={() => navigate("/labeling_swings")}>
          タスクを作成
        </Button>
      </Stack>
    );
  }

  return (
    <>
      <MovieMetainfoRemandButton movieMetainfoJobFragment={labelingJob} />
      <SwingPositionClassificationSummary
        movieMetainfoFragment={data}
        swingPositionsFragment={master}
      />
      <Spacer height={16} />
      <Divider />
      <List>
        {movieClips.map((movieClip) => (
          <React.Fragment key={movieClip.id}>
            <ListItem
              ref={(ref) => {
                if (ref !== null && anchor === movieClip.id) {
                  listItemRef.current = ref;
                }
              }}
              sx={{
                backgroundColor:
                  anchor === movieClip.id ? Colors.yellow10 : undefined,
              }}
            >
              <SwingPositionClassificationItem
                editable
                masterFragment={master}
                movieClipFragment={movieClip}
                movieMetainfoFragment={data}
              />
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>
      <SwingPositionClassificationChat movieMetainfoFragment={data} />
    </>
  );
}
