/**
 * @generated SignedSource<<1027958f7d687830452b2208968c9863>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useNotificationCountQuery$variables = Record<PropertyKey, never>;
export type useNotificationCountQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useNotificationCount">;
};
export type useNotificationCountQuery = {
  response: useNotificationCountQuery$data;
  variables: useNotificationCountQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useNotificationCountQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "useNotificationCount"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useNotificationCountQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "NotificationCount",
        "kind": "LinkedField",
        "name": "notificationCount",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "inReviews",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "unreadComments",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3da85a1dce3dce2ecb0ce9bee8c33319",
    "id": null,
    "metadata": {},
    "name": "useNotificationCountQuery",
    "operationKind": "query",
    "text": "query useNotificationCountQuery {\n  ...useNotificationCount\n}\n\nfragment useNotificationCount on Query {\n  notificationCount {\n    id\n    inReviews\n    unreadComments\n  }\n}\n"
  }
};

(node as any).hash = "4f5e5860339226cdfc834c7a2bbc75ce";

export default node;
