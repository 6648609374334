import { useEffect, Suspense } from "react";
import ContentLayout from "@navigation/ContentLayout";
import SwingScenes from "@components/templates/SwingScenes";
import graphql from "babel-plugin-relay/macro";
import {
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import { useNavigate } from "react-router-dom";
import { isNil } from "@lib/utils/commons";
import Loading from "@components/atoms/Loading";
import { SwingScenesScreenQuery } from "@generated/SwingScenesScreenQuery.graphql";
import { Button, Divider, Stack } from "@mui/material";

const query = graphql`
  query SwingScenesScreenQuery {
    ...SwingScenes
  }
`;

function ScreenContent({
  queryReference,
}: {
  queryReference: PreloadedQuery<SwingScenesScreenQuery>;
}) {
  const data = usePreloadedQuery<SwingScenesScreenQuery>(query, queryReference);
  return <SwingScenes swingScenesFragment={data} />;
}

export default function SwingScenesScreen() {
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<SwingScenesScreenQuery>(query);
  const navigate = useNavigate();
  useEffect(() => {
    loadQuery(
      {},
      {
        fetchPolicy: "network-only",
      }
    );
    return () => {
      disposeQuery();
    };
  }, [loadQuery, disposeQuery]);

  return (
    <ContentLayout title="スイング診断シーン一覧">
      <Suspense fallback={<Loading />}>
        {!isNil(queryReference) && (
          <>
            <Stack direction="row" justifyContent="flex-end">
              <Button onClick={() => navigate("/swing_scenes/new")}>
                新規作成
              </Button>
            </Stack>
            <Divider />
            <ScreenContent queryReference={queryReference} />
          </>
        )}
      </Suspense>
    </ContentLayout>
  );
}
