/**
 * @generated SignedSource<<f81b8b14e9e19fb1d9860583de7d7e79>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateSwingClassificationMutationInput = {
  clientMutationId?: string | null | undefined;
  id: string;
  needReview: boolean;
  secondary: boolean;
  swingFrameId: string;
};
export type SwingPositionClassificationItemMutation$variables = {
  input: UpdateSwingClassificationMutationInput;
};
export type SwingPositionClassificationItemMutation$data = {
  readonly updateSwingClassification: {
    readonly __typename: "MovieClip";
    readonly ballTrajectory: boolean;
    readonly commentContents: ReadonlyArray<{
      readonly content: string;
      readonly createdAt: any;
      readonly id: string;
      readonly user: {
        readonly avatar: {
          readonly signedUrl: string | null | undefined;
        } | null | undefined;
        readonly name: string;
      };
    }>;
    readonly id: string;
    readonly swingClassification: {
      readonly id: string;
      readonly needReview: boolean;
      readonly secondary: boolean;
      readonly swingFrame: {
        readonly id: string;
        readonly name: string;
      };
    } | null | undefined;
    readonly swingImage: {
      readonly id: string;
      readonly signedUrl: string | null | undefined;
    };
  } | {
    readonly __typename: "UserError";
    readonly message: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type SwingPositionClassificationItemMutation = {
  response: SwingPositionClassificationItemMutation$data;
  variables: SwingPositionClassificationItemMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ballTrajectory",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "signedUrl",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "SwingImage",
  "kind": "LinkedField",
  "name": "swingImage",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v5/*: any*/)
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "SwingClassification",
  "kind": "LinkedField",
  "name": "swingClassification",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "needReview",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "secondary",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SwingFrame",
      "kind": "LinkedField",
      "name": "swingFrame",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        (v7/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "content",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v11 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "type": "UserError",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SwingPositionClassificationItemMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "updateSwingClassification",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v6/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CommentContent",
                "kind": "LinkedField",
                "name": "commentContents",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Avatar",
                        "kind": "LinkedField",
                        "name": "avatar",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "MovieClip",
            "abstractKey": null
          },
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SwingPositionClassificationItemMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "updateSwingClassification",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v6/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CommentContent",
                "kind": "LinkedField",
                "name": "commentContents",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Avatar",
                        "kind": "LinkedField",
                        "name": "avatar",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "MovieClip",
            "abstractKey": null
          },
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1975be6cc19fa06cd4b59a265d7feaff",
    "id": null,
    "metadata": {},
    "name": "SwingPositionClassificationItemMutation",
    "operationKind": "mutation",
    "text": "mutation SwingPositionClassificationItemMutation(\n  $input: UpdateSwingClassificationMutationInput!\n) {\n  updateSwingClassification(input: $input) {\n    __typename\n    ... on MovieClip {\n      id\n      ballTrajectory\n      swingImage {\n        id\n        signedUrl\n      }\n      swingClassification {\n        id\n        needReview\n        secondary\n        swingFrame {\n          id\n          name\n        }\n      }\n      commentContents {\n        id\n        content\n        createdAt\n        user {\n          name\n          avatar {\n            signedUrl\n            id\n          }\n          id\n        }\n      }\n    }\n    ... on UserError {\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ed543884fbc3979ea5e72ed592d76fa6";

export default node;
