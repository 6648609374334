import { isNil } from "@lib/utils/commons";

export function decodeId(id: string): number | null {
  const decodedData = window.atob(id).split(":");
  if (decodedData.length > 1) {
    const decodedId = Number(decodedData[decodedData.length - 1]);
    if (!isNil(decodedId)) {
      return decodedId;
    }
  }
  return null;
}

export function encodeId(id: number | string, name: string): string {
  const encoded = btoa(`${name}:${id}`);
  return encoded;
}
