import { useCallback, useState } from "react";
import { Button, Stack } from "@mui/material";
import Loading from "@components/atoms/Loading";
import { useNavigate } from "react-router-dom";
import usePreventDoubleClick from "@lib/hooks/usePreventDoubleClick";
import useMessage from "@lib/hooks/useMessage";
import Message from "@components/molecules/Message";
import graphql from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import { MovieInReviewRemandData$key } from "@generated/MovieInReviewRemandData.graphql";
import {
  MovieInReviewRemandMutation,
  MovieInReviewRemandMutation$data,
} from "@generated/MovieInReviewRemandMutation.graphql";
import Colors from "@constants/Colors";

const query = graphql`
  fragment MovieInReviewRemandData on MovieMetainfoJob {
    id
  }
`;

const mutation = graphql`
  mutation MovieInReviewRemandMutation(
    $input: UpdateMovieMetainfoJobMutationInput!
  ) {
    updateMovieMetainfoJob(input: $input) {
      __typename
      ... on MovieMetainfoJob {
        id
      }
      ... on UserError {
        message
      }
    }
  }
`;

export default function MovieInReviewRemand({
  movieMetainfoFragment,
}: {
  movieMetainfoFragment: MovieInReviewRemandData$key;
}): JSX.Element {
  const navigate = useNavigate();
  const { processing, onClick, onRelease } = usePreventDoubleClick();
  const [, setMessage] = useMessage();
  const [confirm, setConfirm] = useState<boolean>(false);
  const { id } = useFragment(query, movieMetainfoFragment);
  const [commit] = useMutation<MovieInReviewRemandMutation>(mutation);
  const handleRemand = useCallback(async () => {
    if (processing) {
      return;
    }
    onClick();
    setConfirm(false);
    const result = await new Promise<
      MovieInReviewRemandMutation$data["updateMovieMetainfoJob"]
    >((resolve) => {
      commit({
        variables: {
          input: {
            id,
            workflow: "remand",
          },
        },
        onCompleted: ({ updateMovieMetainfoJob }) => {
          resolve(updateMovieMetainfoJob);
        },
      });
    });
    if (result.__typename === "MovieMetainfoJob") {
      setMessage({
        mode: "toast",
        title: "差戻しました",
      });
      navigate("/movie_in_reviews");
    } else {
      setMessage({
        mode: "error",
        title: "差戻せませんでした",
        subline: result.__typename === "UserError" ? result.message : "",
      });
      onRelease();
    }
  }, [commit, id, navigate, setMessage, processing, onClick, onRelease]);
  return (
    <>
      <Stack direction="row" justifyContent="center">
        <Button
          color="error"
          disabled={processing}
          onClick={() => setConfirm(true)}
          variant="contained"
        >
          ラベリングを差戻す
        </Button>
      </Stack>
      {processing && <Loading color={Colors.white} mask />}
      <Message
        closeText="キャンセル"
        confirmText="理解して、差戻す"
        message="この操作は取り消せません。よろしいですか？"
        onClose={() => setConfirm(false)}
        onConfirm={handleRemand}
        title="ラベリングを差戻しますか？"
        visible={confirm}
      />
    </>
  );
}
