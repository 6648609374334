import { Link } from "@mui/material";
import Spacer from "@components/atoms/Spacer";
import Text from "@components/atoms/Text";
import styled from "styled-components";
import { ErrorType } from "@lib/hooks/useExecptionalError";

export default function Fallback({ error }: { error?: ErrorType }) {
  return (
    <>
      <Spacer height={24} />
      <Spacer height={48} />
      <Content>
        <Message>
          <Text variant="h5">エラーが発生しました</Text>
          <Spacer height={24} />

          {error?.code === "NetworkError" ? (
            <Text>
              オフラインになっています。インターネット環境の良いところでページを再読み込みしてください。
            </Text>
          ) : error?.code === "NotFound" ? (
            <Text>ページが見つかりません</Text>
          ) : error?.message !== undefined ? (
            <Text>{error?.message}</Text>
          ) : (
            <Text>
              InfluencerWorksをご利用ありがとうございます。
              {"\n"}
              ただいまサービスに接続しにくい状況となっています。
              しばらく時間を開けてから「ページ再読み込み」を押してください。
              {"\n"}
              ご迷惑をおかけし申し訳ございませんが、よろしくお願いします。
            </Text>
          )}

          <Spacer height={24} />

          <Link href="/">HOMEに戻る</Link>
        </Message>
      </Content>
    </>
  );
}
const Content = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
`;

const Message = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  maxwidth: 700px;
`;
