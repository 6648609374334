/**
 * @generated SignedSource<<7168e3c68e8b88b0d8df5748ba2745be>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MovieMetainfoJobType = "AnnotateObject" | "LabelingSwing";
export type AnnotateObjectsScreenQuery$variables = {
  after?: string | null | undefined;
  first: number;
  type: MovieMetainfoJobType;
};
export type AnnotateObjectsScreenQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AnnotateObjectsPagination">;
};
export type AnnotateObjectsScreenQuery = {
  response: AnnotateObjectsScreenQuery$data;
  variables: AnnotateObjectsScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "type"
},
v3 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "type",
    "variableName": "type"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AnnotateObjectsScreenQuery",
    "selections": [
      {
        "args": (v3/*: any*/),
        "kind": "FragmentSpread",
        "name": "AnnotateObjectsPagination"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "AnnotateObjectsScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "MovieMetainfoJobConnection",
        "kind": "LinkedField",
        "name": "movieMetainfoJobs",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MovieMetainfoJobEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MovieMetainfoJob",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MovieMetainfo",
                    "kind": "LinkedField",
                    "name": "movieMetainfo",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "assignedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currentPage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "limit",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "filters": [
          "type"
        ],
        "handle": "connection",
        "key": "AnnotateObjects__movieMetainfoJobs",
        "kind": "LinkedHandle",
        "name": "movieMetainfoJobs"
      }
    ]
  },
  "params": {
    "cacheID": "9b1aef2ba041d1a0de5a92b145472b17",
    "id": null,
    "metadata": {},
    "name": "AnnotateObjectsScreenQuery",
    "operationKind": "query",
    "text": "query AnnotateObjectsScreenQuery(\n  $first: Int!\n  $after: String\n  $type: MovieMetainfoJobType!\n) {\n  ...AnnotateObjectsPagination_2VM0Du\n}\n\nfragment AnnotateObjectsPagination_2VM0Du on Query {\n  movieMetainfoJobs(first: $first, after: $after, type: $type) {\n    edges {\n      node {\n        id\n        movieMetainfo {\n          id\n        }\n        status\n        assignedAt\n        __typename\n      }\n      cursor\n    }\n    currentPage\n    totalCount\n    limit\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "febf9fac37fd655821eb524576ebc6ac";

export default node;
