import { useEffect, Suspense } from "react";
import ContentLayout from "@navigation/ContentLayout";
import SwingSceneCreate from "@components/templates/SwingSceneCreate";
import graphql from "babel-plugin-relay/macro";
import {
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import { isNil } from "@lib/utils/commons";
import Loading from "@components/atoms/Loading";
import { SwingSceneCreateScreenQuery } from "@generated/SwingSceneCreateScreenQuery.graphql";

const query = graphql`
  query SwingSceneCreateScreenQuery {
    ...SwingSceneCreate
  }
`;

function ScreenContent({
  queryReference,
}: {
  queryReference: PreloadedQuery<SwingSceneCreateScreenQuery>;
}): JSX.Element {
  const data = usePreloadedQuery<SwingSceneCreateScreenQuery>(
    query,
    queryReference
  );
  return <SwingSceneCreate swingFramesFragment={data} />;
}

export default function SwingSceneCreateScreen(): JSX.Element {
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<SwingSceneCreateScreenQuery>(query);
  useEffect(() => {
    loadQuery({});
    return () => {
      disposeQuery();
    };
  }, [loadQuery, disposeQuery]);
  return (
    <ContentLayout
      breadcrumbs={[
        {
          label: "診断シーン一覧",
          path: "/swing_scenes",
        },
      ]}
      title="診断シーン 作成"
    >
      <Suspense fallback={<Loading />}>
        {!isNil(queryReference) && (
          <ScreenContent queryReference={queryReference} />
        )}
      </Suspense>
    </ContentLayout>
  );
}
