import { useEffect, Suspense } from "react";
import ContentLayout from "@navigation/ContentLayout";
import ChangeProfile, { Mode } from "@components/templates/ChangeProfile";
import graphql from "babel-plugin-relay/macro";
import {
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import { isNil } from "@lib/utils/commons";
import Loading from "@components/atoms/Loading";
import { ChangeProfileScreenQuery } from "@generated/ChangeProfileScreenQuery.graphql";

const query = graphql`
  query ChangeProfileScreenQuery {
    admin {
      ...ChangeProfile
    }
  }
`;

function ScreenContent({
  queryReference,
  mode,
}: {
  queryReference: PreloadedQuery<ChangeProfileScreenQuery>;
  mode: Mode;
}): JSX.Element {
  const { admin } = usePreloadedQuery<ChangeProfileScreenQuery>(
    query,
    queryReference
  );
  return <ChangeProfile adminFragment={admin} mode={mode} />;
}

export default function ChangeProfileScreen({
  mode,
}: {
  mode: Mode;
}): JSX.Element {
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<ChangeProfileScreenQuery>(query);
  useEffect(() => {
    loadQuery({});
    return () => {
      disposeQuery();
    };
  }, [loadQuery, disposeQuery]);

  return (
    <ContentLayout title={`${mode === "email" ? "Email" : "パスワード"}変更`}>
      <Suspense fallback={<Loading />}>
        {!isNil(queryReference) && (
          <ScreenContent mode={mode} queryReference={queryReference} />
        )}
      </Suspense>
    </ContentLayout>
  );
}
