/**
 * @generated SignedSource<<0b32b07fe38211e4d55dd2acc3326d77>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SwingScenesScreenQuery$variables = Record<PropertyKey, never>;
export type SwingScenesScreenQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SwingScenes">;
};
export type SwingScenesScreenQuery = {
  response: SwingScenesScreenQuery$data;
  variables: SwingScenesScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SwingScenesScreenQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "SwingScenes"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SwingScenesScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SwingScene",
        "kind": "LinkedField",
        "name": "swingScenes",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SwingFrame",
            "kind": "LinkedField",
            "name": "swingFrame",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "angle",
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2e1475b0323d94fa236afe9ed31bea72",
    "id": null,
    "metadata": {},
    "name": "SwingScenesScreenQuery",
    "operationKind": "query",
    "text": "query SwingScenesScreenQuery {\n  ...SwingScenes\n}\n\nfragment SwingScenes on Query {\n  swingScenes {\n    id\n    title\n    swingFrame {\n      name\n      angle\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c80cae4dfde820c82d60332516bc662a";

export default node;
