import { Box, Stack } from "@mui/material";
import Text from "@components/atoms/Text";
import Thumbnail from "@components/atoms/Thumbnail";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { DrillStepData$key } from "@generated/DrillStepData.graphql";
import { isNil } from "@lib/utils/commons";

const query = graphql`
  fragment DrillStepData on DrillStep {
    content
    thumbnail {
      signedUrl
    }
  }
`;

export default function DrillStep({
  drillStepFragment,
  step,
}: {
  drillStepFragment: DrillStepData$key;
  step: number;
}) {
  const { content, thumbnail } = useFragment<DrillStepData$key>(
    query,
    drillStepFragment
  );
  return (
    <Stack direction="row" spacing={1}>
      {!isNil(thumbnail) && <Thumbnail size={300} url={thumbnail?.signedUrl} />}
      <Box>
        <Text bold variant="h6">
          Step{step}.
        </Text>
        <Text variant="h6">{content}</Text>
      </Box>
    </Stack>
  );
}
