import { useCallback, useState } from "react";
import { Stack, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import usePreventDoubleClick from "@lib/hooks/usePreventDoubleClick";
import useMessage from "@lib/hooks/useMessage";
import Message from "@components/molecules/Message";
import graphql from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import { MovieInReviewApproveData$key } from "@generated/MovieInReviewApproveData.graphql";
import {
  MovieInReviewApproveMutation,
  MovieInReviewApproveMutation$data,
} from "@generated/MovieInReviewApproveMutation.graphql";

const query = graphql`
  fragment MovieInReviewApproveData on MovieMetainfoJob {
    id
  }
`;

const mutation = graphql`
  mutation MovieInReviewApproveMutation(
    $input: UpdateMovieMetainfoJobMutationInput!
  ) {
    updateMovieMetainfoJob(input: $input) {
      __typename
      ... on MovieMetainfoJob {
        id
      }
      ... on UserError {
        message
      }
    }
  }
`;

export default function MovieInReviewApprove({
  movieMetainfoFragment,
}: {
  movieMetainfoFragment: MovieInReviewApproveData$key;
}): JSX.Element {
  const navigate = useNavigate();
  const { processing, onClick, onRelease } = usePreventDoubleClick();
  const [, setMessage] = useMessage();
  const [confirm, setConfirm] = useState<boolean>(false);
  const { id } = useFragment(query, movieMetainfoFragment);
  const [commit] = useMutation<MovieInReviewApproveMutation>(mutation);

  const handleApprove = useCallback(async () => {
    if (processing) {
      return;
    }
    onClick();
    const result = await new Promise<
      MovieInReviewApproveMutation$data["updateMovieMetainfoJob"]
    >((resolve) => {
      commit({
        variables: {
          input: {
            id,
            workflow: "approve",
          },
        },
        onCompleted: ({ updateMovieMetainfoJob }) => {
          resolve(updateMovieMetainfoJob);
        },
      });
    });
    if (result.__typename === "MovieMetainfoJob") {
      setMessage({
        mode: "toast",
        title: "承認しました",
      });
      navigate("/movie_in_reviews");
    } else {
      setMessage({
        mode: "error",
        title: "承認できませんでした",
        subline: result.__typename === "UserError" ? result.message : "",
      });
      onRelease();
    }
  }, [commit, id, navigate, setMessage, processing, onClick, onRelease]);
  return (
    <>
      <Stack direction="row" justifyContent="center">
        <Button
          disabled={processing}
          onClick={() => setConfirm(true)}
          variant="contained"
        >
          ラベリングを承認する
        </Button>
      </Stack>
      <Message
        closeText="キャンセル"
        confirmText="理解して、承認する"
        message="この操作は取り消せません。よろしいですか？"
        onClose={() => setConfirm(false)}
        onConfirm={handleApprove}
        title="ラベリングを承認しますか？"
        visible={confirm}
      />
    </>
  );
}
