import { Divider, Stack, Button } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { SwingFrameDisplay$key } from "@generated/SwingFrameDisplay.graphql";
import Content from "@components/atoms/Content";
import Thumbnail from "@components/atoms/Thumbnail";
import { SwingFrameAngle } from "@constants/App";
import { useNavigate } from "react-router-dom";
import Icon from "@components/atoms/Icon";
import Colors from "@constants/Colors";

const query = graphql`
  fragment SwingFrameDisplay on SwingFrame {
    id
    name
    description
    angle
    orderNumber
    thumbnail {
      signedUrl
    }
    swingPosition {
      name
    }
  }
`;

export default function SwingFrameDisplay({
  definitionFragment,
}: {
  definitionFragment: SwingFrameDisplay$key;
}): JSX.Element {
  const data = useFragment<SwingFrameDisplay$key>(query, definitionFragment);
  const navigate = useNavigate();
  return (
    <Stack direction="column" flex={1} spacing={1}>
      <Stack direction="row" justifyContent="flex-end" spacing={1}>
        <Button
          color="primary"
          onClick={() => navigate(`/swing_frames/${data.id}/movies`)}
          startIcon={<Icon color={Colors.white} name="image" size={18} />}
          variant="contained"
        >
          分類されたスイング写真を見る
        </Button>
      </Stack>
      <Content column="カメラアングル" value={SwingFrameAngle[data.angle]} />
      <Divider />
      <Content column="名称" value={data.name} />
      <Divider />
      <Thumbnail url={data.thumbnail?.signedUrl} />
      <Divider />
      <Content column="説明" value={data.description} />
      <Divider />
      <Content column="表示順(降順)" value={data.orderNumber} />
    </Stack>
  );
}
