/**
 * @generated SignedSource<<d8ab6d9a6427e917cd4580cf1c777438>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type UserType = "Admin" | "Annotater";
import { FragmentRefs } from "relay-runtime";
export type MemberDisplay$data = {
  readonly avatar: {
    readonly signedUrl: string | null | undefined;
  } | null | undefined;
  readonly createdAt: any;
  readonly email: string;
  readonly id: string;
  readonly name: string;
  readonly phoneNumber: string | null | undefined;
  readonly type: UserType;
  readonly " $fragmentType": "MemberDisplay";
};
export type MemberDisplay$key = {
  readonly " $data"?: MemberDisplay$data;
  readonly " $fragmentSpreads": FragmentRefs<"MemberDisplay">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MemberDisplay",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phoneNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Avatar",
      "kind": "LinkedField",
      "name": "avatar",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "signedUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "f4455c222ec5106195d9eb6f9f2d66e0";

export default node;
