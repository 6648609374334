import { Uploadable, UploadableMap } from "relay-runtime";

export function makeUploadablesFromFileList(
  objectPath: string,
  files: Uploadable[]
): UploadableMap {
  return Object.fromEntries<Uploadable>(
    Array.from(files, (file, i) => [`${objectPath}.${i}`, file])
  );
}

export function makeUploadablesFromFile(
  objectPath: string,
  file: Uploadable
): UploadableMap {
  const entries: [PropertyKey, Uploadable][] = [[objectPath, file]];
  return Object.fromEntries<Uploadable>(entries);
}
