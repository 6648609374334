/**
 * @generated SignedSource<<92c80a61634170ce30674ce57c6192a7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CauseScreenQuery$variables = {
  id: string;
};
export type CauseScreenQuery$data = {
  readonly cause: {
    readonly " $fragmentSpreads": FragmentRefs<"CauseData">;
  };
};
export type CauseScreenQuery = {
  response: CauseScreenQuery$data;
  variables: CauseScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CauseScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "cause",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CauseData"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CauseScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "cause",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "content",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CauseArchive",
                "kind": "LinkedField",
                "name": "causeArchives",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "oldId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "main",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SwingPosition",
                "kind": "LinkedField",
                "name": "swingPosition",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "LessonTemplate",
                "kind": "LinkedField",
                "name": "lessonTemplates",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "goal",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Cause",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ad99f363dd7a58ef08efc733b56d6cce",
    "id": null,
    "metadata": {},
    "name": "CauseScreenQuery",
    "operationKind": "query",
    "text": "query CauseScreenQuery(\n  $id: ID!\n) {\n  cause(id: $id) {\n    __typename\n    ...CauseData\n    ... on Cause {\n      id\n    }\n  }\n}\n\nfragment CauseData on Cause {\n  id\n  content\n  causeArchives {\n    oldId\n    main\n    id\n  }\n  swingPosition {\n    name\n    id\n  }\n  lessonTemplates {\n    id\n    goal\n  }\n}\n"
  }
};
})();

(node as any).hash = "6cf10ab65e469944960f75ca978d3da0";

export default node;
