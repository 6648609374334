import React from "react";
import { Stack } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { usePaginationFragment, useFragment } from "react-relay";
import { MovieInReviewsPagination$key } from "@generated/MovieInReviewsPagination.graphql";
import { MovieInReviewsAdmin$key } from "@generated/MovieInReviewsAdmin.graphql";
import OffsetPagination from "@components/atoms/OffsetPagination";
import MovieInReviewsCard from "@components/organisms/MovieInReviews/MovieInReviewsCard";
import Spacer from "@components/atoms/Spacer";

const query = graphql`
  fragment MovieInReviewsPagination on Query
  @refetchable(queryName: "MovieInReviewsPaginationQuery")
  @argumentDefinitions(
    after: { type: "String" }
    first: { type: "Int" }
    inReview: { type: "Boolean" }
  ) {
    movieMetainfos(first: $first, after: $after, inReview: $inReview)
      @connection(key: "MovieInReviewsList__movieMetainfos") {
      edges {
        node {
          id
          ...MovieInReviewsCard
        }
      }
      currentPage
      totalCount
      limit
    }
  }
`;

const adminQuery = graphql`
  fragment MovieInReviewsAdmin on User {
    ...MovieInReviewsCardAdmin
  }
`;

export default function MovieInReviews({
  movieFragment,
  adminFragment,
}: {
  movieFragment: MovieInReviewsPagination$key;
  adminFragment: MovieInReviewsAdmin$key;
}) {
  const { data } = usePaginationFragment(query, movieFragment);
  const admin = useFragment(adminQuery, adminFragment);
  const { edges, currentPage, limit, totalCount } = data.movieMetainfos;
  return (
    <>
      <Stack alignItems="flex-start" direction="row" flexWrap="wrap">
        {edges.map(({ node }) => (
          <React.Fragment key={node.id}>
            <MovieInReviewsCard adminFragment={admin} movieFragment={node} />
            <Spacer width={8} />
          </React.Fragment>
        ))}
      </Stack>
      <OffsetPagination
        currentPage={currentPage}
        limit={limit}
        path="movie_in_reviews"
        totalCount={totalCount}
      />
    </>
  );
}
