/**
 * @generated SignedSource<<728ae8ea83b0294a8fdafedd26175d00>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DrillStepData$data = {
  readonly content: string;
  readonly thumbnail: {
    readonly signedUrl: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "DrillStepData";
};
export type DrillStepData$key = {
  readonly " $data"?: DrillStepData$data;
  readonly " $fragmentSpreads": FragmentRefs<"DrillStepData">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DrillStepData",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Thumbnail",
      "kind": "LinkedField",
      "name": "thumbnail",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "signedUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DrillStep",
  "abstractKey": null
};

(node as any).hash = "87fe3068ffe6640eb33cc1aac7ba1919";

export default node;
