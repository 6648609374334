/**
 * @generated SignedSource<<ab0562ab467167e970bc17d2a79bfad2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DrillScreenQuery$variables = {
  id: string;
};
export type DrillScreenQuery$data = {
  readonly drill: {
    readonly " $fragmentSpreads": FragmentRefs<"DrillData">;
  };
};
export type DrillScreenQuery = {
  response: DrillScreenQuery$data;
  variables: DrillScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "signedUrl",
    "storageKey": null
  },
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Thumbnail",
  "kind": "LinkedField",
  "name": "thumbnail",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DrillScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "drill",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DrillData"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DrillScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "drill",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "club",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "implement",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tips",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "level",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "period",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "place",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "practiceMethod",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sideEffect",
                "storageKey": null
              },
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Video",
                "kind": "LinkedField",
                "name": "video",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SwingPosition",
                "kind": "LinkedField",
                "name": "swingPosition",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "DrillPoint",
                "kind": "LinkedField",
                "name": "drillPoints",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pointType",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "DrillStep",
                "kind": "LinkedField",
                "name": "drillSteps",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "content",
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "type": "Drill",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2a6c3881b203c6e2cc5f11c34e28e69d",
    "id": null,
    "metadata": {},
    "name": "DrillScreenQuery",
    "operationKind": "query",
    "text": "query DrillScreenQuery(\n  $id: ID!\n) {\n  drill(id: $id) {\n    __typename\n    ...DrillData\n    ... on Drill {\n      id\n    }\n  }\n}\n\nfragment DrillData on Drill {\n  ...DrillMainData\n  drillPoints {\n    id\n    ...DrillPointData\n  }\n  drillSteps {\n    id\n    ...DrillStepData\n  }\n}\n\nfragment DrillMainData on Drill {\n  club\n  title\n  description\n  implement\n  tips\n  level\n  period\n  place\n  practiceMethod\n  sideEffect\n  thumbnail {\n    signedUrl\n    id\n  }\n  video {\n    signedUrl\n    id\n  }\n  swingPosition {\n    name\n    id\n  }\n}\n\nfragment DrillPointData on DrillPoint {\n  pointType\n  title\n  thumbnail {\n    signedUrl\n    id\n  }\n}\n\nfragment DrillStepData on DrillStep {\n  content\n  thumbnail {\n    signedUrl\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "f1e83763697ca93764db9f871eefa393";

export default node;
