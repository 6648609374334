/**
 * @generated SignedSource<<33ed00f249ed07196779abb26d29ff87>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RemoveSwingFrameMutationInput = {
  clientMutationId?: string | null | undefined;
  id: string;
};
export type SwingFrameRemoveMutation$variables = {
  input: RemoveSwingFrameMutationInput;
};
export type SwingFrameRemoveMutation$data = {
  readonly removeSwingFrame: {
    readonly success: boolean;
  };
};
export type SwingFrameRemoveMutation = {
  response: SwingFrameRemoveMutation$data;
  variables: SwingFrameRemoveMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ProcessResult",
    "kind": "LinkedField",
    "name": "removeSwingFrame",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SwingFrameRemoveMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SwingFrameRemoveMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "41be8698f51a51018f2b2234fc9cee24",
    "id": null,
    "metadata": {},
    "name": "SwingFrameRemoveMutation",
    "operationKind": "mutation",
    "text": "mutation SwingFrameRemoveMutation(\n  $input: RemoveSwingFrameMutationInput!\n) {\n  removeSwingFrame(input: $input) {\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "b4fc1120342ed41b60ef370bae622af0";

export default node;
