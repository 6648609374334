import React from "react";
import {
  Stack,
  List,
  ListItemButton,
  ListItemText,
  Divider,
  Chip,
} from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { usePaginationFragment } from "react-relay";
import { CausesPagination$key } from "@generated/CausesPagination.graphql";
import OffsetPagination from "@components/atoms/OffsetPagination";
import { decodeId } from "@lib/utils/convertId";

const query = graphql`
  fragment CausesPagination on Query
  @refetchable(queryName: "CausesPaginationQuery")
  @argumentDefinitions(after: { type: "String" }, first: { type: "Int" }) {
    causes(first: $first, after: $after)
      @connection(key: "CausesList__causes") {
      edges {
        node {
          id
          content
          swingPosition {
            name
          }
          lessonTemplates {
            id
          }
        }
      }
      currentPage
      totalCount
      limit
    }
  }
`;

export default function Causes({
  causesFragment,
}: {
  causesFragment: CausesPagination$key;
}) {
  const { data } = usePaginationFragment(query, causesFragment);
  const { edges, currentPage, limit, totalCount } = data.causes;
  return (
    <>
      <List>
        {edges.map(({ node }) => (
          <React.Fragment key={node.id}>
            <ListItemButton component="a" href={`causes/${node.id}`}>
              <Stack alignItems="flex-start" direction="column">
                <Stack alignItems="flex-start" direction="row" spacing={1}>
                  <Chip label={`ID: ${decodeId(node.id)}`} variant="outlined" />
                  <Chip
                    color="primary"
                    label={node.swingPosition.name}
                    variant="outlined"
                  />
                  <Chip
                    color="success"
                    label={`テンプレート:${node.lessonTemplates.length}件`}
                    variant="outlined"
                  />
                </Stack>
                <ListItemText primary={node.content} />
              </Stack>
            </ListItemButton>
            <Divider />
          </React.Fragment>
        ))}
      </List>
      <OffsetPagination
        currentPage={currentPage}
        limit={limit}
        path="causes"
        totalCount={totalCount}
      />
    </>
  );
}
