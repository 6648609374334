// Note: ラベリングのrenderパフォーマンスが重いので、FragmentCollocationを停止する
import { useState } from "react";
import { Stack } from "@mui/material";
import Spacer from "@components/atoms/Spacer";
import SwingPositionClassificationChip, {
  SwingListItem,
} from "@components/molecules/SwingPositionClassification/SwingPositionClassificationChip";

export default function SwingPositionClassificationChips({
  disabled = false,
  swingPositions,
  selectedId,
  onSelect,
}: {
  disabled?: boolean;
  swingPositions: SwingListItem[];
  selectedId: string | null;
  onSelect: (id: string | null) => void;
}) {
  const [openSwingPositionId, setOpenSwingPositionId] = useState<string | null>(
    null
  );
  return (
    <Stack alignItems="flex-start" direction="row" flex={1} flexWrap="wrap">
      {swingPositions.map((swingPosition) => (
        <SwingPositionClassificationChip
          key={swingPosition.id}
          disabled={disabled}
          onOpenMenu={setOpenSwingPositionId}
          onSelect={onSelect}
          openSwingPositionId={openSwingPositionId}
          selectedId={selectedId}
          swingPosition={swingPosition}
        />
      ))}
      <Spacer height={8} />
    </Stack>
  );
}
