import { CardContent, CardMedia, CardHeader, Stack, Chip } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { CoachingsCard$key } from "@generated/CoachingsCard.graphql";
import Text from "@components/atoms/Text";
import Avatar from "@components/atoms/Avatar";
import { decodeId } from "@lib/utils/convertId";

const query = graphql`
  fragment CoachingsCard on Coaching {
    id
    thumbnail
    version
    name
    cause {
      content
      swingPosition {
        name
      }
    }
  }
`;

export default function CoachingsCard({
  movieFragment,
}: {
  movieFragment: CoachingsCard$key;
}) {
  const { id, cause, thumbnail, version, name } = useFragment(
    query,
    movieFragment
  );
  return (
    <>
      <CardHeader
        avatar={<Avatar size={32} url="" />}
        subheader={`ID: ${decodeId(id)}`}
        title={name}
      />
      <CardMedia component="img" height="250" image={thumbnail ?? ""} />
      <CardContent>
        <Stack direction="row" spacing={1}>
          <Chip
            color="primary"
            label={`ver${version}`}
            size="small"
            variant="outlined"
          />
          <Chip
            label={cause.swingPosition.name}
            size="small"
            variant="outlined"
          />
        </Stack>
        <Text variant="body2">{cause.content}</Text>
      </CardContent>
    </>
  );
}
