import React from "react";
import {
  Stack,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
} from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { ProgressData$key } from "@generated/ProgressData.graphql";
import ProgressWorker from "@components/organisms/Progress/ProgressWorker";
import ProgressFrame from "@components/organisms/Progress/ProgressFrame";
import ProgressObject from "@components/organisms/Progress/ProgressObject";
import Spacer from "@components/atoms/Spacer";

const query = graphql`
  fragment ProgressData on Progress {
    swingFrames {
      id
      ...ProgressFrame
    }
    users {
      id
      ...ProgressWorker
    }
    annotations {
      id
      ...ProgressObject
    }
  }
`;

export default function Progress({
  progressFragment,
}: {
  progressFragment: ProgressData$key;
}) {
  const { swingFrames, users, annotations } = useFragment(
    query,
    progressFragment
  );
  return (
    <Stack direction="column" spacing={2}>
      <Spacer height={24} />
      <Stack direction="row" flexWrap="wrap" justifyContent="flex-start">
        {users.map((user) => (
          <React.Fragment key={user.id}>
            <ProgressWorker progressFragment={user} />
            <Spacer width={8} />
          </React.Fragment>
        ))}
      </Stack>
      <Spacer height={24} />
      <Divider />
      <Table aria-label="simple table" sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell component="th">アングル</TableCell>
            <TableCell component="th">名前</TableCell>
            <TableCell component="th">ラベル件数</TableCell>
            <TableCell component="th">分類画像</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {swingFrames.map((swingFrame) => (
            <TableRow key={swingFrame.id}>
              <ProgressFrame progressFragment={swingFrame} />
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Spacer height={24} />
      <Divider />
      <Table aria-label="simple table" sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell component="th">オブジェクト</TableCell>
            <TableCell component="th">アノテーション件数</TableCell>
            <TableCell component="th">分類画像</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {annotations.map((annotation) => (
            <TableRow key={annotation.id}>
              <ProgressObject progressFragment={annotation} />
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Stack>
  );
}
