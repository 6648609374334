import {
  Box,
  DialogContent,
  Dialog,
  DialogTitle,
  IconButton,
} from "@mui/material";
import Icon from "@components/atoms/Icon";
import { useNavigate } from "react-router-dom";

export default function ModalLayout({
  title,
  children,
}: {
  title: string;
  children: JSX.Element;
}) {
  const navigate = useNavigate();
  const handleClose = () => {
    navigate(-1);
  };
  return (
    <Dialog fullWidth onClose={handleClose} open scroll="paper">
      <DialogTitle>{title}</DialogTitle>
      <Box
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <IconButton aria-label="close" onClick={handleClose}>
          <Icon name="close" size={24} />
        </IconButton>
      </Box>
      <DialogContent sx={{ padding: 2 }}>{children}</DialogContent>
    </Dialog>
  );
}
