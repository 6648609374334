/**
 * @generated SignedSource<<552435140400fbc01b5665cbb1a6a976>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SwingFrameInputSwingPositions$data = {
  readonly swingPositions: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly " $fragmentType": "SwingFrameInputSwingPositions";
};
export type SwingFrameInputSwingPositions$key = {
  readonly " $data"?: SwingFrameInputSwingPositions$data;
  readonly " $fragmentSpreads": FragmentRefs<"SwingFrameInputSwingPositions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SwingFrameInputSwingPositions",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SwingPosition",
      "kind": "LinkedField",
      "name": "swingPositions",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "7b5add2467b5efedcc43c391ead141d9";

export default node;
