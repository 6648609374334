import { useEffect, Suspense } from "react";
import ModalLayout from "@navigation/ModalLayout";
import CommentModal from "@components/templates/CommentModal";
import graphql from "babel-plugin-relay/macro";
import {
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import { isNil } from "@lib/utils/commons";
import Loading from "@components/atoms/Loading";
import { CommentModalScreenQuery } from "@generated/CommentModalScreenQuery.graphql";
import { PageLimit } from "@constants/App";
import useQueryId from "@lib/hooks/useQueryId";
import useReadComment from "@lib/hooks/useReadComment";

const query = graphql`
  query CommentModalScreenQuery($id: ID!, $first: Int!) {
    comment(id: $id) {
      id
      ...CommentModalContentPagination @arguments(first: $first)
    }
    admin {
      ...CommentModalAdmin
    }
  }
`;

function ScreenContent({
  queryReference,
}: {
  queryReference: PreloadedQuery<CommentModalScreenQuery>;
}): JSX.Element {
  const { admin, comment } = usePreloadedQuery<CommentModalScreenQuery>(
    query,
    queryReference
  );
  return <CommentModal adminFragment={admin} commentsFragment={comment} />;
}

export default function CommentModalScreen(): JSX.Element {
  const id = useQueryId();
  const { readComment } = useReadComment();
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<CommentModalScreenQuery>(query);
  useEffect(() => {
    loadQuery({ id, first: PageLimit });
    return () => {
      disposeQuery();
    };
  }, [id, loadQuery, disposeQuery]);
  useEffect(() => {
    if (!isNil(id)) {
      readComment(id);
    }
  }, [id, readComment]);
  return (
    <ModalLayout title="スレッド">
      <Suspense fallback={<Loading />}>
        {!isNil(queryReference) && (
          <ScreenContent queryReference={queryReference} />
        )}
      </Suspense>
    </ModalLayout>
  );
}
