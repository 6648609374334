/**
 * @generated SignedSource<<50db146f02ef0ee9cc5ee60d7559695d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DrawerLayoutQuery$variables = Record<PropertyKey, never>;
export type DrawerLayoutQuery$data = {
  readonly admin: {
    readonly " $fragmentSpreads": FragmentRefs<"NavigationHeaderAdmin">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"NavigationDrawerCounts">;
};
export type DrawerLayoutQuery = {
  response: DrawerLayoutQuery$data;
  variables: DrawerLayoutQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DrawerLayoutQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "admin",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "NavigationHeaderAdmin"
          }
        ],
        "storageKey": null
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "NavigationDrawerCounts"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DrawerLayoutQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "admin",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Avatar",
            "kind": "LinkedField",
            "name": "avatar",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "signedUrl",
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "NotificationCount",
        "kind": "LinkedField",
        "name": "notificationCount",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "inReviews",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "unreadComments",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7f963c5c3263b33333158d215f027726",
    "id": null,
    "metadata": {},
    "name": "DrawerLayoutQuery",
    "operationKind": "query",
    "text": "query DrawerLayoutQuery {\n  admin {\n    ...NavigationHeaderAdmin\n    id\n  }\n  ...NavigationDrawerCounts\n}\n\nfragment NavigationDrawerCounts on Query {\n  ...useNotificationCount\n}\n\nfragment NavigationHeaderAdmin on User {\n  id\n  name\n  email\n  avatar {\n    signedUrl\n    id\n  }\n}\n\nfragment useNotificationCount on Query {\n  notificationCount {\n    id\n    inReviews\n    unreadComments\n  }\n}\n"
  }
};
})();

(node as any).hash = "07f98b58cb5dc4e77398db3b97bada33";

export default node;
