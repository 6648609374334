/**
 * @generated SignedSource<<8875b9a27e42107f54e2454dc75b04c7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateTmpImageMutationInput = {
  ballTrajectory?: boolean | null | undefined;
  clientMutationId?: string | null | undefined;
  id: string;
  swingFrameId?: string | null | undefined;
};
export type LabelingSwingItemAddMutation$variables = {
  input: UpdateTmpImageMutationInput;
};
export type LabelingSwingItemAddMutation$data = {
  readonly updateTmpImage: {
    readonly __typename: "MovieJob";
    readonly id: string;
    readonly movieMetainfo: {
      readonly id: string;
      readonly movieClips: ReadonlyArray<{
        readonly ballTrajectory: boolean;
        readonly commentContents: ReadonlyArray<{
          readonly content: string;
          readonly createdAt: any;
          readonly id: string;
          readonly user: {
            readonly avatar: {
              readonly signedUrl: string | null | undefined;
            } | null | undefined;
            readonly name: string;
          };
        }>;
        readonly id: string;
        readonly swingClassification: {
          readonly id: string;
          readonly needReview: boolean;
          readonly secondary: boolean;
          readonly swingFrame: {
            readonly id: string;
            readonly name: string;
          };
        } | null | undefined;
        readonly swingImage: {
          readonly id: string;
          readonly signedUrl: string | null | undefined;
        };
      }>;
    };
    readonly tmpImages: ReadonlyArray<{
      readonly id: string;
      readonly movieClip: {
        readonly ballTrajectory: boolean;
        readonly id: string;
        readonly swingClassification: {
          readonly swingFrame: {
            readonly id: string;
          };
        } | null | undefined;
      } | null | undefined;
      readonly signedUrl: string;
    }>;
  } | {
    readonly __typename: "UserError";
    readonly message: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type LabelingSwingItemAddMutation = {
  response: LabelingSwingItemAddMutation$data;
  variables: LabelingSwingItemAddMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ballTrajectory",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "signedUrl",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "SwingImage",
  "kind": "LinkedField",
  "name": "swingImage",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v5/*: any*/)
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "SwingClassification",
  "kind": "LinkedField",
  "name": "swingClassification",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "needReview",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "secondary",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SwingFrame",
      "kind": "LinkedField",
      "name": "swingFrame",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        (v7/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "content",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "SwingFrame",
  "kind": "LinkedField",
  "name": "swingFrame",
  "plural": false,
  "selections": [
    (v3/*: any*/)
  ],
  "storageKey": null
},
v12 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "type": "UserError",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LabelingSwingItemAddMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "updateTmpImage",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "MovieMetainfo",
                "kind": "LinkedField",
                "name": "movieMetainfo",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MovieClip",
                    "kind": "LinkedField",
                    "name": "movieClips",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v6/*: any*/),
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CommentContent",
                        "kind": "LinkedField",
                        "name": "commentContents",
                        "plural": true,
                        "selections": [
                          (v3/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              (v7/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Avatar",
                                "kind": "LinkedField",
                                "name": "avatar",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TmpImage",
                "kind": "LinkedField",
                "name": "tmpImages",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MovieClip",
                    "kind": "LinkedField",
                    "name": "movieClip",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SwingClassification",
                        "kind": "LinkedField",
                        "name": "swingClassification",
                        "plural": false,
                        "selections": [
                          (v11/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "MovieJob",
            "abstractKey": null
          },
          (v12/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LabelingSwingItemAddMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "updateTmpImage",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "MovieMetainfo",
                "kind": "LinkedField",
                "name": "movieMetainfo",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MovieClip",
                    "kind": "LinkedField",
                    "name": "movieClips",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v6/*: any*/),
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CommentContent",
                        "kind": "LinkedField",
                        "name": "commentContents",
                        "plural": true,
                        "selections": [
                          (v3/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              (v7/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Avatar",
                                "kind": "LinkedField",
                                "name": "avatar",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/),
                                  (v3/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TmpImage",
                "kind": "LinkedField",
                "name": "tmpImages",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MovieClip",
                    "kind": "LinkedField",
                    "name": "movieClip",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SwingClassification",
                        "kind": "LinkedField",
                        "name": "swingClassification",
                        "plural": false,
                        "selections": [
                          (v11/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "MovieJob",
            "abstractKey": null
          },
          (v12/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4cd8cd28858bd45e7d157bb9780c4a75",
    "id": null,
    "metadata": {},
    "name": "LabelingSwingItemAddMutation",
    "operationKind": "mutation",
    "text": "mutation LabelingSwingItemAddMutation(\n  $input: UpdateTmpImageMutationInput!\n) {\n  updateTmpImage(input: $input) {\n    __typename\n    ... on MovieJob {\n      id\n      movieMetainfo {\n        id\n        movieClips {\n          id\n          ballTrajectory\n          swingImage {\n            id\n            signedUrl\n          }\n          swingClassification {\n            id\n            needReview\n            secondary\n            swingFrame {\n              id\n              name\n            }\n          }\n          commentContents {\n            id\n            content\n            createdAt\n            user {\n              name\n              avatar {\n                signedUrl\n                id\n              }\n              id\n            }\n          }\n        }\n      }\n      tmpImages {\n        id\n        signedUrl\n        movieClip {\n          id\n          ballTrajectory\n          swingClassification {\n            swingFrame {\n              id\n            }\n            id\n          }\n        }\n      }\n    }\n    ... on UserError {\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "dc85ec7c7e4865e6e8e81979199e3ded";

export default node;
