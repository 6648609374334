/**
 * @generated SignedSource<<13d82d75d67eb0228cb3c7e652171f1f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArticleData$data = {
  readonly articleSections: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"ArticleSectionData">;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleMainData">;
  readonly " $fragmentType": "ArticleData";
};
export type ArticleData$key = {
  readonly " $data"?: ArticleData$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleData">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArticleData",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleMainData"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ArticleSection",
      "kind": "LinkedField",
      "name": "articleSections",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ArticleSectionData"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "5705efd7b2a5562c17044f2d8eff23f3";

export default node;
