import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { useNavigate } from "react-router-dom";
import { SwingSceneCreate$key } from "@generated/SwingSceneCreate.graphql";
import SwingSceneInput from "@components/organisms/SwingScene/SwingSceneInput";

const query = graphql`
  fragment SwingSceneCreate on Query {
    ...SwingSceneInputSwingFrames
  }
`;

export default function SwingSceneCreate({
  swingFramesFragment,
}: {
  swingFramesFragment: SwingSceneCreate$key;
}): JSX.Element {
  const navigate = useNavigate();
  const swigFrames = useFragment<SwingSceneCreate$key>(
    query,
    swingFramesFragment
  );
  return (
    <SwingSceneInput
      input={{
        swingFrameId: "",
        title: "",
        draw: "",
        correctForm: "",
        incorrectForm: "",
        point: "",
        view: "",
        orderNumber: 0,
      }}
      onClose={() => navigate("/swing_scenes")}
      swingFramesFragment={swigFrames}
    />
  );
}
