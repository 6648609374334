/**
 * @generated SignedSource<<afb5a6ec5cf9c0d4aa4b4311d165eee0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MovieMetainfosSearchQuery$data = {
  readonly clubs: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly members: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly " $fragmentType": "MovieMetainfosSearchQuery";
};
export type MovieMetainfosSearchQuery$key = {
  readonly " $data"?: MovieMetainfosSearchQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"MovieMetainfosSearchQuery">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MovieMetainfosSearchQuery",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Club",
      "kind": "LinkedField",
      "name": "clubs",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "members",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "1d7200de6b78e1efe1eae10c54cb0f7e";

export default node;
