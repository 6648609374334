import { useState, useCallback, useMemo, useRef, useEffect } from "react";
import { Box, Stack, Divider, IconButton, Chip } from "@mui/material";
import styled from "styled-components";
import graphql from "babel-plugin-relay/macro";
import { ConnectionHandler } from "react-relay/hooks";
import { useFragment, useMutation } from "react-relay";
import { CommentItemData$key } from "@generated/CommentItemData.graphql";
import { CommentItemAdmin$key } from "@generated/CommentItemAdmin.graphql";
import {
  CommentItemMutation,
  CommentItemMutation$data,
} from "@generated/CommentItemMutation.graphql";
import { dateFormat } from "@lib/utils/date";
import Icon from "@components/atoms/Icon";
import Text from "@components/atoms/Text";
import Avatar from "@components/atoms/Avatar";
import Loading from "@components/atoms/Loading";
import Message from "@components/molecules/Message";
import useMessage from "@lib/hooks/useMessage";
import usePreventDoubleClick from "@lib/hooks/usePreventDoubleClick";
import HtmlText from "@components/atoms/HtmlText";
import { isNil } from "@lib/utils/commons";
import { decodeId } from "@lib/utils/convertId";
import BoundingBox from "@components/atoms/BoundingBox";
import { ObjectDetectionObjectType } from "@constants/App";

const query = graphql`
  fragment CommentItemData on CommentContent {
    id
    content
    createdAt
    comment {
      id
      movieMetainfo {
        direction
      }
    }
    user {
      id
      name
      avatar {
        signedUrl
      }
    }
    movieClip {
      id
      width
      height
      swingImage {
        id
        signedUrl
      }
      swingClassification {
        secondary
        swingFrame {
          name
        }
      }
    }
    objectDetection {
      id
      objectType
      xmin
      ymin
      xmax
      ymax
    }
  }
`;

const adminQuery = graphql`
  fragment CommentItemAdmin on User {
    id
  }
`;

const mutation = graphql`
  mutation CommentItemMutation(
    $input: RemoveCommentContentMutationInput!
    $connections: [ID!]!
  ) {
    removeCommentContent(input: $input) {
      __typename
      ... on CommentContent {
        id @deleteEdge(connections: $connections)
      }
      ... on UserError {
        message
      }
    }
  }
`;

type BoxSize = {
  width: number;
  height: number;
};

export default function CommentItem({
  commentFragment,
  adminFragment,
}: {
  commentFragment: CommentItemData$key;
  adminFragment: CommentItemAdmin$key;
}) {
  const [, setMessage] = useMessage();
  const [confirm, setConfirm] = useState<boolean>(false);
  const { id, content, createdAt, user, comment, movieClip, objectDetection } =
    useFragment(query, commentFragment);
  const admin = useFragment(adminQuery, adminFragment);
  const {
    movieMetainfo: { direction },
  } = comment;
  const { swingImage, swingClassification, width, height } = movieClip ?? {
    swingImage: null,
    swingClassification: null,
    width: null,
    height: null,
  };
  const [commit] = useMutation<CommentItemMutation>(mutation);
  const { processing, onClick, onRelease } = usePreventDoubleClick();
  const connectionID = ConnectionHandler.getConnectionID(
    comment.id,
    "Comment__commentContents"
  );
  const boxRef = useRef<HTMLImageElement>(null);
  const [boxSize, setBoxSize] = useState<BoxSize | null>(null);
  const boxStyle = useMemo(() => {
    const styles: { [index: string]: string } = { position: "relative" };
    if (!isNil(direction) && direction === "portrait") {
      styles.maxWidth = "300px";
    } else if (direction === "square") {
      styles.maxWidth = "300px";
      styles.maxHeight = "300px";
    } else {
      styles.maxHeight = "300px";
    }
    return styles;
  }, [direction]);

  useEffect(() => {
    if (boxRef !== null && !isNil(boxRef.current)) {
      setBoxSize({
        width: boxRef.current.clientWidth,
        height: boxRef.current.clientHeight,
      });
    }
  }, [boxRef]);
  const handleRemove = useCallback(async () => {
    if (processing || admin.id !== user.id) {
      return;
    }
    onClick();
    setConfirm(false);
    const result = await new Promise<
      CommentItemMutation$data["removeCommentContent"]
    >((resolve) => {
      commit({
        variables: {
          input: {
            id,
          },
          connections: [connectionID],
        },
        onCompleted: ({ removeCommentContent }) => {
          resolve(removeCommentContent);
        },
      });
    });
    if (result.__typename === "UserError") {
      setMessage({
        mode: "error",
        title: result.message,
      });
    }
    onRelease();
  }, [
    commit,
    id,
    connectionID,
    admin,
    user,
    setMessage,
    processing,
    onClick,
    onRelease,
  ]);

  return (
    <Stack direction="column" sx={{ width: "100%" }}>
      <Stack
        alignItems="flex-start"
        direction="row"
        justifyContent="flex-start"
        spacing={2}
      >
        <Stack direction="column" spacing={1}>
          <Avatar url={user.avatar?.signedUrl} />
        </Stack>
        <Stack direction="column" flex={1} spacing={1}>
          <Text style={{ fontSize: 10 }}>ID:{decodeId(id)}</Text>
          <Stack direction="row" spacing={1}>
            <Text style={{ fontSize: 10 }} variant="subtitle2">
              {user.name}
            </Text>
            <Text style={{ fontSize: 10 }} variant="subtitle2">
              {dateFormat(createdAt, "M月D日 HH:mm")}
            </Text>
          </Stack>
          <Divider />
          <Text>
            <HtmlText text={content} />
          </Text>
          {!isNil(movieClip) && !isNil(swingImage) && (
            <Stack direction="column">
              <Stack flex={1} sx={boxStyle}>
                <Image
                  key={swingImage.id}
                  ref={boxRef}
                  src={swingImage.signedUrl ?? ""}
                />
                {!isNil(objectDetection) &&
                  boxSize !== null &&
                  !isNil(width) &&
                  !isNil(height) && (
                    <BoundingBox
                      boundingBoxes={[
                        {
                          type: objectDetection.objectType,
                          xmin: objectDetection.xmin,
                          ymin: objectDetection.ymin,
                          xmax: objectDetection.xmax,
                          ymax: objectDetection.ymax,
                        },
                      ]}
                      boxSize={boxSize}
                      originalSize={{ width, height }}
                    />
                  )}
              </Stack>
              <Stack
                alignItems="flex-start"
                direction="row"
                flexWrap="wrap"
                justifyContent="flex-start"
              >
                <Box sx={{ padding: "2px" }}>
                  <Chip
                    label={`ID: ${decodeId(movieClip.id)}`}
                    size="small"
                    variant="outlined"
                  />
                </Box>

                {!isNil(swingClassification) && (
                  <>
                    <Box sx={{ padding: "2px" }}>
                      <Chip
                        color="primary"
                        label={swingClassification.swingFrame.name}
                        size="small"
                        variant="outlined"
                      />
                    </Box>

                    {swingClassification.secondary === true && (
                      <Box sx={{ padding: "2px" }}>
                        <Chip
                          color="success"
                          label="予備フラグ"
                          size="small"
                          variant="outlined"
                        />
                      </Box>
                    )}
                  </>
                )}
                {!isNil(objectDetection) && (
                  <Box sx={{ padding: "2px" }}>
                    <Chip
                      color="primary"
                      label={
                        ObjectDetectionObjectType[objectDetection.objectType]
                      }
                      size="small"
                      variant="outlined"
                    />
                  </Box>
                )}
              </Stack>
            </Stack>
          )}
        </Stack>
      </Stack>
      {admin.id === user.id && (
        <Stack direction="row" justifyContent="flex-end">
          <IconButton onClick={() => setConfirm(true)}>
            <Icon name="trash" size={16} />
          </IconButton>
        </Stack>
      )}
      <Message
        closeText="キャンセル"
        confirmText="削除する"
        message="この操作は取り消せません。よろしいですか？"
        onClose={() => setConfirm(false)}
        onConfirm={handleRemove}
        title="コメントを削除しますか？"
        visible={confirm}
      />
      {processing && <Loading mask />}
    </Stack>
  );
}

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
`;
