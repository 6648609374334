/**
 * @generated SignedSource<<4695275dd301090aaa65904ad2f54906>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SwingSceneSwingFrames$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SwingSceneInputSwingFrames">;
  readonly " $fragmentType": "SwingSceneSwingFrames";
};
export type SwingSceneSwingFrames$key = {
  readonly " $data"?: SwingSceneSwingFrames$data;
  readonly " $fragmentSpreads": FragmentRefs<"SwingSceneSwingFrames">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SwingSceneSwingFrames",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SwingSceneInputSwingFrames"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "fda6073ad9a1f2fb42f03eefc1225848";

export default node;
