import { useCallback, useMemo, useState } from "react";
import graphql from "babel-plugin-relay/macro";
import { Stack, Button } from "@mui/material";
import { useFragment, useMutation } from "react-relay";
import { object, string, mixed, number } from "@lib/utils/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Spacer from "@components/atoms/Spacer";
import { UploadableMap } from "relay-runtime";
import {
  Form,
  TextField,
  SelectField,
  FilePicker,
  ErrorText,
} from "@components/molecules/TextInput";
import useMessage from "@lib/hooks/useMessage";
import { resolveError } from "@lib/utils/error";
import {
  SwingFrameInputMutation,
  SwingFrameInputMutation$data,
  SwingFrameAngle,
} from "@generated/SwingFrameInputMutation.graphql";
import { SwingFrameInputSwingPositions$key } from "@generated/SwingFrameInputSwingPositions.graphql";
import { SwingFrameAngle as angles } from "@constants/App";
import { getKeys, isNil } from "@lib/utils/commons";

const query = graphql`
  fragment SwingFrameInputSwingPositions on Query {
    swingPositions {
      id
      name
    }
  }
`;

const mutation = graphql`
  mutation SwingFrameInputMutation($input: SaveSwingFrameMutationInput!) {
    saveSwingFrame(input: $input) {
      __typename
      ... on SwingFrame {
        id
        name
        description
        angle
        orderNumber
        thumbnail {
          id
          signedUrl
        }
        swingPosition {
          id
          name
        }
      }
      ... on UserError {
        message
      }
    }
  }
`;

type Input = {
  swingPositionId: string;
  name: string;
  description: string;
  angle: SwingFrameAngle;
  orderNumber: number;
};

type Params = {
  id?: string;
  swingPositionId: string;
  name: string;
  description: string;
  angle: SwingFrameAngle;
  orderNumber: number;
  thumbnail?: string | null;
};

const AngleOptions = getKeys(angles).map((key) => ({
  label: angles[key],
  value: key,
}));

export default function SwingFrameInput({
  input,
  onClose,
  swingPositionsFragment,
}: {
  input: Params;
  onClose: () => void;
  swingPositionsFragment: SwingFrameInputSwingPositions$key;
}) {
  const [file, setFile] = useState<UploadableMap | null>(null);
  const [commit] = useMutation<SwingFrameInputMutation>(mutation);
  const { swingPositions } = useFragment(query, swingPositionsFragment);
  const swingPositionOptions = useMemo(
    () =>
      swingPositions.map((swingPosition) => ({
        label: swingPosition.name,
        value: swingPosition.id,
      })),
    [swingPositions]
  );
  const [, setMessage] = useMessage();
  const {
    id,
    name,
    description,
    angle,
    swingPositionId,
    orderNumber,
    thumbnail,
  } = input;
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<Input>({
    defaultValues: {
      swingPositionId,
      name,
      description,
      angle,
      orderNumber,
    },
    mode: "all",
    resolver: yupResolver(
      object().shape({
        swingPositionId: string().required("選択してください"),
        name: string().default("").trim().required("入力してください"),
        description: string().default("").trim().required("入力してください"),
        angle: mixed<SwingFrameAngle>()
          .oneOf(getKeys(angles), "選択してください")
          .required("選択してくださいしてください"),
        orderNumber: number().required("入力してください"),
      })
    ),
  });
  const handleSave = useCallback(async () => {
    await handleSubmit(
      async ({
        name: inputName,
        description: inputDescription,
        angle: inputAngle,
        swingPositionId: inputSwingPositionId,
        orderNumber: inputOrderNumber,
      }: Input) => {
        try {
          const result = await new Promise<
            SwingFrameInputMutation$data["saveSwingFrame"]
          >((resolve) => {
            commit({
              variables: {
                input: {
                  id,
                  description: inputDescription,
                  name: inputName,
                  angle: inputAngle,
                  orderNumber: inputOrderNumber,
                  swingPositionId: inputSwingPositionId,
                  file: null,
                },
              },
              uploadables: file ?? undefined,
              onCompleted({ saveSwingFrame }) {
                resolve(saveSwingFrame);
              },
            });
          });
          if (result.__typename === "UserError") {
            setMessage({
              title: "保存できませんでした",
              subline: result.message,
              mode: "error",
            });
          } else {
            setMessage({
              title: "保存しました",
              mode: "toast",
            });
            onClose();
          }
        } catch (e: unknown) {
          setMessage({
            title: "保存できませんでした",
            subline:
              resolveError(e).message ?? "ログイン情報が正しくありません",
            mode: "error",
          });
        }
      }
    )();
  }, [commit, id, file, handleSubmit, setMessage, onClose]);

  return (
    <Form>
      <Stack direction="column" justifyContent="flex-start">
        <SelectField
          control={control}
          label="スイングポジション"
          name="swingPositionId"
          options={swingPositionOptions}
        />
        {!isValid && errors.swingPositionId !== undefined && (
          <ErrorText error={errors.swingPositionId.message} />
        )}
        <Spacer height={24} />
        <TextField control={control} label="名称" name="name" />
        {!isValid && errors.name !== undefined && (
          <ErrorText error={errors.name.message} />
        )}
        <Spacer height={24} />
        <TextField
          control={control}
          label="説明"
          name="description"
          numberOfLines={3}
        />
        {!isValid && errors.description !== undefined && (
          <ErrorText error={errors.description.message} />
        )}
        <Spacer height={24} />
        <SelectField
          control={control}
          label="アングル"
          name="angle"
          options={AngleOptions}
        />
        {!isValid && errors.angle !== undefined && (
          <ErrorText error={errors.angle.message} />
        )}
        <Spacer height={24} />
        <TextField
          control={control}
          label="表示順(降順)"
          name="orderNumber"
          type="numeric"
        />
        {!isValid && errors.orderNumber !== undefined && (
          <ErrorText error={errors.orderNumber.message} />
        )}
        <Spacer height={24} />
        <FilePicker defaultUrl={thumbnail} onChange={setFile} />
        <Spacer height={24} />
        <Stack direction="row" justifyContent="center" spacing={2}>
          <Button
            disabled={!isValid || (isNil(file) && isNil(thumbnail))}
            onClick={handleSave}
            variant="contained"
          >
            保存する
          </Button>
          <Button onClick={onClose} variant="outlined">
            戻る
          </Button>
        </Stack>
      </Stack>
    </Form>
  );
}
