/**
 * @generated SignedSource<<7906f53ee8efaff340b0b1b10efb8f06>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SwingPositionClassificationChat$data = {
  readonly comment: {
    readonly contentCount: number;
    readonly id: string;
  } | null | undefined;
  readonly id: string;
  readonly movieClips: ReadonlyArray<{
    readonly id: string;
  }>;
  readonly " $fragmentType": "SwingPositionClassificationChat";
};
export type SwingPositionClassificationChat$key = {
  readonly " $data"?: SwingPositionClassificationChat$data;
  readonly " $fragmentSpreads": FragmentRefs<"SwingPositionClassificationChat">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SwingPositionClassificationChat",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Comment",
      "kind": "LinkedField",
      "name": "comment",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "contentCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MovieClip",
      "kind": "LinkedField",
      "name": "movieClips",
      "plural": true,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "MovieMetainfo",
  "abstractKey": null
};
})();

(node as any).hash = "bba1fbff759cc2d2088bd611a7c3d0dc";

export default node;
