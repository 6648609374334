import { Divider, Stack } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { MemberDisplay$key } from "@generated/MemberDisplay.graphql";
import Content from "@components/atoms/Content";
import Thumbnail from "@components/atoms/Thumbnail";
import { dateFormat } from "@lib/utils/date";
import { decodeId } from "@lib/utils/convertId";
import { UserType } from "@constants/App";

const query = graphql`
  fragment MemberDisplay on User {
    id
    name
    email
    type
    phoneNumber
    createdAt
    avatar {
      signedUrl
    }
  }
`;

export default function MemberDisplay({
  userFragment,
}: {
  userFragment: MemberDisplay$key;
}): JSX.Element {
  const data = useFragment<MemberDisplay$key>(query, userFragment);
  return (
    <Stack direction="column" flex={1} spacing={1}>
      <Content column="ID" value={decodeId(data.id)} />
      <Divider />
      <Content column="名前" value={data.name} />
      <Divider />
      <Thumbnail url={data.avatar?.signedUrl} />
      <Divider />
      <Content column="種別" value={UserType[data.type]} />
      <Divider />
      <Content column="Email" value={data.email} />
      <Divider />
      <Content column="電話番号" value={data.phoneNumber} />
      <Divider />
      <Content
        column="作成日"
        value={dateFormat(data.createdAt, "YYYY年M月D日")}
      />
    </Stack>
  );
}
