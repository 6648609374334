/**
 * @generated SignedSource<<bf50780b9e251beea49179509de4d98b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MovieMetainfoJobStatus = "approved" | "generating" | "in_review" | "in_working" | "remanded" | "waiting";
export type MovieMetainfoJobType = "AnnotateObject" | "LabelingSwing";
import { FragmentRefs } from "relay-runtime";
export type MovieInReviewLabeling$data = {
  readonly id: string;
  readonly movieClips: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"SwingPositionClassificationItemClip">;
  }>;
  readonly movieMetainfoJobs: ReadonlyArray<{
    readonly id: string;
    readonly reviewer: {
      readonly id: string;
    } | null | undefined;
    readonly status: MovieMetainfoJobStatus;
    readonly type: MovieMetainfoJobType;
    readonly " $fragmentSpreads": FragmentRefs<"MovieInReviewApproveData" | "MovieInReviewRemandData">;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"SwingPositionClassificationChat" | "SwingPositionClassificationItemMeta" | "SwingPositionClassificationSummary">;
  readonly " $fragmentType": "MovieInReviewLabeling";
};
export type MovieInReviewLabeling$key = {
  readonly " $data"?: MovieInReviewLabeling$data;
  readonly " $fragmentSpreads": FragmentRefs<"MovieInReviewLabeling">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MovieInReviewLabeling",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "MovieMetainfoJob",
      "kind": "LinkedField",
      "name": "movieMetainfoJobs",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "reviewer",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "MovieInReviewRemandData"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "MovieInReviewApproveData"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SwingPositionClassificationSummary"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SwingPositionClassificationChat"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SwingPositionClassificationItemMeta"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MovieClip",
      "kind": "LinkedField",
      "name": "movieClips",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SwingPositionClassificationItemClip"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "MovieMetainfo",
  "abstractKey": null
};
})();

(node as any).hash = "6808c41e2e173845aef5fcda271a4fd4";

export default node;
