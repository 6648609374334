import React, { useMemo } from "react";
import { Divider, List, ListItemAvatar, ListItemButton } from "@mui/material";
import Text from "@components/atoms/Text";
import { SwingFrameAngle } from "@constants/App";
import { getKeys } from "@lib/utils/commons";
import Icon from "@components/atoms/Icon";
import Colors from "@constants/Colors";

const angles = getKeys(SwingFrameAngle).map((key) => ({
  value: key,
  label: SwingFrameAngle[key],
}));

type LinkType = {
  label: string;
  url: string;
};

export default function SwingFramesList(): JSX.Element {
  const links = useMemo<LinkType[]>(() => {
    const urls: LinkType[] = [];
    angles.forEach((angle) => {
      urls.push({
        label: angle.label,
        url: angle.value,
      });
    });
    return urls;
  }, []);
  return (
    <List>
      {links.map((link) => (
        <React.Fragment key={link.url}>
          <ListItemButton
            onClick={() =>
              window.open(
                `/definitions/${link.url}`,
                "_blank",
                "width=600,height=600"
              )
            }
          >
            <ListItemAvatar>
              <Icon color={Colors.blue} name="book" size={32} />
            </ListItemAvatar>
            <Text bold color={Colors.blue}>
              {link.label}
            </Text>
          </ListItemButton>
          <Divider />
        </React.Fragment>
      ))}
    </List>
  );
}
