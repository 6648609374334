import { useState } from "react";
import {
  Stack,
  Button,
  Divider,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { UserInvites$key } from "@generated/UserInvites.graphql";
import Icon from "@components/atoms/Icon";
import UserInviteCreate from "@components/organisms/UserInvites/UserInvitesCreate";
import UserInviteItem from "@components/organisms/UserInvites/UserInvitesItem";

const query = graphql`
  fragment UserInvites on Query {
    userInvites {
      id
      ...UserInvitesItem
    }
  }
`;

export default function UserInvites({
  invitesFragment,
}: {
  invitesFragment: UserInvites$key;
}) {
  const [open, setOpen] = useState<boolean>(false);
  const { userInvites } = useFragment(query, invitesFragment);
  return (
    <>
      <Stack direction="row" justifyContent="flex-end">
        <Button onClick={() => setOpen(true)}>新規作成</Button>
      </Stack>

      {userInvites.length > 0 && (
        <>
          <Divider />
          <Table aria-label="simple table" sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell component="th">ID</TableCell>
                <TableCell component="th">種別</TableCell>
                <TableCell component="th">Email</TableCell>
                <TableCell component="th">有効期限</TableCell>
                <TableCell component="th">操作</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userInvites.map((userInvite) => (
                <TableRow key={userInvite.id}>
                  <UserInviteItem inviteFragment={userInvite} />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      )}
      <Dialog onClose={() => setOpen(false)} open={open}>
        <DialogTitle>ユーザー招待</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <Icon name="close" size={24} />
        </IconButton>
        <DialogContent dividers>
          <UserInviteCreate />
        </DialogContent>
      </Dialog>
    </>
  );
}
