/**
 * @generated SignedSource<<93c4bf0e8d7521f2e2357d174a238a87>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MovieMetainfoAngle = "back" | "front";
import { FragmentRefs } from "relay-runtime";
export type LabelingSwingItemData$data = {
  readonly id: string;
  readonly movieMetainfo: {
    readonly angle: MovieMetainfoAngle | null | undefined;
  };
  readonly " $fragmentType": "LabelingSwingItemData";
};
export type LabelingSwingItemData$key = {
  readonly " $data"?: LabelingSwingItemData$data;
  readonly " $fragmentSpreads": FragmentRefs<"LabelingSwingItemData">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LabelingSwingItemData",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MovieMetainfo",
      "kind": "LinkedField",
      "name": "movieMetainfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "angle",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "MovieJob",
  "abstractKey": null
};

(node as any).hash = "47bd0dc78cb637cd3e9f1ea567211a06";

export default node;
