import { useCallback } from "react";
import { Stack, Button } from "@mui/material";
import { object, string } from "@lib/utils/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Spacer from "@components/atoms/Spacer";
import { PasswordField, ErrorText } from "@components/molecules/TextInput";
import { UpdateParam } from "@components/organisms/ChangeProfile/type";
import { PasswordPattern } from "@constants/App";

type Input = {
  password: string;
};

export default function ChangeProfilePassword({
  onChange,
}: {
  onChange: (data: UpdateParam) => Promise<void>;
}) {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<Input>({
    defaultValues: {
      password: "",
    },
    mode: "all",
    resolver: yupResolver(
      object().shape({
        password: string()
          .trim()
          .min(8, "8文字以上で入力してください")
          .matches(
            PasswordPattern,
            "半角英字、数字、記号を組み合わせてください"
          )
          .required("入力してください"),
      })
    ),
  });
  const handleUpdate = useCallback(async () => {
    await handleSubmit(async ({ password }: Input) => {
      await onChange({
        changeType: "password",
        changeValue: password,
      });
    })();
  }, [onChange, handleSubmit]);

  return (
    <Stack direction="column" justifyContent="flex-start">
      <PasswordField control={control} label="パスワード" name="password" />
      {!isValid && errors.password !== undefined && (
        <ErrorText error={errors.password.message} />
      )}
      <Spacer height={24} />

      <Stack alignItems="flex-start" direction="row">
        <Button disabled={!isValid} onClick={handleUpdate} variant="outlined">
          変更する
        </Button>
      </Stack>
    </Stack>
  );
}
