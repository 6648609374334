import { Stack } from "@mui/material";
import Text from "@components/atoms/Text";
import Thumbnail from "@components/atoms/Thumbnail";
import Spacer from "@components/atoms/Spacer";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import {
  ArticleContentData$key,
  ArticleContentData$data,
} from "@generated/ArticleContentData.graphql";
import { isNil } from "@lib/utils/commons";
import VideoDialog from "@components/atoms/VideoDialog";

const query = graphql`
  fragment ArticleContentData on ArticleContent {
    title
    content
    thumbnail {
      signedUrl
    }
    video {
      signedUrl
    }
  }
`;

function VideoAndThumbnail({
  thumbnail,
  video,
}: {
  thumbnail: ArticleContentData$data["thumbnail"];
  video: ArticleContentData$data["video"];
}) {
  if (isNil(thumbnail)) {
    return null;
  }
  if (isNil(video)) {
    return <Thumbnail size={500} url={thumbnail.signedUrl} />;
  }
  return (
    <VideoDialog
      thumbnailUrl={thumbnail.signedUrl}
      videoUrl={video.signedUrl}
    />
  );
}

export default function ArticleContent({
  articleContentFragment,
}: {
  articleContentFragment: ArticleContentData$key;
}) {
  const { title, content, thumbnail, video } =
    useFragment<ArticleContentData$key>(query, articleContentFragment);

  return (
    <Stack direction="column" flex={1}>
      <Text variant="h6">{title}</Text>
      <Text>{content}</Text>
      <VideoAndThumbnail thumbnail={thumbnail} video={video} />
      <Spacer height={16} />
    </Stack>
  );
}
