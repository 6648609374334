import { Stack, Box } from "@mui/material";
import Text from "@components/atoms/Text";
import ExpandImage from "@components/atoms/ExpandImage";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { CoachingImage$key } from "@generated/CoachingImage.graphql";

const query = graphql`
  fragment CoachingImage on CoachingImage {
    content
    title
    swingImage {
      signedUrl
    }
  }
`;

export default function CoachingImage({
  coachingFragment,
}: {
  coachingFragment: CoachingImage$key;
}) {
  const { title, content, swingImage } = useFragment<CoachingImage$key>(
    query,
    coachingFragment
  );
  return (
    <Stack direction="column" flex={1} spacing={1}>
      <Text bold variant="h2">
        {title}
      </Text>
      <Stack
        alignItems="flex-start"
        direction="row"
        justifyContent="flex-start"
        spacing={2}
      >
        <Box>
          <ExpandImage src={swingImage?.signedUrl} />
        </Box>
        <Stack flex={1}>
          <Text>{content}</Text>
        </Stack>
      </Stack>
    </Stack>
  );
}
