import { useEffect, Suspense } from "react";
import ContentLayout from "@navigation/ContentLayout";
import UserInvites from "@components/templates/UserInvites";
import graphql from "babel-plugin-relay/macro";
import {
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import { isNil } from "@lib/utils/commons";
import Loading from "@components/atoms/Loading";
import { UserInvitesScreenQuery } from "@generated/UserInvitesScreenQuery.graphql";

const query = graphql`
  query UserInvitesScreenQuery {
    ...UserInvites
  }
`;

function ScreenContent({
  queryReference,
}: {
  queryReference: PreloadedQuery<UserInvitesScreenQuery>;
}) {
  const data = usePreloadedQuery<UserInvitesScreenQuery>(query, queryReference);
  return <UserInvites invitesFragment={data} />;
}

export default function UserInvitesScreen() {
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<UserInvitesScreenQuery>(query);

  useEffect(() => {
    loadQuery(
      {},
      {
        fetchPolicy: "network-only",
      }
    );
    return () => {
      disposeQuery();
    };
  }, [loadQuery, disposeQuery]);

  return (
    <ContentLayout title="ユーザー招待">
      <Suspense fallback={<Loading />}>
        {!isNil(queryReference) && (
          <ScreenContent queryReference={queryReference} />
        )}
      </Suspense>
    </ContentLayout>
  );
}
