/**
 * @generated SignedSource<<13757551f09e15d02358f664bb0699c9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProgressWorker$data = {
  readonly annotatedCount: number;
  readonly avatar: {
    readonly signedUrl: string | null | undefined;
  } | null | undefined;
  readonly classifiedCount: number;
  readonly inReviewCount: number;
  readonly lastAnnotatedCount: number;
  readonly lastClassifiedCount: number;
  readonly name: string;
  readonly reviewedCount: number;
  readonly " $fragmentType": "ProgressWorker";
};
export type ProgressWorker$key = {
  readonly " $data"?: ProgressWorker$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProgressWorker">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProgressWorker",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Avatar",
      "kind": "LinkedField",
      "name": "avatar",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "signedUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "classifiedCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastClassifiedCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "annotatedCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastAnnotatedCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "inReviewCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reviewedCount",
      "storageKey": null
    }
  ],
  "type": "ProgressUser",
  "abstractKey": null
};

(node as any).hash = "c7af861a13ef911368bc90e96ed2cda6";

export default node;
