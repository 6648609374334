/**
 * @generated SignedSource<<2297ac9ad2c073ec19bb0535e42ea15a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type VerifyUserChangeType = "email" | "invite" | "password";
export type CreateVerifyAdminMutationInput = {
  changeType: VerifyUserChangeType;
  changeValue: string;
  clientMutationId?: string | null | undefined;
};
export type ChangeProfileUpdateMutation$variables = {
  input: CreateVerifyAdminMutationInput;
};
export type ChangeProfileUpdateMutation$data = {
  readonly createVerifyAdmin: {
    readonly message: string | null | undefined;
    readonly success: boolean;
  };
};
export type ChangeProfileUpdateMutation = {
  response: ChangeProfileUpdateMutation$data;
  variables: ChangeProfileUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ProcessResult",
    "kind": "LinkedField",
    "name": "createVerifyAdmin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChangeProfileUpdateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChangeProfileUpdateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7e83abd464eb9cfa46312c2c707226aa",
    "id": null,
    "metadata": {},
    "name": "ChangeProfileUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation ChangeProfileUpdateMutation(\n  $input: CreateVerifyAdminMutationInput!\n) {\n  createVerifyAdmin(input: $input) {\n    message\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "5e3746377e63db58313663013f4ff228";

export default node;
