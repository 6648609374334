import { useCallback, useState } from "react";
import { Button } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import Loading from "@components/atoms/Loading";
import Message from "@components/molecules/Message";
import { useNavigate } from "react-router-dom";
import useMessage from "@lib/hooks/useMessage";
import usePreventDoubleClick from "@lib/hooks/usePreventDoubleClick";
import {
  SwingFrameRemoveMutation,
  SwingFrameRemoveMutation$data,
} from "@generated/SwingFrameRemoveMutation.graphql";
import { SwingFrameRemove$key } from "@generated/SwingFrameRemove.graphql";
import Colors from "@constants/Colors";

const query = graphql`
  fragment SwingFrameRemove on SwingFrame {
    id
  }
`;

const mutation = graphql`
  mutation SwingFrameRemoveMutation($input: RemoveSwingFrameMutationInput!) {
    removeSwingFrame(input: $input) {
      success
    }
  }
`;

export default function SwingFrameRemove({
  definitionFragment,
}: {
  definitionFragment: SwingFrameRemove$key;
}) {
  const navigate = useNavigate();
  const [, setMessage] = useMessage();
  const [confirm, setConfirm] = useState<boolean>(false);
  const { id } = useFragment(query, definitionFragment);
  const [commit] = useMutation<SwingFrameRemoveMutation>(mutation);
  const { processing, onClick, onRelease } = usePreventDoubleClick();
  const handleRemove = useCallback(async () => {
    if (processing) {
      return;
    }
    onClick();
    const result = await new Promise<
      SwingFrameRemoveMutation$data["removeSwingFrame"]
    >((resolve) => {
      commit({
        variables: {
          input: {
            id,
          },
        },
        onCompleted: ({ removeSwingFrame }) => {
          resolve(removeSwingFrame);
        },
      });
    });
    if (result.success === false) {
      setMessage({
        mode: "error",
        title: "削除できませんでした",
      });
      onRelease();
    } else {
      setMessage({
        mode: "toast",
        title: "削除しました",
      });
      navigate("/swing_frames");
    }
  }, [commit, id, setMessage, processing, onClick, onRelease, navigate]);

  return (
    <>
      <Button color="error" onClick={() => setConfirm(true)}>
        削除する
      </Button>
      <Message
        closeText="キャンセル"
        confirmText="削除する"
        message="この操作は取り消せません。よろしいですか？"
        onClose={() => setConfirm(false)}
        onConfirm={handleRemove}
        title="スイング定義を削除しますか？"
        visible={confirm}
      />
      {processing && <Loading color={Colors.white} mask />}
    </>
  );
}
