// See. https://mui.com/material-ui/react-typography/
import { Typography, TypographyTypeMap } from "@mui/material";

export default function Text({
  children,
  color,
  variant = "body1",
  align = "inherit",
  bold = false,
  noWrap = false,
  style,
}: {
  children: React.ReactNode;
  color?: string;
  variant?: TypographyTypeMap["props"]["variant"];
  align?: TypographyTypeMap["props"]["align"];
  bold?: boolean;
  noWrap?: boolean;
  style?: React.CSSProperties;
}) {
  return (
    <Typography
      align={align}
      color={color}
      sx={{
        ...style,
        fontWeight: bold ? 800 : 400,
        whiteSpace: noWrap ? "nowrap" : "normal",
      }}
      variant={variant}
    >
      {children}
    </Typography>
  );
}
