import { useEffect, Suspense } from "react";
import ContentLayout from "@navigation/ContentLayout";
import Cause from "@components/templates/Cause";
import graphql from "babel-plugin-relay/macro";
import {
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import { isNil } from "@lib/utils/commons";
import Loading from "@components/atoms/Loading";
import { CauseScreenQuery } from "@generated/CauseScreenQuery.graphql";
import useQueryId from "@lib/hooks/useQueryId";

const query = graphql`
  query CauseScreenQuery($id: ID!) {
    cause(id: $id) {
      ...CauseData
    }
  }
`;

function ScreenContent({
  queryReference,
}: {
  queryReference: PreloadedQuery<CauseScreenQuery>;
}): JSX.Element {
  const { cause } = usePreloadedQuery<CauseScreenQuery>(query, queryReference);
  return <Cause causeFragment={cause} />;
}

export default function CauseScreen(): JSX.Element {
  const id = useQueryId();
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<CauseScreenQuery>(query);
  useEffect(() => {
    loadQuery({ id });
    return () => {
      disposeQuery();
    };
  }, [id, loadQuery, disposeQuery]);

  return (
    <ContentLayout
      breadcrumbs={[{ label: "スイング課題一覧", path: "/causes" }]}
      title="スイング課題詳細"
    >
      <Suspense fallback={<Loading />}>
        {!isNil(queryReference) && (
          <ScreenContent queryReference={queryReference} />
        )}
      </Suspense>
    </ContentLayout>
  );
}
