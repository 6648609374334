import { useCallback, useMemo, useState } from "react";
import graphql from "babel-plugin-relay/macro";
import { Stack, Button, Divider } from "@mui/material";
import { useFragment, useMutation } from "react-relay";
import { object, string, number } from "@lib/utils/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Spacer from "@components/atoms/Spacer";
import { UploadableMap, Uploadable } from "relay-runtime";
import {
  Form,
  TextField,
  SelectField,
  FilePicker,
  ErrorText,
} from "@components/molecules/TextInput";
import useMessage from "@lib/hooks/useMessage";
import { resolveError } from "@lib/utils/error";
import {
  SwingSceneInputMutation,
  SwingSceneInputMutation$data,
} from "@generated/SwingSceneInputMutation.graphql";
import { SwingSceneInputSwingFrames$key } from "@generated/SwingSceneInputSwingFrames.graphql";
import { isNil } from "@lib/utils/commons";
import { SwingFrameAngle } from "@constants/App";

const query = graphql`
  fragment SwingSceneInputSwingFrames on Query {
    swingFrames {
      id
      name
      angle
    }
  }
`;

const mutation = graphql`
  mutation SwingSceneInputMutation($input: SaveSwingSceneMutationInput!) {
    saveSwingScene(input: $input) {
      __typename
      ... on SwingScene {
        id
        title
        draw
        correctForm
        incorrectForm
        point
        view
        orderNumber
        thumbnail {
          signedUrl
        }
        video {
          signedUrl
        }
        swingFrame {
          name
          angle
        }
      }
      ... on UserError {
        message
      }
    }
  }
`;

type Input = {
  swingFrameId: string;
  title: string;
  draw: string;
  correctForm: string;
  incorrectForm: string;
  point: string;
  view: string;
  orderNumber: number;
};

type Params = {
  id?: string;
  swingFrameId: string;
  title: string;
  draw: string;
  correctForm: string;
  incorrectForm: string;
  point: string;
  view: string;
  orderNumber: number;
  thumbnailUrl?: string | null;
  videoUrl?: string | null;
};

export default function SwingSceneInput({
  input,
  onClose,
  swingFramesFragment,
}: {
  input: Params;
  onClose: () => void;
  swingFramesFragment: SwingSceneInputSwingFrames$key;
}) {
  const [image, setImage] = useState<UploadableMap | null>(null);
  const [video, setVideo] = useState<UploadableMap | null>(null);
  const [commit] = useMutation<SwingSceneInputMutation>(mutation);
  const { swingFrames } = useFragment(query, swingFramesFragment);
  const swingFrameOptions = useMemo(
    () =>
      swingFrames.map((swingFrame) => ({
        label: `${swingFrame.name}(${SwingFrameAngle[swingFrame.angle]})`,
        value: swingFrame.id,
      })),
    [swingFrames]
  );
  const [, setMessage] = useMessage();
  const {
    id,
    title,
    draw,
    correctForm,
    incorrectForm,
    point,
    view,
    swingFrameId,
    orderNumber,
    thumbnailUrl,
    videoUrl,
  } = input;

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<Input>({
    defaultValues: {
      title,
      draw,
      correctForm,
      incorrectForm,
      point,
      view,
      swingFrameId,
      orderNumber,
    },
    mode: "all",
    resolver: yupResolver(
      object().shape({
        swingFrameId: string().required("選択してください"),
        title: string().default("").trim().required("入力してください"),
        draw: string().default("").trim().required("入力してください"),
        correctForm: string().default("").trim().required("入力してください"),
        incorrectForm: string().default("").trim().required("入力してください"),
        point: string().default("").trim().required("入力してください"),
        view: string().default("").trim().required("入力してください"),
        orderNumber: number().required("入力してください"),
      })
    ),
  });
  const handleSave = useCallback(async () => {
    await handleSubmit(
      async ({
        title: inputTitle,
        draw: inputDraw,
        correctForm: inputCorrectForm,
        incorrectForm: inputIncorrectForm,
        point: inputPoint,
        view: inputView,
        swingFrameId: inputSwingFrameId,
        orderNumber: inputOrderNumber,
      }: Input) => {
        const uploadables: {
          [index: string]: Uploadable;
        } = {};
        if (image !== null) {
          uploadables["variables.input.thumbnail"] =
            image["variables.input.file"];
        }
        if (video !== null) {
          uploadables["variables.input.video"] = video["variables.input.file"];
        }
        try {
          const result = await new Promise<
            SwingSceneInputMutation$data["saveSwingScene"]
          >((resolve) => {
            commit({
              variables: {
                input: {
                  id,
                  title: inputTitle,
                  draw: inputDraw,
                  correctForm: inputCorrectForm,
                  incorrectForm: inputIncorrectForm,
                  point: inputPoint,
                  view: inputView,
                  swingFrameId: inputSwingFrameId,
                  orderNumber: inputOrderNumber,
                  thumbnail: null,
                  video: null,
                },
              },
              uploadables,
              onCompleted({ saveSwingScene }) {
                resolve(saveSwingScene);
              },
            });
          });
          if (result.__typename === "UserError") {
            setMessage({
              title: "保存できませんでした",
              subline: result.message,
              mode: "error",
            });
          } else {
            setMessage({
              title: "保存しました",
              mode: "toast",
            });
            onClose();
          }
        } catch (e: unknown) {
          setMessage({
            title: "保存できませんでした",
            subline:
              resolveError(e).message ?? "ログイン情報が正しくありません",
            mode: "error",
          });
        }
      }
    )();
  }, [commit, id, image, video, handleSubmit, setMessage, onClose]);

  return (
    <Form>
      <Stack direction="column" justifyContent="flex-start">
        <SelectField
          control={control}
          label="スイング定義"
          name="swingFrameId"
          options={swingFrameOptions}
        />
        {!isValid && errors.swingFrameId !== undefined && (
          <ErrorText error={errors.swingFrameId.message} />
        )}
        <Spacer height={24} />
        <TextField control={control} label="タイトル" name="title" />
        {!isValid && errors.title !== undefined && (
          <ErrorText error={errors.title.message} />
        )}
        <Spacer height={24} />
        <TextField control={control} label="線の引き方" name="draw" />
        {!isValid && errors.draw !== undefined && (
          <ErrorText error={errors.draw.message} />
        )}
        <Spacer height={24} />
        <TextField
          control={control}
          label="正しいフォーム"
          name="correctForm"
        />
        {!isValid && errors.correctForm !== undefined && (
          <ErrorText error={errors.correctForm.message} />
        )}
        <Spacer height={24} />
        <TextField
          control={control}
          label="間違ったフォーム"
          name="incorrectForm"
        />
        {!isValid && errors.incorrectForm !== undefined && (
          <ErrorText error={errors.incorrectForm.message} />
        )}
        <Spacer height={24} />
        <TextField control={control} label="診断ポイント" name="point" />
        {!isValid && errors.point !== undefined && (
          <ErrorText error={errors.point.message} />
        )}
        <Spacer height={24} />
        <TextField control={control} label="スイングの見方" name="view" />
        {!isValid && errors.view !== undefined && (
          <ErrorText error={errors.view.message} />
        )}
        <Spacer height={24} />
        <TextField
          control={control}
          label="表示順(降順)"
          name="orderNumber"
          type="numeric"
        />
        {!isValid && errors.orderNumber !== undefined && (
          <ErrorText error={errors.orderNumber.message} />
        )}
        <Spacer height={24} />
        <FilePicker
          content="写真を選択"
          defaultUrl={thumbnailUrl}
          onChange={setImage}
          type="image"
        />
        <Spacer height={24} />
        <FilePicker
          content="動画を選択"
          defaultUrl={videoUrl}
          onChange={setVideo}
          type="video"
        />
        <Spacer height={24} />
        <Divider />
        <Spacer height={24} />
        <Stack direction="row" justifyContent="center" spacing={2}>
          <Button
            disabled={!isValid || (isNil(image) && isNil(thumbnailUrl))}
            onClick={handleSave}
            variant="contained"
          >
            保存する
          </Button>
          <Button onClick={onClose} variant="outlined">
            戻る
          </Button>
        </Stack>
        <Spacer height={18} />
      </Stack>
    </Form>
  );
}
