import { Card, Stack, CardContent } from "@mui/material";
import Content from "@components/atoms/Content";
import Avatar from "@components/atoms/Avatar";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import Spacer from "@components/atoms/Spacer";
import Text from "@components/atoms/Text";
import { ProgressWorker$key } from "@generated/ProgressWorker.graphql";

const query = graphql`
  fragment ProgressWorker on ProgressUser {
    name
    avatar {
      signedUrl
    }
    classifiedCount
    lastClassifiedCount
    annotatedCount
    lastAnnotatedCount
    inReviewCount
    reviewedCount
  }
`;

export default function ProgressWorker({
  progressFragment,
}: {
  progressFragment: ProgressWorker$key;
}) {
  const {
    name,
    avatar,
    classifiedCount,
    lastClassifiedCount,
    annotatedCount,
    lastAnnotatedCount,
    inReviewCount,
    reviewedCount,
  } = useFragment<ProgressWorker$key>(query, progressFragment);
  return (
    <Card sx={{ minWidth: 300, marginBottom: 1 }}>
      <Stack direction="column" spacing={1}>
        <Stack alignItems="center" direction="row" justifyContent="center">
          <Avatar size={64} url={avatar?.signedUrl} />
        </Stack>
        <Stack alignItems="center" direction="row" justifyContent="center">
          <Text>{name}</Text>
        </Stack>
      </Stack>
      <CardContent>
        <Content column="ラベリング総数" value={`${classifiedCount}件`} />
        <Spacer height={8} />
        <Content column="アノテーション総数" value={`${annotatedCount}件`} />
        <Spacer height={8} />
        <Content
          column="ラベリング直近7日"
          value={`${lastClassifiedCount}件`}
        />
        <Spacer height={8} />
        <Content
          column="アノテーション直近7日"
          value={`${lastAnnotatedCount}件`}
        />
        <Spacer height={8} />
        <Content column="レビュー数" value={`${reviewedCount}件`} />
        <Spacer height={8} />
        <Content column="レビュー待ち" value={`${inReviewCount}件`} />
      </CardContent>
    </Card>
  );
}
