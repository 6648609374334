import { Stack, Divider } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { SwingSceneDisplay$key } from "@generated/SwingSceneDisplay.graphql";
import Content from "@components/atoms/Content";
import VideoDialog from "@components/atoms/VideoDialog";

const query = graphql`
  fragment SwingSceneDisplay on SwingScene {
    id
    title
    draw
    correctForm
    incorrectForm
    point
    view
    orderNumber
    thumbnail {
      signedUrl
    }
    video {
      signedUrl
    }
    swingFrame {
      name
      angle
    }
  }
`;

export default function SwingSceneDisplay({
  swingSceneFragment,
}: {
  swingSceneFragment: SwingSceneDisplay$key;
}): JSX.Element {
  const data = useFragment<SwingSceneDisplay$key>(query, swingSceneFragment);
  return (
    <Stack direction="column" flex={1} spacing={1}>
      <Content column="スイングポジション" value={data.swingFrame.name} />
      <Divider />
      <Content column="名称" value={data.title} />
      <Divider />
      <VideoDialog
        thumbnailSize={500}
        thumbnailUrl={data.thumbnail?.signedUrl}
        videoUrl={data.video?.signedUrl}
      />
      <Divider />
      <Content column="線の引き方" value={data.draw} />
      <Divider />
      <Content column="正しいフォーム" value={data.correctForm} />
      <Divider />
      <Content column="間違ったフォーム" value={data.incorrectForm} />
      <Divider />
      <Content column="診断ポイント" value={data.point} />
      <Divider />
      <Content column="スイングの見方" value={data.view} />
      <Divider />
      <Content column="表示順(降順)" value={data.orderNumber} />
    </Stack>
  );
}
