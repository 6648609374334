import React from "react";
import { Stack, Box, Tab, Tabs } from "@mui/material";
import { useNavigate } from "react-router-dom";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { MovieMetainfoData$key } from "@generated/MovieMetainfoData.graphql";
import { MovieMetainfoQuery$key } from "@generated/MovieMetainfoQuery.graphql";
import Spacer from "@components/atoms/Spacer";
import MovieMetainfoLabels from "@components/organisms/MovieMetainfo/MovieMetainfoLabels";
import MovieMetainfoDetection from "@components/organisms/MovieMetainfo/MovieMetainfoDetection";

const query = graphql`
  fragment MovieMetainfoData on MovieMetainfo {
    id
    ...MovieMetainfoDetection
    ...MovieMetainfoLabelsData
  }
`;

const masterQuery = graphql`
  fragment MovieMetainfoQuery on Query {
    ...MovieMetainfoLabelsQuery
  }
`;

export type TabType = "detection" | "labels";

export default function MovieMetainfo({
  movieMetainfoFragment,
  masterFragment,
  initialTab,
}: {
  movieMetainfoFragment: MovieMetainfoData$key;
  masterFragment: MovieMetainfoQuery$key;
  initialTab: TabType;
}): JSX.Element {
  const navigate = useNavigate();
  const movieMetainfo = useFragment<MovieMetainfoData$key>(
    query,
    movieMetainfoFragment
  );
  const { id } = movieMetainfo;
  const master = useFragment<MovieMetainfoQuery$key>(
    masterQuery,
    masterFragment
  );
  const handleChange = (
    event: React.SyntheticEvent,
    newValue: "labels" | "detection"
  ) => {
    navigate(`/movie_metainfos/${id}/${newValue}`);
    event.preventDefault();
  };
  return (
    <>
      <Stack direction="row">
        <Stack flex={1}>
          <Tabs onChange={handleChange} value={initialTab}>
            <Tab label="ラベリング" value="labels" />
            <Tab label="物体検出" value="detection" />
          </Tabs>
        </Stack>
      </Stack>
      <Spacer height={16} />
      <Box>
        {initialTab === "labels" ? (
          <MovieMetainfoLabels
            masterFragment={master}
            movieMetainfoFragment={movieMetainfo}
          />
        ) : (
          <MovieMetainfoDetection movieMetainfoFragment={movieMetainfo} />
        )}
      </Box>
    </>
  );
}
