/**
 * @generated SignedSource<<4844aeb24399f236f38fb4b5b0ab782c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MovieJobStatus = "closing" | "done" | "generating" | "ignored" | "in_working" | "waiting";
export type UpdateMovieJobMutationInput = {
  clientMutationId?: string | null | undefined;
  id: string;
  ignoreReason?: string | null | undefined;
  status: MovieJobStatus;
};
export type LabelingSwingIgnoreMutation$variables = {
  input: UpdateMovieJobMutationInput;
};
export type LabelingSwingIgnoreMutation$data = {
  readonly updateMovieJob: {
    readonly __typename: "MovieJob";
    readonly id: string;
    readonly ignoreReason: string | null | undefined;
    readonly status: MovieJobStatus;
  } | {
    readonly __typename: "UserError";
    readonly message: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type LabelingSwingIgnoreMutation = {
  response: LabelingSwingIgnoreMutation$data;
  variables: LabelingSwingIgnoreMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "updateMovieJob",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ignoreReason",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          }
        ],
        "type": "MovieJob",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "type": "UserError",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LabelingSwingIgnoreMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LabelingSwingIgnoreMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "823c0d6881ad997ce7adc119f7c83f3d",
    "id": null,
    "metadata": {},
    "name": "LabelingSwingIgnoreMutation",
    "operationKind": "mutation",
    "text": "mutation LabelingSwingIgnoreMutation(\n  $input: UpdateMovieJobMutationInput!\n) {\n  updateMovieJob(input: $input) {\n    __typename\n    ... on MovieJob {\n      id\n      ignoreReason\n      status\n    }\n    ... on UserError {\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "31a8c847e89a449ca19b915e15a4bd80";

export default node;
