import { useEffect, Suspense } from "react";
import { Tab, Tabs } from "@mui/material";
import ContentLayout from "@navigation/ContentLayout";
import LabelingSwing, { TabType } from "@components/templates/LabelingSwing";
import { useNavigate } from "react-router-dom";
import graphql from "babel-plugin-relay/macro";
import {
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import { isNil } from "@lib/utils/commons";
import Loading from "@components/atoms/Loading";
import { LabelingSwingScreenQuery } from "@generated/LabelingSwingScreenQuery.graphql";
import useQueryId from "@lib/hooks/useQueryId";
import Spacer from "@components/atoms/Spacer";

const query = graphql`
  query LabelingSwingScreenQuery($id: ID!) {
    movieMetainfoJob(id: $id) {
      movieJob {
        ...LabelingSwing
      }
    }
    ...LabelingSwingQuery
  }
`;

function ScreenContent({
  queryReference,
  tab,
}: {
  queryReference: PreloadedQuery<LabelingSwingScreenQuery>;
  tab: TabType;
}) {
  const data = usePreloadedQuery<LabelingSwingScreenQuery>(
    query,
    queryReference
  );
  const { movieMetainfoJob } = data;
  const { movieJob } = movieMetainfoJob;
  return (
    <LabelingSwing
      movieJobFragment={movieJob}
      swingsFragment={data}
      tab={tab}
    />
  );
}

export default function LabelingSwingScreen({ tab }: { tab: TabType }) {
  const id = useQueryId();
  const navigate = useNavigate();
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<LabelingSwingScreenQuery>(query);
  useEffect(() => {
    loadQuery(
      { id },
      {
        fetchPolicy: "network-only",
      }
    );
    return () => {
      disposeQuery();
    };
  }, [id, loadQuery, disposeQuery]);

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: "working" | "confirm"
  ) => {
    navigate(
      `/labeling_swings/${id}/${newValue === "confirm" ? "confirm" : ""}`
    );
    event.preventDefault();
  };

  return (
    <ContentLayout
      breadcrumbs={[
        { label: "ラベリングタスク一覧", path: "/labeling_swings" },
      ]}
      title="ラベリングタスク"
    >
      <Suspense fallback={<Loading />}>
        {!isNil(queryReference) && (
          <>
            <Tabs onChange={handleChange} value={tab}>
              <Tab label="作業中" value="working" />
              <Tab label="確認中" value="confirm" />
            </Tabs>
            <Spacer height={16} />
            <ScreenContent queryReference={queryReference} tab={tab} />
          </>
        )}
      </Suspense>
    </ContentLayout>
  );
}
