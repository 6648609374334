/**
 * @generated SignedSource<<2ff3c35bafd1e0d5810178cb9cd573f6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MovieJobsIgnoredSwingQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"MovieJobsIgnoredItemSwingQuery">;
  readonly " $fragmentType": "MovieJobsIgnoredSwingQuery";
};
export type MovieJobsIgnoredSwingQuery$key = {
  readonly " $data"?: MovieJobsIgnoredSwingQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"MovieJobsIgnoredSwingQuery">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MovieJobsIgnoredSwingQuery",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MovieJobsIgnoredItemSwingQuery"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "681f400cbed3fc4821b6a1d34cb50381";

export default node;
