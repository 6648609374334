import React, { useEffect, useMemo, useRef, useState } from "react";
import { Stack, Divider, List, ListItem, ListItemAvatar } from "@mui/material";
import Text from "@components/atoms/Text";
import Icon from "@components/atoms/Icon";
import Colors, { BoundingBoxColors } from "@constants/Colors";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { ObjectDetectionsRow$key } from "@generated/ObjectDetectionsRow.graphql";
import styled from "styled-components";
import { isNil } from "@lib/utils/commons";
import BoundingBox from "@components/atoms/BoundingBox";
import { ObjectDetectionObjectType } from "@constants/App";
import { decodeId } from "@lib/utils/convertId";
import Avatar from "@components/atoms/Avatar";
import { dateFormat } from "@lib/utils/date";

const query = graphql`
  fragment ObjectDetectionsRow on ObjectDetection {
    id
    objectType
    xmin
    ymin
    xmax
    ymax
    movieClip {
      width
      height
      swingImage {
        id
        signedUrl
      }
    }
    movieMetainfo {
      direction
    }
    commentContents {
      id
      content
      createdAt
      user {
        name
        avatar {
          signedUrl
        }
      }
    }
  }
`;

type BoxSize = {
  width: number;
  height: number;
};

export default function ObjectDetectionsRow({
  objectDetectionFragment,
}: {
  objectDetectionFragment: ObjectDetectionsRow$key;
}) {
  const {
    id,
    objectType,
    xmin,
    ymin,
    xmax,
    ymax,
    movieClip,
    movieMetainfo,
    commentContents,
  } = useFragment<ObjectDetectionsRow$key>(query, objectDetectionFragment);
  const { width, height, swingImage } = movieClip;

  const boxRef = useRef<HTMLImageElement>(null);
  const [boxSize, setBoxSize] = useState<BoxSize | null>(null);
  const boxStyle = useMemo(() => {
    const styles: { [index: string]: string } = { position: "relative" };
    if (
      !isNil(movieMetainfo.direction) &&
      movieMetainfo.direction === "portrait"
    ) {
      styles.maxWidth = "400px";
    } else if (movieMetainfo.direction === "square") {
      styles.maxWidth = "400px";
      styles.maxHeight = "400px";
    } else {
      styles.maxHeight = "400px";
    }
    return styles;
  }, [movieMetainfo.direction]);

  useEffect(() => {
    if (boxRef !== null && !isNil(boxRef.current)) {
      setBoxSize({
        width: boxRef.current.clientWidth,
        height: boxRef.current.clientHeight,
      });
    }
  }, [boxRef]);

  return (
    <Stack
      alignItems="flex-start"
      direction="row"
      flex={1}
      justifyContent="flex-start"
      spacing={2}
    >
      <Stack flex={1} sx={boxStyle}>
        <Image
          key={swingImage.id}
          ref={boxRef}
          src={swingImage.signedUrl ?? ""}
        />
        {boxSize !== null && !isNil(width) && !isNil(height) && (
          <BoundingBox
            boundingBoxes={[
              {
                type: objectType,
                xmin,
                ymin,
                xmax,
                ymax,
              },
            ]}
            boxSize={boxSize}
            originalSize={{ width, height }}
          />
        )}
      </Stack>
      <Stack
        alignItems="flex-start"
        direction="column"
        spacing={2}
        sx={{ width: "300px" }}
      >
        <Text>ID: {decodeId(id)}</Text>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="center"
          spacing={1}
        >
          <Icon color={BoundingBoxColors[objectType]} name="square" size={20} />
          <Text>{ObjectDetectionObjectType[objectType]}</Text>
        </Stack>
        {commentContents.length > 0 && (
          <List>
            {commentContents.map((row) => (
              <React.Fragment key={row.id}>
                <Divider />
                <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    <Avatar size={24} url={row.user.avatar?.signedUrl} />
                  </ListItemAvatar>
                  <Stack flex={1}>
                    <Text style={{ fontSize: 12 }}>ID:{decodeId(row.id)}</Text>
                    <Text style={{ fontSize: 12 }}>{row.content}</Text>
                    <Text
                      color={Colors.gray}
                      style={{ fontSize: 12 }}
                    >{`${dateFormat(row.createdAt, "M月D日 HH:mm")} ${row.user.name}`}</Text>
                  </Stack>
                </ListItem>
              </React.Fragment>
            ))}
          </List>
        )}
      </Stack>
    </Stack>
  );
}

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
`;
