/**
 * @generated SignedSource<<b242df0b60c10379f4018b26195aa985>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SwingFrameAngle = "back" | "front";
import { FragmentRefs } from "relay-runtime";
export type SwingFramesView$data = {
  readonly swingFrames: ReadonlyArray<{
    readonly angle: SwingFrameAngle;
    readonly description: string;
    readonly id: string;
    readonly name: string;
    readonly thumbnail: {
      readonly signedUrl: string | null | undefined;
    } | null | undefined;
  }>;
  readonly " $fragmentType": "SwingFramesView";
};
export type SwingFramesView$key = {
  readonly " $data"?: SwingFramesView$data;
  readonly " $fragmentSpreads": FragmentRefs<"SwingFramesView">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "angle"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SwingFramesView",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "angle",
          "variableName": "angle"
        }
      ],
      "concreteType": "SwingFrame",
      "kind": "LinkedField",
      "name": "swingFrames",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "angle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Thumbnail",
          "kind": "LinkedField",
          "name": "thumbnail",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "signedUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "7263de3fc21ac99e67ff211f3b801c75";

export default node;
