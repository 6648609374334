import { Stack, Divider, Chip, List, ListItemButton } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { CauseData$key } from "@generated/CauseData.graphql";
import Content from "@components/atoms/Content";
import { decodeId } from "@lib/utils/convertId";
import { useNavigate } from "react-router-dom";
import Text from "@components/atoms/Text";
import Colors from "@constants/Colors";

const query = graphql`
  fragment CauseData on Cause {
    id
    content
    causeArchives {
      oldId
      main
    }
    swingPosition {
      name
    }
    lessonTemplates {
      id
      goal
    }
  }
`;

export default function Cause({
  causeFragment,
}: {
  causeFragment: CauseData$key;
}): JSX.Element {
  const { id, content, swingPosition, lessonTemplates, causeArchives } =
    useFragment<CauseData$key>(query, causeFragment);
  const navigate = useNavigate();
  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="column" spacing={1}>
        <Content column="ID" value={decodeId(id)} />
        <Content column="名称" value={content} />
        <Content column="スイングポジション" value={swingPosition.name} />
      </Stack>
      <Divider />
      <Stack direction="row" spacing={1}>
        <Stack sx={{ width: 150 }}>
          <Text bold>旧課題ID</Text>
        </Stack>
        <Stack direction="row" flex={1} spacing={1}>
          {causeArchives.map((causeArchive) => (
            <Chip
              key={causeArchive.oldId}
              color={causeArchive.main === true ? "primary" : "default"}
              label={causeArchive.oldId}
              variant="outlined"
            />
          ))}
        </Stack>
      </Stack>
      <Divider />
      {lessonTemplates.length > 0 ? (
        <Stack direction="column" spacing={1}>
          <Stack alignItems="flex-start">
            <Chip
              label={`診断テンプレート: ${lessonTemplates.length}件`}
              size="small"
              variant="outlined"
            />
          </Stack>
          <List>
            {lessonTemplates.map((lessonTemplate) => (
              <ListItemButton
                key={lessonTemplate.id}
                onClick={() =>
                  navigate(`/lesson_templates/${lessonTemplate.id}`)
                }
              >
                <Text color={Colors.blue}>{lessonTemplate.goal}</Text>
              </ListItemButton>
            ))}
          </List>
        </Stack>
      ) : (
        <Text>診断テンプレートがありません</Text>
      )}
    </Stack>
  );
}
