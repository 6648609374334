/**
 * @generated SignedSource<<6761d4c50ebf5938aaca90f77f9a3033>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SwingFrameAngle = "back" | "front";
export type SaveSwingSceneMutationInput = {
  clientMutationId?: string | null | undefined;
  correctForm: string;
  draw: string;
  id?: string | null | undefined;
  incorrectForm: string;
  orderNumber: number;
  point: string;
  swingFrameId: string;
  thumbnail?: any | null | undefined;
  title: string;
  video?: any | null | undefined;
  view: string;
};
export type SwingSceneInputMutation$variables = {
  input: SaveSwingSceneMutationInput;
};
export type SwingSceneInputMutation$data = {
  readonly saveSwingScene: {
    readonly __typename: "SwingScene";
    readonly correctForm: string;
    readonly draw: string;
    readonly id: string;
    readonly incorrectForm: string;
    readonly orderNumber: number;
    readonly point: string;
    readonly swingFrame: {
      readonly angle: SwingFrameAngle;
      readonly name: string;
    };
    readonly thumbnail: {
      readonly signedUrl: string | null | undefined;
    } | null | undefined;
    readonly title: string;
    readonly video: {
      readonly signedUrl: string | null | undefined;
    } | null | undefined;
    readonly view: string;
  } | {
    readonly __typename: "UserError";
    readonly message: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type SwingSceneInputMutation = {
  response: SwingSceneInputMutation$data;
  variables: SwingSceneInputMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "draw",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "correctForm",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "incorrectForm",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "point",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "view",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "orderNumber",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "signedUrl",
  "storageKey": null
},
v12 = [
  (v11/*: any*/)
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "angle",
  "storageKey": null
},
v15 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "type": "UserError",
  "abstractKey": null
},
v16 = [
  (v11/*: any*/),
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SwingSceneInputMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "saveSwingScene",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Thumbnail",
                "kind": "LinkedField",
                "name": "thumbnail",
                "plural": false,
                "selections": (v12/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Thumbnail",
                "kind": "LinkedField",
                "name": "video",
                "plural": false,
                "selections": (v12/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SwingFrame",
                "kind": "LinkedField",
                "name": "swingFrame",
                "plural": false,
                "selections": [
                  (v13/*: any*/),
                  (v14/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "SwingScene",
            "abstractKey": null
          },
          (v15/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SwingSceneInputMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "saveSwingScene",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Thumbnail",
                "kind": "LinkedField",
                "name": "thumbnail",
                "plural": false,
                "selections": (v16/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Thumbnail",
                "kind": "LinkedField",
                "name": "video",
                "plural": false,
                "selections": (v16/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SwingFrame",
                "kind": "LinkedField",
                "name": "swingFrame",
                "plural": false,
                "selections": [
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "SwingScene",
            "abstractKey": null
          },
          (v15/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "60d1ee4fd1480cfb5410358668c91a89",
    "id": null,
    "metadata": {},
    "name": "SwingSceneInputMutation",
    "operationKind": "mutation",
    "text": "mutation SwingSceneInputMutation(\n  $input: SaveSwingSceneMutationInput!\n) {\n  saveSwingScene(input: $input) {\n    __typename\n    ... on SwingScene {\n      id\n      title\n      draw\n      correctForm\n      incorrectForm\n      point\n      view\n      orderNumber\n      thumbnail {\n        signedUrl\n        id\n      }\n      video {\n        signedUrl\n        id\n      }\n      swingFrame {\n        name\n        angle\n        id\n      }\n    }\n    ... on UserError {\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "06efe16d5ca35c036cd7bdc257ffed2b";

export default node;
