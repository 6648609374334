import { useEffect, Suspense } from "react";
import ContentLayout from "@navigation/ContentLayout";
import SwingFrames from "@components/templates/SwingFrames";
import graphql from "babel-plugin-relay/macro";
import {
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import { useNavigate } from "react-router-dom";
import { isNil } from "@lib/utils/commons";
import Loading from "@components/atoms/Loading";
import { SwingFramesScreenQuery } from "@generated/SwingFramesScreenQuery.graphql";
import { Button, Divider, Stack } from "@mui/material";

const query = graphql`
  query SwingFramesScreenQuery {
    ...SwingFrames
  }
`;

function ScreenContent({
  queryReference,
}: {
  queryReference: PreloadedQuery<SwingFramesScreenQuery>;
}) {
  const data = usePreloadedQuery<SwingFramesScreenQuery>(query, queryReference);
  return <SwingFrames definitionsFragment={data} />;
}

export default function SwingFramesScreen() {
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<SwingFramesScreenQuery>(query);
  const navigate = useNavigate();
  useEffect(() => {
    loadQuery(
      {},
      {
        fetchPolicy: "network-only",
      }
    );
    return () => {
      disposeQuery();
    };
  }, [loadQuery, disposeQuery]);

  return (
    <ContentLayout title="スイングフレーム定義一覧">
      <Suspense fallback={<Loading />}>
        {!isNil(queryReference) && (
          <>
            <Stack direction="row" justifyContent="flex-end">
              <Button onClick={() => navigate("/swing_frames/new")}>
                新規作成
              </Button>
            </Stack>
            <Divider />
            <ScreenContent queryReference={queryReference} />
          </>
        )}
      </Suspense>
    </ContentLayout>
  );
}
