import { useEffect, Suspense } from "react";
import ContentLayout from "@navigation/ContentLayout";
import Profile from "@components/templates/Profile";
import graphql from "babel-plugin-relay/macro";
import {
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import { isNil } from "@lib/utils/commons";
import Loading from "@components/atoms/Loading";
import { ProfileScreenQuery } from "@generated/ProfileScreenQuery.graphql";

const query = graphql`
  query ProfileScreenQuery {
    admin {
      ...ProfileData
    }
  }
`;

function ScreenContent({
  queryReference,
}: {
  queryReference: PreloadedQuery<ProfileScreenQuery>;
}): JSX.Element {
  const { admin } = usePreloadedQuery<ProfileScreenQuery>(
    query,
    queryReference
  );
  return <Profile adminFragment={admin} />;
}

export default function ProfileScreen(): JSX.Element {
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<ProfileScreenQuery>(query);
  useEffect(() => {
    loadQuery({});
    return () => {
      disposeQuery();
    };
  }, [loadQuery, disposeQuery]);

  return (
    <ContentLayout title="アカウント管理">
      <Suspense fallback={<Loading />}>
        {!isNil(queryReference) && (
          <ScreenContent queryReference={queryReference} />
        )}
      </Suspense>
    </ContentLayout>
  );
}
