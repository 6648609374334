import { Stack, Chip, Paper } from "@mui/material";
import Text from "@components/atoms/Text";
import VideoDialog from "@components/atoms/VideoDialog";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { DrillMainData$key } from "@generated/DrillMainData.graphql";
import {
  DrillLevel,
  DrillPeriod,
  DrillPlace,
  DrillSideEffect,
} from "@constants/App";
import Content from "@components/atoms/Content";

const query = graphql`
  fragment DrillMainData on Drill {
    club
    title
    description
    implement
    tips
    level
    period
    place
    practiceMethod
    sideEffect
    thumbnail {
      signedUrl
    }
    video {
      signedUrl
    }
    swingPosition {
      name
    }
  }
`;

export default function DrillMain({
  drillFragment,
}: {
  drillFragment: DrillMainData$key;
}) {
  const drill = useFragment<DrillMainData$key>(query, drillFragment);

  return (
    <Stack direction="column" flex={1} spacing={1}>
      <Text bold variant="h2">
        {drill.title}
      </Text>
      <Stack direction="row">
        <Chip
          color="primary"
          label={drill.swingPosition.name}
          variant="outlined"
        />
      </Stack>
      <VideoDialog
        thumbnailSize={300}
        thumbnailUrl={drill.thumbnail?.signedUrl}
        videoUrl={drill.video?.signedUrl}
      />
      <Paper sx={{ padding: 2 }}>
        <Stack direction="column" spacing={1}>
          <Content column="概要" value={drill.description} />
          <Content column="使用するクラブ" value={drill.club} />
          <Content column="道具" value={drill.implement} />
          <Content column="難易度" value={DrillLevel[drill.level]} />
          <Content column="習得時間" value={DrillPeriod[drill.period]} />
          <Content column="練習場所" value={DrillPlace[drill.place]} />
          <Content column="練習方法" value={drill.practiceMethod} />
          <Content column="練習のコツ" value={drill.tips} />
          <Content
            column="練習の悪影響"
            value={DrillSideEffect[drill.sideEffect]}
          />
        </Stack>
      </Paper>
    </Stack>
  );
}
