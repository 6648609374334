import { useCallback } from "react";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { ChangeProfileEmail$key } from "@generated/ChangeProfileEmail.graphql";
import { Stack, Button } from "@mui/material";
import { object, string } from "@lib/utils/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Spacer from "@components/atoms/Spacer";
import { TextField, ErrorText } from "@components/molecules/TextInput";
import { UpdateParam } from "@components/organisms/ChangeProfile/type";

const query = graphql`
  fragment ChangeProfileEmail on User {
    email
  }
`;

type Input = {
  email: string;
};

export default function ChangeProfileEmail({
  adminFragment,
  onChange,
}: {
  adminFragment: ChangeProfileEmail$key;
  onChange: (data: UpdateParam) => Promise<void>;
}) {
  const { email } = useFragment<ChangeProfileEmail$key>(query, adminFragment);
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<Input>({
    defaultValues: {
      email,
    },
    mode: "all",
    resolver: yupResolver(
      object().shape({
        email: string()
          .default("")
          .trim()
          .email("Emailの形式が正しくありません")
          .required("入力してください"),
      })
    ),
  });
  const handleUpdate = useCallback(async () => {
    await handleSubmit(async ({ email: inputEmail }: Input) => {
      if (inputEmail === email) {
        return;
      }
      await onChange({
        changeType: "email",
        changeValue: inputEmail,
      });
    })();
  }, [onChange, email, handleSubmit]);

  return (
    <Stack direction="column" justifyContent="flex-start">
      <TextField control={control} label="Email" name="email" type="email" />
      {!isValid && errors.email !== undefined && (
        <ErrorText error={errors.email.message} />
      )}
      <Spacer height={24} />

      <Stack alignItems="flex-start" direction="row">
        <Button disabled={!isValid} onClick={handleUpdate} variant="outlined">
          変更する
        </Button>
      </Stack>
    </Stack>
  );
}
