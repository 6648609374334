import React, { useEffect, useRef } from "react";
import { Box, ListItem, Divider, List, Stack } from "@mui/material";
import Text from "@components/atoms/Text";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { MovieInReviewAnnotation$key } from "@generated/MovieInReviewAnnotation.graphql";
import { MovieInReviewAnnotationAdmin$key } from "@generated/MovieInReviewAnnotationAdmin.graphql";
import useQueryParams from "@lib/hooks/useQueryParams";
import useScroll from "@lib/hooks/useScroll";
import { PageAnchorName } from "@constants/App";
import ObjectDetectionItemSummery from "@components/molecules/ObjectDetectionItem/ObjectDetectionItemSummery";
import ObjectDetectionItemRow from "@components/molecules/ObjectDetectionItem/ObjectDetectionItemRow";
import ObjectDetectionChat from "@components/molecules/ObjectDetectionItem/ObjectDetectionChat";
import MovieInReviewApprove from "@components/organisms/MovieInReview/MovieInReviewApprove";
import MovieInReviewRemand from "@components/organisms/MovieInReview/MovieInReviewRemand";
import { isNil } from "@lib/utils/commons";
import Colors from "@constants/Colors";

const query = graphql`
  fragment MovieInReviewAnnotation on MovieMetainfo {
    id
    ...ObjectDetectionItemRowDirection
    ...ObjectDetectionChat
    movieClips {
      id
      ...ObjectDetectionItemRow
    }
    movieMetainfoJobs {
      id
      type
      status
      reviewer {
        id
      }
      ...ObjectDetectionItemSummery
      ...MovieInReviewRemandData
      ...MovieInReviewApproveData
    }
  }
`;

const adminQuery = graphql`
  fragment MovieInReviewAnnotationAdmin on User {
    id
  }
`;

export default function MovieInReviewAnnotation({
  movieMetainfoFragment,
  adminFragment,
}: {
  movieMetainfoFragment: MovieInReviewAnnotation$key;
  adminFragment: MovieInReviewAnnotationAdmin$key;
}): JSX.Element {
  const listItemRef = useRef<HTMLLIElement | null>(null);
  const { id: adminId } = useFragment<MovieInReviewAnnotationAdmin$key>(
    adminQuery,
    adminFragment
  );
  const movieMetainfo = useFragment<MovieInReviewAnnotation$key>(
    query,
    movieMetainfoFragment
  );
  const { movieClips, movieMetainfoJobs } = movieMetainfo;
  const annotationJob = movieMetainfoJobs.find(
    (row) => row.type === "AnnotateObject"
  );
  const param = useQueryParams(PageAnchorName);
  const anchor = param[PageAnchorName];
  const { scrollTo } = useScroll();
  useEffect(() => {
    if (listItemRef.current !== null) {
      scrollTo(listItemRef);
    }
  }, [scrollTo]);
  if (isNil(annotationJob)) {
    return <Text>レビュー中のアノテーションはありません</Text>;
  }
  return (
    <>
      <ObjectDetectionItemSummery movieMetainfoJobFragment={annotationJob} />
      <Box>
        <List>
          {movieClips.map((movieClip) => (
            <React.Fragment key={movieClip.id}>
              <ListItem
                ref={(ref) => {
                  if (ref !== null && anchor === movieClip.id) {
                    listItemRef.current = ref;
                  }
                }}
                sx={{
                  backgroundColor:
                    anchor === movieClip.id ? Colors.yellow10 : undefined,
                }}
              >
                <ObjectDetectionItemRow
                  movieClipFragment={movieClip}
                  movieMetainfoFragment={movieMetainfo}
                />
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>
        {annotationJob.reviewer?.id === adminId &&
          annotationJob.status === "in_review" && (
            <React.Fragment key={annotationJob.id}>
              <Stack direction="row" justifyContent="center" spacing={2}>
                <MovieInReviewApprove movieMetainfoFragment={annotationJob} />
                <MovieInReviewRemand movieMetainfoFragment={annotationJob} />
              </Stack>
            </React.Fragment>
          )}
      </Box>
      <ObjectDetectionChat movieMetainfoFragment={movieMetainfo} />
    </>
  );
}
