import Icon from "@components/atoms/Icon";
import { useEffect, useRef, useState } from "react";
import { Box, Dialog, DialogContent, IconButton, Stack } from "@mui/material";
import Video from "@components/atoms/Video";
import Thumbnail from "@components/atoms/Thumbnail";
import Colors from "@constants/Colors";
import { isNil } from "@lib/utils/commons";

export default function VideoDialog({
  videoUrl,
  thumbnailUrl,
  thumbnailSize = 500,
  layerColor = Colors.layerGray,
  iconSize = 40,
  iconColor = Colors.white,
}: {
  videoUrl: string | null | undefined;
  thumbnailUrl: string | null | undefined;
  thumbnailSize?: number;
  layerColor?: string;
  iconSize?: number;
  iconColor?: string;
}) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  useEffect(() => {
    if (open && videoRef.current !== null) {
      videoRef.current.play();
    }
  }, [open]);
  const handleClose = () => {
    if (videoRef.current !== null) {
      videoRef.current.pause();
    }
    setOpen(false);
  };
  return (
    <>
      <Box sx={{ position: "relative", width: `${thumbnailSize}px` }}>
        <Thumbnail size={thumbnailSize} url={thumbnailUrl} />
        {!isNil(videoUrl) && (
          <Stack
            onClick={() => setOpen(true)}
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: layerColor,
            }}
          >
            <Icon color={iconColor} name="play" size={iconSize} />
          </Stack>
        )}
      </Box>
      <Dialog fullScreen onClose={handleClose} open={open} scroll="paper">
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <Icon name="close" size={24} />
        </IconButton>
        <DialogContent>
          <Video src={videoUrl} />
        </DialogContent>
      </Dialog>
    </>
  );
}
