import { useCallback } from "react";
import { Pagination, Stack } from "@mui/material/";
import { offsetName } from "@lib/hooks/usePaginationOffset";
import Text from "@components/atoms/Text";
import Spacer from "@components/atoms/Spacer";

type Params = {
  path: string;
  currentPage: number;
  limit: number;
  totalCount: number;
};

export default function OffsetPagination({
  path,
  currentPage,
  limit,
  totalCount,
}: Params) {
  const handleChange = useCallback(
    (_: React.ChangeEvent<unknown>, page: number) => {
      const after = limit * (page - 1);
      window.location.href = `/${path.replace(/^\//, "")}?${offsetName}=${after}`;
    },
    [limit, path]
  );
  const count = totalCount / limit;
  if (count < 1) {
    return null;
  }
  return (
    <Stack direction="row" spacing={2}>
      <Pagination
        color="primary"
        count={Math.ceil(count)}
        onChange={handleChange}
        page={currentPage}
      />
      <Stack>
        <Spacer height={8} />
        <Text bold variant="caption">
          {totalCount.toLocaleString()}件
        </Text>
      </Stack>
    </Stack>
  );
}
