import { useEffect, Suspense } from "react";
import ContentLayout from "@navigation/ContentLayout";
import ObjectDetections from "@components/templates/ObjectDetections";
import graphql from "babel-plugin-relay/macro";
import {
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import { isNil } from "@lib/utils/commons";
import Loading from "@components/atoms/Loading";
import { ObjectDetectionsScreenQuery } from "@generated/ObjectDetectionsScreenQuery.graphql";
import { PageLimit, ObjectDetectionObjectType } from "@constants/App";
import usePaginationOffset from "@lib/hooks/usePaginationOffset";
import useQueryId from "@lib/hooks/useQueryId";

const query = graphql`
  query ObjectDetectionsScreenQuery(
    $first: Int!
    $after: String
    $objectType: ObjectDetectionObjectType!
  ) {
    ...ObjectDetectionsPagination
      @arguments(first: $first, after: $after, objectType: $objectType)
  }
`;

function ScreenContent({
  queryReference,
}: {
  queryReference: PreloadedQuery<ObjectDetectionsScreenQuery>;
}) {
  const data = usePreloadedQuery<ObjectDetectionsScreenQuery>(
    query,
    queryReference
  );
  return <ObjectDetections objectDetectionsFragment={data} />;
}

export default function ObjectDetectionsScreen() {
  const objectType =
    useQueryId<keyof typeof ObjectDetectionObjectType>("object_type");
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<ObjectDetectionsScreenQuery>(query);
  const after = usePaginationOffset();
  useEffect(() => {
    loadQuery(
      {
        first: PageLimit,
        after,
        objectType,
      },
      {
        fetchPolicy: "network-only",
      }
    );
    return () => {
      disposeQuery();
    };
  }, [after, objectType, loadQuery, disposeQuery]);

  return (
    <ContentLayout title="バウンディングボックス一覧">
      <Suspense fallback={<Loading />}>
        {!isNil(queryReference) && (
          <ScreenContent queryReference={queryReference} />
        )}
      </Suspense>
    </ContentLayout>
  );
}
