/**
 * @generated SignedSource<<ab952fb01b90a23e86eed1f7ff37f3ca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useNotificationCount$data = {
  readonly notificationCount: {
    readonly id: string;
    readonly inReviews: number;
    readonly unreadComments: number;
  };
  readonly " $fragmentType": "useNotificationCount";
};
export type useNotificationCount$key = {
  readonly " $data"?: useNotificationCount$data;
  readonly " $fragmentSpreads": FragmentRefs<"useNotificationCount">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./useNotificationCountQuery.graphql')
    }
  },
  "name": "useNotificationCount",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "NotificationCount",
      "kind": "LinkedField",
      "name": "notificationCount",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "inReviews",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unreadComments",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "4f5e5860339226cdfc834c7a2bbc75ce";

export default node;
