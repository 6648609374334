/**
 * @generated SignedSource<<d0d12fb5d743fa849ec3a84810f06307>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MovieMetainfoAngle = "back" | "front";
export type MovieMetainfoSwing = "full" | "half" | "quarter";
export type MovieMetainfosPaginationQuery$variables = {
  after?: string | null | undefined;
  angle?: MovieMetainfoAngle | null | undefined;
  clubId?: string | null | undefined;
  first?: number | null | undefined;
  ids?: string | null | undefined;
  reviewerId?: string | null | undefined;
  swing?: MovieMetainfoSwing | null | undefined;
  userId?: string | null | undefined;
};
export type MovieMetainfosPaginationQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"MovieMetainfosPagination">;
};
export type MovieMetainfosPaginationQuery = {
  response: MovieMetainfosPaginationQuery$data;
  variables: MovieMetainfosPaginationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "angle"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "clubId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "reviewerId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "swing"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "angle",
    "variableName": "angle"
  },
  {
    "kind": "Variable",
    "name": "clubId",
    "variableName": "clubId"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "ids"
  },
  {
    "kind": "Variable",
    "name": "reviewerId",
    "variableName": "reviewerId"
  },
  {
    "kind": "Variable",
    "name": "swing",
    "variableName": "swing"
  },
  {
    "kind": "Variable",
    "name": "userId",
    "variableName": "userId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "Avatar",
    "kind": "LinkedField",
    "name": "avatar",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "signedUrl",
        "storageKey": null
      },
      (v2/*: any*/)
    ],
    "storageKey": null
  },
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MovieMetainfosPaginationQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "MovieMetainfosPagination"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MovieMetainfosPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MovieMetainfoConnection",
        "kind": "LinkedField",
        "name": "movieMetainfos",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MovieMetainfoEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MovieMetainfo",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "thumbnail",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sourceUrl",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "clipCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MovieMetainfoJob",
                    "kind": "LinkedField",
                    "name": "movieMetainfoJobs",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "appliedAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": (v4/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "reviewer",
                        "plural": false,
                        "selections": (v4/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Club",
                    "kind": "LinkedField",
                    "name": "club",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "angle",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "direction",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "gender",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hand",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "place",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "swing",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currentPage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "limit",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": [
          "angle",
          "swing",
          "clubId",
          "userId",
          "reviewerId",
          "ids"
        ],
        "handle": "connection",
        "key": "MovieMetainfosList__movieMetainfos",
        "kind": "LinkedHandle",
        "name": "movieMetainfos"
      }
    ]
  },
  "params": {
    "cacheID": "448461b55ad61c576a772d11063a8161",
    "id": null,
    "metadata": {},
    "name": "MovieMetainfosPaginationQuery",
    "operationKind": "query",
    "text": "query MovieMetainfosPaginationQuery(\n  $after: String\n  $angle: MovieMetainfoAngle\n  $clubId: ID\n  $first: Int\n  $ids: String\n  $reviewerId: ID\n  $swing: MovieMetainfoSwing\n  $userId: ID\n) {\n  ...MovieMetainfosPagination_JMcv2\n}\n\nfragment MovieMetainfoItemCard on MovieMetainfo {\n  id\n  thumbnail\n  sourceUrl\n  clipCount\n  movieMetainfoJobs {\n    id\n    ...MovieMetainfoItemTask\n  }\n  ...useMovieMetainfo\n}\n\nfragment MovieMetainfoItemTask on MovieMetainfoJob {\n  type\n  appliedAt\n  user {\n    name\n    avatar {\n      signedUrl\n      id\n    }\n    id\n  }\n  reviewer {\n    name\n    avatar {\n      signedUrl\n      id\n    }\n    id\n  }\n}\n\nfragment MovieMetainfosPagination_JMcv2 on Query {\n  movieMetainfos(first: $first, after: $after, angle: $angle, swing: $swing, clubId: $clubId, userId: $userId, reviewerId: $reviewerId, ids: $ids) {\n    edges {\n      node {\n        id\n        ...MovieMetainfoItemCard\n        __typename\n      }\n      cursor\n    }\n    currentPage\n    totalCount\n    limit\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment useMovieMetainfo on MovieMetainfo {\n  id\n  club {\n    id\n    name\n  }\n  angle\n  direction\n  gender\n  hand\n  place\n  swing\n}\n"
  }
};
})();

(node as any).hash = "7243e6ec235cdb516852b381ab5b210d";

export default node;
