/**
 * @generated SignedSource<<4087af020fba06b65fb05bbce48dbaf8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MovieMetainfoDirection = "landscape" | "portrait" | "square";
import { FragmentRefs } from "relay-runtime";
export type ObjectDetectionItemRowDirection$data = {
  readonly direction: MovieMetainfoDirection | null | undefined;
  readonly " $fragmentType": "ObjectDetectionItemRowDirection";
};
export type ObjectDetectionItemRowDirection$key = {
  readonly " $data"?: ObjectDetectionItemRowDirection$data;
  readonly " $fragmentSpreads": FragmentRefs<"ObjectDetectionItemRowDirection">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ObjectDetectionItemRowDirection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "direction",
      "storageKey": null
    }
  ],
  "type": "MovieMetainfo",
  "abstractKey": null
};

(node as any).hash = "85fc87f2aa76314f153f1db0b9e99c4b";

export default node;
