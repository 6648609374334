/**
 * @generated SignedSource<<0e4ebab92c5901f32373396e1b96ef09>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommentsAdmin$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CommentsItemAdmin">;
  readonly " $fragmentType": "CommentsAdmin";
};
export type CommentsAdmin$key = {
  readonly " $data"?: CommentsAdmin$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommentsAdmin">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommentsAdmin",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommentsItemAdmin"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "2085e19273f33cccb3da17a7dbfee19d";

export default node;
