/**
 * @generated SignedSource<<3ff9e2b18f9d6228eaac7b7f675be846>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MovieMetainfoItemCard$data = {
  readonly clipCount: number;
  readonly id: string;
  readonly movieMetainfoJobs: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"MovieMetainfoItemTask">;
  }>;
  readonly sourceUrl: string;
  readonly thumbnail: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"useMovieMetainfo">;
  readonly " $fragmentType": "MovieMetainfoItemCard";
};
export type MovieMetainfoItemCard$key = {
  readonly " $data"?: MovieMetainfoItemCard$data;
  readonly " $fragmentSpreads": FragmentRefs<"MovieMetainfoItemCard">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MovieMetainfoItemCard",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sourceUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clipCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MovieMetainfoJob",
      "kind": "LinkedField",
      "name": "movieMetainfoJobs",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "MovieMetainfoItemTask"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useMovieMetainfo"
    }
  ],
  "type": "MovieMetainfo",
  "abstractKey": null
};
})();

(node as any).hash = "e86055754fddfdaef623ee93963e3168";

export default node;
