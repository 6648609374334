/**
 * @generated SignedSource<<704233e8a811d95692fd8db0fb3b111f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SwingFrameCreate$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SwingFrameInputSwingPositions">;
  readonly " $fragmentType": "SwingFrameCreate";
};
export type SwingFrameCreate$key = {
  readonly " $data"?: SwingFrameCreate$data;
  readonly " $fragmentSpreads": FragmentRefs<"SwingFrameCreate">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SwingFrameCreate",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SwingFrameInputSwingPositions"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "a24f4ea6de6d35220c5a9cb0e2441242";

export default node;
