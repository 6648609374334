import { HeaderHeight } from "@constants/App";

export default function useScroll() {
  const scrollTop = (
    ref?: React.MutableRefObject<HTMLElement | null>,
    event?: React.SyntheticEvent
  ): void => {
    if (ref !== undefined && ref.current !== null) {
      ref.current.scrollIntoView();
    } else {
      window.scrollTo(0, HeaderHeight);
    }
    event?.preventDefault();
  };

  const scrollTo = (ref?: React.MutableRefObject<HTMLElement | null>): void => {
    if (ref !== undefined && ref.current !== null) {
      const target = ref.current.getBoundingClientRect();
      const positionY = target.top - HeaderHeight;
      if (positionY > 0) {
        window.scrollTo(0, positionY);
      }
    }
  };

  const scrollBottom = (
    ref?: React.MutableRefObject<HTMLElement | null>,
    event?: React.SyntheticEvent
  ): void => {
    const element = document.documentElement;
    if (ref !== undefined && ref.current !== null) {
      window.scroll(0, element.scrollHeight + ref.current.clientHeight);
    } else {
      window.scroll(0, element.scrollHeight - element.clientHeight);
    }
    event?.preventDefault();
  };

  return {
    scrollTop,
    scrollTo,
    scrollBottom,
  };
}
