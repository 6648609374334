import {
  Environment,
  Network,
  RecordSource,
  Store,
  RequestParameters,
  Variables,
  CacheConfig,
  UploadableMap,
} from "relay-runtime";
import axios from "axios";
import type { AxiosResponse, AxiosError } from "axios";
import { head, isNil } from "@lib/utils/commons";
import { apiEndpoint } from "@constants/Env";
import { setExceptionalError, errorAtom } from "@lib/hooks/useExecptionalError";
import refreshToken from "@lib/utils/refreshToken";
import { SecureStoreManager } from "@lib/utils/secureStoreManager";
import { resolveError } from "@lib/utils/error";

type ResponseError = {
  message: string;
  locations: {
    [index: string]: unknown;
  }[];
  path: unknown[];
  extensions: {
    code: string;
  };
};

type ApiResponse<T = object> = {
  errors?: ResponseError[];
  data: T;
};

type Maintenance = {
  code: string;
  message: string;
};

const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
});

let retry = false;

axiosInstance.interceptors.request.use(async (config) => {
  const token = SecureStoreManager.getAccessToken();
  if (token !== null) {
    config.headers.set("Authorization", `Bearer ${token}`);
  }
  return config;
});

axiosInstance.interceptors.response.use(
  async (response: AxiosResponse<ApiResponse>) => {
    if (response.data.errors !== undefined) {
      const error = head<ResponseError>(response.data.errors);
      if (error?.extensions?.code === "TokenExpired" && !retry) {
        retry = true;
        return retryWithtokenRefresh(response);
      }
      await handleError(error);
    }
    return response;
  },
  async (error: AxiosError) => {
    if (error.response?.status === 504) {
      const { data } = error.response as AxiosResponse<Maintenance>;
      if (data.code === "UnderMaintenance") {
        setExceptionalError(errorAtom, {
          code: "Maintenance",
          message: data.message,
        });
        return;
      }
    }
    const result = resolveError(error);
    setExceptionalError(errorAtom, {
      code: result.code === "ERR_NETWORK" ? "NetworkError" : "ServiceDown",
      message: result.message,
    });
    throw error;
  }
);

async function fetchGraphQL(text: string, variables: Variables) {
  const { data }: { data: AxiosResponse<ApiResponse> } =
    await axiosInstance.post(
      apiEndpoint,
      JSON.stringify({
        query: text,
        variables,
      })
    );
  return data;
}

async function fetchMultipartGraphQL(
  params: RequestParameters,
  variables: Variables,
  _cacheConfig: CacheConfig,
  uploadables: UploadableMap
) {
  const formData = new FormData();
  formData.append(
    "operations",
    JSON.stringify({ query: params.text, variables })
  );
  const map: Record<string, string[]> = {};
  Object.keys(uploadables).forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(uploadables, key)) {
      map[key] = [key];
    }
  });
  formData.append("map", JSON.stringify(map));

  Object.keys(uploadables).forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(uploadables, key)) {
      const file = getFile(uploadables[key] as File);
      formData.append(key, uploadables[key], file.name);
    }
  });
  const { data }: { data: AxiosResponse<ApiResponse> } =
    await axiosInstance.post(apiEndpoint, formData, {
      timeout: 1800000,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  return data;
}

// NOTE: 本来は下記の formData への追記記述でいけるはずだけど、うまくいかないので上の方法で実行している.
//        おそらく react-native/Expo/Relay 辺りの問題だと思われるので version up などで直り次第修正する.
//        以下事象:
//        axios/fetch/xhr それぞれ試したが multipart/form-data として認識されてはいて
//        binary data 以外は送信できたが binary data のみ飛ばなかった.
//        (それぞれの方法全部で binary data が飛ばないことを wireshark で確認済みなので client の問題っぽい)
// formData.append(key, uploadables[key], (uploadables[key] as File).name);
function getFile(data: File) {
  return {
    name: data.name,
    type: data.type,
    uri: data.name,
  };
}

async function retryWithtokenRefresh(response: AxiosResponse<ApiResponse>) {
  const { accessToken } = await refreshToken(relayEnvironment);

  const { config } = response;
  config.headers.Authorization = `Bearer ${accessToken}`;
  const result = await axiosInstance.request(config);
  return result;
}

// Relay passes a "params" object with the query name and text. So we define a helper function
// to call our fetchGraphQL utility with params.text.
async function fetchRelay(
  params: RequestParameters,
  variables: Variables,
  _cacheConfig: CacheConfig,
  uploadables?: UploadableMap | null
) {
  if (!isNil(uploadables)) {
    return fetchMultipartGraphQL(params, variables, _cacheConfig, uploadables);
  }
  return fetchGraphQL(<string>params.text, variables);
}

async function handleError(error?: ResponseError) {
  try {
    switch (error?.extensions?.code) {
      case "UnsupportedVersion":
        setExceptionalError(errorAtom, {
          code: "Upgrade",
          message: "",
        });
        break;
      case "Unauthorized":
      case "InvalidToken":
        setExceptionalError(errorAtom, {
          code: "Expired",
          message: "",
        });
        window.location.href = "/sign_in";
        break;
      default:
        throw new Error(JSON.stringify(error));
    }
  } catch (e: unknown) {
    setExceptionalError(errorAtom, {
      code: "ServiceDown",
      message: resolveError(e).message,
    });
  }
}

// Export a singleton instance of Relay Environment configured with our network function:
const relayEnvironment = new Environment({
  network: Network.create(fetchRelay),
  store: new Store(new RecordSource()),
});
export default relayEnvironment;
