const Colors = {
  green: "#14CAE2",
  white: "#FFFFFF",
  black0: "#000000",
  black: "#292C35",
  gray: "#8E8E93",
  gray10: "#F4F4F4",
  gray20: "#EFEFEF",
  gray30: "#E0E0E0",
  orange: "#FF5E2B",
  orange20: "#FF5E2B20",
  blue: "#448EF6",
  blue20: "#448EF620",
  blue50: "#448EF650",
  drawer: "#51596d",
  drawerDark: "#3f4860",
  dark: "#1F1D1B",
  pink: "#F699FF",
  layerWhite: "#B8B8B850",
  layerBlack: "#00000070",
  layerGray: "#00000030",
  yellow: "#FFE600",
  yellow10: "#FFE60030",
};

export const BoundingBoxColors = {
  ball: Colors.blue,
  head: Colors.orange,
  shaft: Colors.yellow,
} as const;

export default Colors;
