import React from "react";
import { Stack, Card, CardActions, Divider, Button } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { usePaginationFragment } from "react-relay";
import { CoachingsPagination$key } from "@generated/CoachingsPagination.graphql";
import OffsetPagination from "@components/atoms/OffsetPagination";
import Spacer from "@components/atoms/Spacer";
import CoachingsCard from "@components/organisms/Coachings/CoachingsCard";
import { useNavigate } from "react-router-dom";

const query = graphql`
  fragment CoachingsPagination on Query
  @refetchable(queryName: "CoachingsPaginationQuery")
  @argumentDefinitions(after: { type: "String" }, first: { type: "Int" }) {
    coachings(first: $first, after: $after)
      @connection(key: "Coachings__coachings") {
      edges {
        node {
          id
          ...CoachingsCard
        }
      }
      currentPage
      totalCount
      limit
    }
  }
`;

export default function Coachings({
  coachingsFragment,
}: {
  coachingsFragment: CoachingsPagination$key;
}) {
  const { data } = usePaginationFragment(query, coachingsFragment);
  const { edges, currentPage, limit, totalCount } = data.coachings;
  const navigate = useNavigate();
  return (
    <>
      <Stack alignItems="flex-start" direction="row" flexWrap="wrap">
        {edges.map(({ node }) => (
          <React.Fragment key={node.id}>
            <Card sx={{ width: 280, marginBottom: 1 }}>
              <CoachingsCard movieFragment={node} />
              <Divider />
              <CardActions>
                <Stack flex={1}>
                  <Button
                    onClick={() => {
                      navigate(`/coachings/${node.id}`);
                    }}
                    variant="contained"
                  >
                    詳細
                  </Button>
                </Stack>
              </CardActions>
            </Card>
            <Spacer width={8} />
          </React.Fragment>
        ))}
      </Stack>
      <Spacer height={8} />
      <OffsetPagination
        currentPage={currentPage}
        limit={limit}
        path="coachings"
        totalCount={totalCount}
      />
    </>
  );
}
