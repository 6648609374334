import React, { useCallback, useState, useMemo } from "react";
import {
  Box,
  Card,
  List,
  ListItem,
  Divider,
  Stack,
  Button,
} from "@mui/material";
import Text from "@components/atoms/Text";
import Message from "@components/molecules/Message";
import Loading from "@components/atoms/Loading";
import useMessage from "@lib/hooks/useMessage";
import usePreventDoubleClick from "@lib/hooks/usePreventDoubleClick";
import graphql from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import SwingPositionClassificationItem from "@components/molecules/SwingPositionClassification/SwingPositionClassificationItem";
import SwingPositionClassificationSummary from "@components/molecules/SwingPositionClassification/SwingPositionClassificationSummary";
import SwingPositionClassificationChat from "@components/molecules/SwingPositionClassification/SwingPositionClassificationChat";
import Spacer from "@components/atoms/Spacer";
import { LabelingSwingConfirm$key } from "@generated/LabelingSwingConfirm.graphql";
import { LabelingSwingConfirmQuery$key } from "@generated/LabelingSwingConfirmQuery.graphql";
import {
  LabelingSwingConfirmMutation,
  LabelingSwingConfirmMutation$data,
} from "@generated/LabelingSwingConfirmMutation.graphql";
import Colors from "@constants/Colors";
import { MaxClipCount, MinmunLabelingCount } from "@constants/App";
import { Closing } from "@components/organisms/LabelingSwing/hints";
import Content from "@components/atoms/Content";

const query = graphql`
  fragment LabelingSwingConfirm on MovieJob {
    id
    status
    movieMetainfo {
      id
      ...SwingPositionClassificationItemMeta
      ...SwingPositionClassificationSummary
      ...SwingPositionClassificationChat
      movieClips {
        id
        swingClassification {
          id
        }
        ...SwingPositionClassificationItemClip
      }
      movieMetainfoJobs {
        id
        type
      }
    }
  }
`;

const masterQuery = graphql`
  fragment LabelingSwingConfirmQuery on Query {
    ...SwingPositionClassificationItemQuery
    ...SwingPositionClassificationSummaryQuery
  }
`;

const mutation = graphql`
  mutation LabelingSwingConfirmMutation(
    $input: UpdateMovieMetainfoJobMutationInput!
  ) {
    updateMovieMetainfoJob(input: $input) {
      __typename
      ... on MovieMetainfoJob {
        id
      }
      ... on UserError {
        message
      }
    }
  }
`;

export default function LabelingSwingConfirm({
  movieJobFragment,
  masterFragment,
}: {
  movieJobFragment: LabelingSwingConfirm$key;
  masterFragment: LabelingSwingConfirmQuery$key;
}) {
  const movieJob = useFragment<LabelingSwingConfirm$key>(
    query,
    movieJobFragment
  );
  const master = useFragment<LabelingSwingConfirmQuery$key>(
    masterQuery,
    masterFragment
  );
  const { status, movieMetainfo } = movieJob;
  const { movieClips, movieMetainfoJobs } = movieMetainfo;
  const labelingJob = movieMetainfoJobs.find(
    (row) => row.type === "LabelingSwing"
  );
  const { processing, onClick, onRelease } = usePreventDoubleClick();
  const [, setMessage] = useMessage();
  const [confirm, setConfirm] = useState<boolean>(false);
  const [commit] = useMutation<LabelingSwingConfirmMutation>(mutation);
  const sumOFLabels = useMemo<number>(
    () => movieClips.filter((clip) => clip.swingClassification !== null).length,
    [movieClips]
  );
  const handleClose = useCallback(async () => {
    if (processing || labelingJob === undefined) {
      return;
    }
    onClick();
    const result = await new Promise<
      LabelingSwingConfirmMutation$data["updateMovieMetainfoJob"]
    >((resolve) => {
      commit({
        variables: {
          input: {
            id: labelingJob.id,
            workflow: "apply",
          },
        },
        onCompleted: ({ updateMovieMetainfoJob }) => {
          resolve(updateMovieMetainfoJob);
        },
      });
    });
    if (result.__typename !== "MovieMetainfoJob") {
      setMessage({
        mode: "error",
        title: "処理に失敗しました",
        subline: "処理に失敗しました",
      });
      setConfirm(false);
      onRelease();
    } else {
      window.location.href = "/labeling_swings";
    }
  }, [commit, labelingJob, setMessage, processing, onClick, onRelease]);

  const onConfirm = useCallback(() => {
    if (movieClips.length === 0) {
      setMessage({
        mode: "error",
        title: "ラベル付けされた画像がありません",
        subline: "ラベル付を行ってください",
      });
    } else if (movieClips.length > MaxClipCount) {
      setMessage({
        mode: "error",
        title: `ラベル付けされた画像は${MaxClipCount}枚以下にしてください`,
        subline: "厳選して不要なラベル付け画像は削除してください",
      });
    } else {
      setConfirm(true);
    }
  }, [movieClips, setMessage]);
  return (
    <Box>
      <Card sx={{ padding: 2, backgroundColor: Colors.blue20 }}>
        <Text bold>
          作業内容を確認して、よろしければ「このスイング動画のラベル付を完了する」を押してください。
        </Text>
        <Spacer height={8} />
        {Closing.map((hint) => (
          <Content
            key={hint.title}
            column={hint.title}
            columnWidth={60}
            value={hint.value}
          />
        ))}
      </Card>

      <Spacer height={24} />
      <SwingPositionClassificationSummary
        movieMetainfoFragment={movieMetainfo}
        swingPositionsFragment={master}
      />
      <Spacer height={24} />

      {movieClips.length === 0 ? (
        <Text>ラベル付けされた画像がありません。ラベル付を行ってください</Text>
      ) : (
        <List>
          {movieClips.map((movieClip) => (
            <React.Fragment key={movieClip.id}>
              <ListItem>
                <SwingPositionClassificationItem
                  editable
                  masterFragment={master}
                  movieClipFragment={movieClip}
                  movieMetainfoFragment={movieMetainfo}
                />
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>
      )}
      <Spacer height={24} />
      <Stack
        alignItems="center"
        direction="column"
        justifyContent="center"
        spacing={1}
      >
        <Button
          disabled={sumOFLabels < MinmunLabelingCount || status === "closing"}
          onClick={onConfirm}
          variant="contained"
        >
          {status === "closing" ? "完了処理中..." : "ラベル付け作業を完了する"}
        </Button>
        <Text variant="subtitle2">
          最低{MinmunLabelingCount}個以上ラベル付けしてください
        </Text>
      </Stack>
      <SwingPositionClassificationChat movieMetainfoFragment={movieMetainfo} />
      <Message
        closeText="キャンセル"
        confirmText="理解して、完了する"
        message="完了すると、このスイング動画のラベル付けはできなくなります。よろしいですか？"
        onClose={() => setConfirm(false)}
        onConfirm={handleClose}
        title="ラベル付け作業を完了しますか？"
        visible={confirm}
      />
      {processing && <Loading color={Colors.white} mask />}
    </Box>
  );
}
