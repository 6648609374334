import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay/hooks";
import { SecureStoreManager } from "@lib/utils/secureStoreManager";
import { signOutMutation } from "@generated/signOutMutation.graphql";
import RelayEnvironment from "../../RelayEnvironment";

const mutation = graphql`
  mutation signOutMutation($input: SignOutMutationInput!) {
    signOut(input: $input) {
      success
    }
  }
`;

export default async function onSignOut(): Promise<void> {
  await new Promise<void>((resolve) => {
    commitMutation<signOutMutation>(RelayEnvironment, {
      mutation,
      variables: {
        input: {},
      },
      onCompleted: () => {
        resolve();
      },
      onError: () => {
        resolve();
      },
    });
  });
  SecureStoreManager.deleteAccessTokenSet();
  window.location.href = "/sign_in";
}
