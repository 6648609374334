import { useEffect, Suspense } from "react";
import ContentLayout from "@navigation/ContentLayout";
import Causes from "@components/templates/Causes";
import graphql from "babel-plugin-relay/macro";
import {
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import { isNil } from "@lib/utils/commons";
import Loading from "@components/atoms/Loading";
import { CausesScreenQuery } from "@generated/CausesScreenQuery.graphql";
import usePaginationOffset from "@lib/hooks/usePaginationOffset";
import { PageLimitLarge } from "@constants/App";

const query = graphql`
  query CausesScreenQuery($first: Int!, $after: String) {
    ...CausesPagination @arguments(first: $first, after: $after)
  }
`;

function ScreenContent({
  queryReference,
}: {
  queryReference: PreloadedQuery<CausesScreenQuery>;
}) {
  const data = usePreloadedQuery<CausesScreenQuery>(query, queryReference);
  return <Causes causesFragment={data} />;
}

export default function CausesScreen() {
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<CausesScreenQuery>(query);
  const after = usePaginationOffset();
  useEffect(() => {
    loadQuery({
      first: PageLimitLarge,
      after,
    });
    return () => {
      disposeQuery();
    };
  }, [after, loadQuery, disposeQuery]);

  return (
    <ContentLayout title="原因一覧">
      <Suspense fallback={<Loading />}>
        {!isNil(queryReference) && (
          <ScreenContent queryReference={queryReference} />
        )}
      </Suspense>
    </ContentLayout>
  );
}
