import { useSearchParams } from "react-router-dom";
import { isNil } from "@lib/utils/commons";

export const offsetName = "offset";

export default function usePaginationOffset(): string | undefined {
  try {
    const [searchParams] = useSearchParams();
    const offset = searchParams.get(offsetName);
    if (!isNil(offset) && offset !== "") {
      if (!Number.isNaN(Number(offset))) {
        return btoa(offset).replace(/=/g, "");
      }
    }
    return undefined;
  } catch {
    return undefined;
  }
}
