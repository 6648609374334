import { useEffect, Suspense } from "react";
import ContentLayout from "@navigation/ContentLayout";
import Member from "@components/templates/Member";
import graphql from "babel-plugin-relay/macro";
import {
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import { isNil } from "@lib/utils/commons";
import Loading from "@components/atoms/Loading";
import { MemberScreenQuery } from "@generated/MemberScreenQuery.graphql";
import useQueryId from "@lib/hooks/useQueryId";

const query = graphql`
  query MemberScreenQuery($id: ID!) {
    member(id: $id) {
      ...MemberData
    }
  }
`;

function ScreenContent({
  queryReference,
}: {
  queryReference: PreloadedQuery<MemberScreenQuery>;
}): JSX.Element {
  const { member } = usePreloadedQuery<MemberScreenQuery>(
    query,
    queryReference
  );
  return <Member userFragment={member} />;
}

export default function MemberScreen(): JSX.Element {
  const id = useQueryId();
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<MemberScreenQuery>(query);
  useEffect(() => {
    loadQuery({ id: id ?? null });
    return () => {
      disposeQuery();
    };
  }, [id, loadQuery, disposeQuery]);
  return (
    <ContentLayout
      breadcrumbs={[
        {
          label: "メンバー一覧",
          path: "/members",
        },
      ]}
      title="メンバー詳細"
    >
      <Suspense fallback={<Loading />}>
        {!isNil(queryReference) && (
          <ScreenContent queryReference={queryReference} />
        )}
      </Suspense>
    </ContentLayout>
  );
}
