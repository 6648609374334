/**
 * @generated SignedSource<<ed7f61199998a479f7a93efa6a63a191>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserInvitesScreenQuery$variables = Record<PropertyKey, never>;
export type UserInvitesScreenQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"UserInvites">;
};
export type UserInvitesScreenQuery = {
  response: UserInvitesScreenQuery$data;
  variables: UserInvitesScreenQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserInvitesScreenQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "UserInvites"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UserInvitesScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserInvite",
        "kind": "LinkedField",
        "name": "userInvites",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "role",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "expiredAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a1b4a81da36972dcb9394d4ea72901a2",
    "id": null,
    "metadata": {},
    "name": "UserInvitesScreenQuery",
    "operationKind": "query",
    "text": "query UserInvitesScreenQuery {\n  ...UserInvites\n}\n\nfragment UserInvites on Query {\n  userInvites {\n    id\n    ...UserInvitesItem\n  }\n}\n\nfragment UserInvitesItem on UserInvite {\n  id\n  email\n  role\n  expiredAt\n}\n"
  }
};

(node as any).hash = "5ce2ed2bd957cfff80bb9886a67c3379";

export default node;
