/**
 * @generated SignedSource<<58779f94f6c3fb946f1f90d808eeda3f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SwingFrameAngle = "back" | "front";
import { FragmentRefs } from "relay-runtime";
export type SwingFrameMoviesFrame$data = {
  readonly angle: SwingFrameAngle;
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "SwingFrameMoviesFrame";
};
export type SwingFrameMoviesFrame$key = {
  readonly " $data"?: SwingFrameMoviesFrame$data;
  readonly " $fragmentSpreads": FragmentRefs<"SwingFrameMoviesFrame">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SwingFrameMoviesFrame",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "angle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "SwingFrame",
  "abstractKey": null
};

(node as any).hash = "5a59cc38db3df5081bde1b3def03f88b";

export default node;
