/**
 * @generated SignedSource<<1bfdf5174f5635f4406b01f2e0c5e5f8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MovieMetainfosQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"MovieMetainfosSearchQuery">;
  readonly " $fragmentType": "MovieMetainfosQuery";
};
export type MovieMetainfosQuery$key = {
  readonly " $data"?: MovieMetainfosQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"MovieMetainfosQuery">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MovieMetainfosQuery",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MovieMetainfosSearchQuery"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "562d4862df72af69407ab8ee1dc78918";

export default node;
