/**
 * @generated SignedSource<<84dccdd4ecd08da4e4d229c23e7fcf6a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProgressData$data = {
  readonly annotations: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"ProgressObject">;
  }>;
  readonly swingFrames: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"ProgressFrame">;
  }>;
  readonly users: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"ProgressWorker">;
  }>;
  readonly " $fragmentType": "ProgressData";
};
export type ProgressData$key = {
  readonly " $data"?: ProgressData$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProgressData">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProgressData",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SwingFrame",
      "kind": "LinkedField",
      "name": "swingFrames",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProgressFrame"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProgressUser",
      "kind": "LinkedField",
      "name": "users",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProgressWorker"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProgressAnnotation",
      "kind": "LinkedField",
      "name": "annotations",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProgressObject"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Progress",
  "abstractKey": null
};
})();

(node as any).hash = "df2e87c320f227dc3e6dc8ac3eb4a346";

export default node;
