import SignIn from "@components/templates/SignIn";
import { Box } from "@mui/material";

export default function SignInScreen() {
  return (
    <Box width="100%">
      <SignIn />
    </Box>
  );
}
