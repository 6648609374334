import React from "react";
import { Stack, List, ListItem, Divider } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { usePaginationFragment, useFragment } from "react-relay";
import { Comments$key } from "@generated/Comments.graphql";
import { CommentsAdmin$key } from "@generated/CommentsAdmin.graphql";
import CommentsItem from "@components/organisms/Comments/CommentsItem";
import OffsetPagination from "@components/atoms/OffsetPagination";
import Spacer from "@components/atoms/Spacer";

const query = graphql`
  fragment Comments on Query
  @refetchable(queryName: "CommentsPaginationQuery")
  @argumentDefinitions(after: { type: "String" }, first: { type: "Int" }) {
    comments(first: $first, after: $after)
      @connection(key: "CommentList_comments") {
      edges {
        cursor
        node {
          id
          ...CommentsItem
        }
      }
      currentPage
      totalCount
      limit
    }
  }
`;

const adminQuery = graphql`
  fragment CommentsAdmin on User {
    ...CommentsItemAdmin
  }
`;

export default function Comments({
  commentsFragment,
  adminFragment,
}: {
  commentsFragment: Comments$key;
  adminFragment: CommentsAdmin$key;
}) {
  const admin = useFragment<CommentsAdmin$key>(adminQuery, adminFragment);
  const { data } = usePaginationFragment(query, commentsFragment);
  const { edges, currentPage, limit, totalCount } = data.comments;
  return (
    <Stack direction="column" paddingX={2}>
      <List>
        {edges.map(({ node }) => (
          <React.Fragment key={node.id}>
            <ListItem>
              <CommentsItem adminFragment={admin} commentFragment={node} />
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>
      <Spacer height={8} />
      <OffsetPagination
        currentPage={currentPage}
        limit={limit}
        path="comments"
        totalCount={totalCount}
      />
    </Stack>
  );
}
