import { useEffect, Suspense } from "react";
import ContentLayout from "@navigation/ContentLayout";
import Article from "@components/templates/Article";
import graphql from "babel-plugin-relay/macro";
import {
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import { isNil } from "@lib/utils/commons";
import Loading from "@components/atoms/Loading";
import { ArticleScreenQuery } from "@generated/ArticleScreenQuery.graphql";
import useQueryId from "@lib/hooks/useQueryId";

const query = graphql`
  query ArticleScreenQuery($id: ID!) {
    article(id: $id) {
      ...ArticleData
    }
  }
`;

function ScreenContent({
  queryReference,
}: {
  queryReference: PreloadedQuery<ArticleScreenQuery>;
}): JSX.Element {
  const { article } = usePreloadedQuery<ArticleScreenQuery>(
    query,
    queryReference
  );
  return <Article articleFragment={article} />;
}

export default function ArticleScreen(): JSX.Element {
  const id = useQueryId();
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<ArticleScreenQuery>(query);
  useEffect(() => {
    loadQuery({ id });
    return () => {
      disposeQuery();
    };
  }, [id, loadQuery, disposeQuery]);

  return (
    <ContentLayout
      breadcrumbs={[{ label: "記事一覧", path: "/articles" }]}
      title="記事詳細"
    >
      <Suspense fallback={<Loading />}>
        {!isNil(queryReference) && (
          <ScreenContent queryReference={queryReference} />
        )}
      </Suspense>
    </ContentLayout>
  );
}
