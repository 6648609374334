/**
 * @generated SignedSource<<48c0cb65173b4ed0e06289af1e237e12>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CauseData$data = {
  readonly causeArchives: ReadonlyArray<{
    readonly main: boolean;
    readonly oldId: number;
  }>;
  readonly content: string;
  readonly id: string;
  readonly lessonTemplates: ReadonlyArray<{
    readonly goal: string;
    readonly id: string;
  }>;
  readonly swingPosition: {
    readonly name: string;
  };
  readonly " $fragmentType": "CauseData";
};
export type CauseData$key = {
  readonly " $data"?: CauseData$data;
  readonly " $fragmentSpreads": FragmentRefs<"CauseData">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CauseData",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CauseArchive",
      "kind": "LinkedField",
      "name": "causeArchives",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "oldId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "main",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SwingPosition",
      "kind": "LinkedField",
      "name": "swingPosition",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LessonTemplate",
      "kind": "LinkedField",
      "name": "lessonTemplates",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "goal",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Cause",
  "abstractKey": null
};
})();

(node as any).hash = "4d1731c119cd0dbade1657324b600656";

export default node;
