import { Controller } from "react-hook-form";
import { CommonProps } from "@components/molecules/TextInput/type";
import { TextField } from "@mui/material";
import React from "react";

export default function PasswordField({
  name,
  label,
  placeholder,
  disabled,
  required,
  control,
}: CommonProps) {
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { value, onBlur, onChange: onChangeText },
        fieldState: { invalid },
      }) => (
        <TextField
          disabled={disabled}
          error={invalid}
          label={label}
          onBlur={onBlur}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            onChangeText(event.target.value)
          }
          placeholder={placeholder}
          required={required}
          style={{
            width: "100%",
            margin: "0px",
          }}
          type="password"
          value={value}
        />
      )}
    />
  );
}
