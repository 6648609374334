import { Stack } from "@mui/material";
import Text from "@components/atoms/Text";
import Thumbnail from "@components/atoms/Thumbnail";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { DrillPointData$key } from "@generated/DrillPointData.graphql";
import { isNil } from "@lib/utils/commons";
import { DrillPointPointType } from "@constants/App";

const query = graphql`
  fragment DrillPointData on DrillPoint {
    pointType
    title
    thumbnail {
      signedUrl
    }
  }
`;

export default function DrillPoint({
  drillPointFragment,
}: {
  drillPointFragment: DrillPointData$key;
}) {
  const { pointType, title, thumbnail } = useFragment<DrillPointData$key>(
    query,
    drillPointFragment
  );
  return (
    <Stack direction="column" spacing={1}>
      <Text bold variant="h5">
        {DrillPointPointType[pointType]}
      </Text>
      <Text variant="h6">{title}</Text>
      {!isNil(thumbnail) && <Thumbnail size={500} url={thumbnail?.signedUrl} />}
    </Stack>
  );
}
