/**
 * @generated SignedSource<<f301f68e636ae83f15497c6a2975704f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SwingFrameMoviesPaginationQuery$variables = {
  after?: string | null | undefined;
  first?: number | null | undefined;
  swingFrameId: string;
};
export type SwingFrameMoviesPaginationQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SwingFrameMoviesPagination">;
};
export type SwingFrameMoviesPaginationQuery = {
  response: SwingFrameMoviesPaginationQuery$data;
  variables: SwingFrameMoviesPaginationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "swingFrameId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "swingFrameId",
    "variableName": "swingFrameId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "signedUrl",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  (v2/*: any*/),
  (v4/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SwingFrameMoviesPaginationQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "SwingFrameMoviesPagination"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SwingFrameMoviesPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SwingClassificationConnection",
        "kind": "LinkedField",
        "name": "swingClassifications",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SwingClassificationEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SwingClassification",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MovieClip",
                    "kind": "LinkedField",
                    "name": "movieClip",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "ballTrajectory",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SwingImage",
                        "kind": "LinkedField",
                        "name": "swingImage",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SwingClassification",
                        "kind": "LinkedField",
                        "name": "swingClassification",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "needReview",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "secondary",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SwingFrame",
                            "kind": "LinkedField",
                            "name": "swingFrame",
                            "plural": false,
                            "selections": (v5/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CommentContent",
                        "kind": "LinkedField",
                        "name": "commentContents",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "content",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "createdAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Avatar",
                                "kind": "LinkedField",
                                "name": "avatar",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MovieMetainfo",
                    "kind": "LinkedField",
                    "name": "movieMetainfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "direction",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "angle",
                        "storageKey": null
                      },
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "sourceUrl",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Club",
                        "kind": "LinkedField",
                        "name": "club",
                        "plural": false,
                        "selections": (v5/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "trimmingStart",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "trimmingEnd",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "duration",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "width",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "height",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "gender",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hand",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "place",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "swing",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currentPage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "limit",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": [
          "swingFrameId"
        ],
        "handle": "connection",
        "key": "SwingFrameMovies_swingClassifications",
        "kind": "LinkedHandle",
        "name": "swingClassifications"
      }
    ]
  },
  "params": {
    "cacheID": "975be092aaeba55105bffa944bf949a2",
    "id": null,
    "metadata": {},
    "name": "SwingFrameMoviesPaginationQuery",
    "operationKind": "query",
    "text": "query SwingFrameMoviesPaginationQuery(\n  $after: String\n  $first: Int\n  $swingFrameId: ID!\n) {\n  ...SwingFrameMoviesPagination_3ZrwhQ\n}\n\nfragment SwingFrameMoviesPagination_3ZrwhQ on Query {\n  swingClassifications(first: $first, after: $after, swingFrameId: $swingFrameId) {\n    edges {\n      node {\n        id\n        movieClip {\n          id\n          ...SwingPositionClassificationItemClip\n        }\n        movieMetainfo {\n          ...SwingPositionClassificationItemMeta\n          id\n        }\n        __typename\n      }\n      cursor\n    }\n    currentPage\n    totalCount\n    limit\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment SwingPositionClassificationItemClip on MovieClip {\n  id\n  ballTrajectory\n  swingImage {\n    id\n    signedUrl\n  }\n  swingClassification {\n    id\n    needReview\n    secondary\n    swingFrame {\n      id\n      name\n    }\n  }\n  commentContents {\n    id\n    content\n    createdAt\n    user {\n      name\n      avatar {\n        signedUrl\n        id\n      }\n      id\n    }\n  }\n  ...SwingPositionClassificationRemove\n}\n\nfragment SwingPositionClassificationItemMeta on MovieMetainfo {\n  direction\n  angle\n  ...SwingPositionClassificationMeta\n  ...useMovieMetainfo\n}\n\nfragment SwingPositionClassificationMeta on MovieMetainfo {\n  id\n  sourceUrl\n  club {\n    id\n    name\n  }\n  trimmingStart\n  trimmingEnd\n  duration\n  width\n  height\n  angle\n  direction\n  gender\n  hand\n  place\n  swing\n}\n\nfragment SwingPositionClassificationRemove on MovieClip {\n  id\n}\n\nfragment useMovieMetainfo on MovieMetainfo {\n  id\n  club {\n    id\n    name\n  }\n  angle\n  direction\n  gender\n  hand\n  place\n  swing\n}\n"
  }
};
})();

(node as any).hash = "f4042a9f81839df841e34a6781b0373b";

export default node;
