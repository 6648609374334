import { Controller } from "react-hook-form";
import { CommonProps } from "@components/molecules/TextInput/type";
import { TextField } from "@mui/material";
import React from "react";

interface Props extends CommonProps {
  type?: "text" | "email" | "tel" | "url" | "search" | "numeric";
  numberOfLines?: number;
}

export default function TextFieldUi({
  type = "text",
  label,
  name,
  placeholder,
  disabled,
  maxLength,
  required,
  control,
  numberOfLines = 1,
}: Props) {
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { value, onBlur, onChange: onChangeText },
        fieldState: { invalid },
      }) => (
        <TextField
          disabled={disabled}
          error={invalid}
          label={label}
          multiline={numberOfLines > 1}
          onBlur={onBlur}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            if (
              maxLength !== undefined &&
              event.target.value.length > maxLength
            ) {
              return;
            }
            onChangeText(event.target.value);
          }}
          placeholder={placeholder}
          required={required}
          rows={numberOfLines}
          style={{
            width: "100%",
            margin: "0px",
          }}
          type={type}
          value={value}
        />
      )}
    />
  );
}
