import React, { useRef, useEffect } from "react";
import { Breadcrumbs, Box, ListItem, Divider, List } from "@mui/material";
import Spacer from "@components/atoms/Spacer";
import Text from "@components/atoms/Text";
import graphql from "babel-plugin-relay/macro";
import { usePaginationFragment, useFragment } from "react-relay";
import { SwingFrameMoviesPagination$key } from "@generated/SwingFrameMoviesPagination.graphql";
import { SwingFrameMoviesQuery$key } from "@generated/SwingFrameMoviesQuery.graphql";
import { SwingFrameMoviesFrame$key } from "@generated/SwingFrameMoviesFrame.graphql";
import SwingPositionClassificationItem from "@components/molecules/SwingPositionClassification/SwingPositionClassificationItem";
import OffsetPagination from "@components/atoms/OffsetPagination";
import { SwingFrameAngle, PageAnchorName } from "@constants/App";
import useQueryParams from "@lib/hooks/useQueryParams";
import useScroll from "@lib/hooks/useScroll";

const query = graphql`
  fragment SwingFrameMoviesPagination on Query
  @refetchable(queryName: "SwingFrameMoviesPaginationQuery")
  @argumentDefinitions(
    after: { type: "String" }
    first: { type: "Int" }
    swingFrameId: { type: "ID!" }
  ) {
    swingClassifications(
      first: $first
      after: $after
      swingFrameId: $swingFrameId
    ) @connection(key: "SwingFrameMovies_swingClassifications") {
      edges {
        node {
          id
          movieClip {
            id
            ...SwingPositionClassificationItemClip
          }
          movieMetainfo {
            ...SwingPositionClassificationItemMeta
          }
        }
      }
      currentPage
      totalCount
      limit
    }
  }
`;

const masterQuery = graphql`
  fragment SwingFrameMoviesQuery on Query {
    swingPositions {
      id
      name
    }
    ...SwingPositionClassificationItemQuery
  }
`;

const swingQuery = graphql`
  fragment SwingFrameMoviesFrame on SwingFrame {
    id
    angle
    name
  }
`;

export default function SwingFrameMovies({
  swingPositionsFragment,
  masterFragment,
  frameFragment,
}: {
  swingPositionsFragment: SwingFrameMoviesPagination$key;
  masterFragment: SwingFrameMoviesQuery$key;
  frameFragment: SwingFrameMoviesFrame$key;
}) {
  const listItemRef = useRef<HTMLLIElement | null>(null);
  const { data } = usePaginationFragment(query, swingPositionsFragment);
  const { edges, currentPage, limit, totalCount } = data.swingClassifications;
  const master = useFragment<SwingFrameMoviesQuery$key>(
    masterQuery,
    masterFragment
  );
  const { angle, name, id } = useFragment<SwingFrameMoviesFrame$key>(
    swingQuery,
    frameFragment
  );
  const param = useQueryParams(PageAnchorName);
  const anchor = param[PageAnchorName];
  const { scrollTo } = useScroll();
  useEffect(() => {
    if (listItemRef.current !== null) {
      scrollTo(listItemRef);
    }
  }, [scrollTo]);
  return (
    <Box>
      <Breadcrumbs separator=">">
        <Text bold>{SwingFrameAngle[angle]}</Text>
        <Text bold>{name}</Text>
      </Breadcrumbs>
      <Spacer height={8} />
      <Divider />
      <Spacer height={8} />
      <List>
        {edges.map(({ node }) => (
          <React.Fragment key={node.id}>
            <ListItem
              ref={(ref) => {
                if (ref !== null && anchor === node.movieClip.id) {
                  listItemRef.current = ref;
                }
              }}
            >
              <SwingPositionClassificationItem
                editable
                masterFragment={master}
                movieClipFragment={node.movieClip}
                movieMetainfoFragment={node.movieMetainfo}
              />
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>
      <OffsetPagination
        currentPage={currentPage}
        limit={limit}
        path={`swing_frames/${id}/movies`}
        totalCount={totalCount}
      />
    </Box>
  );
}
