import React, { useEffect, useRef } from "react";
import { Box, ListItem, Divider, List, Stack } from "@mui/material";
import Text from "@components/atoms/Text";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { MovieInReviewLabeling$key } from "@generated/MovieInReviewLabeling.graphql";
import { MovieInReviewLabelingAdmin$key } from "@generated/MovieInReviewLabelingAdmin.graphql";
import { MovieInReviewLabelingQuery$key } from "@generated/MovieInReviewLabelingQuery.graphql";
import useQueryParams from "@lib/hooks/useQueryParams";
import useScroll from "@lib/hooks/useScroll";
import Colors from "@constants/Colors";
import { PageAnchorName } from "@constants/App";
import SwingPositionClassificationItem from "@components/molecules/SwingPositionClassification/SwingPositionClassificationItem";
import SwingPositionClassificationSummary from "@components/molecules/SwingPositionClassification/SwingPositionClassificationSummary";
import SwingPositionClassificationChat from "@components/molecules/SwingPositionClassification/SwingPositionClassificationChat";
import MovieInReviewApprove from "@components/organisms/MovieInReview/MovieInReviewApprove";
import MovieInReviewRemand from "@components/organisms/MovieInReview/MovieInReviewRemand";
import { isNil } from "@lib/utils/commons";

const query = graphql`
  fragment MovieInReviewLabeling on MovieMetainfo {
    id
    movieMetainfoJobs {
      id
      type
      status
      reviewer {
        id
      }
      ...MovieInReviewRemandData
      ...MovieInReviewApproveData
    }
    ...SwingPositionClassificationSummary
    ...SwingPositionClassificationChat
    ...SwingPositionClassificationItemMeta
    movieClips {
      id
      ...SwingPositionClassificationItemClip
    }
  }
`;

const adminQuery = graphql`
  fragment MovieInReviewLabelingAdmin on User {
    id
  }
`;

const masterQuery = graphql`
  fragment MovieInReviewLabelingQuery on Query {
    swingPositions {
      id
      name
    }
    ...SwingPositionClassificationItemQuery
    ...SwingPositionClassificationSummaryQuery
  }
`;

export default function MovieInReviewLabeling({
  movieMetainfoFragment,
  adminFragment,
  masterFragment,
}: {
  movieMetainfoFragment: MovieInReviewLabeling$key;
  adminFragment: MovieInReviewLabelingAdmin$key;
  masterFragment: MovieInReviewLabelingQuery$key;
}): JSX.Element {
  const listItemRef = useRef<HTMLLIElement | null>(null);
  const { id: adminId } = useFragment<MovieInReviewLabelingAdmin$key>(
    adminQuery,
    adminFragment
  );
  const data = useFragment<MovieInReviewLabeling$key>(
    query,
    movieMetainfoFragment
  );
  const { movieClips, movieMetainfoJobs } = data;
  const master = useFragment<MovieInReviewLabelingQuery$key>(
    masterQuery,
    masterFragment
  );
  const labelingJob = movieMetainfoJobs.find(
    (row) => row.type === "LabelingSwing"
  );
  const param = useQueryParams(PageAnchorName);
  const anchor = param[PageAnchorName];
  const { scrollTo } = useScroll();
  useEffect(() => {
    if (listItemRef.current !== null) {
      scrollTo(listItemRef);
    }
  }, [scrollTo]);
  if (isNil(labelingJob)) {
    return <Text>レビュー中のラベル付はありません</Text>;
  }
  return (
    <>
      <SwingPositionClassificationSummary
        movieMetainfoFragment={data}
        swingPositionsFragment={master}
      />
      <Box>
        <List>
          {movieClips.map((movieClip) => (
            <React.Fragment key={movieClip.id}>
              <ListItem
                ref={(ref) => {
                  if (ref !== null && anchor === movieClip.id) {
                    listItemRef.current = ref;
                  }
                }}
                sx={{
                  backgroundColor:
                    anchor === movieClip.id ? Colors.yellow10 : undefined,
                }}
              >
                <SwingPositionClassificationItem
                  editable
                  masterFragment={master}
                  movieClipFragment={movieClip}
                  movieMetainfoFragment={data}
                />
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>
        {!isNil(labelingJob) &&
          labelingJob.reviewer?.id === adminId &&
          labelingJob.status === "in_review" && (
            <React.Fragment key={labelingJob.id}>
              <Stack direction="row" justifyContent="center" spacing={2}>
                <MovieInReviewApprove movieMetainfoFragment={labelingJob} />
                <MovieInReviewRemand movieMetainfoFragment={labelingJob} />
              </Stack>
            </React.Fragment>
          )}
      </Box>
      <SwingPositionClassificationChat movieMetainfoFragment={data} />
    </>
  );
}
