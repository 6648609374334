import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { Members$key } from "@generated/Members.graphql";
import { dateFormat } from "@lib/utils/date";
import { decodeId } from "@lib/utils/convertId";
import { Link } from "react-router-dom";
import { UserType } from "@constants/App";

const query = graphql`
  fragment Members on Query {
    members {
      id
      name
      email
      type
      createdAt
    }
  }
`;

export default function Members({
  usersFragment,
}: {
  usersFragment: Members$key;
}) {
  const { members } = useFragment(query, usersFragment);
  return (
    <Table aria-label="simple table" sx={{ minWidth: 650 }}>
      <TableHead>
        <TableRow>
          <TableCell component="th">ID</TableCell>
          <TableCell component="th">種別</TableCell>
          <TableCell component="th">名前</TableCell>
          <TableCell component="th">Email</TableCell>
          <TableCell component="th">登録日</TableCell>
          <TableCell component="th">詳細</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {members.map((member) => (
          <TableRow key={member.id}>
            <TableCell>{decodeId(member.id)}</TableCell>
            <TableCell>{UserType[member.type]}</TableCell>
            <TableCell>{member.name}</TableCell>
            <TableCell>{member.email}</TableCell>
            <TableCell>
              {dateFormat(member.createdAt, "YYYY年M月D日")}
            </TableCell>
            <TableCell>
              <Link to={`/members/${member.id}`}>詳細</Link>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
