/**
 * @generated SignedSource<<a23a0265dce3705d3c3aeb9ac060c066>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MovieInReviewAnnotationAdmin$data = {
  readonly id: string;
  readonly " $fragmentType": "MovieInReviewAnnotationAdmin";
};
export type MovieInReviewAnnotationAdmin$key = {
  readonly " $data"?: MovieInReviewAnnotationAdmin$data;
  readonly " $fragmentSpreads": FragmentRefs<"MovieInReviewAnnotationAdmin">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MovieInReviewAnnotationAdmin",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "9b2253df3b3ba96993337f8c76352967";

export default node;
