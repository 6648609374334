import React from "react";
import { ListItem, Divider, List } from "@mui/material";
import Text from "@components/atoms/Text";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import Spacer from "@components/atoms/Spacer";
import { AnnotateObject$key } from "@generated/AnnotateObject.graphql";
import ObjectDetectionItemSummery from "@components/molecules/ObjectDetectionItem/ObjectDetectionItemSummery";
import ObjectDetectionItemRow from "@components/molecules/ObjectDetectionItem/ObjectDetectionItemRow";
import AnnotateObjectHead from "@components/organisms/AnnotateObject/AnnotateObjectHead";

const query = graphql`
  fragment AnnotateObject on MovieMetainfoJob {
    id
    type
    ...AnnotateObjectHead
    ...ObjectDetectionItemSummery
    movieMetainfo {
      ...ObjectDetectionItemRowDirection
      movieClips {
        id
        ...ObjectDetectionItemRow
      }
    }
  }
`;

export default function AnnotateObject({
  movieMetainfoJobFragment,
}: {
  movieMetainfoJobFragment: AnnotateObject$key;
}) {
  const movieMetainfoJob = useFragment<AnnotateObject$key>(
    query,
    movieMetainfoJobFragment
  );
  const { movieMetainfo } = movieMetainfoJob;
  const { movieClips } = movieMetainfo;
  if (movieClips.length === 0) {
    <Text>スイング画像がありません。ラベリングが完了したら表示されます。</Text>;
  }
  return (
    <>
      <AnnotateObjectHead movieMetainfoJobFragment={movieMetainfoJob} />
      <ObjectDetectionItemSummery movieMetainfoJobFragment={movieMetainfoJob} />
      <Spacer height={16} />
      <Divider />
      <List>
        {movieClips.map((movieClip) => (
          <React.Fragment key={movieClip.id}>
            <ListItem>
              <ObjectDetectionItemRow
                movieClipFragment={movieClip}
                movieMetainfoFragment={movieMetainfo}
              />
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>
    </>
  );
}
