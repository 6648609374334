/**
 * @generated SignedSource<<7636c92c91846860eeb7d6906384091d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MovieJobsIgnoredItemSwingQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SwingPositionClassificationMetaQuery">;
  readonly " $fragmentType": "MovieJobsIgnoredItemSwingQuery";
};
export type MovieJobsIgnoredItemSwingQuery$key = {
  readonly " $data"?: MovieJobsIgnoredItemSwingQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"MovieJobsIgnoredItemSwingQuery">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MovieJobsIgnoredItemSwingQuery",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SwingPositionClassificationMetaQuery"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "13311c871803f34ff053b3b08546e494";

export default node;
