import { useEffect, Suspense } from "react";
import ContentLayout from "@navigation/ContentLayout";
import SwingScene from "@components/templates/SwingScene";
import graphql from "babel-plugin-relay/macro";
import {
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import { isNil } from "@lib/utils/commons";
import Loading from "@components/atoms/Loading";
import { SwingSceneScreenQuery } from "@generated/SwingSceneScreenQuery.graphql";
import useQueryId from "@lib/hooks/useQueryId";

const query = graphql`
  query SwingSceneScreenQuery($id: ID!) {
    swingScene(id: $id) {
      ...SwingSceneData
    }
    ...SwingSceneSwingFrames
  }
`;

function ScreenContent({
  queryReference,
}: {
  queryReference: PreloadedQuery<SwingSceneScreenQuery>;
}): JSX.Element {
  const data = usePreloadedQuery<SwingSceneScreenQuery>(query, queryReference);
  const { swingScene } = data;
  return (
    <SwingScene swingFramesFragment={data} swingSceneFragment={swingScene} />
  );
}

export default function SwingSceneScreen(): JSX.Element {
  const id = useQueryId();
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<SwingSceneScreenQuery>(query);
  useEffect(() => {
    loadQuery({ id: id ?? null });
    return () => {
      disposeQuery();
    };
  }, [id, loadQuery, disposeQuery]);
  return (
    <ContentLayout
      breadcrumbs={[
        {
          label: "スイング診断シーン一覧",
          path: "/swing_scenes",
        },
      ]}
      title="スイング診断シーン"
    >
      <Suspense fallback={<Loading />}>
        {!isNil(queryReference) && (
          <ScreenContent queryReference={queryReference} />
        )}
      </Suspense>
    </ContentLayout>
  );
}
