/**
 * @generated SignedSource<<64c1ff9a058a39a3738faae50d66e96e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ObjectDetectionObjectType = "ball" | "head" | "shaft";
import { FragmentRefs } from "relay-runtime";
export type CommentPostList$data = {
  readonly movieClips: ReadonlyArray<{
    readonly id: string;
    readonly objectDetections: ReadonlyArray<{
      readonly id: string;
      readonly objectType: ObjectDetectionObjectType;
    }>;
  }>;
  readonly " $fragmentType": "CommentPostList";
};
export type CommentPostList$key = {
  readonly " $data"?: CommentPostList$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommentPostList">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommentPostList",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "MovieClip",
      "kind": "LinkedField",
      "name": "movieClips",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ObjectDetection",
          "kind": "LinkedField",
          "name": "objectDetections",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "objectType",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "MovieMetainfo",
  "abstractKey": null
};
})();

(node as any).hash = "14c6f67a35ff2c82760be940916a6223";

export default node;
