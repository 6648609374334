import { Breadcrumbs, Link, Card } from "@mui/material";
import Spacer from "@components/atoms/Spacer";
import Text from "@components/atoms/Text";

export type Breadcrumb = {
  label: string;
  path: string;
};

export default function ContentLayout({
  title,
  children,
  breadcrumbs,
  top = false,
}: {
  title: string;
  children: JSX.Element;
  breadcrumbs?: Breadcrumb[];
  top?: boolean;
}) {
  return (
    <>
      <Spacer height={72} />
      {top === true ? (
        <Text bold>{title}</Text>
      ) : (
        <Breadcrumbs>
          <Link color="inherit" href="/">
            TOP
          </Link>
          {breadcrumbs !== undefined &&
            breadcrumbs.map((breadcrumb) => (
              <Link
                key={breadcrumb.path}
                color="inherit"
                href={breadcrumb.path}
              >
                {breadcrumb.label}
              </Link>
            ))}
          <Text bold>{title}</Text>
        </Breadcrumbs>
      )}
      <Spacer height={16} />
      <Card sx={{ padding: 2 }}>{children}</Card>
    </>
  );
}
