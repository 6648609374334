/* eslint-disable */
/**
 * recoilをfunctional componentから切り離して使うための対応
 * GraphQL通信の例外エラーのハンドリングに使用する。
 * https://github.com/facebookexperimental/Recoil/issues/289#issuecomment-777249693
 */
import { RecoilState, useRecoilCallback, atom, useRecoilState } from "recoil";

type ErrorCodeType =
  | "Upgrade"
  | "Maintenance"
  | "ServiceDown"
  | "Expired"
  | "NotFound"
  | "NetworkError";

export type ErrorType = {
  code?: ErrorCodeType;
  message?: string;
};

export const errorAtom = atom<ErrorType | null>({
  key: "EXCEPTION_ERROR",
  default: null,
});

export default function ErrorModule() {
  const [error, setError] = useRecoilState(errorAtom);

  function get(): ErrorType | null {
    return error;
  }

  function set(data: ErrorType | null): void {
    try {
      setError(data);
    } catch (e) {
      throw e;
    }
  }
  return {
    get,
    set,
  };
}

export let setExceptionalError: <T>(
  recoilVal: RecoilState<T>,
  valOrUpdater: ((currVal: T) => T) | T
) => void = null as any;

export function ExceptionalError() {
  useRecoilCallback(({ set }) => {
    setExceptionalError = set;
    return async () => {};
  })();

  return null;
}
