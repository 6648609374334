import { useCallback } from "react";
import graphql from "babel-plugin-relay/macro";
import { useMutation } from "react-relay";
import {
  useReadCommentMutation,
  useReadCommentMutation$data,
} from "@generated/useReadCommentMutation.graphql";
import { reset } from "@lib/hooks/useNotificationCount";

const mutation = graphql`
  mutation useReadCommentMutation($input: UpdateCommentUserMutationInput!) {
    updateCommentUser(input: $input) {
      __typename
      ... on CommentUser {
        id
        user {
          id
        }
        lastReadAt
        unreadCount
      }
      ... on UserError {
        message
      }
    }
  }
`;

export default function useReadComment() {
  const [commit] = useMutation<useReadCommentMutation>(mutation);
  const readComment = useCallback(
    async (commentId: string) => {
      await new Promise<useReadCommentMutation$data["updateCommentUser"]>(
        (resolve) => {
          commit({
            variables: {
              input: {
                commentId,
              },
            },
            onCompleted: ({ updateCommentUser }) => {
              resolve(updateCommentUser);
            },
          });
        }
      );
      reset();
    },
    [commit]
  );
  return { readComment };
}
