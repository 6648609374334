import { useMemo } from "react";
import { Stack, Box, Chip, Badge } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { CommentsItem$key } from "@generated/CommentsItem.graphql";
import { CommentsItemAdmin$key } from "@generated/CommentsItemAdmin.graphql";
import { dateFormat } from "@lib/utils/date";
import Spacer from "@components/atoms/Spacer";
import Text from "@components/atoms/Text";
import Avatar from "@components/atoms/Avatar";
import useMovieMetainfo from "@lib/hooks/useMovieMetainfo";
import Thumbnail from "@components/atoms/Thumbnail";
import { Link } from "react-router-dom";

const query = graphql`
  fragment CommentsItem on Comment {
    id
    contentCount
    lastPostedAt
    movieMetainfo {
      thumbnail
      ...useMovieMetainfo
    }
    commentUsers {
      id
      lastReadAt
      unreadCount
      user {
        id
        name
        avatar {
          signedUrl
        }
      }
    }
  }
`;

const adminQuery = graphql`
  fragment CommentsItemAdmin on User {
    id
  }
`;

export default function CommentsItem({
  commentFragment,
  adminFragment,
}: {
  commentFragment: CommentsItem$key;
  adminFragment: CommentsItemAdmin$key;
}) {
  const { id: adminId } = useFragment<CommentsItemAdmin$key>(
    adminQuery,
    adminFragment
  );
  const { id, contentCount, lastPostedAt, movieMetainfo, commentUsers } =
    useFragment(query, commentFragment);
  const metainfo = useMovieMetainfo({ movieMetainfoFragment: movieMetainfo });
  const unreadCount = useMemo<number>(
    () =>
      commentUsers.find((commentUser) => commentUser.user.id === adminId)
        ?.unreadCount ?? 0,
    [adminId, commentUsers]
  );
  return (
    <Stack direction="column" sx={{ width: "100%" }}>
      <Stack
        alignItems="flex-start"
        direction="row"
        justifyContent="flex-start"
        spacing={2}
      >
        <Stack direction="column" flex={1} spacing={1}>
          <Stack direction="row" spacing={1}>
            <Text style={{ fontSize: 10 }} variant="subtitle2">
              {dateFormat(lastPostedAt, "M月D日 HH:mm")}
            </Text>
          </Stack>
          <Stack alignItems="flex-start" direction="row" spacing={1}>
            <Thumbnail size={150} url={movieMetainfo.thumbnail} />
            <Stack
              alignItems="flex-start"
              direction="row"
              flex={1}
              flexWrap="wrap"
            >
              {metainfo.length > 0 && (
                <>
                  {metainfo.map((row) => (
                    <Box key={row} sx={{ padding: "2px" }}>
                      <Chip
                        color="primary"
                        label={row}
                        size="small"
                        variant="outlined"
                      />
                    </Box>
                  ))}
                  <Spacer height={8} />
                </>
              )}
            </Stack>
          </Stack>
          <Stack direction="row" spacing={1}>
            {commentUsers.map((commentUser) => (
              <Avatar
                key={commentUser.id}
                size={24}
                url={commentUser.user.avatar?.signedUrl}
              />
            ))}
            <Spacer width={4} />
            <Link to={`/comments/${id}`}>{contentCount} Comments</Link>
            <Spacer width={1} />
            <Badge badgeContent={unreadCount} color="error" sx={{ top: 8 }} />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
