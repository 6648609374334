import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { ArticleData$key } from "@generated/ArticleData.graphql";
import ArticleMain from "@components/organisms/Article/ArticleMain";
import ArticleSection from "@components/organisms/Article/ArticleSection";
import Spacer from "@components/atoms/Spacer";

const query = graphql`
  fragment ArticleData on Article {
    ...ArticleMainData
    articleSections {
      id
      ...ArticleSectionData
    }
  }
`;

export default function Article({
  articleFragment,
}: {
  articleFragment: ArticleData$key;
}): JSX.Element {
  const data = useFragment<ArticleData$key>(query, articleFragment);
  return (
    <>
      <ArticleMain articleFragment={data} />
      <Spacer height={24} />
      {data.articleSections.map((articleSection) => (
        <ArticleSection
          key={articleSection.id}
          articleSectionFragment={articleSection}
        />
      ))}
    </>
  );
}
