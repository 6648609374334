import { useCallback, useState } from "react";
import { Stack, IconButton } from "@mui/material";
import Icon from "@components/atoms/Icon";
import Text from "@components/atoms/Text";
import Message from "@components/molecules/Message";
import Loading from "@components/atoms/Loading";
import { FilePicker } from "@components/molecules/TextInput";
import graphql from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import { UploadableMap } from "relay-runtime";
import { AnnotateObjectHead$key } from "@generated/AnnotateObjectHead.graphql";
import {
  AnnotateObjectHeadMutation,
  AnnotateObjectHeadMutation$data,
} from "@generated/AnnotateObjectHeadMutation.graphql";
import { resolveError } from "@lib/utils/error";
import { isNil } from "@lib/utils/commons";
import Colors from "@constants/Colors";
import usePreventDoubleClick from "@lib/hooks/usePreventDoubleClick";
import useMessage from "@lib/hooks/useMessage";
import useMovieClipDownload from "@lib/hooks/useMovieClipDownload";
import { decodeId } from "@lib/utils/convertId";
import clipBoard from "@lib/utils/clipBoard";

const query = graphql`
  fragment AnnotateObjectHead on MovieMetainfoJob {
    id
    type
    movieMetainfo {
      id
    }
  }
`;

const importXml = graphql`
  mutation AnnotateObjectHeadMutation(
    $input: ImportObjectDetectionsMutationInput!
  ) {
    importObjectDetections(input: $input) {
      __typename
      ... on MovieMetainfoJob {
        id
        movieMetainfo {
          movieClips {
            id
            swingImage {
              id
              signedUrl
            }
            objectDetections {
              id
              objectType
              xmin
              ymin
              xmax
              ymax
              commentContents {
                id
                content
                createdAt
                user {
                  name
                  avatar {
                    signedUrl
                  }
                }
              }
            }
          }
        }
      }
      ... on UserError {
        message
      }
    }
  }
`;

export default function AnnotateObjectHead({
  movieMetainfoJobFragment,
}: {
  movieMetainfoJobFragment: AnnotateObjectHead$key;
}) {
  const [, setMessage] = useMessage();
  const [confirm, setConfirm] = useState<boolean>(false);
  const { processing, onClick, onRelease } = usePreventDoubleClick();
  const { id, movieMetainfo } = useFragment<AnnotateObjectHead$key>(
    query,
    movieMetainfoJobFragment
  );
  const { id: movieMetainfoId } = movieMetainfo;
  const { download } = useMovieClipDownload();
  const onDownload = useCallback(async () => {
    if (processing) {
      return;
    }
    onClick();

    await download({
      movieMetainfoId,
      onError: (message: string) => {
        setMessage({
          mode: "error",
          title: message,
        });
      },
    });
    onRelease();
    setConfirm(false);
  }, [movieMetainfoId, download, processing, onRelease, onClick, setMessage]);
  const handleCopy = useCallback(async () => {
    await clipBoard(`${decodeId(id)}`);
    setMessage({
      mode: "toast",
      title: "IDをコピーしました",
    });
  }, [id, setMessage]);

  const [commit] = useMutation<AnnotateObjectHeadMutation>(importXml);
  const handleUload = useCallback(
    async (uploadables: UploadableMap | null) => {
      try {
        if (uploadables === null) {
          return;
        }
        const { type } = uploadables["variables.input.file"];
        if (isNil(type) || type !== "text/xml") {
          throw new Error("XMLファイルを選択してください");
        }
        if (processing) {
          return;
        }
        onClick();
        const result = await new Promise<
          AnnotateObjectHeadMutation$data["importObjectDetections"]
        >((resolve) => {
          commit({
            variables: {
              input: {
                movieMetainfoJobId: id,
                file: null,
              },
            },
            uploadables,
            onCompleted({ importObjectDetections }) {
              resolve(importObjectDetections);
            },
          });
        });
        if (result.__typename === "UserError") {
          setMessage({
            mode: "error",
            title: "インポートできませんでした",
            subline: result.message,
          });
        } else {
          setMessage({
            mode: "toast",
            title: "インポートしました",
          });
        }
      } catch (e: unknown) {
        setMessage({
          title: "インポートできませんでした",
          subline:
            resolveError(e).message ??
            "XMLファイルをアップロードできませんでした",
          mode: "error",
        });
      } finally {
        onRelease();
      }
    },
    [commit, id, processing, onRelease, onClick, setMessage]
  );

  return (
    <>
      <Stack direction="row" justifyContent="flex-end" spacing={2}>
        <Stack alignItems="center" direction="row" flex={1} spacing={1}>
          <Text>ID:{decodeId(id)}</Text>
          <IconButton color="secondary" onClick={handleCopy}>
            <Icon color={Colors.black} name="copy" size={24} />
          </IconButton>
        </Stack>
        <FilePicker
          content={<Text color={Colors.white}>XMLをインポート</Text>}
          onChange={handleUload}
          type="xml"
        />
        <IconButton color="secondary" onClick={() => setConfirm(true)}>
          <Icon color={Colors.black} name="download" size={24} />
        </IconButton>
      </Stack>

      <Message
        closeText="キャンセル"
        confirmText="ダウンロードする"
        onClose={() => setConfirm(false)}
        onConfirm={onDownload}
        title="スイング画像をダウンロードしますか？"
        visible={confirm}
      />

      {processing && <Loading mask />}
    </>
  );
}
