import { useState } from "react";
import { Stack, Button, Divider } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { SwingFrameData$key } from "@generated/SwingFrameData.graphql";
import { SwingFrameSwingPositions$key } from "@generated/SwingFrameSwingPositions.graphql";
import SwingFrameDisplay from "@components/organisms/SwingFrame/SwingFrameDisplay";
import SwingFrameInput from "@components/organisms/SwingFrame/SwingFrameInput";
import SwingFrameRemove from "@components/organisms/SwingFrame/SwingFrameRemove";

const query = graphql`
  fragment SwingFrameData on SwingFrame {
    id
    name
    description
    angle
    orderNumber
    thumbnail {
      id
      signedUrl
    }
    swingPosition {
      id
    }
    ...SwingFrameDisplay
    ...SwingFrameRemove
  }
`;

const swingQuery = graphql`
  fragment SwingFrameSwingPositions on Query {
    ...SwingFrameInputSwingPositions
  }
`;

export default function SwingFrame({
  definitionFragment,
  swingFragment,
}: {
  definitionFragment: SwingFrameData$key;
  swingFragment: SwingFrameSwingPositions$key;
}): JSX.Element {
  const [edit, setEdit] = useState<boolean>(false);
  const data = useFragment<SwingFrameData$key>(query, definitionFragment);
  const {
    id,
    name,
    description,
    angle,
    swingPosition,
    orderNumber,
    thumbnail,
  } = data;
  const swigPositions = useFragment<SwingFrameSwingPositions$key>(
    swingQuery,
    swingFragment
  );
  return (
    <Stack direction="column" spacing={2}>
      {edit ? (
        <SwingFrameInput
          input={{
            id,
            swingPositionId: swingPosition.id,
            name,
            description,
            angle,
            orderNumber,
            thumbnail: thumbnail?.signedUrl,
          }}
          onClose={() => setEdit(false)}
          swingPositionsFragment={swigPositions}
        />
      ) : (
        <>
          <SwingFrameDisplay definitionFragment={data} />
          <Divider />
          <Stack direction="row" justifyContent="center" spacing={2}>
            <Button onClick={() => setEdit(true)} variant="contained">
              編集する
            </Button>
            <SwingFrameRemove definitionFragment={data} />
          </Stack>
        </>
      )}
    </Stack>
  );
}
