/**
 * @generated SignedSource<<fc074cf1866ea80667540cd28c5cf5b7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SwingFrameAngle = "back" | "front";
import { FragmentRefs } from "relay-runtime";
export type SwingPositionClassificationItemQuery$data = {
  readonly swingPositions: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
    readonly swingFrames: ReadonlyArray<{
      readonly angle: SwingFrameAngle;
      readonly id: string;
      readonly name: string;
    }>;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"SwingPositionClassificationMetaQuery">;
  readonly " $fragmentType": "SwingPositionClassificationItemQuery";
};
export type SwingPositionClassificationItemQuery$key = {
  readonly " $data"?: SwingPositionClassificationItemQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"SwingPositionClassificationItemQuery">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SwingPositionClassificationItemQuery",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SwingPosition",
      "kind": "LinkedField",
      "name": "swingPositions",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "SwingFrame",
          "kind": "LinkedField",
          "name": "swingFrames",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "angle",
              "storageKey": null
            },
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SwingPositionClassificationMetaQuery"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "cd1bb00b1cddded39462e946ddb3d468";

export default node;
