import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { LabelingSwing$key } from "@generated/LabelingSwing.graphql";
import { LabelingSwingQuery$key } from "@generated/LabelingSwingQuery.graphql";
import LabelingSwingWorking from "@components/organisms/LabelingSwing/LabelingSwingWorking";
import LabelingSwingConfirm from "@components/organisms/LabelingSwing/LabelingSwingConfirm";

const query = graphql`
  fragment LabelingSwing on MovieJob {
    ...LabelingSwingWorking
    ...LabelingSwingConfirm
  }
`;

const masterQuery = graphql`
  fragment LabelingSwingQuery on Query {
    ...LabelingSwingWorkingQuery
    ...LabelingSwingConfirmQuery
  }
`;

export type TabType = "working" | "confirm";

export default function LabelingSwing({
  movieJobFragment,
  swingsFragment,
  tab,
}: {
  movieJobFragment: LabelingSwing$key;
  swingsFragment: LabelingSwingQuery$key;
  tab: TabType;
}) {
  const movieJob = useFragment<LabelingSwing$key>(query, movieJobFragment);
  const swings = useFragment<LabelingSwingQuery$key>(
    masterQuery,
    swingsFragment
  );
  return tab === "working" ? (
    <LabelingSwingWorking masterFragment={swings} movieJobFragment={movieJob} />
  ) : (
    <LabelingSwingConfirm masterFragment={swings} movieJobFragment={movieJob} />
  );
}
