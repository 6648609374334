import { useEffect, Suspense, useState } from "react";
import { Outlet } from "react-router-dom";
import { Container, Box, AppBar, Drawer, Stack } from "@mui/material";
import Loading from "@components/atoms/Loading";
import graphql from "babel-plugin-relay/macro";
import {
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import Header from "@components/organisms/Navigation/NavigationHeader";
import DrawerContent from "@components/organisms/Navigation/NavigationDrawer";
import { DrawerLayoutQuery } from "@generated/DrawerLayoutQuery.graphql";
import { DrawerWidth } from "@constants/App";
import { isNil } from "@lib/utils/commons";
import Colors from "@constants/Colors";

const query = graphql`
  query DrawerLayoutQuery {
    admin {
      ...NavigationHeaderAdmin
    }
    ...NavigationDrawerCounts
  }
`;

function ScreenContent({
  queryReference,
}: {
  queryReference: PreloadedQuery<DrawerLayoutQuery>;
}) {
  const [open, setOpen] = useState<boolean>(false);
  const data = usePreloadedQuery<DrawerLayoutQuery>(query, queryReference);
  return (
    <Container
      sx={{ flex: 1, display: "flex", backgroundColor: Colors.gray10 }}
    >
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Header
          adminFragment={data.admin}
          drawer={open ? "open" : "close"}
          toggleDrawer={setOpen}
        />
      </AppBar>
      <Box component="nav">
        <Drawer
          container={window.document.body}
          ModalProps={{
            keepMounted: true,
          }}
          onClose={() => setOpen(false)}
          open={open}
          sx={(theme) => ({
            [theme.breakpoints.up("md")]: {
              display: "none",
            },
            width: DrawerWidth,
          })}
          variant="temporary"
        >
          <DrawerContent drawerFragment={data} />
        </Drawer>
        <Drawer
          open
          sx={(theme) => ({
            [theme.breakpoints.down("md")]: {
              display: "none",
            },
            width: DrawerWidth,
          })}
          variant="permanent"
        >
          <DrawerContent drawerFragment={data} />
        </Drawer>
      </Box>

      <Stack
        direction="column"
        flex={1}
        padding={2}
        sx={{
          overflowX: "hidden",
        }}
      >
        <Outlet />
      </Stack>
    </Container>
  );
}

export default function DrawerLayout() {
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<DrawerLayoutQuery>(query);
  useEffect(() => {
    loadQuery({});
    return () => {
      disposeQuery();
    };
  }, [loadQuery, disposeQuery]);
  return (
    <Suspense fallback={<Loading />}>
      {!isNil(queryReference) && (
        <ScreenContent queryReference={queryReference} />
      )}
    </Suspense>
  );
}
