/**
 * @generated SignedSource<<09ed5395753208cd66371f47c8fc8fac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UserType = "Admin" | "Annotater";
export type CreateUserInviteMutationInput = {
  clientMutationId?: string | null | undefined;
  email: string;
  role: UserType;
};
export type UserInvitesCreateMutation$variables = {
  input: CreateUserInviteMutationInput;
};
export type UserInvitesCreateMutation$data = {
  readonly createUserInvite: {
    readonly __typename: "UserError";
    readonly message: string;
  } | {
    readonly __typename: "UserInvite";
    readonly email: string;
    readonly expiredAt: any;
    readonly id: string;
    readonly role: UserType;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type UserInvitesCreateMutation = {
  response: UserInvitesCreateMutation$data;
  variables: UserInvitesCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "createUserInvite",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "role",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "expiredAt",
            "storageKey": null
          }
        ],
        "type": "UserInvite",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "type": "UserError",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserInvitesCreateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserInvitesCreateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c3a8e1b4b76baba231d9c107b02afbd1",
    "id": null,
    "metadata": {},
    "name": "UserInvitesCreateMutation",
    "operationKind": "mutation",
    "text": "mutation UserInvitesCreateMutation(\n  $input: CreateUserInviteMutationInput!\n) {\n  createUserInvite(input: $input) {\n    __typename\n    ... on UserInvite {\n      id\n      email\n      role\n      expiredAt\n    }\n    ... on UserError {\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "aec62073ceba145c9b5d794227ed18f7";

export default node;
