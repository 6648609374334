/**
 * @generated SignedSource<<b1f9fdb0f1bc2c144be2219b30eeb0ab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MovieInReviewAdmin$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"MovieInReviewAnnotationAdmin" | "MovieInReviewLabelingAdmin">;
  readonly " $fragmentType": "MovieInReviewAdmin";
};
export type MovieInReviewAdmin$key = {
  readonly " $data"?: MovieInReviewAdmin$data;
  readonly " $fragmentSpreads": FragmentRefs<"MovieInReviewAdmin">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MovieInReviewAdmin",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MovieInReviewLabelingAdmin"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MovieInReviewAnnotationAdmin"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "1fdb8f58823ce6013df888a93de18612";

export default node;
