import { useEffect, useRef, useMemo, useCallback } from "react";
import { isNil } from "@lib/utils/commons";
import { Box } from "@mui/material";
import { BoundingBoxColors } from "@constants/Colors";

type Size = {
  width: number;
  height: number;
};

type BBboxInfo = {
  x: number;
  y: number;
  width: number;
  height: number;
  color: string;
};

type BoundingBoxType = "ball" | "head" | "shaft";

type BBox = {
  type: BoundingBoxType;
  xmin: number;
  ymin: number;
  xmax: number;
  ymax: number;
};

type ResizedRatio = {
  widthRatio: number;
  heightRatio: number;
};

export default function BoundingBox({
  boxSize,
  originalSize,
  boundingBoxes,
}: {
  boxSize: Size;
  originalSize: Size;
  boundingBoxes: BBox[];
}) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const { widthRatio, heightRatio } = useMemo<ResizedRatio>(
    () => ({
      widthRatio: boxSize.width / originalSize.width,
      heightRatio: boxSize.height / originalSize.height,
    }),
    [boxSize, originalSize]
  );

  const getBoundingBoxInfo = useCallback(
    (data: BBox): BBboxInfo => ({
      x: data.xmin * widthRatio,
      y: data.ymin * heightRatio,
      width: (data.xmax - data.xmin) * widthRatio,
      height: (data.ymax - data.ymin) * heightRatio,
      color: BoundingBoxColors[data.type],
    }),
    [widthRatio, heightRatio]
  );

  useEffect(() => {
    if (canvasRef !== null && !isNil(canvasRef.current)) {
      const context = canvasRef.current.getContext("2d");
      if (context !== null) {
        context.clearRect(0, 0, boxSize.width, boxSize.height);
        boundingBoxes.forEach((box) => {
          const boxInfo = getBoundingBoxInfo(box);
          context.strokeStyle = boxInfo.color;
          context.lineWidth = 2;
          context.strokeRect(
            boxInfo.x,
            boxInfo.y,
            boxInfo.width,
            boxInfo.height
          );
        });
      }
    }
  }, [
    canvasRef,
    boxSize,
    widthRatio,
    heightRatio,
    boundingBoxes,
    getBoundingBoxInfo,
  ]);

  return (
    <Box sx={{ position: "absolute", top: 0, left: 0, zIndex: 1 }}>
      <canvas ref={canvasRef} height={boxSize.height} width={boxSize.width} />
    </Box>
  );
}
