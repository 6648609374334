import graphql from "babel-plugin-relay/macro";
import { useRefetchableFragment } from "react-relay";
import { useNotificationCount$key } from "@generated/useNotificationCount.graphql";
import Storage from "@lib/utils/storage";
import { dayjs } from "@lib/utils/date";
import { LastNoticedAt, LastNoticeInterval } from "@constants/App";

const storage = new Storage("cookie");
const query = graphql`
  fragment useNotificationCount on Query
  @refetchable(queryName: "useNotificationCountQuery") {
    notificationCount {
      id
      inReviews
      unreadComments
    }
  }
`;

export function refetchable(): boolean {
  const lastAt = storage.getItem(LastNoticedAt);
  if (lastAt === null) {
    return true;
  }
  const lastDate = dayjs(lastAt);
  const now = dayjs();
  const minutesElapsed = now.diff(lastDate, "minute");
  return minutesElapsed > LastNoticeInterval;
}

export function reset() {
  storage.removeItem(LastNoticedAt);
}

export default function useNotificationCount({
  notificationFragment,
}: {
  notificationFragment: useNotificationCount$key;
}) {
  const [data, refetch] = useRefetchableFragment(query, notificationFragment);
  const {
    notificationCount: { inReviews, unreadComments },
  } = data;

  return {
    inReviews,
    unreadComments,
    refetch: () =>
      refetch(
        {},
        {
          onComplete: () => {
            storage.setItem(LastNoticedAt, dayjs().toISOString());
          },
        }
      ),
  };
}
