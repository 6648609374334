import { Stack, Divider } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { MemberData$key } from "@generated/MemberData.graphql";
import MemberDisplay from "@components/organisms/Member/MemberDisplay";
import MemberRemove from "@components/organisms/Member/MemberRemove";

const query = graphql`
  fragment MemberData on User {
    type
    ...MemberDisplay
    ...MemberRemove
  }
`;

export default function Member({
  userFragment,
}: {
  userFragment: MemberData$key;
}): JSX.Element {
  const data = useFragment<MemberData$key>(query, userFragment);
  return (
    <Stack direction="column" spacing={2}>
      <MemberDisplay userFragment={data} />
      {data.type !== "Admin" && (
        <>
          <Divider />
          <Stack direction="row" justifyContent="center" spacing={2}>
            <MemberRemove userFragment={data} />
          </Stack>
        </>
      )}
    </Stack>
  );
}
