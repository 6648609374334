/**
 * @generated SignedSource<<025612fbd2764701a6306b1d1e53653b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommentPost$data = {
  readonly id: string;
  readonly movieMetainfo: {
    readonly id: string;
  };
  readonly " $fragmentType": "CommentPost";
};
export type CommentPost$key = {
  readonly " $data"?: CommentPost$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommentPost">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommentPost",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "MovieMetainfo",
      "kind": "LinkedField",
      "name": "movieMetainfo",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Comment",
  "abstractKey": null
};
})();

(node as any).hash = "c34d352f4ee2e6077e43577bba8cd7c8";

export default node;
