import React, { useEffect, useRef, useState } from "react";
import Icon from "@components/atoms/Icon";
import { isNil } from "@lib/utils/commons";
import { Stack } from "@mui/material";

const Video = React.memo(
  ({
    src,
    autoPlay = false,
  }: {
    src: string | null | undefined;
    autoPlay?: boolean;
  }) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const boxRef = useRef<HTMLDivElement>(null);
    const [videoStyle, setVideoStyle] = useState<React.CSSProperties>({
      width: "auto",
      height: "auto",
    });
    useEffect(() => {
      const video = videoRef.current;
      const handleLoadedMetadata = () => {
        const container = boxRef.current;
        if (container === null || video === null) {
          return;
        }
        const containerWidth = container.offsetWidth;
        const containerHeight = container.offsetHeight;
        const { videoWidth } = video;
        const { videoHeight } = video;

        if (videoWidth / videoHeight > containerWidth / containerHeight) {
          // 横長
          setVideoStyle({
            maxWidth: "90%",
            height: "auto",
          });
        } else {
          // 縦長
          setVideoStyle({
            width: "auto",
            maxHeight: "90%",
          });
        }
      };
      video?.addEventListener("loadedmetadata", handleLoadedMetadata);
      return () => {
        video?.removeEventListener("loadedmetadata", handleLoadedMetadata);
      };
    }, []);
    useEffect(() => {
      if (videoRef.current !== null && !isNil(src)) {
        videoRef.current.load();
      }
    }, [src]);
    return (
      <Stack
        ref={boxRef}
        alignItems="center"
        justifyContent="center"
        sx={{ width: "100%", height: "100%" }}
      >
        {!isNil(src) ? (
          <video
            ref={videoRef}
            autoPlay={autoPlay}
            controls
            muted
            style={videoStyle}
          >
            <source src={src} type="video/mp4" />
            <p>再生できませんでした</p>
          </video>
        ) : (
          <Icon name="image" size={100} />
        )}
      </Stack>
    );
  }
);

export default Video;
