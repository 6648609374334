/**
 * @generated SignedSource<<9ee72a1349d1b6b86344992f6bfeadab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ObjectDetectionObjectType = "ball" | "head" | "shaft";
import { FragmentRefs } from "relay-runtime";
export type ObjectDetectionChat$data = {
  readonly comment: {
    readonly contentCount: number;
    readonly id: string;
  } | null | undefined;
  readonly id: string;
  readonly movieClips: ReadonlyArray<{
    readonly id: string;
    readonly objectDetections: ReadonlyArray<{
      readonly id: string;
      readonly objectType: ObjectDetectionObjectType;
    }>;
  }>;
  readonly " $fragmentType": "ObjectDetectionChat";
};
export type ObjectDetectionChat$key = {
  readonly " $data"?: ObjectDetectionChat$data;
  readonly " $fragmentSpreads": FragmentRefs<"ObjectDetectionChat">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ObjectDetectionChat",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Comment",
      "kind": "LinkedField",
      "name": "comment",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "contentCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MovieClip",
      "kind": "LinkedField",
      "name": "movieClips",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ObjectDetection",
          "kind": "LinkedField",
          "name": "objectDetections",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "objectType",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "MovieMetainfo",
  "abstractKey": null
};
})();

(node as any).hash = "9c53f30b10a4affcf6aa210f0a8963e5";

export default node;
