/**
 * @generated SignedSource<<c61830402555c601ceee5edf93becff5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProfileScreenQuery$variables = Record<PropertyKey, never>;
export type ProfileScreenQuery$data = {
  readonly admin: {
    readonly " $fragmentSpreads": FragmentRefs<"ProfileData">;
  };
};
export type ProfileScreenQuery = {
  response: ProfileScreenQuery$data;
  variables: ProfileScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProfileScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "admin",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProfileData"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ProfileScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Avatar",
            "kind": "LinkedField",
            "name": "avatar",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "signedUrl",
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "25fdc39e2f9827ab3c75f950cd4c718c",
    "id": null,
    "metadata": {},
    "name": "ProfileScreenQuery",
    "operationKind": "query",
    "text": "query ProfileScreenQuery {\n  admin {\n    ...ProfileData\n    id\n  }\n}\n\nfragment ProfileAvatar on User {\n  avatar {\n    signedUrl\n    id\n  }\n}\n\nfragment ProfileData on User {\n  ...ProfileInfo\n  ...ProfileAvatar\n}\n\nfragment ProfileInfo on User {\n  name\n}\n"
  }
};
})();

(node as any).hash = "34eca6db932321af7832ca8df17f5ddb";

export default node;
