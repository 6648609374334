import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { SwingScenes$key } from "@generated/SwingScenes.graphql";
import { SwingFrameAngle } from "@constants/App";
import { Link } from "react-router-dom";
import { decodeId } from "@lib/utils/convertId";

const query = graphql`
  fragment SwingScenes on Query {
    swingScenes {
      id
      title
      swingFrame {
        name
        angle
      }
    }
  }
`;

export default function SwingScenes({
  swingScenesFragment,
}: {
  swingScenesFragment: SwingScenes$key;
}) {
  const { swingScenes } = useFragment(query, swingScenesFragment);
  return (
    <Table aria-label="simple table" sx={{ minWidth: 650 }}>
      <TableHead>
        <TableRow>
          <TableCell component="th">ID</TableCell>
          <TableCell component="th">タイトル</TableCell>
          <TableCell component="th">スイングポジション</TableCell>
          <TableCell component="th">カメラアングル</TableCell>
          <TableCell component="th">詳細</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {swingScenes.map((swingScene) => (
          <TableRow key={swingScene.id}>
            <TableCell>{decodeId(swingScene.id)}</TableCell>
            <TableCell>{swingScene.title}</TableCell>
            <TableCell>{swingScene.swingFrame.name}</TableCell>
            <TableCell>
              {SwingFrameAngle[swingScene.swingFrame.angle]}
            </TableCell>

            <TableCell>
              <Link to={`/swing_scenes/${swingScene.id}`}>詳細</Link>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
