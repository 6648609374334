/**
 * @generated SignedSource<<59029f0c1fb6c6e79d3f310791c548fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SwingPositionClassificationRemove$data = {
  readonly id: string;
  readonly " $fragmentType": "SwingPositionClassificationRemove";
};
export type SwingPositionClassificationRemove$key = {
  readonly " $data"?: SwingPositionClassificationRemove$data;
  readonly " $fragmentSpreads": FragmentRefs<"SwingPositionClassificationRemove">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SwingPositionClassificationRemove",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "MovieClip",
  "abstractKey": null
};

(node as any).hash = "21522eebea0a063b240e1f03534c681f";

export default node;
