import { useParams } from "react-router-dom";

export default function useQueryId<T = string>(name?: string): T {
  const params = useParams();
  const value = params[name ?? "id"];
  if (value === undefined) {
    throw new Error(`useQueryId: not found in params`);
  }
  return value as T;
}
