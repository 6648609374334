/**
 * @generated SignedSource<<220d228f201deb268f83369d53e45fa5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DrillLevel = "easy" | "hard" | "normal" | "very_easy" | "very_hard";
export type DrillPeriod = "long" | "medium" | "shrot" | "very_long" | "very_short";
export type DrillPlace = "ground" | "house";
export type DrillSideEffect = "dangerous" | "few" | "nothing" | "noticing" | "warning";
import { FragmentRefs } from "relay-runtime";
export type DrillMainData$data = {
  readonly club: string | null | undefined;
  readonly description: string | null | undefined;
  readonly implement: string | null | undefined;
  readonly level: DrillLevel;
  readonly period: DrillPeriod;
  readonly place: DrillPlace;
  readonly practiceMethod: string | null | undefined;
  readonly sideEffect: DrillSideEffect;
  readonly swingPosition: {
    readonly name: string;
  };
  readonly thumbnail: {
    readonly signedUrl: string | null | undefined;
  } | null | undefined;
  readonly tips: string | null | undefined;
  readonly title: string | null | undefined;
  readonly video: {
    readonly signedUrl: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "DrillMainData";
};
export type DrillMainData$key = {
  readonly " $data"?: DrillMainData$data;
  readonly " $fragmentSpreads": FragmentRefs<"DrillMainData">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "signedUrl",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DrillMainData",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "club",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "implement",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tips",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "level",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "period",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "place",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "practiceMethod",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sideEffect",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Thumbnail",
      "kind": "LinkedField",
      "name": "thumbnail",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Video",
      "kind": "LinkedField",
      "name": "video",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SwingPosition",
      "kind": "LinkedField",
      "name": "swingPosition",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Drill",
  "abstractKey": null
};
})();

(node as any).hash = "b23d56d3cb6f9ea272f97818a65f2b87";

export default node;
