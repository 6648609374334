import { useCallback, useMemo } from "react";
import { Stack, Button, Box, Chip } from "@mui/material";
import { object, string } from "@lib/utils/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import graphql from "babel-plugin-relay/macro";
import { ConnectionHandler } from "relay-runtime";
import { useMutation, useFragment } from "react-relay";
import {
  Form,
  SelectField,
  TextField,
  ErrorText,
} from "@components/molecules/TextInput";
import { isNil } from "@lib/utils/commons";
import {
  CommentPostMutation,
  CommentPostMutation$data,
} from "@generated/CommentPostMutation.graphql";
import { CommentPost$key } from "@generated/CommentPost.graphql";
import { CommentPostList$key } from "@generated/CommentPostList.graphql";
import useMessage from "@lib/hooks/useMessage";
import usePreventDoubleClick from "@lib/hooks/usePreventDoubleClick";
import Spacer from "@components/atoms/Spacer";
import Loading from "@components/atoms/Loading";
import { decodeId } from "@lib/utils/convertId";
import { FrequentComments, ObjectDetectionObjectType } from "@constants/App";

const query = graphql`
  fragment CommentPost on Comment {
    id
    movieMetainfo {
      id
    }
  }
`;

const clipsQuery = graphql`
  fragment CommentPostList on MovieMetainfo {
    movieClips {
      id
      objectDetections {
        id
        objectType
      }
    }
  }
`;

const mutation = graphql`
  mutation CommentPostMutation(
    $input: CreateCommentMutationInput!
    $connections: [ID!]!
  ) {
    createComment(input: $input) {
      __typename
      ... on CommentItem {
        comment {
          id
          contentCount
          lastPostedAt
          movieMetainfo {
            id
            thumbnail
          }
          commentUsers {
            id
            lastReadAt
            unreadCount
            user {
              id
              name
              avatar {
                signedUrl
              }
            }
          }
        }
        commentContentEdge @appendEdge(connections: $connections) {
          cursor
          node {
            id
            content
            createdAt
            comment {
              id
            }
            user {
              id
              name
              avatar {
                signedUrl
              }
            }
            movieClip {
              id
              swingImage {
                signedUrl
              }
            }
            objectDetection {
              id
              objectType
              xmin
              ymin
              xmax
              ymax
            }
          }
        }
      }
      ... on UserError {
        message
      }
    }
  }
`;

type Input = {
  movieClipId: string | null;
  objectDetectionId: string | null;
  content: string;
};

export default function CommentPost({
  commentFragment,
  movieFragment,
}: {
  commentFragment: CommentPost$key;
  movieFragment: CommentPostList$key;
}) {
  const { movieClips } = useFragment(clipsQuery, movieFragment);
  const { id, movieMetainfo } = useFragment(query, commentFragment);
  const connectionID = ConnectionHandler.getConnectionID(
    id,
    "Comment__commentContents"
  );
  const [, setMessage] = useMessage();
  const { processing, onClick, onRelease } = usePreventDoubleClick();
  const [commit] = useMutation<CommentPostMutation>(mutation);
  const clips = useMemo(
    () =>
      movieClips.map((row) => {
        const decode = decodeId(row.id);
        if (decode === null) {
          throw new Error("Data Error");
        }
        return {
          value: decode,
          label: `ID: ${decode}`,
        };
      }),
    [movieClips]
  );
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors, isValid },
  } = useForm<Input>({
    defaultValues: {
      movieClipId: null,
      objectDetectionId: null,
      content: "",
    },
    mode: "all",
    resolver: yupResolver(
      object().shape({
        movieClipId: string().required("選択してください").nullable(),
        objectDetectionId: string().required("選択してください").nullable(),
        content: string()
          .default("")
          .trim()
          .min(1, "入力してください")
          .required("入力してください"),
      })
    ),
  });
  const currentClip = watch("movieClipId");
  const objectDetections = useMemo(() => {
    if (currentClip === null) {
      return [];
    }

    const clip = movieClips.find((row) => {
      const decode = decodeId(row.id);
      if (decode === null) {
        throw new Error("Data Error");
      }
      return decode === Number(currentClip);
    });
    if (isNil(clip)) {
      return [];
    }
    return clip.objectDetections.map((row) => {
      const decode = decodeId(row.id);
      if (decode === null) {
        throw new Error("Data Error");
      }
      return {
        value: decode,
        label: ObjectDetectionObjectType[row.objectType],
      };
    });
  }, [currentClip, movieClips]);
  const selectTemplate = useCallback(
    (word: string) => {
      const currentText = getValues("content");
      const words = [word, currentText];
      setValue("content", words.join("\n"), { shouldValidate: true });
    },
    [getValues, setValue]
  );
  const handleCreate = useCallback(async () => {
    await handleSubmit(
      async ({
        movieClipId: inputId,
        objectDetectionId: inputObject,
        content: inputContent,
      }: Input) => {
        if (processing) {
          return;
        }
        onClick();
        const result = await new Promise<
          CommentPostMutation$data["createComment"]
        >((resolve) => {
          commit({
            variables: {
              input: {
                movieMetainfoId: movieMetainfo.id,
                content: inputContent,
                movieClipId: inputId,
                objectDetectionId: inputObject,
              },
              connections: [connectionID],
            },
            onCompleted: ({ createComment }) => {
              resolve(createComment);
            },
          });
        });
        setValue("movieClipId", null);
        setValue("content", "", { shouldValidate: true });
        if (result.__typename !== "CommentItem") {
          setMessage({
            mode: "error",
            title:
              result.__typename === "UserError"
                ? result.message
                : "投稿できませんでした",
          });
        }
        onRelease();
      }
    )();
  }, [
    commit,
    connectionID,
    movieMetainfo,
    setMessage,
    processing,
    onClick,
    onRelease,
    handleSubmit,
    setValue,
  ]);

  return (
    <Form>
      <Stack direction="column" justifyContent="flex-start" spacing={2}>
        <SelectField
          control={control}
          label="対象の画像ID"
          name="movieClipId"
          options={clips}
        />
        {!isValid && errors.movieClipId !== undefined && (
          <ErrorText error={errors.movieClipId.message} />
        )}
        <Spacer height={8} />
        <SelectField
          control={control}
          disabled={objectDetections.length === 0}
          label="対象の物体"
          name="objectDetectionId"
          options={objectDetections}
        />
        {!isValid && errors.objectDetectionId !== undefined && (
          <ErrorText error={errors.objectDetectionId.message} />
        )}
        <Spacer height={8} />
        <TextField
          control={control}
          name="content"
          numberOfLines={3}
          placeholder="コメントを入力"
        />
        {!isValid && errors.content !== undefined && (
          <ErrorText error={errors.content.message} />
        )}
        <Stack
          alignItems="flex-start"
          direction="row"
          flexWrap="wrap"
          justifyContent="flex-start"
        >
          {FrequentComments.map((row) => (
            <Box key={row} sx={{ padding: "2px" }}>
              <Chip
                label={row}
                onClick={() => selectTemplate(row)}
                size="small"
                variant="outlined"
              />
            </Box>
          ))}
        </Stack>
        <Stack alignItems="flex-end" direction="row">
          <Button
            disabled={!isValid}
            onClick={handleCreate}
            variant="contained"
          >
            投稿する
          </Button>
          {processing && <Loading />}
        </Stack>
        <Spacer height={18} />
      </Stack>
    </Form>
  );
}
