import { useState } from "react";
import { Stack, Divider, Button } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { SwingSceneData$key } from "@generated/SwingSceneData.graphql";
import { SwingSceneSwingFrames$key } from "@generated/SwingSceneSwingFrames.graphql";
import SwingSceneDisplay from "@components/organisms/SwingScene/SwingSceneDisplay";
import SwingSceneInput from "@components/organisms/SwingScene/SwingSceneInput";
import SwingSceneRemove from "@components/organisms/SwingScene/SwingSceneRemove";

const query = graphql`
  fragment SwingSceneData on SwingScene {
    id
    title
    draw
    correctForm
    incorrectForm
    point
    view
    orderNumber
    thumbnail {
      signedUrl
    }
    video {
      signedUrl
    }
    swingFrame {
      id
      name
      angle
    }
    ...SwingSceneDisplay
    ...SwingSceneRemove
  }
`;

const frameQuery = graphql`
  fragment SwingSceneSwingFrames on Query {
    ...SwingSceneInputSwingFrames
  }
`;

export default function SwingScene({
  swingSceneFragment,
  swingFramesFragment,
}: {
  swingSceneFragment: SwingSceneData$key;
  swingFramesFragment: SwingSceneSwingFrames$key;
}): JSX.Element {
  const [edit, setEdit] = useState<boolean>(false);
  const data = useFragment<SwingSceneData$key>(query, swingSceneFragment);
  const swingFrames = useFragment<SwingSceneSwingFrames$key>(
    frameQuery,
    swingFramesFragment
  );
  const {
    id,
    title,
    draw,
    correctForm,
    incorrectForm,
    point,
    view,
    orderNumber,
    thumbnail,
    video,
    swingFrame,
  } = data;

  return (
    <Stack direction="column" spacing={2}>
      {edit ? (
        <SwingSceneInput
          input={{
            id,
            swingFrameId: swingFrame.id,
            title,
            draw,
            correctForm,
            incorrectForm,
            point,
            view,
            orderNumber,
            thumbnailUrl: thumbnail?.signedUrl,
            videoUrl: video?.signedUrl,
          }}
          onClose={() => setEdit(false)}
          swingFramesFragment={swingFrames}
        />
      ) : (
        <>
          <SwingSceneDisplay swingSceneFragment={data} />
          <Divider />
          <Stack direction="row" justifyContent="center" spacing={2}>
            <Button onClick={() => setEdit(true)} variant="contained">
              編集する
            </Button>
            <SwingSceneRemove frameFragment={data} />
          </Stack>
        </>
      )}
    </Stack>
  );
}
