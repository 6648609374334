/**
 * @generated SignedSource<<cc9395d5f7b57f93b2d6d5162d4b5870>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChangeProfileScreenQuery$variables = Record<PropertyKey, never>;
export type ChangeProfileScreenQuery$data = {
  readonly admin: {
    readonly " $fragmentSpreads": FragmentRefs<"ChangeProfile">;
  };
};
export type ChangeProfileScreenQuery = {
  response: ChangeProfileScreenQuery$data;
  variables: ChangeProfileScreenQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ChangeProfileScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "admin",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ChangeProfile"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ChangeProfileScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f1a6d1a1634a367c46089d60cbc6849c",
    "id": null,
    "metadata": {},
    "name": "ChangeProfileScreenQuery",
    "operationKind": "query",
    "text": "query ChangeProfileScreenQuery {\n  admin {\n    ...ChangeProfile\n    id\n  }\n}\n\nfragment ChangeProfile on User {\n  ...ChangeProfileEmail\n}\n\nfragment ChangeProfileEmail on User {\n  email\n}\n"
  }
};

(node as any).hash = "04f95978d4a56d3c5c36e9f366c2896b";

export default node;
