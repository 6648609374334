import { useCallback, useState } from "react";
import { Button } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import Loading from "@components/atoms/Loading";
import usePreventDoubleClick from "@lib/hooks/usePreventDoubleClick";
import { SwingPositionClassificationRemove$key } from "@generated/SwingPositionClassificationRemove.graphql";
import {
  SwingPositionClassificationRemoveMutation,
  SwingPositionClassificationRemoveMutation$data,
} from "@generated/SwingPositionClassificationRemoveMutation.graphql";
import useMessage from "@lib/hooks/useMessage";
import Colors from "@constants/Colors";
import Message from "@components/molecules/Message";

const query = graphql`
  fragment SwingPositionClassificationRemove on MovieClip {
    id
  }
`;

const mutation = graphql`
  mutation SwingPositionClassificationRemoveMutation(
    $input: RemoveMovieClipMutationInput!
  ) {
    removeMovieClip(input: $input) {
      __typename
      ... on MovieMetainfo {
        id
        movieClips {
          id
        }
      }
      ... on UserError {
        message
      }
    }
  }
`;

export default function SwingPositionClassificationRemove({
  movieClipFragment,
}: {
  movieClipFragment: SwingPositionClassificationRemove$key;
}) {
  const [, setMessage] = useMessage();
  const { processing, onClick, onRelease } = usePreventDoubleClick();
  const { id } = useFragment(query, movieClipFragment);
  const [dialog, setDialog] = useState<boolean>(false);
  const [commit] =
    useMutation<SwingPositionClassificationRemoveMutation>(mutation);
  const onRemove = useCallback(async () => {
    if (processing) {
      return;
    }
    onClick();
    const result = await new Promise<
      SwingPositionClassificationRemoveMutation$data["removeMovieClip"]
    >((resolve) => {
      commit({
        variables: {
          input: {
            id,
          },
        },
        onCompleted: ({ removeMovieClip }) => {
          resolve(removeMovieClip);
        },
      });
    });
    if (result.__typename === "UserError") {
      setMessage({
        mode: "error",
        title: result.message,
      });
      onRelease();
      setDialog(false);
    } else {
      window.location.reload();
    }
  }, [id, commit, processing, onClick, onRelease, setMessage]);

  return (
    <>
      <Button color="error" onClick={() => setDialog(true)} size="small">
        削除する
      </Button>
      <Message
        closeText="キャンセル"
        confirmText="理解して、削除する"
        message="このスイング画像を削除します。この処理は取り消せません。"
        onClose={() => setDialog(false)}
        onConfirm={onRemove}
        title="削除しますか？"
        visible={dialog}
      />
      {processing && (
        <Loading color={Colors.white} mask maskPosition="absolute" />
      )}
    </>
  );
}
