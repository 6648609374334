import { useMemo } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import getTheme from "@constants/Theme";
import { isNil } from "@lib/utils/commons";
import DrawerLayout from "@navigation/DrawerLayout";
import AuthLayout from "@navigation/AuthLayout";
import { CssBaseline, useMediaQuery } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import errorRecoil, { ErrorType } from "@lib/hooks/useExecptionalError";
import MessageScreen from "@screens/MessageScreen";
import SignInScreen from "@screens/SignInScreen";
import VerifyInviteScreen from "@screens/VerifyInviteScreen";
import ProgressScreen from "@screens/ProgressScreen";
import FallbackScreen from "@screens/FallbackScreen";
import ArticleScreen from "@screens/ArticleScreen";
import ArticlesScreen from "@screens/ArticlesScreen";
import DrillScreen from "@screens/DrillScreen";
import DrillsScreen from "@screens/DrillsScreen";
import MovieMetainfoScreen from "@screens/MovieMetainfoScreen";
import MovieMetainfosScreen from "@screens/MovieMetainfosScreen";
import ProfileScreen from "@screens/ProfileScreen";
import MovieInReviewScreenScreen from "@screens/MovieInReviewScreen";
import MovieInReviewsScreenScreen from "@screens/MovieInReviewsScreen";
import SwingFrameScreen from "@screens/SwingFrameScreen";
import SwingFrameCreateScreen from "@screens/SwingFrameCreateScreen";
import SwingFramesScreen from "@screens/SwingFramesScreen";
import SwingFramesViewScreen from "@screens/SwingFramesViewScreen";
import SwingSceneCreateScreen from "@screens/SwingSceneCreateScreen";
import SwingSceneScreen from "@screens/SwingSceneScreen";
import SwingScenesScreen from "@screens/SwingScenesScreen";
import SwingFrameMoviesScreen from "@screens/SwingFrameMoviesScreen";
import CauseScreen from "@screens/CauseScreen";
import CausesScreen from "@screens/CausesScreen";
import LessonTemplateScreen from "@screens/LessonTemplateScreen";
import LessonTemplatesScreen from "@screens/LessonTemplatesScreen";
import ChangeProfileScreen from "@screens/ChangeProfileScreen";
import MemberScreen from "@screens/MemberScreen";
import MembersScreen from "@screens/MembersScreen";
import UserInvitesScreen from "@screens/UserInvitesScreen";
import CommentsScreen from "@screens/CommentsScreen";
import CommentScreen from "@screens/CommentScreen";
import CommentModalScreen from "@screens/CommentModalScreen";
import MovieJobsIgnoredScreen from "@screens/MovieJobsIgnoredScreen";
import CoachingsScreen from "@screens/CoachingsScreen";
import CoachingScreen from "@screens/CoachingScreen";
import AnnotateObjectScreen from "@screens/AnnotateObjectScreen";
import AnnotateObjectsScreen from "@screens/AnnotateObjectsScreen";
import LabelingSwingsScreen from "@screens/LabelingSwingsScreen";
import LabelingSwingScreen from "@screens/LabelingSwingScreen";
import ObjectDetectionsScreen from "@screens/ObjectDetectionsScreen";

export default function App() {
  const isDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const { get: getError } = errorRecoil();
  const theme = useMemo(() => getTheme(isDarkMode), [isDarkMode]);
  const location = useLocation();
  const background = location.state?.background;
  const error = getError();
  if (error !== null) {
    return ExecptionError(error);
  }
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes location={background ?? location}>
        <Route element={<AuthLayout />}>
          <Route element={<SignInScreen />} path="/sign_in" />
          <Route element={<VerifyInviteScreen />} path="/verify_invite/:id" />
          <Route
            element={<SwingFramesViewScreen />}
            path="/definitions/:angle"
          />
        </Route>
        <Route element={<DrawerLayout />}>
          <Route element={<ProgressScreen />} path="/" />
          <Route element={<ArticlesScreen />} path="/articles" />
          <Route element={<DrillsScreen />} path="/drills" />
          <Route element={<ProfileScreen />} path="/profile" />
          <Route
            element={<ChangeProfileScreen mode={"password" as const} />}
            path="/change_password"
          />
          <Route
            element={<ChangeProfileScreen mode={"email" as const} />}
            path="/change_email"
          />
          <Route element={<MovieMetainfosScreen />} path="/movie_metainfos" />
          <Route element={<CommentsScreen />} path="/comments" />
          <Route element={<CommentScreen />} path="/comments/:id" />
          <Route
            element={<MovieJobsIgnoredScreen />}
            path="/movie_jobs_ignored"
          />
          <Route
            element={<AnnotateObjectScreen />}
            path="/annotate_objects/:id"
          />
          <Route element={<AnnotateObjectsScreen />} path="/annotate_objects" />
          <Route
            element={<LabelingSwingScreen tab={"working" as const} />}
            path="/labeling_swings/:id"
          />
          <Route
            element={<LabelingSwingScreen tab={"confirm" as const} />}
            path="/labeling_swings/:id/confirm"
          />
          <Route element={<LabelingSwingsScreen />} path="/labeling_swings" />
          <Route
            element={<MovieInReviewsScreenScreen />}
            path="/movie_in_reviews"
          />
          <Route
            element={<MovieInReviewScreenScreen tab={"labels" as const} />}
            path="/movie_in_reviews/:id/labels"
          />
          <Route
            element={<MovieInReviewScreenScreen tab={"detection" as const} />}
            path="/movie_in_reviews/:id/detection"
          />
          <Route element={<SwingFramesScreen />} path="/swing_frames" />
          <Route
            element={<SwingFrameCreateScreen />}
            path="/swing_frames/new"
          />
          <Route element={<SwingFrameScreen />} path="/swing_frames/:id" />
          <Route
            element={<SwingFrameMoviesScreen />}
            path="/swing_frames/:id/movies"
          />
          <Route
            element={<SwingSceneCreateScreen />}
            path="/swing_scenes/new"
          />
          <Route
            element={<ObjectDetectionsScreen />}
            path="/object_detections/:object_type"
          />
          <Route element={<SwingScenesScreen />} path="/swing_scenes" />
          <Route element={<SwingSceneScreen />} path="/swing_scenes/:id" />
          <Route element={<CoachingsScreen />} path="/coachings" />
          <Route element={<CoachingScreen />} path="/coachings/:id" />
          <Route element={<ArticleScreen />} path="/articles/:id" />
          <Route element={<DrillScreen />} path="/drills/:id" />
          <Route
            element={<MovieMetainfoScreen tab={"labels" as const} />}
            path="/movie_metainfos/:id/labels"
          />
          <Route
            element={<MovieMetainfoScreen tab={"detection" as const} />}
            path="/movie_metainfos/:id/detection"
          />
          <Route element={<CausesScreen />} path="/causes" />
          <Route element={<CauseScreen />} path="/causes/:id" />
          <Route element={<LessonTemplatesScreen />} path="/lesson_templates" />
          <Route
            element={<LessonTemplateScreen />}
            path="/lesson_templates/:id"
          />
          <Route element={<MembersScreen />} path="/members" />
          <Route element={<MemberScreen />} path="/members/:id" />
          <Route element={<UserInvitesScreen />} path="/user_invites" />
        </Route>
      </Routes>
      {!isNil(background) && (
        <Routes location={location}>
          <Route element={<CommentModalScreen />} path="/comments/modal/:id" />
        </Routes>
      )}
      <MessageScreen />
    </ThemeProvider>
  );
}

function ExecptionError(error: ErrorType) {
  const { code } = error;
  if (code !== undefined && code === "Expired") {
    return null;
  }
  return <FallbackScreen error={error} />;
}
