import React from "react";
import {
  List,
  ListItemButton,
  ListItemText,
  Divider,
  Chip,
  Stack,
} from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { usePaginationFragment } from "react-relay";
import { LessonTemplatesPagination$key } from "@generated/LessonTemplatesPagination.graphql";
import OffsetPagination from "@components/atoms/OffsetPagination";
import { decodeId } from "@lib/utils/convertId";

const query = graphql`
  fragment LessonTemplatesPagination on Query
  @refetchable(queryName: "LessonTemplatesPaginationQuery")
  @argumentDefinitions(after: { type: "String" }, first: { type: "Int" }) {
    lessonTemplates(first: $first, after: $after)
      @connection(key: "LessonTemplatesList__lessonTemplates") {
      edges {
        node {
          id
          goal
          cause {
            content
            swingPosition {
              name
            }
          }
          drills {
            id
          }
        }
      }
      currentPage
      totalCount
      limit
    }
  }
`;

export default function LessonTemplates({
  templatesFragment,
}: {
  templatesFragment: LessonTemplatesPagination$key;
}) {
  const { data } = usePaginationFragment(query, templatesFragment);
  const { edges, currentPage, limit, totalCount } = data.lessonTemplates;
  return (
    <>
      <List>
        {edges.map(({ node }) => (
          <React.Fragment key={node.id}>
            <ListItemButton component="a" href={`lesson_templates/${node.id}`}>
              <Stack alignItems="flex-start" direction="column" spacing={1}>
                <Stack alignItems="flex-start" direction="row" spacing={1}>
                  <Chip
                    color="success"
                    label={`ID: ${decodeId(node.id)}`}
                    size="small"
                    variant="outlined"
                  />
                  <Chip
                    color="primary"
                    label={node.cause.swingPosition.name}
                    size="small"
                    variant="outlined"
                  />
                  <Chip
                    label={`ドリル: ${node.drills.length}個`}
                    size="small"
                    variant="outlined"
                  />
                </Stack>
                <ListItemText
                  primary={node.goal}
                  secondary={node.cause.content}
                />
              </Stack>
            </ListItemButton>
            <Divider />
          </React.Fragment>
        ))}
      </List>
      <OffsetPagination
        currentPage={currentPage}
        limit={limit}
        path="lesson_templates"
        totalCount={totalCount}
      />
    </>
  );
}
