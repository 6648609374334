import { useEffect, Suspense } from "react";
import ContentLayout from "@navigation/ContentLayout";
import LabelingSwingTasks from "@components/templates/LabelingSwings";
import graphql from "babel-plugin-relay/macro";
import {
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import { isNil } from "@lib/utils/commons";
import Loading from "@components/atoms/Loading";
import { LabelingSwingsScreenQuery } from "@generated/LabelingSwingsScreenQuery.graphql";
import { PageLimit } from "@constants/App";
import usePaginationOffset from "@lib/hooks/usePaginationOffset";

const query = graphql`
  query LabelingSwingsScreenQuery(
    $first: Int!
    $after: String
    $type: MovieMetainfoJobType!
  ) {
    ...LabelingSwingsPagination
      @arguments(first: $first, after: $after, type: $type)
  }
`;

function ScreenContent({
  queryReference,
}: {
  queryReference: PreloadedQuery<LabelingSwingsScreenQuery>;
}) {
  const data = usePreloadedQuery<LabelingSwingsScreenQuery>(
    query,
    queryReference
  );
  return <LabelingSwingTasks movieMetainfoJobsFragment={data} />;
}

export default function LabelingSwingsScreen() {
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<LabelingSwingsScreenQuery>(query);
  const after = usePaginationOffset();
  useEffect(() => {
    loadQuery(
      {
        first: PageLimit,
        after,
        type: "LabelingSwing",
      },
      {
        fetchPolicy: "network-only",
      }
    );
    return () => {
      disposeQuery();
    };
  }, [after, loadQuery, disposeQuery]);

  return (
    <ContentLayout title="ラベリングタスク一覧">
      <Suspense fallback={<Loading />}>
        {!isNil(queryReference) && (
          <ScreenContent queryReference={queryReference} />
        )}
      </Suspense>
    </ContentLayout>
  );
}
