import AvatarUi from "@mui/material/Avatar";
import Icon from "@components/atoms/Icon";

export default function Avatar({
  url,
  size = 24,
}: {
  url: string | null | undefined;
  size?: number;
}) {
  return url === null || url === undefined ? (
    <Icon name="account" size={size} />
  ) : (
    <AvatarUi src={url} sx={{ width: size, height: size }} />
  );
}
