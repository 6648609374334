/**
 * @generated SignedSource<<71956efa57ce28636ac6f2c6d6457db1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateCommentUserMutationInput = {
  clientMutationId?: string | null | undefined;
  commentId: string;
};
export type useReadCommentMutation$variables = {
  input: UpdateCommentUserMutationInput;
};
export type useReadCommentMutation$data = {
  readonly updateCommentUser: {
    readonly __typename: "CommentUser";
    readonly id: string;
    readonly lastReadAt: any | null | undefined;
    readonly unreadCount: number;
    readonly user: {
      readonly id: string;
    };
  } | {
    readonly __typename: "UserError";
    readonly message: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type useReadCommentMutation = {
  response: useReadCommentMutation$data;
  variables: useReadCommentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "updateCommentUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastReadAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "unreadCount",
            "storageKey": null
          }
        ],
        "type": "CommentUser",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "type": "UserError",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useReadCommentMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useReadCommentMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "6bcda1aeef519a0ed4efca5731430c63",
    "id": null,
    "metadata": {},
    "name": "useReadCommentMutation",
    "operationKind": "mutation",
    "text": "mutation useReadCommentMutation(\n  $input: UpdateCommentUserMutationInput!\n) {\n  updateCommentUser(input: $input) {\n    __typename\n    ... on CommentUser {\n      id\n      user {\n        id\n      }\n      lastReadAt\n      unreadCount\n    }\n    ... on UserError {\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "86b632eb0695d38b4e4b40a5e886f564";

export default node;
