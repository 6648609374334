import React, { useCallback, useMemo, useState } from "react";
import {
  Box,
  Stack,
  Chip,
  Divider,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Switch,
  FormControlLabel,
  List,
  ListItem,
  ListItemAvatar,
} from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import Image from "@components/atoms/Image";
import Loading from "@components/atoms/Loading";
import Text from "@components/atoms/Text";
import Icon from "@components/atoms/Icon";
import usePreventDoubleClick from "@lib/hooks/usePreventDoubleClick";
import { SwingPositionClassificationItemClip$key } from "@generated/SwingPositionClassificationItemClip.graphql";
import { SwingPositionClassificationItemMeta$key } from "@generated/SwingPositionClassificationItemMeta.graphql";
import { SwingPositionClassificationItemQuery$key } from "@generated/SwingPositionClassificationItemQuery.graphql";
import {
  SwingPositionClassificationItemMutation,
  SwingPositionClassificationItemMutation$data,
} from "@generated/SwingPositionClassificationItemMutation.graphql";
import {
  SwingPositionClassificationItemRemoveMutation,
  SwingPositionClassificationItemRemoveMutation$data,
} from "@generated/SwingPositionClassificationItemRemoveMutation.graphql";
import {
  SwingPositionClassificationItemClipMutation,
  SwingPositionClassificationItemClipMutation$data,
} from "@generated/SwingPositionClassificationItemClipMutation.graphql";
import useMessage from "@lib/hooks/useMessage";
import Colors from "@constants/Colors";
import Avatar from "@components/atoms/Avatar";
import Spacer from "@components/atoms/Spacer";
import { isNil } from "@lib/utils/commons";
import SwingPositionClassificationMeta from "@components/molecules/SwingPositionClassification/SwingPositionClassificationMeta";
import SwingPositionClassificationRemove from "@components/molecules/SwingPositionClassification/SwingPositionClassificationRemove";
import SwingPositionClassificationClipLink from "@components/molecules/SwingPositionClassification/SwingPositionClassificationClipLink";
import SwingPositionClassificationChips from "@components/molecules/SwingPositionClassification/SwingPositionClassificationChips";
import useMovieMetainfo from "@lib/hooks/useMovieMetainfo";
import { decodeId } from "@lib/utils/convertId";
import { dateFormat } from "@lib/utils/date";

const clipQuery = graphql`
  fragment SwingPositionClassificationItemClip on MovieClip {
    id
    ballTrajectory
    swingImage {
      id
      signedUrl
    }
    swingClassification {
      id
      needReview
      secondary
      swingFrame {
        id
        name
      }
    }
    commentContents {
      id
      content
      createdAt
      user {
        name
        avatar {
          signedUrl
        }
      }
    }
    ...SwingPositionClassificationRemove
  }
`;

const metaQuery = graphql`
  fragment SwingPositionClassificationItemMeta on MovieMetainfo {
    direction
    angle
    ...SwingPositionClassificationMeta
    ...useMovieMetainfo
  }
`;

const masterQuery = graphql`
  fragment SwingPositionClassificationItemQuery on Query {
    swingPositions {
      id
      name
      swingFrames {
        id
        angle
        name
      }
    }
    ...SwingPositionClassificationMetaQuery
  }
`;

const mutation = graphql`
  mutation SwingPositionClassificationItemMutation(
    $input: UpdateSwingClassificationMutationInput!
  ) {
    updateSwingClassification(input: $input) {
      __typename
      ... on MovieClip {
        id
        ballTrajectory
        swingImage {
          id
          signedUrl
        }
        swingClassification {
          id
          needReview
          secondary
          swingFrame {
            id
            name
          }
        }
        commentContents {
          id
          content
          createdAt
          user {
            name
            avatar {
              signedUrl
            }
          }
        }
      }
      ... on UserError {
        message
      }
    }
  }
`;

const removeMutation = graphql`
  mutation SwingPositionClassificationItemRemoveMutation(
    $input: RemoveSwingClassificationMutationInput!
  ) {
    removeSwingClassification(input: $input) {
      __typename
      ... on MovieClip {
        id
        ballTrajectory
        swingImage {
          id
          signedUrl
        }
        swingClassification {
          id
          needReview
          secondary
          swingFrame {
            id
            name
          }
        }
      }
      ... on UserError {
        message
      }
    }
  }
`;

const clipMutation = graphql`
  mutation SwingPositionClassificationItemClipMutation(
    $input: UpdateMovieClipMutationInput!
  ) {
    updateMovieClip(input: $input) {
      __typename
      ... on MovieClip {
        id
        ballTrajectory
        swingImage {
          id
          signedUrl
        }
        swingClassification {
          id
          needReview
          secondary
          swingFrame {
            id
            name
          }
        }
      }
      ... on UserError {
        message
      }
    }
  }
`;

export default function SwingPositionClassificationItem({
  movieClipFragment,
  movieMetainfoFragment,
  masterFragment,
  editable = false,
}: {
  movieClipFragment: SwingPositionClassificationItemClip$key;
  movieMetainfoFragment: SwingPositionClassificationItemMeta$key;
  masterFragment: SwingPositionClassificationItemQuery$key;
  editable?: boolean;
}) {
  const [, setMessage] = useMessage();
  const [edit, setEdit] = useState<boolean>(false);
  const { processing, onClick, onRelease } = usePreventDoubleClick();
  const movieClip = useFragment(clipQuery, movieClipFragment);
  const movieMetainfo = useFragment(metaQuery, movieMetainfoFragment);
  const { angle, direction } = movieMetainfo;
  const {
    id,
    ballTrajectory,
    swingImage,
    swingClassification,
    commentContents,
  } = movieClip;
  const {
    id: swingClassificationId,
    needReview,
    secondary,
    swingFrame,
  } = swingClassification ?? { needReview: false, secondary: false };
  const boxHeight = useMemo<number>(() => {
    if (!isNil(direction) && direction === "portrait") {
      return 400;
    }
    return 200;
  }, [direction]);

  const metainfo = useMovieMetainfo({ movieMetainfoFragment: movieMetainfo });
  const master = useFragment(masterQuery, masterFragment);
  const swingPositions = useMemo(
    () =>
      master.swingPositions
        .map((swingPosition) => {
          const frames = swingPosition.swingFrames.filter(
            (row) => row.angle === angle
          );
          return {
            id: swingPosition.id,
            name: swingPosition.name,
            swingFrames: frames,
          };
        })
        .filter((row) => row.swingFrames.length > 0),
    [angle, master]
  );
  const [commit] =
    useMutation<SwingPositionClassificationItemMutation>(mutation);
  const [removeCommit] =
    useMutation<SwingPositionClassificationItemRemoveMutation>(removeMutation);
  const [clipCommit] =
    useMutation<SwingPositionClassificationItemClipMutation>(clipMutation);

  const handleSelect = useCallback(
    async (
      swingFrameId: string,
      selectedNeedReview: boolean,
      selectedSecondary: boolean
    ) => {
      if (processing) {
        return;
      }
      onClick();
      const result = await new Promise<
        SwingPositionClassificationItemMutation$data["updateSwingClassification"]
      >((resolve) => {
        commit({
          variables: {
            input: {
              id,
              swingFrameId,
              needReview: selectedNeedReview,
              secondary: selectedSecondary,
            },
          },
          onCompleted: ({ updateSwingClassification }) => {
            resolve(updateSwingClassification);
          },
        });
      });
      if (result.__typename === "UserError") {
        setMessage({
          mode: "error",
          title: result.message,
        });
      } else {
        setMessage({
          mode: "toast",
          title: "保存しました",
        });
      }
      onRelease();
    },
    [id, commit, processing, onClick, onRelease, setMessage]
  );

  const handleRemove = useCallback(async () => {
    if (processing || isNil(swingClassificationId)) {
      return;
    }
    onClick();
    const result = await new Promise<
      SwingPositionClassificationItemRemoveMutation$data["removeSwingClassification"]
    >((resolve) => {
      removeCommit({
        variables: {
          input: {
            id: swingClassificationId,
          },
        },
        onCompleted: ({ removeSwingClassification }) => {
          resolve(removeSwingClassification);
        },
      });
    });
    if (result.__typename === "UserError") {
      setMessage({
        mode: "error",
        title: result.message,
      });
    } else {
      setMessage({
        mode: "toast",
        title: "保存しました",
      });
    }
    onRelease();
  }, [
    swingClassificationId,
    removeCommit,
    processing,
    onClick,
    onRelease,
    setMessage,
  ]);

  const handleUpdateClip = useCallback(async () => {
    if (processing) {
      return;
    }
    onClick();
    const result = await new Promise<
      SwingPositionClassificationItemClipMutation$data["updateMovieClip"]
    >((resolve) => {
      clipCommit({
        variables: {
          input: {
            id,
            ballTrajectory: ballTrajectory !== true,
          },
        },
        onCompleted: ({ updateMovieClip }) => {
          resolve(updateMovieClip);
        },
      });
    });
    if (result.__typename === "UserError") {
      setMessage({
        mode: "error",
        title: result.message,
      });
    } else {
      setMessage({
        mode: "toast",
        title: "保存しました",
      });
    }
    onRelease();
  }, [
    id,
    ballTrajectory,
    clipCommit,
    processing,
    onClick,
    onRelease,
    setMessage,
  ]);

  if (isNil(angle)) {
    return <Text>撮影アングルを登録してください</Text>;
  }
  return (
    <Stack direction="column">
      <Stack
        direction="row"
        flexWrap="wrap"
        justifyContent="flex-start"
        spacing={2}
        sx={{
          position: "relative",
        }}
      >
        <Box flex={1} sx={{ height: `${boxHeight}px`, minWidth: 300 }}>
          <Image key={swingImage.id} src={swingImage.signedUrl} />
        </Box>
        <Box flex={1}>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="flex-start"
            spacing={1}
          >
            <Box flex={1}>
              <Text>{`ID:${decodeId(id)}`}</Text>
            </Box>
            <SwingPositionClassificationClipLink id={id} />
          </Stack>
          <Stack direction="column" spacing={1}>
            <SwingPositionClassificationChips
              disabled={!editable}
              onSelect={(selectedId: string | null) => {
                if (selectedId !== null) {
                  handleSelect(selectedId, needReview, secondary);
                } else {
                  handleRemove();
                }
              }}
              selectedId={swingFrame?.id ?? null}
              swingPositions={swingPositions}
            />
            <Divider />
            <FormControlLabel
              control={
                <Switch
                  checked={needReview}
                  disabled={!editable || swingFrame === undefined}
                  onChange={() => {
                    if (swingFrame !== undefined) {
                      handleSelect(
                        swingFrame.id,
                        needReview !== true,
                        secondary
                      );
                    }
                  }}
                  value="checked"
                />
              }
              label="レビューを要求"
            />
            <Divider />
            <Stack direction="column" spacing={1}>
              <FormControlLabel
                control={
                  <Switch
                    checked={secondary}
                    disabled={!editable || swingFrame === undefined}
                    onChange={() => {
                      if (swingFrame !== undefined) {
                        handleSelect(
                          swingFrame.id,
                          needReview,
                          secondary !== true
                        );
                      }
                    }}
                    value="checked"
                  />
                }
                label="予備のラベリング"
              />
              <Divider />
              <FormControlLabel
                control={
                  <Switch
                    checked={ballTrajectory}
                    disabled={!editable}
                    onChange={handleUpdateClip}
                    value="checked"
                  />
                }
                label="ボール弾道がある"
              />
              <Divider />
              <Stack
                alignItems="flex-start"
                direction="row"
                flex={1}
                flexWrap="wrap"
              >
                {metainfo.length > 0 ? (
                  <>
                    {metainfo.map((row) => (
                      <Box key={row} sx={{ padding: "2px" }}>
                        <Chip color="primary" label={row} variant="outlined" />
                      </Box>
                    ))}
                    <Spacer height={8} />
                  </>
                ) : (
                  <Text bold color={Colors.orange}>
                    メタ情報が登録されていません
                  </Text>
                )}
              </Stack>
              {editable && (
                <Stack
                  alignItems="center"
                  direction="row"
                  justifyContent="flex-start"
                  spacing={3}
                >
                  <Chip
                    icon={
                      <>
                        <Spacer width={8} />
                        <Icon name="edit" size={16} />
                      </>
                    }
                    label="メタ情報を編集"
                    onClick={() => setEdit(true)}
                  />
                  <SwingPositionClassificationRemove
                    movieClipFragment={movieClip}
                  />
                </Stack>
              )}
            </Stack>
          </Stack>
        </Box>
      </Stack>
      {commentContents.length > 0 && (
        <List sx={{ paddingLeft: 2, paddingRight: 2 }}>
          {commentContents.map((row) => (
            <React.Fragment key={row.id}>
              <Divider />
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar size={24} url={row.user.avatar?.signedUrl} />
                </ListItemAvatar>
                <Stack flex={1}>
                  <Text style={{ fontSize: 12 }}>ID:{decodeId(row.id)}</Text>
                  <Text style={{ fontSize: 12 }}>{row.content}</Text>
                  <Text
                    color={Colors.gray}
                    style={{ fontSize: 12 }}
                  >{`${dateFormat(row.createdAt, "M月D日 HH:mm")} ${row.user.name}`}</Text>
                </Stack>
              </ListItem>
            </React.Fragment>
          ))}
        </List>
      )}
      <Dialog fullScreen onClose={() => setEdit(false)} open={edit}>
        <DialogTitle>メタ情報を編集</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setEdit(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <Icon name="close" size={24} />
        </IconButton>
        <DialogContent dividers>
          <SwingPositionClassificationMeta
            clubsFragment={master}
            movieInfoFragment={movieMetainfo}
          />
        </DialogContent>
      </Dialog>
      {processing && (
        <Loading color={Colors.white} mask maskPosition="absolute" />
      )}
    </Stack>
  );
}
