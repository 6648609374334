import styled from "styled-components";

export default function Spacer({
  width,
  height,
}: {
  width?: number;
  height?: number;
}) {
  return <Wrapper height={height} width={width} />;
}

const Wrapper = styled.div<{ width?: number; height?: number }>`
  ${(props) => {
    const { width } = props;
    return `width: ${width ?? 1}px`;
  }};
  ${(props) => {
    const { height } = props;
    return `height: ${height ?? 1}px`;
  }};
`;
