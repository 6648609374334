import { ListItem, Box, ListItemText } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { MovieMetainfoItemTask$key } from "@generated/MovieMetainfoItemTask.graphql";
import Avatar from "@components/atoms/Avatar";
import { dateFormat } from "@lib/utils/date";
import { MovieMetainfoJobType } from "@constants/App";
import Text from "@components/atoms/Text";

const query = graphql`
  fragment MovieMetainfoItemTask on MovieMetainfoJob {
    type
    appliedAt
    user {
      name
      avatar {
        signedUrl
      }
    }
    reviewer {
      name
      avatar {
        signedUrl
      }
    }
  }
`;

export default function MovieMetainfoItemTask({
  jobFragment,
}: {
  jobFragment: MovieMetainfoItemTask$key;
}) {
  const data = useFragment(query, jobFragment);
  const { type, appliedAt, user } = data;
  const message = `${user.name}さんが${
    appliedAt === null
      ? `${MovieMetainfoJobType[type]}中です`
      : `${MovieMetainfoJobType[type]}しました`
  }`;
  return (
    <ListItem disablePadding>
      <Box sx={{ padding: 1 }}>
        <Avatar size={24} url={user.avatar?.signedUrl} />
      </Box>
      <ListItemText
        primary={<Text style={{ fontSize: 13 }}>{message}</Text>}
        secondary={
          <Text style={{ fontSize: 10 }}>
            {dateFormat(appliedAt, "M月D日 HH:mm")}
          </Text>
        }
      />
    </ListItem>
  );
}
