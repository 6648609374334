/**
 * @generated SignedSource<<58f75fab4c810e101f538117c96e88d0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArticleScreenQuery$variables = {
  id: string;
};
export type ArticleScreenQuery$data = {
  readonly article: {
    readonly " $fragmentSpreads": FragmentRefs<"ArticleData">;
  };
};
export type ArticleScreenQuery = {
  response: ArticleScreenQuery$data;
  variables: ArticleScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "content",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "signedUrl",
    "storageKey": null
  },
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Thumbnail",
  "kind": "LinkedField",
  "name": "thumbnail",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ArticleScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "article",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ArticleData"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ArticleScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "article",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "category",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ArticleSection",
                "kind": "LinkedField",
                "name": "articleSections",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v6/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ArticleContent",
                    "kind": "LinkedField",
                    "name": "articleContents",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v6/*: any*/),
                      (v2/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Video",
                        "kind": "LinkedField",
                        "name": "video",
                        "plural": false,
                        "selections": (v4/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "type": "Article",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "77e585c71532226074f31cefb7d696f4",
    "id": null,
    "metadata": {},
    "name": "ArticleScreenQuery",
    "operationKind": "query",
    "text": "query ArticleScreenQuery(\n  $id: ID!\n) {\n  article(id: $id) {\n    __typename\n    ...ArticleData\n    ... on Article {\n      id\n    }\n  }\n}\n\nfragment ArticleContentData on ArticleContent {\n  title\n  content\n  thumbnail {\n    signedUrl\n    id\n  }\n  video {\n    signedUrl\n    id\n  }\n}\n\nfragment ArticleData on Article {\n  ...ArticleMainData\n  articleSections {\n    id\n    ...ArticleSectionData\n  }\n}\n\nfragment ArticleMainData on Article {\n  category\n  content\n  status\n  thumbnail {\n    signedUrl\n    id\n  }\n  title\n}\n\nfragment ArticleSectionData on ArticleSection {\n  id\n  title\n  content\n  articleContents {\n    id\n    ...ArticleContentData\n  }\n}\n"
  }
};
})();

(node as any).hash = "2a03e6e4435628311f30cedc431a9007";

export default node;
