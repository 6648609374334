/**
 * @generated SignedSource<<ae602ba0e6369d0d2a92868074c659b7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LessonTemplateData$data = {
  readonly drills: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"LessonTemplateDrill">;
  }>;
  readonly lessonTemplateForms: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"LessonTemplateFormData">;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"LessonTemplateMain">;
  readonly " $fragmentType": "LessonTemplateData";
};
export type LessonTemplateData$key = {
  readonly " $data"?: LessonTemplateData$data;
  readonly " $fragmentSpreads": FragmentRefs<"LessonTemplateData">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LessonTemplateData",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LessonTemplateMain"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LessonTemplateForm",
      "kind": "LinkedField",
      "name": "lessonTemplateForms",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "LessonTemplateFormData"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Drill",
      "kind": "LinkedField",
      "name": "drills",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "LessonTemplateDrill"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LessonTemplate",
  "abstractKey": null
};
})();

(node as any).hash = "474d6eda20f1b8b02e85200eae9974d5";

export default node;
