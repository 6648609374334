import { useMemo } from "react";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { isNil } from "@lib/utils/commons";
import { useMovieMetainfo$key } from "@generated/useMovieMetainfo.graphql";
import {
  MovieMetainfoAngle,
  MovieMetainfoDirection,
  MovieMetainfoGender,
  MovieMetainfoHand,
  MovieMetainfoPlace,
  MovieMetainfoSwing,
} from "@constants/App";

const query = graphql`
  fragment useMovieMetainfo on MovieMetainfo {
    id
    club {
      id
      name
    }
    angle
    direction
    gender
    hand
    place
    swing
  }
`;

export default function useMovieMetainfo({
  movieMetainfoFragment,
}: {
  movieMetainfoFragment: useMovieMetainfo$key;
}): string[] {
  const movieMetainfo = useFragment(query, movieMetainfoFragment);
  const metainfo = useMemo(() => {
    const result: string[] = [];
    if (!isNil(movieMetainfo.swing)) {
      result.push(MovieMetainfoSwing[movieMetainfo.swing]);
    }
    if (!isNil(movieMetainfo.club)) {
      result.push(movieMetainfo.club.name);
    }
    if (!isNil(movieMetainfo.angle)) {
      result.push(MovieMetainfoAngle[movieMetainfo.angle]);
    }
    if (!isNil(movieMetainfo.direction)) {
      result.push(MovieMetainfoDirection[movieMetainfo.direction]);
    }
    if (!isNil(movieMetainfo.gender)) {
      result.push(MovieMetainfoGender[movieMetainfo.gender]);
    }
    if (!isNil(movieMetainfo.hand)) {
      result.push(MovieMetainfoHand[movieMetainfo.hand]);
    }
    if (!isNil(movieMetainfo.place)) {
      result.push(MovieMetainfoPlace[movieMetainfo.place]);
    }
    return result;
  }, [movieMetainfo]);

  return metainfo;
}
