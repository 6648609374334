/**
 * @generated SignedSource<<8e64c3530937c37544b81a62bc896767>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MovieMetainfoJobType = "AnnotateObject" | "LabelingSwing";
import { FragmentRefs } from "relay-runtime";
export type MovieMetainfoItemTask$data = {
  readonly appliedAt: any | null | undefined;
  readonly reviewer: {
    readonly avatar: {
      readonly signedUrl: string | null | undefined;
    } | null | undefined;
    readonly name: string;
  } | null | undefined;
  readonly type: MovieMetainfoJobType;
  readonly user: {
    readonly avatar: {
      readonly signedUrl: string | null | undefined;
    } | null | undefined;
    readonly name: string;
  };
  readonly " $fragmentType": "MovieMetainfoItemTask";
};
export type MovieMetainfoItemTask$key = {
  readonly " $data"?: MovieMetainfoItemTask$data;
  readonly " $fragmentSpreads": FragmentRefs<"MovieMetainfoItemTask">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Avatar",
    "kind": "LinkedField",
    "name": "avatar",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "signedUrl",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MovieMetainfoItemTask",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appliedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "reviewer",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "MovieMetainfoJob",
  "abstractKey": null
};
})();

(node as any).hash = "3f8bbe2a3ae31e314be00f7ce1498288";

export default node;
