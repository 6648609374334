/**
 * @generated SignedSource<<f31651fefb1946a67aa72dd9a81d2ba6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MovieMetainfoAngle = "back" | "front";
export type MovieMetainfoDirection = "landscape" | "portrait" | "square";
export type MovieMetainfoGender = "female" | "male" | "unknown";
export type MovieMetainfoHand = "left" | "right";
export type MovieMetainfoPlace = "course" | "driving_range" | "indoor";
export type MovieMetainfoSwing = "full" | "half" | "quarter";
import { FragmentRefs } from "relay-runtime";
export type useMovieMetainfo$data = {
  readonly angle: MovieMetainfoAngle | null | undefined;
  readonly club: {
    readonly id: string;
    readonly name: string;
  } | null | undefined;
  readonly direction: MovieMetainfoDirection | null | undefined;
  readonly gender: MovieMetainfoGender | null | undefined;
  readonly hand: MovieMetainfoHand | null | undefined;
  readonly id: string;
  readonly place: MovieMetainfoPlace | null | undefined;
  readonly swing: MovieMetainfoSwing | null | undefined;
  readonly " $fragmentType": "useMovieMetainfo";
};
export type useMovieMetainfo$key = {
  readonly " $data"?: useMovieMetainfo$data;
  readonly " $fragmentSpreads": FragmentRefs<"useMovieMetainfo">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useMovieMetainfo",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Club",
      "kind": "LinkedField",
      "name": "club",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "angle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "direction",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gender",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hand",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "place",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "swing",
      "storageKey": null
    }
  ],
  "type": "MovieMetainfo",
  "abstractKey": null
};
})();

(node as any).hash = "e2ba821a43c11c95ea5a26e0bb956d95";

export default node;
