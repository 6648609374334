import { useCallback } from "react";
import graphql from "babel-plugin-relay/macro";
import { Stack, Button } from "@mui/material";
import { useFragment, useMutation } from "react-relay";
import { object, string } from "@lib/utils/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Spacer from "@components/atoms/Spacer";
import { Form, TextField, ErrorText } from "@components/molecules/TextInput";
import useMessage from "@lib/hooks/useMessage";
import { resolveError } from "@lib/utils/error";
import { ProfileInfo$key } from "@generated/ProfileInfo.graphql";
import {
  ProfileInfoUpdateMutation,
  ProfileInfoUpdateMutation$data,
} from "@generated/ProfileInfoUpdateMutation.graphql";

const query = graphql`
  fragment ProfileInfo on User {
    name
  }
`;

const mutation = graphql`
  mutation ProfileInfoUpdateMutation($input: UpdateAdminMutationInput!) {
    updateAdmin(input: $input) {
      __typename
      ... on User {
        id
        name
      }
      ... on UserError {
        message
      }
    }
  }
`;

type Input = {
  name: string;
};

export default function ProfileInfo({
  adminFragment,
}: {
  adminFragment: ProfileInfo$key;
}) {
  const { name } = useFragment<ProfileInfo$key>(query, adminFragment);
  const [commit] = useMutation<ProfileInfoUpdateMutation>(mutation);
  const [, setMessage] = useMessage();
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<Input>({
    defaultValues: {
      name,
    },
    mode: "all",
    resolver: yupResolver(
      object().shape({
        name: string().default("").trim().required("入力してください"),
      })
    ),
  });
  const handleUpdate = useCallback(async () => {
    await handleSubmit(async ({ name: inputName }: Input) => {
      try {
        const result = await new Promise<
          ProfileInfoUpdateMutation$data["updateAdmin"]
        >((resolve) => {
          commit({
            variables: {
              input: {
                name: inputName,
              },
            },
            onCompleted({ updateAdmin }) {
              resolve(updateAdmin);
            },
          });
        });
        if (result.__typename === "UserError") {
          setMessage({
            title: "保存できませんでした",
            subline: result.message,
            mode: "error",
          });
        } else {
          setMessage({
            title: "保存しました",
            mode: "toast",
          });
        }
      } catch (e: unknown) {
        setMessage({
          title: "保存できませんでした",
          subline: resolveError(e).message ?? "ログイン情報が正しくありません",
          mode: "error",
        });
      }
    })();
  }, [commit, handleSubmit, setMessage]);

  return (
    <Form>
      <Stack direction="column" justifyContent="flex-start">
        <TextField control={control} label="名前" name="name" />
        {!isValid && errors.name !== undefined && (
          <ErrorText error={errors.name.message} />
        )}
        <Spacer height={24} />
        <Stack alignItems="flex-start" direction="row">
          <Button disabled={!isValid} onClick={handleUpdate} variant="outlined">
            保存する
          </Button>
        </Stack>
      </Stack>
    </Form>
  );
}
