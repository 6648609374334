import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { useNavigate } from "react-router-dom";
import { SwingFrameCreate$key } from "@generated/SwingFrameCreate.graphql";
import SwingFrameInput from "@components/organisms/SwingFrame/SwingFrameInput";

const query = graphql`
  fragment SwingFrameCreate on Query {
    ...SwingFrameInputSwingPositions
  }
`;

export default function SwingFrameCreate({
  swingFragment,
}: {
  swingFragment: SwingFrameCreate$key;
}): JSX.Element {
  const navigate = useNavigate();
  const swigPositions = useFragment<SwingFrameCreate$key>(query, swingFragment);
  return (
    <SwingFrameInput
      input={{
        swingPositionId: "",
        name: "",
        description: "",
        angle: "back",
        orderNumber: 0,
      }}
      onClose={() => navigate("/swing_frames")}
      swingPositionsFragment={swigPositions}
    />
  );
}
