/**
 * @generated SignedSource<<73ea409588472c0d88a4c44ee402bba9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MovieInReviewApproveData$data = {
  readonly id: string;
  readonly " $fragmentType": "MovieInReviewApproveData";
};
export type MovieInReviewApproveData$key = {
  readonly " $data"?: MovieInReviewApproveData$data;
  readonly " $fragmentSpreads": FragmentRefs<"MovieInReviewApproveData">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MovieInReviewApproveData",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "MovieMetainfoJob",
  "abstractKey": null
};

(node as any).hash = "596304a78448e6073b381bfc34ed895b";

export default node;
