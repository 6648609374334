import Colors from "@constants/Colors";
import { Theme, createTheme } from "@mui/material/styles";
import { DrawerWidth } from "@constants/App";

declare module "@mui/material/styles" {
  interface Palette {
    neutral: Palette["primary"];
  }

  interface PaletteOptions {
    neutral?: PaletteOptions["primary"];
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    neutral: true;
  }
}

declare module "@mui/material/Slider" {
  interface SliderPropsColorOverrides {
    success: true;
  }
}
export default function getTheme(isDarkMode: boolean): Theme {
  const baseBackground = isDarkMode ? Colors.white : Colors.white;
  return createTheme({
    palette: {
      primary: {
        main: Colors.blue,
      },
      secondary: {
        main: Colors.white,
      },
      text: {
        primary: Colors.black,
        secondary: Colors.gray,
      },
      background: {
        default: baseBackground,
      },
      success: {
        main: Colors.green,
      },
      neutral: {
        main: Colors.gray,
      },
    },
    typography: {
      fontSize: 14,
      h1: { fontSize: 32 },
      h2: { fontSize: 22 },
      h3: { fontSize: 22 },
      h4: { fontSize: 19 },
      h5: { fontSize: 19 },
      h6: { fontSize: 19 },
      subtitle1: { fontSize: 13 },
      body1: { fontSize: 16 },
      fontFamily: ["NotoSanJP", "sans-serif"].join(),
    },
    components: {
      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundColor: Colors.drawer,
            color: Colors.white,
            width: DrawerWidth,
            fontWeight: 700,
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: 700,
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: baseBackground,
          },
        },
      },
      MuiLink: {
        defaultProps: {
          underline: "hover",
        },
      },
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            h1: "h1",
            h2: "h2",
            h3: "h2",
            h4: "h4",
            h5: "h4",
            h6: "h4",
            subtitle1: "span",
            subtitle2: "span",
            body1: "span",
            body2: "span",
          },
        },
      },
    },
  });
}
