import { useEffect, Suspense } from "react";
import ContentLayout from "@navigation/ContentLayout";
import Coaching from "@components/templates/Coaching";
import graphql from "babel-plugin-relay/macro";
import {
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import { isNil } from "@lib/utils/commons";
import Loading from "@components/atoms/Loading";
import { CoachingScreenQuery } from "@generated/CoachingScreenQuery.graphql";
import useQueryId from "@lib/hooks/useQueryId";

const query = graphql`
  query CoachingScreenQuery($id: ID!) {
    coaching(id: $id) {
      ...CoachingData
    }
  }
`;

function ScreenContent({
  queryReference,
}: {
  queryReference: PreloadedQuery<CoachingScreenQuery>;
}): JSX.Element {
  const { coaching } = usePreloadedQuery<CoachingScreenQuery>(
    query,
    queryReference
  );
  return <Coaching coachingFragment={coaching} />;
}

export default function CoachingScreen(): JSX.Element {
  const id = useQueryId();
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<CoachingScreenQuery>(query);
  useEffect(() => {
    loadQuery({ id });
    return () => {
      disposeQuery();
    };
  }, [id, loadQuery, disposeQuery]);

  return (
    <ContentLayout
      breadcrumbs={[{ label: "診断一覧", path: "/coachings" }]}
      title="診断詳細"
    >
      <Suspense fallback={<Loading />}>
        {!isNil(queryReference) && (
          <ScreenContent queryReference={queryReference} />
        )}
      </Suspense>
    </ContentLayout>
  );
}
