/**
 * @generated SignedSource<<908425fa4907fdeee529581e5a2a4d7d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MovieMetainfoDirection = "landscape" | "portrait" | "square";
export type ObjectDetectionObjectType = "ball" | "head" | "shaft";
import { FragmentRefs } from "relay-runtime";
export type ObjectDetectionsRow$data = {
  readonly commentContents: ReadonlyArray<{
    readonly content: string;
    readonly createdAt: any;
    readonly id: string;
    readonly user: {
      readonly avatar: {
        readonly signedUrl: string | null | undefined;
      } | null | undefined;
      readonly name: string;
    };
  }>;
  readonly id: string;
  readonly movieClip: {
    readonly height: number | null | undefined;
    readonly swingImage: {
      readonly id: string;
      readonly signedUrl: string | null | undefined;
    };
    readonly width: number | null | undefined;
  };
  readonly movieMetainfo: {
    readonly direction: MovieMetainfoDirection | null | undefined;
  };
  readonly objectType: ObjectDetectionObjectType;
  readonly xmax: number;
  readonly xmin: number;
  readonly ymax: number;
  readonly ymin: number;
  readonly " $fragmentType": "ObjectDetectionsRow";
};
export type ObjectDetectionsRow$key = {
  readonly " $data"?: ObjectDetectionsRow$data;
  readonly " $fragmentSpreads": FragmentRefs<"ObjectDetectionsRow">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "signedUrl",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ObjectDetectionsRow",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "objectType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "xmin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ymin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "xmax",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ymax",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MovieClip",
      "kind": "LinkedField",
      "name": "movieClip",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "width",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "height",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SwingImage",
          "kind": "LinkedField",
          "name": "swingImage",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MovieMetainfo",
      "kind": "LinkedField",
      "name": "movieMetainfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "direction",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CommentContent",
      "kind": "LinkedField",
      "name": "commentContents",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "content",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Avatar",
              "kind": "LinkedField",
              "name": "avatar",
              "plural": false,
              "selections": [
                (v1/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ObjectDetection",
  "abstractKey": null
};
})();

(node as any).hash = "a64fe2abd128f5c9ce6b53048bb8acaa";

export default node;
