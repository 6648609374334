import { useMemo } from "react";
import {
  Stack,
  Box,
  Chip,
  ListItem,
  Divider,
  List,
  ListItemAvatar,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { SwingPositionClassificationSummary$key } from "@generated/SwingPositionClassificationSummary.graphql";
import { SwingPositionClassificationSummaryQuery$key } from "@generated/SwingPositionClassificationSummaryQuery.graphql";
import Spacer from "@components/atoms/Spacer";
import Avatar from "@components/atoms/Avatar";
import Icon from "@components/atoms/Icon";
import Text from "@components/atoms/Text";
import Colors from "@constants/Colors";
import { dateFormat } from "@lib/utils/date";
import { isNil } from "@lib/utils/commons";
import useSwingFrameUrl from "@lib/hooks/useSwingFrameUrl";

const query = graphql`
  fragment SwingPositionClassificationSummary on MovieMetainfo {
    id
    angle
    movieMetainfoJobs {
      id
      type
      appliedAt
      approvedAt
      user {
        name
        avatar {
          signedUrl
        }
      }
      reviewer {
        name
        avatar {
          signedUrl
        }
      }
    }
    movieClips {
      id
      swingClassification {
        swingFrame {
          name
          angle
        }
      }
    }
    ...useSwingFrameUrl
  }
`;

const masterQuery = graphql`
  fragment SwingPositionClassificationSummaryQuery on Query {
    swingFrames {
      name
      angle
      swingPosition {
        name
      }
    }
  }
`;

export default function SwingPositionClassificationSummary({
  movieMetainfoFragment,
  swingPositionsFragment,
}: {
  movieMetainfoFragment: SwingPositionClassificationSummary$key;
  swingPositionsFragment: SwingPositionClassificationSummaryQuery$key;
}) {
  const movieMetainfo = useFragment<SwingPositionClassificationSummary$key>(
    query,
    movieMetainfoFragment
  );
  const swingFrameUrl = useSwingFrameUrl({
    movieMetainfoFragment: movieMetainfo,
  });
  const { swingFrames } = useFragment(masterQuery, swingPositionsFragment);
  const { angle, movieClips, movieMetainfoJobs } = movieMetainfo;
  const { approvedAt, appliedAt, reviewer, user } = useMemo(() => {
    const labeling = movieMetainfoJobs.find(
      (job) => job.type === "LabelingSwing"
    );
    if (labeling === undefined) {
      return {
        approvedAt: null,
        appliedAt: null,
        reviewer: null,
        user: null,
      };
    }
    return {
      approvedAt: labeling.approvedAt,
      appliedAt: labeling.appliedAt,
      reviewer: labeling.reviewer,
      user: labeling.user,
    };
  }, [movieMetainfoJobs]);
  const summaries = useMemo<{
    [index: string]: number;
  }>(() => {
    const summary: {
      [index: string]: number;
    } = {};
    const frames = swingFrames.filter((frame) => frame.angle === angle);
    frames.forEach((swingFrame) => {
      summary[swingFrame.name] = 0;
    });
    movieClips.forEach((movieClip) => {
      if (!isNil(movieClip.swingClassification)) {
        const { swingFrame } = movieClip.swingClassification;
        if (swingFrame.angle === angle) {
          summary[swingFrame.name] += 1;
        }
      }
    });
    return summary;
  }, [angle, swingFrames, movieClips]);
  const sumOfLabel = useMemo<number>(
    () =>
      movieClips.filter((movieClip) => movieClip.swingClassification !== null)
        .length,
    [movieClips]
  );

  return (
    <>
      <List>
        <ListItem>
          <ListItemAvatar>
            <Avatar size={32} url={user?.avatar?.signedUrl} />
          </ListItemAvatar>
          <ListItemText
            primary={`${user?.name}さんが${appliedAt === null ? "ラベリング中です" : "ラベリングしました"}`}
            secondary={dateFormat(appliedAt, "M月D日 HH:mm")}
          />
        </ListItem>
        <Divider />
        {!isNil(reviewer) && (
          <>
            <ListItem>
              <ListItemAvatar>
                <Avatar size={32} url={reviewer.avatar?.signedUrl} />
              </ListItemAvatar>
              <ListItemText
                primary={`${reviewer.name}さんが${
                  approvedAt === null ? "レビュー中です" : "承認しました"
                }`}
                secondary={
                  approvedAt !== null
                    ? dateFormat(approvedAt, "M月D日 HH:mm")
                    : null
                }
              />
            </ListItem>
            <Divider />
          </>
        )}
        {swingFrameUrl !== null && (
          <>
            <ListItemButton
              onClick={() => {
                window.open(
                  swingFrameUrl.url,
                  "_blank",
                  "width=600,height=600"
                );
              }}
            >
              <ListItemAvatar>
                <Icon color={Colors.blue} name="book" size={32} />
              </ListItemAvatar>
              <Text bold color={Colors.blue}>
                スイングポジション定義書を見る ({swingFrameUrl.label})
              </Text>
            </ListItemButton>
            <Divider />
          </>
        )}
      </List>
      <Spacer height={8} />
      <Stack alignItems="flex-start" direction="row" flex={1} flexWrap="wrap">
        {Object.entries(summaries).map(([name, count]) => (
          <Box key={name} sx={{ padding: "2px" }}>
            <Chip color="info" label={`${name}: ${count}`} variant="outlined" />
          </Box>
        ))}
        <Box sx={{ padding: "2px" }}>
          <Chip color="info" label={`合計: ${sumOfLabel}`} variant="outlined" />
        </Box>
      </Stack>
    </>
  );
}
