import {
  addMethod,
  string,
  object,
  number,
  StringSchema,
  NumberSchema,
  date,
  mixed,
} from "yup";
import * as Phone from "libphonenumber-js";
import {
  isPhoneNumber,
  isDate,
  isNumberString,
  isEmail,
  isURL,
} from "@lib/utils/validate";
import { isNil } from "@lib/utils/commons";
import { NameKanaPattern } from "@constants/App";

type PhoneNumber = {
  region?: Phone.CountryCode;
  message?: string;
};

const DefaultMessage = "入力に誤りがあります";

addMethod<StringSchema>(
  string,
  "replace",
  function replace(regex: RegExp, replaceValue: string) {
    return this.transform((value) => {
      if (typeof value !== "string") {
        return "";
      }
      return value.replace(regex, replaceValue);
    });
  }
);

addMethod<StringSchema<string | undefined>>(
  string,
  "toNull",
  function toNull() {
    return this.transform((value: string) =>
      value === "" || value === undefined ? null : value
    );
  }
);

addMethod<StringSchema>(string, "kana", function kana(message?: string) {
  return this.test("kana", message ?? DefaultMessage, (value) => {
    if (!isNil(value) && value !== "") {
      const schema = string().matches(NameKanaPattern);
      return schema.isValidSync(value);
    }
    return true;
  });
});

addMethod<StringSchema>(
  string,
  "phoneNumber",
  function phoneNumber({ region = "JP", message }: PhoneNumber) {
    return this.test("allowEmpty", message ?? DefaultMessage, (value) => {
      if (!isNil(value) && value !== "") {
        return isPhoneNumber(value, region);
      }
      return true;
    });
  }
);

addMethod<StringSchema>(string, "url", function url(message?: string) {
  return this.test("allowEmpty", message ?? DefaultMessage, (value) => {
    if (!isNil(value) && value !== "") {
      return isURL(value);
    }
    return true;
  });
});

addMethod<StringSchema>(
  string,
  "dateString",
  function dateString(message?: string) {
    return this.transform((value) => {
      if (typeof value !== "string") {
        return false;
      }
      if (value === "") {
        return true;
      }
      return isDate(value) ?? null;
    }).test(
      "allowEmpty",
      message ?? DefaultMessage,
      (value) => !isNil(value) && value !== ""
    );
  }
);

addMethod<StringSchema>(
  string,
  "numberString",
  function numberString(message?: string) {
    return this.test("allowEmpty", message ?? DefaultMessage, (value) => {
      if (!isNil(value) && value !== "") {
        return isNumberString(value);
      }
      return true;
    });
  }
);

addMethod<StringSchema>(string, "email", function email(message?: string) {
  return this.test("allowEmpty", message ?? DefaultMessage, (value) => {
    if (!isNil(value) && value !== "") {
      return isEmail(value);
    }
    return true;
  });
});

addMethod<StringSchema>(
  string,
  "matches",
  function matches(regex: RegExp, message?: string) {
    return this.test("allowEmpty", message ?? DefaultMessage, (value) => {
      if (!isNil(value) && value !== "") {
        return regex.test(value);
      }
      return true;
    });
  }
);

addMethod<NumberSchema>(
  number,
  "length",
  function matches(length: { min: number; max: number }, message?: string) {
    return this.test("allowEmpty", message ?? DefaultMessage, (value) => {
      if (!isNil(value)) {
        return (
          `${value}`.length >= length.min && `${value}`.length <= length.max
        );
      }
      return true;
    });
  }
);

export { object, string, number, date, mixed };

declare module "yup" {
  interface StringSchema {
    toNull(): this;
    replace(regex: RegExp, replaceValue: string): this;
    phoneNumber(option: PhoneNumber): this;
    dateString(message?: string): this;
    numberString(message?: string): this;
    email(message?: string): this;
    kana(message?: string): this;
    matches(regex: RegExp, message?: string): this;
    url(message?: string): this;
  }
  interface NumberSchema {
    length(length: { min: number; max: number }, message?: string): this;
  }
}
