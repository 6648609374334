/**
 * @generated SignedSource<<3f2e77755bd5d2abdca664b3bf04360f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MovieMetainfoAngle = "back" | "front";
export type RemoveTmpImagesMutationInput = {
  clientMutationId?: string | null | undefined;
  ids: ReadonlyArray<string>;
  movieJobId: string;
};
export type LabelingSwingWorkingMutation$variables = {
  input: RemoveTmpImagesMutationInput;
};
export type LabelingSwingWorkingMutation$data = {
  readonly removeTmpImages: {
    readonly id: string;
    readonly movieMetainfo: {
      readonly angle: MovieMetainfoAngle | null | undefined;
      readonly id: string;
      readonly movieClips: ReadonlyArray<{
        readonly id: string;
        readonly swingImage: {
          readonly id: string;
          readonly signedUrl: string | null | undefined;
        };
      }>;
    };
    readonly tmpImages: ReadonlyArray<{
      readonly id: string;
      readonly signedUrl: string;
    }>;
  };
};
export type LabelingSwingWorkingMutation = {
  response: LabelingSwingWorkingMutation$data;
  variables: LabelingSwingWorkingMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "signedUrl",
    "storageKey": null
  }
],
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "MovieJob",
    "kind": "LinkedField",
    "name": "removeTmpImages",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "MovieMetainfo",
        "kind": "LinkedField",
        "name": "movieMetainfo",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "angle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "MovieClip",
            "kind": "LinkedField",
            "name": "movieClips",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SwingImage",
                "kind": "LinkedField",
                "name": "swingImage",
                "plural": false,
                "selections": (v2/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TmpImage",
        "kind": "LinkedField",
        "name": "tmpImages",
        "plural": true,
        "selections": (v2/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LabelingSwingWorkingMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LabelingSwingWorkingMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "6d6f5a75efe57e28cc5e8e1d6ed66eee",
    "id": null,
    "metadata": {},
    "name": "LabelingSwingWorkingMutation",
    "operationKind": "mutation",
    "text": "mutation LabelingSwingWorkingMutation(\n  $input: RemoveTmpImagesMutationInput!\n) {\n  removeTmpImages(input: $input) {\n    id\n    movieMetainfo {\n      id\n      angle\n      movieClips {\n        id\n        swingImage {\n          id\n          signedUrl\n        }\n      }\n    }\n    tmpImages {\n      id\n      signedUrl\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "66458fe976dcdb9e53b3591c0440eedc";

export default node;
