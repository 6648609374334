import { useEffect, Suspense } from "react";
import ContentLayout from "@navigation/ContentLayout";
import Coachings from "@components/templates/Coachings";
import graphql from "babel-plugin-relay/macro";
import {
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import { isNil } from "@lib/utils/commons";
import Loading from "@components/atoms/Loading";
import { CoachingsScreenQuery } from "@generated/CoachingsScreenQuery.graphql";
import { PageLimit } from "@constants/App";
import usePaginationOffset from "@lib/hooks/usePaginationOffset";

const query = graphql`
  query CoachingsScreenQuery($first: Int!, $after: String) {
    ...CoachingsPagination @arguments(first: $first, after: $after)
  }
`;

function ScreenContent({
  queryReference,
}: {
  queryReference: PreloadedQuery<CoachingsScreenQuery>;
}) {
  const data = usePreloadedQuery<CoachingsScreenQuery>(query, queryReference);
  return <Coachings coachingsFragment={data} />;
}

export default function CoachingsScreen() {
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<CoachingsScreenQuery>(query);
  const after = usePaginationOffset();
  useEffect(() => {
    loadQuery({
      first: PageLimit,
      after,
    });
    return () => {
      disposeQuery();
    };
  }, [after, loadQuery, disposeQuery]);

  return (
    <ContentLayout title="診断一覧">
      <Suspense fallback={<Loading />}>
        {!isNil(queryReference) && (
          <ScreenContent queryReference={queryReference} />
        )}
      </Suspense>
    </ContentLayout>
  );
}
