/**
 * @generated SignedSource<<7bc9e680c3389b370d3bace2015cefbe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SwingFrameAngle = "back" | "front";
import { FragmentRefs } from "relay-runtime";
export type SwingScenes$data = {
  readonly swingScenes: ReadonlyArray<{
    readonly id: string;
    readonly swingFrame: {
      readonly angle: SwingFrameAngle;
      readonly name: string;
    };
    readonly title: string;
  }>;
  readonly " $fragmentType": "SwingScenes";
};
export type SwingScenes$key = {
  readonly " $data"?: SwingScenes$data;
  readonly " $fragmentSpreads": FragmentRefs<"SwingScenes">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SwingScenes",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SwingScene",
      "kind": "LinkedField",
      "name": "swingScenes",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SwingFrame",
          "kind": "LinkedField",
          "name": "swingFrame",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "angle",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "1cdd16b64d7bd3cfe33b1c0f66adc926";

export default node;
