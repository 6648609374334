/**
 * @generated SignedSource<<df3cf3b7241259f8775714612fe73217>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ObjectDetectionObjectType = "ball" | "head" | "shaft";
export type ImportObjectDetectionsMutationInput = {
  clientMutationId?: string | null | undefined;
  file: any;
  movieMetainfoJobId: string;
};
export type AnnotateObjectHeadMutation$variables = {
  input: ImportObjectDetectionsMutationInput;
};
export type AnnotateObjectHeadMutation$data = {
  readonly importObjectDetections: {
    readonly __typename: "MovieMetainfoJob";
    readonly id: string;
    readonly movieMetainfo: {
      readonly movieClips: ReadonlyArray<{
        readonly id: string;
        readonly objectDetections: ReadonlyArray<{
          readonly commentContents: ReadonlyArray<{
            readonly content: string;
            readonly createdAt: any;
            readonly id: string;
            readonly user: {
              readonly avatar: {
                readonly signedUrl: string | null | undefined;
              } | null | undefined;
              readonly name: string;
            };
          }>;
          readonly id: string;
          readonly objectType: ObjectDetectionObjectType;
          readonly xmax: number;
          readonly xmin: number;
          readonly ymax: number;
          readonly ymin: number;
        }>;
        readonly swingImage: {
          readonly id: string;
          readonly signedUrl: string | null | undefined;
        };
      }>;
    };
  } | {
    readonly __typename: "UserError";
    readonly message: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type AnnotateObjectHeadMutation = {
  response: AnnotateObjectHeadMutation$data;
  variables: AnnotateObjectHeadMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "signedUrl",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "SwingImage",
  "kind": "LinkedField",
  "name": "swingImage",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v4/*: any*/)
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectType",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "xmin",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ymin",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "xmax",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ymax",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "content",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v14 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "type": "UserError",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AnnotateObjectHeadMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "importObjectDetections",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "MovieMetainfo",
                "kind": "LinkedField",
                "name": "movieMetainfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MovieClip",
                    "kind": "LinkedField",
                    "name": "movieClips",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ObjectDetection",
                        "kind": "LinkedField",
                        "name": "objectDetections",
                        "plural": true,
                        "selections": [
                          (v3/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CommentContent",
                            "kind": "LinkedField",
                            "name": "commentContents",
                            "plural": true,
                            "selections": [
                              (v3/*: any*/),
                              (v11/*: any*/),
                              (v12/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "User",
                                "kind": "LinkedField",
                                "name": "user",
                                "plural": false,
                                "selections": [
                                  (v13/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Avatar",
                                    "kind": "LinkedField",
                                    "name": "avatar",
                                    "plural": false,
                                    "selections": [
                                      (v4/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "MovieMetainfoJob",
            "abstractKey": null
          },
          (v14/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AnnotateObjectHeadMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "importObjectDetections",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "MovieMetainfo",
                "kind": "LinkedField",
                "name": "movieMetainfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MovieClip",
                    "kind": "LinkedField",
                    "name": "movieClips",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ObjectDetection",
                        "kind": "LinkedField",
                        "name": "objectDetections",
                        "plural": true,
                        "selections": [
                          (v3/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CommentContent",
                            "kind": "LinkedField",
                            "name": "commentContents",
                            "plural": true,
                            "selections": [
                              (v3/*: any*/),
                              (v11/*: any*/),
                              (v12/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "User",
                                "kind": "LinkedField",
                                "name": "user",
                                "plural": false,
                                "selections": [
                                  (v13/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Avatar",
                                    "kind": "LinkedField",
                                    "name": "avatar",
                                    "plural": false,
                                    "selections": [
                                      (v4/*: any*/),
                                      (v3/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v3/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "MovieMetainfoJob",
            "abstractKey": null
          },
          (v14/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5ebf1fa9dda7814552909d9a66a9135e",
    "id": null,
    "metadata": {},
    "name": "AnnotateObjectHeadMutation",
    "operationKind": "mutation",
    "text": "mutation AnnotateObjectHeadMutation(\n  $input: ImportObjectDetectionsMutationInput!\n) {\n  importObjectDetections(input: $input) {\n    __typename\n    ... on MovieMetainfoJob {\n      id\n      movieMetainfo {\n        movieClips {\n          id\n          swingImage {\n            id\n            signedUrl\n          }\n          objectDetections {\n            id\n            objectType\n            xmin\n            ymin\n            xmax\n            ymax\n            commentContents {\n              id\n              content\n              createdAt\n              user {\n                name\n                avatar {\n                  signedUrl\n                  id\n                }\n                id\n              }\n            }\n          }\n        }\n        id\n      }\n    }\n    ... on UserError {\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0e6beebe3ba3d12ae1214ec5a7bf29c2";

export default node;
