import React, { useRef, useEffect } from "react";
import { Breadcrumbs, Box, ListItem, Divider, List } from "@mui/material";
import Spacer from "@components/atoms/Spacer";
import Text from "@components/atoms/Text";
import graphql from "babel-plugin-relay/macro";
import { usePaginationFragment } from "react-relay";
import { ObjectDetectionsPagination$key } from "@generated/ObjectDetectionsPagination.graphql";
import ObjectDetectionsRow from "@components/organisms/ObjectDetections/ObjectDetectionsRow";
import OffsetPagination from "@components/atoms/OffsetPagination";
import { ObjectDetectionObjectType, PageAnchorName } from "@constants/App";
import useQueryParams from "@lib/hooks/useQueryParams";
import useScroll from "@lib/hooks/useScroll";
import useQueryId from "@lib/hooks/useQueryId";

const query = graphql`
  fragment ObjectDetectionsPagination on Query
  @refetchable(queryName: "ObjectDetectionsPaginationQuery")
  @argumentDefinitions(
    after: { type: "String" }
    first: { type: "Int" }
    objectType: { type: "ObjectDetectionObjectType!" }
  ) {
    objectDetections(first: $first, after: $after, objectType: $objectType)
      @connection(key: "ObjectDetections__objectDetections") {
      edges {
        node {
          id
          ...ObjectDetectionsRow
        }
      }
      currentPage
      totalCount
      limit
    }
  }
`;

export default function ObjectDetections({
  objectDetectionsFragment,
}: {
  objectDetectionsFragment: ObjectDetectionsPagination$key;
}) {
  const objectType =
    useQueryId<keyof typeof ObjectDetectionObjectType>("object_type");
  const listItemRef = useRef<HTMLLIElement | null>(null);
  const { data } = usePaginationFragment(query, objectDetectionsFragment);
  const { edges, currentPage, limit, totalCount } = data.objectDetections;
  const param = useQueryParams([PageAnchorName, "objectType"]);
  const anchor = param[PageAnchorName];
  const { scrollTo } = useScroll();
  useEffect(() => {
    if (listItemRef.current !== null) {
      scrollTo(listItemRef);
    }
  }, [scrollTo]);
  return (
    <Box>
      <Breadcrumbs separator=">">
        <Text bold>{ObjectDetectionObjectType[objectType]}</Text>
      </Breadcrumbs>
      <Spacer height={8} />
      <Divider />
      <Spacer height={8} />
      <List>
        {edges.map(({ node }) => (
          <React.Fragment key={node.id}>
            <ListItem
              ref={(ref) => {
                if (ref !== null && anchor === node.id) {
                  listItemRef.current = ref;
                }
              }}
            >
              <ObjectDetectionsRow objectDetectionFragment={node} />
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>
      <OffsetPagination
        currentPage={currentPage}
        limit={limit}
        path={`object_detections/${objectType}`}
        totalCount={totalCount}
      />
    </Box>
  );
}
