/**
 * @generated SignedSource<<9dc58a511ea578f3bce761ef23dcfadf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MovieMetainfoJobStatus = "approved" | "generating" | "in_review" | "in_working" | "remanded" | "waiting";
import { FragmentRefs } from "relay-runtime";
export type ObjectDetectionItemSummery$data = {
  readonly appliedAt: any | null | undefined;
  readonly approvedAt: any | null | undefined;
  readonly assignedAt: any | null | undefined;
  readonly id: string;
  readonly reviewer: {
    readonly avatar: {
      readonly signedUrl: string | null | undefined;
    } | null | undefined;
    readonly id: string;
    readonly name: string;
  } | null | undefined;
  readonly status: MovieMetainfoJobStatus;
  readonly user: {
    readonly avatar: {
      readonly signedUrl: string | null | undefined;
    } | null | undefined;
    readonly id: string;
    readonly name: string;
  };
  readonly " $fragmentType": "ObjectDetectionItemSummery";
};
export type ObjectDetectionItemSummery$key = {
  readonly " $data"?: ObjectDetectionItemSummery$data;
  readonly " $fragmentSpreads": FragmentRefs<"ObjectDetectionItemSummery">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Avatar",
    "kind": "LinkedField",
    "name": "avatar",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "signedUrl",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ObjectDetectionItemSummery",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "reviewer",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "assignedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appliedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "approvedAt",
      "storageKey": null
    }
  ],
  "type": "MovieMetainfoJob",
  "abstractKey": null
};
})();

(node as any).hash = "94c53a5af216878fba5d45db4356eba3";

export default node;
