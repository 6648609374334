import { Stack } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { LessonTemplateMain$key } from "@generated/LessonTemplateMain.graphql";
import Content from "@components/atoms/Content";
import { decodeId } from "@lib/utils/convertId";

const query = graphql`
  fragment LessonTemplateMain on LessonTemplate {
    id
    goal
    cause {
      content
      swingPosition {
        name
      }
    }
  }
`;

export default function LessonTemplateMain({
  templateFragment,
}: {
  templateFragment: LessonTemplateMain$key;
}): JSX.Element {
  const { id, goal, cause } = useFragment<LessonTemplateMain$key>(
    query,
    templateFragment
  );
  return (
    <Stack direction="column" spacing={1}>
      <Content column="ID" value={decodeId(id)} />
      <Content column="スイング課題" value={cause.content} />
      <Content column="スイングポジション" value={cause.swingPosition.name} />
      <Content column="名称" value={goal} />
    </Stack>
  );
}
