import { useEffect, Suspense } from "react";
import SwingFramesView from "@components/templates/SwingFramesView";
import graphql from "babel-plugin-relay/macro";
import {
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import { isNil } from "@lib/utils/commons";
import Loading from "@components/atoms/Loading";
import {
  SwingFramesViewScreenQuery,
  SwingFrameAngle,
} from "@generated/SwingFramesViewScreenQuery.graphql";
import usePaginationOffset from "@lib/hooks/usePaginationOffset";
import useQueryId from "@lib/hooks/useQueryId";

const query = graphql`
  query SwingFramesViewScreenQuery($angle: SwingFrameAngle!) {
    ...SwingFramesView @arguments(angle: $angle)
  }
`;

function ScreenContent({
  queryReference,
}: {
  queryReference: PreloadedQuery<SwingFramesViewScreenQuery>;
}) {
  const data = usePreloadedQuery<SwingFramesViewScreenQuery>(
    query,
    queryReference
  );
  return <SwingFramesView definitionsFragment={data} />;
}

export default function SwingFramesViewScreen() {
  const angle = useQueryId<SwingFrameAngle>("angle");
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<SwingFramesViewScreenQuery>(query);
  const after = usePaginationOffset();
  useEffect(() => {
    loadQuery(
      {
        angle,
      },
      {
        fetchPolicy: "network-only",
      }
    );
    return () => {
      disposeQuery();
    };
  }, [angle, after, loadQuery, disposeQuery]);

  return (
    <Suspense fallback={<Loading />}>
      {!isNil(queryReference) && (
        <ScreenContent queryReference={queryReference} />
      )}
    </Suspense>
  );
}
