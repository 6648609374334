import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  Toolbar,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Badge,
} from "@mui/material";
import Icon, { IconName } from "@components/atoms/Icon";
import Colors from "@constants/Colors";
import Spacer from "@components/atoms/Spacer";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { NavigationDrawerCounts$key } from "@generated/NavigationDrawerCounts.graphql";
import useNotificationCount, {
  refetchable,
} from "@lib/hooks/useNotificationCount";

const query = graphql`
  fragment NavigationDrawerCounts on Query {
    ...useNotificationCount
  }
`;

interface Menu {
  title: string;
  icon?: IconName;
  url: string;
}

interface MenuInfo extends Menu {
  children?: Menu[];
}

const globalMenus: MenuInfo[] = [
  {
    title: "TOP",
    icon: "home",
    url: "/",
  },
  {
    title: "タスク",
    icon: "labeling",
    url: "/",
    children: [
      {
        title: "ラベリング",
        url: "labeling_swings",
      },
      {
        title: "アノテーション",
        url: "annotate_objects",
      },
      {
        title: "コメント",
        url: "comments",
      },
    ],
  },
  {
    title: "スイング動画",
    icon: "video",
    url: "/",
    children: [
      {
        title: "一覧",
        url: "movie_metainfos",
      },
      {
        title: "レビュー中",
        url: "movie_in_reviews",
      },
      {
        title: "除外済み",
        url: "movie_jobs_ignored",
      },
    ],
  },
  {
    title: "マスタ管理",
    icon: "menu",
    url: "swing_frames",
    children: [
      {
        title: "スイングフレーム定義",
        url: "swing_frames",
      },
      {
        title: "スイング診断シーン",
        url: "swing_scenes",
      },
      {
        title: "スイング課題",
        url: "causes",
      },
      {
        title: "診断テンプレート",
        url: "lesson_templates",
      },
      {
        title: "旧診断",
        url: "coachings",
      },
    ],
  },
  {
    title: "設定",
    icon: "setting",
    url: "members",
    children: [
      {
        title: "メンバー",
        url: "members",
      },
      {
        title: "招待",
        url: "user_invites",
      },
    ],
  },
];

export default function NavigationDrawer({
  drawerFragment,
}: {
  drawerFragment: NavigationDrawerCounts$key;
}) {
  const nav = useNavigate();
  const data = useFragment(query, drawerFragment);
  const { inReviews, unreadComments, refetch } = useNotificationCount({
    notificationFragment: data,
  });
  useEffect(() => {
    if (refetchable()) {
      refetch();
    }
  });

  return (
    <>
      <Toolbar />
      <Spacer height={24} />
      <Box sx={{ overflow: "auto" }}>
        <List>
          {globalMenus.map((row) => (
            <React.Fragment key={`${row.title}-${row.url}`}>
              <ListItem disablePadding>
                {row.children !== undefined ? (
                  <ListItemButton>
                    <IconWrapper>
                      {row.icon !== undefined && (
                        <Icon color={Colors.white} name={row.icon} size={24} />
                      )}
                    </IconWrapper>
                    <ListItemText
                      primary={row.title}
                      primaryTypographyProps={{
                        fontWeight: 900,
                      }}
                    />
                  </ListItemButton>
                ) : (
                  <ListItemButton onClick={() => nav(row.url)}>
                    <IconWrapper>
                      {row.icon !== undefined && (
                        <Icon color={Colors.white} name={row.icon} size={24} />
                      )}
                    </IconWrapper>
                    <ListItemText
                      primary={row.title}
                      primaryTypographyProps={{
                        fontWeight: 900,
                      }}
                    />
                    {row.url === "comments" && unreadComments > 0 && (
                      <Badge badgeContent={unreadComments} color="error" />
                    )}
                  </ListItemButton>
                )}
              </ListItem>
              {row.children !== undefined && (
                <List disablePadding>
                  {row.children.map((menu) => (
                    <ListItem key={`${menu.title}-${menu.url}`} disablePadding>
                      <ListItemButton onClick={() => nav(menu.url)}>
                        <IconWrapper />
                        <ListItemText
                          primary={menu.title}
                          primaryTypographyProps={{
                            variant: "body2",
                            style: { fontWeight: 600 },
                          }}
                        />
                        {menu.url === "movie_in_reviews" && inReviews > 0 && (
                          <Badge badgeContent={inReviews} color="error" />
                        )}
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              )}
            </React.Fragment>
          ))}
        </List>
      </Box>
    </>
  );
}

const IconWrapper = styled.div`
  display: flex;
  width: 32px;
`;
