/**
 * @generated SignedSource<<1f41a13dea359bab3683eceaac61fc67>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MovieMetainfoJobWorkflow = "apply" | "approve" | "remand" | "review";
export type UpdateMovieMetainfoJobMutationInput = {
  clientMutationId?: string | null | undefined;
  id: string;
  workflow: MovieMetainfoJobWorkflow;
};
export type MovieInReviewApproveMutation$variables = {
  input: UpdateMovieMetainfoJobMutationInput;
};
export type MovieInReviewApproveMutation$data = {
  readonly updateMovieMetainfoJob: {
    readonly __typename: "MovieMetainfoJob";
    readonly id: string;
  } | {
    readonly __typename: "UserError";
    readonly message: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type MovieInReviewApproveMutation = {
  response: MovieInReviewApproveMutation$data;
  variables: MovieInReviewApproveMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "updateMovieMetainfoJob",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "type": "MovieMetainfoJob",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "type": "UserError",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MovieInReviewApproveMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MovieInReviewApproveMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c72a8b16ab591384bf08ef2e077fbdae",
    "id": null,
    "metadata": {},
    "name": "MovieInReviewApproveMutation",
    "operationKind": "mutation",
    "text": "mutation MovieInReviewApproveMutation(\n  $input: UpdateMovieMetainfoJobMutationInput!\n) {\n  updateMovieMetainfoJob(input: $input) {\n    __typename\n    ... on MovieMetainfoJob {\n      id\n    }\n    ... on UserError {\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "eaa65781bb512430304993f97a9b837a";

export default node;
