/**
 * @generated SignedSource<<6b0c9f8280dc9cc00f651e881f51969a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProfileInfo$data = {
  readonly name: string;
  readonly " $fragmentType": "ProfileInfo";
};
export type ProfileInfo$key = {
  readonly " $data"?: ProfileInfo$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileInfo">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileInfo",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "11435fe26210d74a4e14de8b45e86a82";

export default node;
