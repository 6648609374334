import { useCallback } from "react";
import { Stack, Button, Box } from "@mui/material";
import Spacer from "@components/atoms/Spacer";
import { resolveError } from "@lib/utils/error";
import {
  Form,
  SelectField,
  NumberField,
  ErrorText,
} from "@components/molecules/TextInput";
import { object, string, mixed, number } from "@lib/utils/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import useMessage from "@lib/hooks/useMessage";
import Video from "@components/atoms/Video";
import { getKeys } from "@lib/utils/commons";
import graphql from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import {
  SwingPositionClassificationMeta$key,
  MovieMetainfoAngle,
  MovieMetainfoDirection,
  MovieMetainfoGender,
  MovieMetainfoHand,
  MovieMetainfoPlace,
  MovieMetainfoSwing,
} from "@generated/SwingPositionClassificationMeta.graphql";
import { SwingPositionClassificationMetaQuery$key } from "@generated/SwingPositionClassificationMetaQuery.graphql";
import {
  SwingPositionClassificationMetaMutation,
  SwingPositionClassificationMetaMutation$data,
} from "@generated/SwingPositionClassificationMetaMutation.graphql";

const query = graphql`
  fragment SwingPositionClassificationMeta on MovieMetainfo {
    id
    sourceUrl
    club {
      id
      name
    }
    trimmingStart
    trimmingEnd
    duration
    width
    height
    angle
    direction
    gender
    hand
    place
    swing
  }
`;

const clubsQury = graphql`
  fragment SwingPositionClassificationMetaQuery on Query {
    clubs {
      id
      name
    }
  }
`;

const mutation = graphql`
  mutation SwingPositionClassificationMetaMutation(
    $input: UpdateMovieMetainfoMutationInput!
  ) {
    updateMovieMetainfo(input: $input) {
      __typename
      ... on MovieMetainfo {
        id
        club {
          id
          name
        }
        trimmingStart
        trimmingEnd
        duration
        width
        height
        angle
        direction
        gender
        hand
        place
        swing
      }
      ... on UserError {
        message
      }
    }
  }
`;

type Input = {
  clubId: string;
  angle: MovieMetainfoAngle;
  direction: MovieMetainfoDirection;
  gender: MovieMetainfoGender;
  hand: MovieMetainfoHand;
  place: MovieMetainfoPlace;
  swing: MovieMetainfoSwing;
  trimmingStart: number;
  trimmingEnd: number;
  duration: number;
  width: number;
  height: number;
};

const angles: {
  [key in MovieMetainfoAngle]: string;
} = {
  back: "背面",
  front: "正面",
};

const directions: {
  [key in MovieMetainfoDirection]: string;
} = {
  landscape: "横型",
  portrait: "縦型",
  square: "正方形",
};

const genders: {
  [key in MovieMetainfoGender]: string;
} = {
  female: "女性",
  male: "男性",
  unknown: "不明",
};

const hands: {
  [key in MovieMetainfoHand]: string;
} = {
  left: "左手",
  right: "右手",
};

const places: {
  [key in MovieMetainfoPlace]: string;
} = {
  course: "コース",
  driving_range: "打ちっぱなし",
  indoor: "インドア",
};

const swings: {
  [key in MovieMetainfoSwing]: string;
} = {
  full: "フルスイング",
  half: "ハーフスイング",
  quarter: "クォータースイング",
};

export default function SwingPositionClassificationMeta({
  movieInfoFragment,
  clubsFragment,
}: {
  movieInfoFragment: SwingPositionClassificationMeta$key;
  clubsFragment: SwingPositionClassificationMetaQuery$key;
}) {
  const {
    id,
    club,
    angle,
    direction,
    gender,
    hand,
    place,
    swing,
    sourceUrl,
    trimmingStart,
    trimmingEnd,
    duration,
    width,
    height,
  } = useFragment(query, movieInfoFragment);
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
  } = useForm<Input>({
    defaultValues: {
      clubId: club?.id ?? "",
      angle: angle ?? "back",
      direction: direction ?? "portrait",
      gender: gender ?? "male",
      hand: hand ?? "right",
      place: place ?? undefined,
      swing: swing ?? "full",
      trimmingStart: trimmingStart ?? 0,
      trimmingEnd: trimmingEnd ?? undefined,
      duration: duration ?? undefined,
      width: width ?? undefined,
      height: height ?? undefined,
    },
    mode: "all",
    resolver: yupResolver(
      object().shape({
        clubId: string().trim().required("選択してくださいしてください"),
        angle: mixed<MovieMetainfoAngle>()
          .oneOf(getKeys(angles), "選択してください")
          .required("選択してくださいしてください"),
        direction: mixed<MovieMetainfoDirection>()
          .oneOf(getKeys(directions), "選択してください")
          .required("選択してくださいしてください"),
        gender: mixed<MovieMetainfoGender>()
          .oneOf(getKeys(genders), "選択してください")
          .required("選択してくださいしてください"),
        hand: mixed<MovieMetainfoHand>()
          .oneOf(getKeys(hands), "選択してください")
          .required("選択してくださいしてください"),
        place: mixed<MovieMetainfoPlace>()
          .oneOf(getKeys(places), "選択してください")
          .required("選択してくださいしてください"),
        swing: mixed<MovieMetainfoSwing>()
          .oneOf(getKeys(swings), "選択してください")
          .required("選択してくださいしてください"),
        trimmingStart: number()
          .required("入力してください")
          .test(
            "compare",
            "トリミング終了より短い時間を入力してください",
            (value) => {
              const end = getValues("trimmingEnd");
              if (end !== undefined && value !== undefined && value >= end) {
                return false;
              }
              return true;
            }
          ),
        trimmingEnd: number()
          .required("入力してください")
          .test(
            "compare",
            "動画時間より短い時間を入力してください",
            (value) => {
              const durationSec = getValues("duration");
              if (
                durationSec !== undefined &&
                value !== undefined &&
                value > durationSec
              ) {
                return false;
              }
              return true;
            }
          ),
        duration: number()
          .required("入力してください")
          .test(
            "compare",
            "トリミング終了より長い時間を入力してください",
            (value) => {
              const end = getValues("trimmingEnd");
              if (end !== undefined && value !== undefined && value < end) {
                return false;
              }
              return true;
            }
          ),
        width: number().required("入力してください"),
        height: number().required("入力してください"),
      })
    ),
  });

  const [, setMessage] = useMessage();
  const [commit] =
    useMutation<SwingPositionClassificationMetaMutation>(mutation);
  const { clubs } = useFragment(clubsQury, clubsFragment);
  const handleUpdate = useCallback(async () => {
    try {
      await handleSubmit(async (input: Input) => {
        const result = await new Promise<
          SwingPositionClassificationMetaMutation$data["updateMovieMetainfo"]
        >((resolve) => {
          commit({
            variables: {
              input: {
                id,
                ...input,
              },
            },
            onCompleted({ updateMovieMetainfo }) {
              resolve(updateMovieMetainfo);
            },
          });
        });
        if (result.__typename === "UserError") {
          setMessage({
            mode: "error",
            title: "保存できませんでした",
            subline: result.message,
          });
        } else {
          setMessage({
            mode: "toast",
            title: "保存しました",
          });
        }
      })();
    } catch (error: unknown) {
      setMessage({
        mode: "error",
        title: "保存できませんでした",
        subline: resolveError(error).message,
      });
    }
  }, [commit, handleSubmit, id, setMessage]);
  return (
    <Form>
      <Stack
        direction="row"
        flexWrap="wrap"
        justifyContent="flex-start"
        sx={{ padding: 2 }}
      >
        <Box sx={{ width: 250 }}>
          <Video src={sourceUrl} />
        </Box>
      </Stack>
      <Stack
        direction="row"
        flexWrap="wrap"
        justifyContent="flex-start"
        sx={{ padding: 2 }}
      >
        <Stack flex={1}>
          <SelectField
            control={control}
            label="クラブ"
            name="clubId"
            options={clubs.map((row) => ({
              label: row.name,
              value: row.id,
            }))}
            required
          />
          {!isValid && errors.clubId !== undefined && (
            <ErrorText error={errors.clubId.message} />
          )}

          <Spacer height={24} />

          <NumberField
            control={control}
            disabled
            label="width"
            name="width"
            required
          />
          <Spacer height={24} />

          <NumberField
            control={control}
            disabled
            label="height"
            name="height"
            required
          />
          <Spacer height={24} />

          <NumberField
            control={control}
            disabled
            label="動画の長さ(秒)"
            name="duration"
            required
          />
          <Spacer height={24} />

          <NumberField
            control={control}
            helperText="秒数は切り捨ててください"
            label="トリミング開始(秒)"
            name="trimmingStart"
            required
          />
          {!isValid && errors.trimmingStart !== undefined && (
            <ErrorText error={errors.trimmingStart.message} />
          )}
          <Spacer height={24} />

          <NumberField
            control={control}
            helperText="秒数は切り上げてください"
            label="トリミング終了(秒)"
            name="trimmingEnd"
            required
          />
          {!isValid && errors.trimmingEnd !== undefined && (
            <ErrorText error={errors.trimmingEnd.message} />
          )}

          <Spacer height={24} />
        </Stack>
        <Stack direction="column" flex={1}>
          <SelectField
            control={control}
            label="カメラ向き"
            name="direction"
            options={getKeys(directions).map((key) => ({
              label: directions[key],
              value: key,
            }))}
            required
          />
          {!isValid && errors.direction !== undefined && (
            <ErrorText error={errors.direction.message} />
          )}
          <Spacer height={24} />

          <SelectField
            control={control}
            label="撮影角度"
            name="angle"
            options={getKeys(angles).map((key) => ({
              label: angles[key],
              value: key,
            }))}
            required
          />
          {!isValid && errors.angle !== undefined && (
            <ErrorText error={errors.angle.message} />
          )}

          <Spacer height={24} />

          <SelectField
            control={control}
            label="利き手"
            name="hand"
            options={getKeys(hands).map((key) => ({
              label: hands[key],
              value: key,
            }))}
            required
          />
          {!isValid && errors.hand !== undefined && (
            <ErrorText error={errors.hand.message} />
          )}

          <Spacer height={24} />
          <SelectField
            control={control}
            label="性別"
            name="gender"
            options={getKeys(genders).map((key) => ({
              label: genders[key],
              value: key,
            }))}
            required
          />
          {!isValid && errors.gender !== undefined && (
            <ErrorText error={errors.gender.message} />
          )}

          <Spacer height={24} />
          <SelectField
            control={control}
            label="撮影場所"
            name="place"
            options={getKeys(places).map((key) => ({
              label: places[key],
              value: key,
            }))}
            required
          />
          {!isValid && errors.place !== undefined && (
            <ErrorText error={errors.place.message} />
          )}
          <Spacer height={24} />

          <SelectField
            control={control}
            label="スイング"
            name="swing"
            options={getKeys(swings).map((key) => ({
              label: swings[key],
              value: key,
            }))}
            required
          />
          {!isValid && errors.swing !== undefined && (
            <ErrorText error={errors.swing.message} />
          )}
          <Spacer height={24} />
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent="center">
        <Button onClick={handleUpdate} variant="contained">
          保存する
        </Button>
      </Stack>
    </Form>
  );
}
