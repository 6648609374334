import { useEffect, Suspense } from "react";
import ContentLayout from "@navigation/ContentLayout";
import Progress from "@components/templates/Progress";
import graphql from "babel-plugin-relay/macro";
import {
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import { isNil } from "@lib/utils/commons";
import Loading from "@components/atoms/Loading";
import { ProgressScreenQuery } from "@generated/ProgressScreenQuery.graphql";

const query = graphql`
  query ProgressScreenQuery {
    progress {
      ...ProgressData
    }
  }
`;

function ScreenContent({
  queryReference,
}: {
  queryReference: PreloadedQuery<ProgressScreenQuery>;
}): JSX.Element {
  const { progress } = usePreloadedQuery<ProgressScreenQuery>(
    query,
    queryReference
  );
  return <Progress progressFragment={progress} />;
}

export default function ProgressScreen(): JSX.Element {
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<ProgressScreenQuery>(query);
  useEffect(() => {
    loadQuery(
      {},
      {
        fetchPolicy: "network-only",
      }
    );
    return () => {
      disposeQuery();
    };
  }, [loadQuery, disposeQuery]);

  return (
    <ContentLayout title="TOP" top>
      <Suspense fallback={<Loading />}>
        {!isNil(queryReference) && (
          <ScreenContent queryReference={queryReference} />
        )}
      </Suspense>
    </ContentLayout>
  );
}
