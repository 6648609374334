import { useEffect, Suspense } from "react";
import ContentLayout from "@navigation/ContentLayout";
import SwingFrameMovies from "@components/templates/SwingFrameMovies";
import graphql from "babel-plugin-relay/macro";
import {
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import { isNil } from "@lib/utils/commons";
import Loading from "@components/atoms/Loading";
import { SwingFrameMoviesScreenQuery } from "@generated/SwingFrameMoviesScreenQuery.graphql";
import useQueryId from "@lib/hooks/useQueryId";
import { PageLimit } from "@constants/App";
import usePaginationOffset from "@lib/hooks/usePaginationOffset";

const query = graphql`
  query SwingFrameMoviesScreenQuery(
    $first: Int!
    $swingFrameId: ID!
    $after: String
  ) {
    ...SwingFrameMoviesPagination
      @arguments(first: $first, after: $after, swingFrameId: $swingFrameId)
    ...SwingFrameMoviesQuery
    swingFrame(id: $swingFrameId) {
      ...SwingFrameMoviesFrame
    }
  }
`;

function ScreenContent({
  queryReference,
}: {
  queryReference: PreloadedQuery<SwingFrameMoviesScreenQuery>;
}) {
  const data = usePreloadedQuery<SwingFrameMoviesScreenQuery>(
    query,
    queryReference
  );
  return (
    <SwingFrameMovies
      frameFragment={data.swingFrame}
      masterFragment={data}
      swingPositionsFragment={data}
    />
  );
}

export default function SwingFrameMoviesScreen() {
  const id = useQueryId();
  const after = usePaginationOffset();
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<SwingFrameMoviesScreenQuery>(query);

  useEffect(() => {
    loadQuery({
      after,
      first: PageLimit,
      swingFrameId: id,
    });
    return () => {
      disposeQuery();
    };
  }, [id, after, loadQuery, disposeQuery]);

  return (
    <ContentLayout
      breadcrumbs={[
        { label: "スイングフレーム定義一覧", path: "/swing_frames" },
        { label: "スイングフレーム定義", path: `/swing_frames/${id}` },
      ]}
      title="スイング分類"
    >
      <Suspense fallback={<Loading />}>
        {!isNil(queryReference) && (
          <ScreenContent queryReference={queryReference} />
        )}
      </Suspense>
    </ContentLayout>
  );
}
