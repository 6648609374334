/**
 * @generated SignedSource<<75d870ad9f295ab71847fb0ed23beb9a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SwingSceneRemove$data = {
  readonly id: string;
  readonly " $fragmentType": "SwingSceneRemove";
};
export type SwingSceneRemove$key = {
  readonly " $data"?: SwingSceneRemove$data;
  readonly " $fragmentSpreads": FragmentRefs<"SwingSceneRemove">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SwingSceneRemove",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "SwingScene",
  "abstractKey": null
};

(node as any).hash = "4cf647e44314e9a88233e339a93990b7";

export default node;
