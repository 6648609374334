import { Divider, Stack } from "@mui/material";
import { useMemo } from "react";
import Text from "@components/atoms/Text";
import { isNil } from "@lib/utils/commons";

export default function Content({
  column,
  value,
  columnWidth = 150,
  bordered = false,
}: {
  column: string;
  value: string | number | null | undefined;
  columnWidth?: number | string;
  bordered?: boolean;
}) {
  const columnStyle = useMemo(() => {
    if (typeof columnWidth === "string") {
      return {
        width: columnWidth,
      };
    }
    if (typeof columnWidth === "number") {
      return {
        width: `${columnWidth}px`,
      };
    }
    return {};
  }, [columnWidth]);
  return (
    <Stack direction="row" justifyContent="center" spacing={1}>
      <Stack sx={columnStyle}>
        <Text bold>{column}</Text>
      </Stack>
      <Stack flex={1}>
        <Text>{isNil(value) ? "" : value}</Text>
        {bordered && <Divider />}
      </Stack>
    </Stack>
  );
}
