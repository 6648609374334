import React, { useState } from "react";
import {
  Stack,
  Box,
  Chip,
  Card,
  List,
  CardContent,
  CardMedia,
  CardActions,
  Divider,
  IconButton,
  Dialog,
  DialogContent,
} from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { MovieMetainfoItemCard$key } from "@generated/MovieMetainfoItemCard.graphql";
import Spacer from "@components/atoms/Spacer";
import Text from "@components/atoms/Text";
import Colors from "@constants/Colors";
import Icon from "@components/atoms/Icon";
import useMovieMetainfo from "@lib/hooks/useMovieMetainfo";
import { decodeId } from "@lib/utils/convertId";
import Video from "@components/atoms/Video";
import MovieMetainfoItemTask from "@components/molecules/MovieMetainfoItem/MovieMetainfoItemTask";

const query = graphql`
  fragment MovieMetainfoItemCard on MovieMetainfo {
    id
    thumbnail
    sourceUrl
    clipCount
    movieMetainfoJobs {
      id
      ...MovieMetainfoItemTask
    }
    ...useMovieMetainfo
  }
`;

export default function MovieMetainfoItemCard({
  movieFragment,
  footer,
}: {
  movieFragment: MovieMetainfoItemCard$key;
  footer?: React.ReactNode;
}) {
  const data = useFragment(query, movieFragment);
  const { id, thumbnail, sourceUrl, clipCount, movieMetainfoJobs } = data;
  const metainfo = useMovieMetainfo({ movieMetainfoFragment: data });
  const [exapnd, setExpand] = useState<boolean>(false);
  return (
    <>
      <Card sx={{ maxWidth: 280, marginBottom: 1 }}>
        <Box sx={{ position: "relative" }}>
          <CardMedia
            component="img"
            height="250"
            image={thumbnail ?? ""}
            onClick={() => setExpand(true)}
          />
          <IconButton
            onClick={() => setExpand(true)}
            sx={{ position: "absolute", top: 8, right: 8 }}
          >
            <Icon name="play" size={24} />
          </IconButton>
        </Box>

        <CardContent>
          <Stack
            alignItems="flex-start"
            direction="row"
            flex={1}
            flexWrap="wrap"
          >
            <Box sx={{ padding: "2px" }}>
              <Chip
                color="default"
                label={`ID: ${decodeId(id)}`}
                size="small"
                variant="outlined"
              />
            </Box>
            <Spacer height={8} />
            {metainfo.length > 0 ? (
              <>
                {metainfo.map((row) => (
                  <Box key={row} sx={{ padding: "2px" }}>
                    <Chip
                      color="primary"
                      label={row}
                      size="small"
                      variant="outlined"
                    />
                  </Box>
                ))}
                <Box sx={{ padding: "2px" }}>
                  <Chip
                    color="primary"
                    label={`${clipCount}枚`}
                    size="small"
                    variant="outlined"
                  />
                </Box>
                <Spacer height={8} />
              </>
            ) : (
              <Text bold color={Colors.orange}>
                メタ情報が登録されていません
              </Text>
            )}
          </Stack>
        </CardContent>
        {movieMetainfoJobs.length > 0 && (
          <List>
            {movieMetainfoJobs.map((movieMetainfoJob) => (
              <React.Fragment key={movieMetainfoJob.id}>
                <Divider />
                <MovieMetainfoItemTask
                  key={movieMetainfoJob.id}
                  jobFragment={movieMetainfoJob}
                />
              </React.Fragment>
            ))}
          </List>
        )}
        {footer !== undefined && (
          <>
            <Divider />
            <CardActions>{footer}</CardActions>
          </>
        )}
      </Card>
      <Dialog onClose={() => setExpand(false)} open={exapnd}>
        <Stack alignItems="flex-end" justifyContent="center">
          <IconButton onClick={() => setExpand(false)}>
            <Icon name="close" size={24} />
          </IconButton>
        </Stack>
        <DialogContent>
          <Video src={sourceUrl} />
        </DialogContent>
      </Dialog>
    </>
  );
}
