/**
 * @generated SignedSource<<278aba903e580da0dd10e0e44d29b73e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProfileData$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ProfileAvatar" | "ProfileInfo">;
  readonly " $fragmentType": "ProfileData";
};
export type ProfileData$key = {
  readonly " $data"?: ProfileData$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileData">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileData",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProfileInfo"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProfileAvatar"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "3fbb74e0ee318f403de0b158b8e8ffce";

export default node;
