/**
 * @generated SignedSource<<449cf3fa48d0bfd394729a3b9ff16e78>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MovieMetainfoQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"MovieMetainfoLabelsQuery">;
  readonly " $fragmentType": "MovieMetainfoQuery";
};
export type MovieMetainfoQuery$key = {
  readonly " $data"?: MovieMetainfoQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"MovieMetainfoQuery">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MovieMetainfoQuery",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MovieMetainfoLabelsQuery"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "172e0089f15ed4b497d37f0f03ae887a";

export default node;
