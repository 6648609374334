import styled from "styled-components";
import { CircularProgress, Backdrop } from "@mui/material";
import Colors from "@constants/Colors";

export default function Loading({
  size = 30,
  color = Colors.blue,
  mask = false,
  maskPosition = "fixed",
}: {
  size?: number;
  color?: string;
  mask?: boolean;
  maskPosition?: "absolute" | "fixed";
}): JSX.Element {
  if (mask) {
    return (
      <Backdrop
        open
        sx={{
          zIndex: (theme) => theme.zIndex.modal + 1,
          color,
          position: maskPosition,
        }}
      >
        <CircularProgress color="inherit" size={size} />
      </Backdrop>
    );
  }
  return (
    <Container color={color}>
      <CircularProgress color="inherit" size={size} />
    </Container>
  );
}

const Container = styled.div<{ color: string }>`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) => `color: ${props.color}`};
`;
