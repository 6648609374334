import { refreshTokenKey, accessTokenKey } from "@constants/Env";
import Storage from "@lib/utils/storage";

const storage = new Storage("cookie");

export class SecureStoreManager {
  static deleteAccessTokenSet(): void {
    SecureStoreManager.deleteAccessToken();
    SecureStoreManager.deleteRefreshToken();
  }

  static deleteAccessToken(): void {
    storage.removeItem(accessTokenKey);
  }

  static deleteRefreshToken(): void {
    storage.removeItem(refreshTokenKey);
  }

  static registerAccessTokenSet(
    accessToken: string,
    refreshToken: string
  ): void {
    SecureStoreManager.registerAccessToken(accessToken);
    SecureStoreManager.registerRefreshToken(refreshToken);
  }

  static registerAccessToken(accessToken: string): void {
    storage.setItem(accessTokenKey, accessToken);
  }

  static registerRefreshToken(refreshToken: string): void {
    storage.setItem(refreshTokenKey, refreshToken);
  }

  static getAccessTokenSet(): {
    accessToken: string | null;
    refreshToken: string | null;
  } {
    const accessToken = SecureStoreManager.getAccessToken();
    const refreshToken = SecureStoreManager.getRefreshToken();
    return { accessToken, refreshToken };
  }

  static getAccessToken(): string | null {
    return storage.getItem(accessTokenKey);
  }

  static getRefreshToken(): string | null {
    return storage.getItem(refreshTokenKey);
  }
}
