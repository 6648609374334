import { useCallback, useState } from "react";
import {
  Button,
  Stack,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import Spacer from "@components/atoms/Spacer";
import Message from "@components/molecules/Message";
import Loading from "@components/atoms/Loading";
import OffsetPagination from "@components/atoms/OffsetPagination";
import graphql from "babel-plugin-relay/macro";
import { usePaginationFragment, useMutation } from "react-relay";
import {
  AnnotateObjectsMutation,
  AnnotateObjectsMutation$data,
} from "@generated/AnnotateObjectsMutation.graphql";
import { dateFormat } from "@lib/utils/date";
import { decodeId } from "@lib/utils/convertId";
import { Link } from "react-router-dom";
import Text from "@components/atoms/Text";
import useMessage from "@lib/hooks/useMessage";
import usePreventDoubleClick from "@lib/hooks/usePreventDoubleClick";
import { AnnotateObjectsPagination$key } from "@generated/AnnotateObjectsPagination.graphql";
import Colors from "@constants/Colors";
import { MovieMetainfoJobStatus } from "@constants/App";

const query = graphql`
  fragment AnnotateObjectsPagination on Query
  @refetchable(queryName: "AnnotateObjectsPaginationQuery")
  @argumentDefinitions(
    after: { type: "String" }
    first: { type: "Int" }
    type: { type: "MovieMetainfoJobType!" }
  ) {
    movieMetainfoJobs(first: $first, after: $after, type: $type)
      @connection(key: "AnnotateObjects__movieMetainfoJobs") {
      edges {
        node {
          id
          movieMetainfo {
            id
          }
          status
          assignedAt
        }
      }
      currentPage
      totalCount
      limit
    }
  }
`;

const mutation = graphql`
  mutation AnnotateObjectsMutation(
    $input: AssignMovieMetainfoJobsMutationInput!
  ) {
    assignMovieMetainfoJobs(input: $input) {
      success
      message
    }
  }
`;

export default function AnnotateObjects({
  annotateObjectsFragment,
}: {
  annotateObjectsFragment: AnnotateObjectsPagination$key;
}) {
  const { data } = usePaginationFragment(query, annotateObjectsFragment);
  const { edges, currentPage, limit, totalCount } = data.movieMetainfoJobs;
  const [, setMessage] = useMessage();
  const [commit] = useMutation<AnnotateObjectsMutation>(mutation);
  const { processing, onClick, onRelease } = usePreventDoubleClick();
  const [confirm, setConfirm] = useState<boolean>(false);
  const handleGenerate = useCallback(async () => {
    if (processing) {
      return;
    }
    onClick();
    const result = await new Promise<
      AnnotateObjectsMutation$data["assignMovieMetainfoJobs"]
    >((resolve) => {
      commit({
        variables: {
          input: {
            type: "AnnotateObject",
          },
        },
        onCompleted: ({ assignMovieMetainfoJobs }) => {
          resolve(assignMovieMetainfoJobs);
        },
      });
    });
    if (result.success === false) {
      setMessage({
        mode: "error",
        title: result.message ?? "生成できませんでした",
      });
      onRelease();
      setConfirm(false);
    } else {
      window.location.reload();
    }
  }, [commit, setMessage, processing, onClick, onRelease]);

  return (
    <Stack direction="column" spacing={1}>
      <Stack direction="row" justifyContent="flex-end">
        <Button
          disabled={processing}
          onClick={() => setConfirm(true)}
          variant="contained"
        >
          アサインする
        </Button>
      </Stack>
      <Divider />
      {edges.length > 0 ? (
        <Table aria-label="simple table" sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell component="th">タスクID</TableCell>
              <TableCell component="th">タスク</TableCell>
              <TableCell component="th">アサイン日時</TableCell>
              <TableCell component="th">詳細</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {edges.map(({ node }) => (
              <TableRow key={node.id}>
                <TableCell>{decodeId(node.id)}</TableCell>
                <TableCell>{MovieMetainfoJobStatus[node.status]}</TableCell>
                <TableCell>
                  {dateFormat(node.assignedAt, "M月D日 HH:mm")}
                </TableCell>
                <TableCell>
                  <Link to={`/annotate_objects/${node.id}`}>詳細</Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <Text>
          作業中のタスクがありません。「タスクをアサインする」をクリックして、アノテーションタスクを生成してください。
          ラベリング済みのスイング動画がある場合、タスクが生成されます。
        </Text>
      )}
      <Spacer height={8} />
      <OffsetPagination
        currentPage={currentPage}
        limit={limit}
        path="annotate_objects"
        totalCount={totalCount}
      />
      <Message
        closeText="キャンセル"
        confirmText="アサインする"
        onClose={() => setConfirm(false)}
        onConfirm={handleGenerate}
        title="アノテーションタスクを自分にアサインします"
        visible={confirm}
      />
      {processing && <Loading color={Colors.white} mask />}
    </Stack>
  );
}
