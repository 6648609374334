import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { isNil } from "@lib/utils/commons";
import { useSwingFrameUrl$key } from "@generated/useSwingFrameUrl.graphql";

const query = graphql`
  fragment useSwingFrameUrl on MovieMetainfo {
    id
    club {
      id
      name
    }
    angle
    swing
  }
`;

type SwingFrameUrl = {
  label: string;
  url: string;
};

export default function useSwingFrameUrl({
  movieMetainfoFragment,
}: {
  movieMetainfoFragment: useSwingFrameUrl$key;
}): SwingFrameUrl | null {
  const { club, angle, swing } = useFragment(query, movieMetainfoFragment);
  if (isNil(club) || isNil(angle) || isNil(swing)) {
    return null;
  }
  return {
    label: angle === "front" ? "正面" : "背面",
    url: `/${["definitions", angle].join("/")}`,
  };
}
