import { Controller } from "react-hook-form";
import { CommonProps } from "@components/molecules/TextInput/type";
import { TextField, MenuItem } from "@mui/material";
import React from "react";

interface Props extends CommonProps {
  options: {
    label: string;
    value: string | number;
  }[];
}

export default function SelectField({
  name,
  label,
  disabled,
  options,
  required,
  style,
  placeholder,
  helperText,
  control,
}: Props) {
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { value, onBlur, onChange: onChangeText },
        fieldState: { invalid },
      }) => (
        <TextField
          disabled={disabled}
          error={invalid}
          helperText={helperText}
          label={label}
          onBlur={onBlur}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            onChangeText(event.target.value)
          }
          placeholder={placeholder}
          required={required}
          select
          style={style ?? { margin: "0px" }}
          value={value ?? ""}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      )}
    />
  );
}
