import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useFragment, usePaginationFragment } from "react-relay";
import { MovieJobsIgnoredPagination$key } from "@generated/MovieJobsIgnoredPagination.graphql";
import { MovieJobsIgnoredSwingQuery$key } from "@generated/MovieJobsIgnoredSwingQuery.graphql";
import MovieJobsIgnoredItem from "@components/organisms/MovieJobsIgnored/MovieJobsIgnoredItem";
import OffsetPagination from "@components/atoms/OffsetPagination";

const query = graphql`
  fragment MovieJobsIgnoredPagination on Query
  @refetchable(queryName: "MovieJobsIgnoredPaginationQuery")
  @argumentDefinitions(after: { type: "String" }, first: { type: "Int" }) {
    movieJobsIgnored(first: $first, after: $after)
      @connection(key: "MovieJobsIgnored__movieJobsIgnored") {
      edges {
        node {
          id
          ...MovieJobsIgnoredItem
        }
      }
      currentPage
      totalCount
      limit
    }
  }
`;

const swingQuery = graphql`
  fragment MovieJobsIgnoredSwingQuery on Query {
    ...MovieJobsIgnoredItemSwingQuery
  }
`;

export default function MovieJobsIgnored({
  movieJobsFragment,
  swingsFragment,
}: {
  movieJobsFragment: MovieJobsIgnoredPagination$key;
  swingsFragment: MovieJobsIgnoredSwingQuery$key;
}) {
  const { data } = usePaginationFragment(query, movieJobsFragment);
  const swing = useFragment(swingQuery, swingsFragment);
  const { edges, currentPage, limit, totalCount } = data.movieJobsIgnored;
  return (
    <>
      <Table aria-label="simple table" sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell component="th">ID</TableCell>
            <TableCell component="th">スイング</TableCell>
            <TableCell component="th">クラブ名</TableCell>
            <TableCell component="th">除外理由</TableCell>
            <TableCell component="th">詳細</TableCell>
            <TableCell component="th">操作</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {edges.map(({ node }) => (
            <MovieJobsIgnoredItem
              key={node.id}
              movieJobFragment={node}
              swingsFragment={swing}
            />
          ))}
        </TableBody>
      </Table>
      <OffsetPagination
        currentPage={currentPage}
        limit={limit}
        path="movie_jobs_ignored"
        totalCount={totalCount}
      />
    </>
  );
}
