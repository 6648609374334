import { useMemo, useRef } from "react";
import Icon from "@components/atoms/Icon";
import styled from "styled-components";
import { max, isNil } from "@lib/utils/commons";
/* eslint-disable import/extensions */
import noimage from "@assets/images/no-image.png";

export type Props = {
  src: string | null | undefined;
  width?: number | string;
  height?: number | string;
  alt?: string;
};

export default function Image({ src, width, height, alt }: Props) {
  const imageRef = useRef<HTMLImageElement>(null);
  const size = useMemo(() => {
    if (typeof width !== "string" && typeof height !== "string") {
      return max([width, height]) ?? 100;
    }
    return 100;
  }, [width, height]);
  const styleWidth = useMemo(() => {
    if (typeof width === "string") {
      return width;
    }
    if (typeof width === "number") {
      return `${width}px`;
    }
    return "auto";
  }, [width]);
  const styleHeight = useMemo(() => {
    if (typeof height === "string") {
      return height;
    }
    if (typeof height === "number") {
      return `${height}px`;
    }
    return "auto";
  }, [height]);
  const handleImageError = () => {
    if (imageRef.current?.src !== undefined) {
      imageRef.current.src = noimage;
    }
  };

  return !isNil(src) ? (
    <Img
      ref={imageRef}
      alt={alt ?? ""}
      height={styleHeight}
      loading="lazy"
      onError={handleImageError}
      src={src}
      width={styleWidth}
    />
  ) : (
    <Icon name="image" size={size} />
  );
}

const Img = styled.img`
  max-width: 100%;
  max-height: 100%;
`;
