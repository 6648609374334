import React from "react";
import { Stack, Divider } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import Text from "@components/atoms/Text";
import { LessonTemplateData$key } from "@generated/LessonTemplateData.graphql";
import LessonTemplateMain from "@components/organisms/LessonTemplate/LessonTemplateMain";
import LessonTemplateForm from "@components/organisms/LessonTemplate/LessonTemplateForm";
import LessonTemplateDrill from "@components/organisms/LessonTemplate/LessonTemplateDrill";

const query = graphql`
  fragment LessonTemplateData on LessonTemplate {
    ...LessonTemplateMain
    lessonTemplateForms {
      id
      ...LessonTemplateFormData
    }
    drills {
      id
      ...LessonTemplateDrill
    }
  }
`;

export default function LessonTemplate({
  templateFragment,
}: {
  templateFragment: LessonTemplateData$key;
}): JSX.Element {
  const template = useFragment<LessonTemplateData$key>(query, templateFragment);
  const { lessonTemplateForms, drills } = template;
  return (
    <Stack direction="column" spacing={2}>
      <LessonTemplateMain templateFragment={template} />
      <Stack direction="column" spacing={2}>
        {lessonTemplateForms.map((lessonTemplateForm) => (
          <React.Fragment key={lessonTemplateForm.id}>
            <Divider />
            <LessonTemplateForm templateFragment={lessonTemplateForm} />
          </React.Fragment>
        ))}
      </Stack>
      <Divider />
      <Stack direction="column" spacing={2}>
        <Text bold>関連付けられたドリル</Text>
        {drills.length > 0 ? (
          drills.map((drill) => (
            <LessonTemplateDrill key={drill.id} drillFragment={drill} />
          ))
        ) : (
          <Text>関連付けられたドリルはありません</Text>
        )}
      </Stack>
    </Stack>
  );
}
