/**
 * @generated SignedSource<<eb3d2dddc6010689fdd3d7c2eed2bc42>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RemoveMovieClipMutationInput = {
  clientMutationId?: string | null | undefined;
  id: string;
};
export type SwingPositionClassificationRemoveMutation$variables = {
  input: RemoveMovieClipMutationInput;
};
export type SwingPositionClassificationRemoveMutation$data = {
  readonly removeMovieClip: {
    readonly __typename: "MovieMetainfo";
    readonly id: string;
    readonly movieClips: ReadonlyArray<{
      readonly id: string;
    }>;
  } | {
    readonly __typename: "UserError";
    readonly message: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type SwingPositionClassificationRemoveMutation = {
  response: SwingPositionClassificationRemoveMutation$data;
  variables: SwingPositionClassificationRemoveMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "removeMovieClip",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "MovieClip",
            "kind": "LinkedField",
            "name": "movieClips",
            "plural": true,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "type": "MovieMetainfo",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "type": "UserError",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SwingPositionClassificationRemoveMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SwingPositionClassificationRemoveMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "5c8061a088187ed2d5d4660334b9b329",
    "id": null,
    "metadata": {},
    "name": "SwingPositionClassificationRemoveMutation",
    "operationKind": "mutation",
    "text": "mutation SwingPositionClassificationRemoveMutation(\n  $input: RemoveMovieClipMutationInput!\n) {\n  removeMovieClip(input: $input) {\n    __typename\n    ... on MovieMetainfo {\n      id\n      movieClips {\n        id\n      }\n    }\n    ... on UserError {\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "29178bb43ee263110d194cfe6db5d543";

export default node;
