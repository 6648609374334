/**
 * @generated SignedSource<<c25f13977ae8ef74c2caf32c6eca7ac0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CoachingImage$data = {
  readonly content: string;
  readonly swingImage: {
    readonly signedUrl: string | null | undefined;
  };
  readonly title: string;
  readonly " $fragmentType": "CoachingImage";
};
export type CoachingImage$key = {
  readonly " $data"?: CoachingImage$data;
  readonly " $fragmentSpreads": FragmentRefs<"CoachingImage">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CoachingImage",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SwingImage",
      "kind": "LinkedField",
      "name": "swingImage",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "signedUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CoachingImage",
  "abstractKey": null
};

(node as any).hash = "fb2e811b7f787697433ea2d5438d8494";

export default node;
