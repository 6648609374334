/**
 * @generated SignedSource<<7e37e88733f6cc487abf4a47ef8baaed>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MembersScreenQuery$variables = Record<PropertyKey, never>;
export type MembersScreenQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"Members">;
};
export type MembersScreenQuery = {
  response: MembersScreenQuery$data;
  variables: MembersScreenQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "MembersScreenQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "Members"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "MembersScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "members",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a79d74bc0c9723f9870a3588bacd7bc6",
    "id": null,
    "metadata": {},
    "name": "MembersScreenQuery",
    "operationKind": "query",
    "text": "query MembersScreenQuery {\n  ...Members\n}\n\nfragment Members on Query {\n  members {\n    id\n    name\n    email\n    type\n    createdAt\n  }\n}\n"
  }
};

(node as any).hash = "63f6bea3bbc9233f0242f587be9c2c7d";

export default node;
