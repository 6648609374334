import { useEffect, Suspense } from "react";
import ContentLayout from "@navigation/ContentLayout";
import Comments from "@components/templates/Comments";
import graphql from "babel-plugin-relay/macro";
import {
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import { isNil } from "@lib/utils/commons";
import Loading from "@components/atoms/Loading";
import { CommentsScreenQuery } from "@generated/CommentsScreenQuery.graphql";
import { PageLimit } from "@constants/App";
import usePaginationOffset from "@lib/hooks/usePaginationOffset";

const query = graphql`
  query CommentsScreenQuery($first: Int!, $after: String) {
    ...Comments @arguments(first: $first, after: $after)
    admin {
      ...CommentsAdmin
    }
  }
`;

function ScreenContent({
  queryReference,
}: {
  queryReference: PreloadedQuery<CommentsScreenQuery>;
}): JSX.Element {
  const data = usePreloadedQuery<CommentsScreenQuery>(query, queryReference);
  return <Comments adminFragment={data.admin} commentsFragment={data} />;
}

export default function CommentsScreen(): JSX.Element {
  const after = usePaginationOffset();
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<CommentsScreenQuery>(query);
  useEffect(() => {
    loadQuery({ first: PageLimit, after }, { fetchPolicy: "network-only" });
    return () => {
      disposeQuery();
    };
  }, [after, loadQuery, disposeQuery]);

  return (
    <ContentLayout title="コメント">
      <Suspense fallback={<Loading />}>
        {!isNil(queryReference) && (
          <ScreenContent queryReference={queryReference} />
        )}
      </Suspense>
    </ContentLayout>
  );
}
