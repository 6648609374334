import { useCallback } from "react";
import { IconButton } from "@mui/material";
import Icon from "@components/atoms/Icon";
import useMessage from "@lib/hooks/useMessage";
import clipBoard from "@lib/utils/clipBoard";
import { useLocation } from "react-router-dom";
import { PageAnchorName } from "@constants/App";
import usePaginationOffset from "@lib/hooks/usePaginationOffset";

export default function SwingPositionClassificationClipLink({
  id,
}: {
  id: string;
}) {
  const [, setMessage] = useMessage();
  const { pathname } = useLocation();
  const after = usePaginationOffset();
  const getOffset = useCallback((): number | null => {
    if (after !== undefined) {
      const decoded = atob(after);
      const originalNum = parseInt(decoded, 10);
      if (!Number.isNaN(originalNum)) {
        return originalNum;
      }
    }
    return null;
  }, [after]);
  const onClip = useCallback(async () => {
    const queries = [`${PageAnchorName}=${id}`];
    const offset = getOffset();
    if (offset !== null) {
      queries.push(`offset=${offset}`);
    }
    const url =
      window.location.port === "80"
        ? window.location.hostname
        : window.location.host;
    const res = await clipBoard(
      `${window.location.protocol}//${url}${pathname}?${queries.join("&")}`
    );
    if (res) {
      setMessage({
        title: "URLをコピーしました",
        mode: "toast",
      });
    } else {
      setMessage({
        title: "URLのコピーできませんでした",
        mode: "error",
      });
    }
  }, [id, getOffset, setMessage, pathname]);

  return (
    <IconButton onClick={onClip}>
      <Icon name="share" size={24} />
    </IconButton>
  );
}
