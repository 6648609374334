import { useCallback } from "react";
import { Stack } from "@mui/material";
import { UploadableMap } from "relay-runtime";
import graphql from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import { FilePicker } from "@components/molecules/TextInput";
import useMessage from "@lib/hooks/useMessage";
import { resolveError } from "@lib/utils/error";
import { ProfileAvatar$key } from "@generated/ProfileAvatar.graphql";
import {
  ProfileAvatarUploadMutation,
  ProfileAvatarUploadMutation$data,
} from "@generated/ProfileAvatarUploadMutation.graphql";

const query = graphql`
  fragment ProfileAvatar on User {
    avatar {
      signedUrl
    }
  }
`;

const mutation = graphql`
  mutation ProfileAvatarUploadMutation($input: UploadAvatarMutationInput!) {
    uploadAvatar(input: $input) {
      __typename
      ... on User {
        id
        avatar {
          signedUrl
        }
      }
      ... on UserError {
        message
      }
    }
  }
`;

export default function ProfileAvatar({
  adminFragment,
}: {
  adminFragment: ProfileAvatar$key;
}) {
  const [, setMessage] = useMessage();
  const [commit] = useMutation<ProfileAvatarUploadMutation>(mutation);
  const handleUload = useCallback(
    async (uploadables: UploadableMap | null) => {
      try {
        if (uploadables === null) {
          return;
        }
        const result = await new Promise<
          ProfileAvatarUploadMutation$data["uploadAvatar"]
        >((resolve) => {
          commit({
            variables: {
              input: {
                file: null,
              },
            },
            uploadables,
            onCompleted({ uploadAvatar }) {
              resolve(uploadAvatar);
            },
          });
        });
        if (result.__typename === "UserError") {
          setMessage({
            mode: "error",
            title: "保存できませんでした",
            subline: result.message,
          });
        } else {
          setMessage({
            mode: "toast",
            title: "保存しました",
          });
        }
      } catch (e: unknown) {
        setMessage({
          title: "保存できませんでした",
          subline:
            resolveError(e).message ?? "アイコンをアップロードできませんでした",
          mode: "error",
        });
      }
    },
    [commit, setMessage]
  );
  const { avatar } = useFragment<ProfileAvatar$key>(query, adminFragment);
  return (
    <Stack alignItems="center" direction="column" justifyContent="center">
      <FilePicker
        avatar
        defaultUrl={avatar?.signedUrl}
        onChange={handleUload}
      />
    </Stack>
  );
}
