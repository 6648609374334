import { Box } from "@mui/material";
import React from "react";

export default function Form({ children }: { children: React.ReactNode }) {
  return (
    <Box
      autoComplete="off"
      component="form"
      noValidate
      sx={{
        "& .MuiTextField-root": { m: 1, width: "25ch" },
      }}
    >
      {children}
    </Box>
  );
}
