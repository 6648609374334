/**
 * @generated SignedSource<<7f0a1aae9514ef17ce7c3f7bb49af50d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LabelingSwingQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"LabelingSwingConfirmQuery" | "LabelingSwingWorkingQuery">;
  readonly " $fragmentType": "LabelingSwingQuery";
};
export type LabelingSwingQuery$key = {
  readonly " $data"?: LabelingSwingQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"LabelingSwingQuery">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LabelingSwingQuery",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LabelingSwingWorkingQuery"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LabelingSwingConfirmQuery"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "df7b3f755c269471328930401bb06223";

export default node;
