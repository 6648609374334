/**
 * @generated SignedSource<<9ede82208d38d8d8b9dcbc59bb24231e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SwingFrameAngle = "back" | "front";
export type SaveSwingFrameMutationInput = {
  angle: SwingFrameAngle;
  clientMutationId?: string | null | undefined;
  description: string;
  file?: any | null | undefined;
  id?: string | null | undefined;
  name: string;
  orderNumber: number;
  swingPositionId: string;
};
export type SwingFrameInputMutation$variables = {
  input: SaveSwingFrameMutationInput;
};
export type SwingFrameInputMutation$data = {
  readonly saveSwingFrame: {
    readonly __typename: "SwingFrame";
    readonly angle: SwingFrameAngle;
    readonly description: string;
    readonly id: string;
    readonly name: string;
    readonly orderNumber: number;
    readonly swingPosition: {
      readonly id: string;
      readonly name: string;
    };
    readonly thumbnail: {
      readonly id: string;
      readonly signedUrl: string | null | undefined;
    } | null | undefined;
  } | {
    readonly __typename: "UserError";
    readonly message: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type SwingFrameInputMutation = {
  response: SwingFrameInputMutation$data;
  variables: SwingFrameInputMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "saveSwingFrame",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "angle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "orderNumber",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Thumbnail",
            "kind": "LinkedField",
            "name": "thumbnail",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "signedUrl",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SwingPosition",
            "kind": "LinkedField",
            "name": "swingPosition",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "type": "SwingFrame",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "type": "UserError",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SwingFrameInputMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SwingFrameInputMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "79f4bc3853cb10c5b819a7156ac8703e",
    "id": null,
    "metadata": {},
    "name": "SwingFrameInputMutation",
    "operationKind": "mutation",
    "text": "mutation SwingFrameInputMutation(\n  $input: SaveSwingFrameMutationInput!\n) {\n  saveSwingFrame(input: $input) {\n    __typename\n    ... on SwingFrame {\n      id\n      name\n      description\n      angle\n      orderNumber\n      thumbnail {\n        id\n        signedUrl\n      }\n      swingPosition {\n        id\n        name\n      }\n    }\n    ... on UserError {\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b68cd685604297f48e249f94bb81d927";

export default node;
