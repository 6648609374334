import { useCallback, useState } from "react";
import { Button } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import Loading from "@components/atoms/Loading";
import Message from "@components/molecules/Message";
import useMessage from "@lib/hooks/useMessage";
import usePreventDoubleClick from "@lib/hooks/usePreventDoubleClick";
import {
  MemberRemoveMutation,
  MemberRemoveMutation$data,
} from "@generated/MemberRemoveMutation.graphql";
import { MemberRemove$key } from "@generated/MemberRemove.graphql";
import Colors from "@constants/Colors";

const query = graphql`
  fragment MemberRemove on User {
    id
  }
`;

const mutation = graphql`
  mutation MemberRemoveMutation($input: RemoveUserMutationInput!) {
    removeUser(input: $input) {
      success
    }
  }
`;

export default function MemberRemove({
  userFragment,
}: {
  userFragment: MemberRemove$key;
}) {
  const [, setMessage] = useMessage();
  const [confirm, setConfirm] = useState<boolean>(false);
  const { id } = useFragment(query, userFragment);
  const [commit] = useMutation<MemberRemoveMutation>(mutation);
  const { processing, onClick, onRelease } = usePreventDoubleClick();
  const handleRemove = useCallback(async () => {
    if (processing) {
      return;
    }
    onClick();
    const result = await new Promise<MemberRemoveMutation$data["removeUser"]>(
      (resolve) => {
        commit({
          variables: {
            input: {
              id,
            },
          },
          onCompleted: ({ removeUser }) => {
            resolve(removeUser);
          },
        });
      }
    );
    if (result.success === false) {
      setMessage({
        mode: "error",
        title: "削除できませんでした",
      });
      onRelease();
    } else {
      setMessage({
        mode: "toast",
        title: "削除しました",
      });
      window.location.href = "/members";
    }
  }, [commit, id, setMessage, processing, onClick, onRelease]);

  return (
    <>
      <Button color="error" onClick={() => setConfirm(true)}>
        削除する
      </Button>
      <Message
        closeText="キャンセル"
        confirmText="削除する"
        message="この操作は取り消せません。よろしいですか？"
        onClose={() => setConfirm(false)}
        onConfirm={handleRemove}
        title="ユーザーを削除しますか？"
        visible={confirm}
      />
      {processing && <Loading color={Colors.white} mask />}
    </>
  );
}
