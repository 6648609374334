/**
 * @generated SignedSource<<29c18ed94d31379558be769f6d985c64>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SwingFrameMoviesQuery$data = {
  readonly swingPositions: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"SwingPositionClassificationItemQuery">;
  readonly " $fragmentType": "SwingFrameMoviesQuery";
};
export type SwingFrameMoviesQuery$key = {
  readonly " $data"?: SwingFrameMoviesQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"SwingFrameMoviesQuery">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SwingFrameMoviesQuery",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SwingPosition",
      "kind": "LinkedField",
      "name": "swingPositions",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SwingPositionClassificationItemQuery"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "4dfc27a64124b5dc1a7ffe1f256e155e";

export default node;
