import React, { useCallback, useState } from "react";
import { styled } from "@mui/material/styles";
import { Button, Box, Stack } from "@mui/material";
import Icon from "@components/atoms/Icon";
import Image from "@components/atoms/Image";
import Video from "@components/atoms/Video";
import Avatar from "@components/atoms/Avatar";
import Colors from "@constants/Colors";
import { makeUploadablesFromFile } from "@lib/utils/relaySupport";
import { UploadableMap } from "relay-runtime";
import { isEmpty } from "@lib/utils/commons";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export function isFile(data: File | Blob): data is File {
  return "name" in data;
}

function ImagePreview({
  preview,
  avatar = false,
}: {
  preview: string | null;
  avatar: boolean;
}) {
  if (isEmpty(preview)) {
    return null;
  }
  if (avatar) {
    return <Avatar size={126} url={preview} />;
  }
  return (
    <Box sx={{ width: 300 }}>
      <Image src={preview} />
    </Box>
  );
}

function VideoPreview({ preview }: { preview: string | null }) {
  if (isEmpty(preview)) {
    return null;
  }
  return (
    <Box sx={{ width: 300 }}>
      <Video src={preview} />
    </Box>
  );
}

type FileTypes = "image" | "video" | "xml";
const PreviewTypes: FileTypes[] = ["image", "video"];
const AcceptMap: {
  [key in FileTypes]: string;
} = {
  image: ".jpg, .jpeg, .png, image/jpeg, image/png",
  video: ".mp4, video/mp4",
  xml: ".xml, text/xml, application/xml",
};

export default function InputFileUpload({
  content = "ファイルを選択",
  onChange,
  defaultUrl,
  avatar = false,
  type = "image",
}: {
  content?: React.ReactNode;
  onChange: (file: UploadableMap | null) => void;
  defaultUrl?: string | null;
  avatar?: boolean;
  type?: FileTypes;
}) {
  const [preview, setPreview] = useState<string | null>(defaultUrl ?? null);
  const onUpload = useCallback(
    async (file: FileList | null) => {
      if (file === null) {
        onChange(null);
        return;
      }
      const uploadables = makeUploadablesFromFile(
        "variables.input.file",
        file[0]
      );
      onChange(uploadables);
      if (PreviewTypes.includes(type)) {
        const reader = new FileReader();
        reader.onload = ({ target }) => {
          if (target !== null) {
            setPreview(target.result as string);
          }
        };
        reader.readAsDataURL(file[0]);
      }
    },
    [onChange, type]
  );
  return (
    <Stack alignItems="flex-start" direction="column" spacing={1}>
      {type === "image" ? (
        <ImagePreview avatar={avatar} preview={preview} />
      ) : (
        <VideoPreview preview={preview} />
      )}

      <Button
        component="label"
        startIcon={<Icon color={Colors.white} name="upload" size={16} />}
        tabIndex={-1}
        variant="contained"
      >
        {content}
        <VisuallyHiddenInput
          accept={AcceptMap[type]}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onUpload(e.target.files);
          }}
          type="file"
        />
      </Button>
    </Stack>
  );
}
