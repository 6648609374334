import { useCallback, useState } from "react";
import graphql from "babel-plugin-relay/macro";
import { useMutation } from "react-relay";
import Loading from "@components/atoms/Loading";
import { Stack } from "@mui/material";
import Text from "@components/atoms/Text";
import {
  VerifyInviteMutation,
  VerifyInviteMutation$data,
} from "@generated/VerifyInviteMutation.graphql";
import Colors from "@constants/Colors";
import useQueryId from "@lib/hooks/useQueryId";
import { SecureStoreManager } from "@lib/utils/secureStoreManager";
import usePreventDoubleClick from "@lib/hooks/usePreventDoubleClick";
import useMountEffect from "@lib/hooks/useMountEffect";

const mutation = graphql`
  mutation VerifyInviteMutation($input: VerifyInviteMutationInput!) {
    verifyInvite(input: $input) {
      __typename
      ... on JwtToken {
        accessToken
        refreshToken
      }
      ... on UserError {
        message
      }
    }
  }
`;

export default function VerifyInvite() {
  const id = useQueryId();
  const { processing, onClick } = usePreventDoubleClick();
  const [error, setError] = useState<string | null>(null);
  const [commit] = useMutation<VerifyInviteMutation>(mutation);
  const verify = useCallback(async () => {
    if (processing) {
      return;
    }
    onClick();
    const result = await new Promise<VerifyInviteMutation$data["verifyInvite"]>(
      (resolve) => {
        commit({
          variables: {
            input: {
              key: id,
            },
          },
          onCompleted: ({ verifyInvite }) => {
            resolve(verifyInvite);
          },
        });
      }
    );
    if (result.__typename === "JwtToken") {
      const { accessToken, refreshToken } = result;
      SecureStoreManager.registerAccessTokenSet(accessToken, refreshToken);
      window.location.href = "/";
    } else {
      setError("この招待URLは有効期限が切れました。再度招待を受けてください");
    }
  }, [id, commit, processing, onClick]);
  useMountEffect(() => {
    verify();
  });

  return error !== null ? (
    <Stack alignItems="center" flex={1} justifyContent="center">
      <Text bold color={Colors.orange}>
        {error}
      </Text>
    </Stack>
  ) : (
    <Loading color={Colors.white} mask />
  );
}
