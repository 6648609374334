import Text from "@components/atoms/Text";
import Colors from "@constants/Colors";
import { Stack } from "@mui/material";

export default function ErrorText({ error }: { error?: string }): JSX.Element {
  return (
    <Stack alignItems="flex-start" justifyContent="flex-start">
      <Text align="left" color={Colors.orange} variant="subtitle1">
        {error ?? "入力に誤りがあります"}
      </Text>
    </Stack>
  );
}
