import React, { useMemo, useCallback, useState } from "react";
import {
  Box,
  Stack,
  Button,
  Divider,
  FormControlLabel,
  Switch,
  Snackbar,
  IconButton,
} from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import Icon from "@components/atoms/Icon";
import Image from "@components/atoms/Image";
import Text from "@components/atoms/Text";
import usePreventDoubleClick from "@lib/hooks/usePreventDoubleClick";
import { LabelingSwingItem$key } from "@generated/LabelingSwingItem.graphql";
import { LabelingSwingItemData$key } from "@generated/LabelingSwingItemData.graphql";
import { LabelingSwingItemQuery$key } from "@generated/LabelingSwingItemQuery.graphql";
import {
  LabelingSwingItemAddMutation,
  LabelingSwingItemAddMutation$data,
} from "@generated/LabelingSwingItemAddMutation.graphql";
import Colors from "@constants/Colors";
import Spacer from "@components/atoms/Spacer";
import Loading from "@components/atoms/Loading";
import SwingPositionClassificationChips from "@components/molecules/SwingPositionClassification/SwingPositionClassificationChips";
import { decodeId } from "@lib/utils/convertId";

const query = graphql`
  fragment LabelingSwingItem on TmpImage {
    id
    signedUrl
    movieClip {
      id
      ballTrajectory
      swingClassification {
        swingFrame {
          id
        }
      }
    }
  }
`;

const jobQuery = graphql`
  fragment LabelingSwingItemData on MovieJob {
    id
    movieMetainfo {
      angle
    }
  }
`;

const positionQuery = graphql`
  fragment LabelingSwingItemQuery on Query {
    swingPositions {
      id
      name
      swingFrames {
        id
        angle
        name
      }
    }
  }
`;

const addMutation = graphql`
  mutation LabelingSwingItemAddMutation($input: UpdateTmpImageMutationInput!) {
    updateTmpImage(input: $input) {
      __typename
      ... on MovieJob {
        id
        movieMetainfo {
          id
          movieClips {
            id
            ballTrajectory
            swingImage {
              id
              signedUrl
            }
            swingClassification {
              id
              needReview
              secondary
              swingFrame {
                id
                name
              }
            }
            commentContents {
              id
              content
              createdAt
              user {
                name
                avatar {
                  signedUrl
                }
              }
            }
          }
        }
        tmpImages {
          id
          signedUrl
          movieClip {
            id
            ballTrajectory
            swingClassification {
              swingFrame {
                id
              }
            }
          }
        }
      }
      ... on UserError {
        message
      }
    }
  }
`;

const LabelingSwingItem = React.memo(
  ({
    jobFragment,
    imageFragment,
    swingPositionFragment,
    boxHeight,
    onSelectRemove,
    onSelectRemoves,
    selectedRemove,
    editable,
  }: {
    jobFragment: LabelingSwingItemData$key;
    imageFragment: LabelingSwingItem$key;
    swingPositionFragment: LabelingSwingItemQuery$key;
    boxHeight: number;
    onSelectRemove: (id: string) => void;
    onSelectRemoves: (id: string, mode: "before" | "after") => void;
    selectedRemove: boolean;
    editable: boolean;
  }) => {
    const { processing, onClick, onRelease } = usePreventDoubleClick();
    const { id, signedUrl, movieClip } = useFragment(query, imageFragment);
    const { ballTrajectory, swingClassification } = movieClip ?? {};
    const { movieMetainfo } = useFragment(jobQuery, jobFragment);
    const { angle } = movieMetainfo;
    const { swingPositions } = useFragment(
      positionQuery,
      swingPositionFragment
    );
    const [addCommit] = useMutation<LabelingSwingItemAddMutation>(addMutation);
    const [message, setMessage] = useState<string | null>(null);

    const targetPositions = useMemo(
      () =>
        swingPositions
          .map((swingPosition) => {
            const frames = swingPosition.swingFrames.filter(
              (row) => row.angle === angle
            );
            return {
              id: swingPosition.id,
              name: swingPosition.name,
              swingFrames: frames,
            };
          })
          .filter((row) => row.swingFrames.length > 0),
      [angle, swingPositions]
    );

    const handleLabeling = useCallback(
      async (swingFrameId?: string | null, ball?: boolean) => {
        if (processing) {
          return;
        }
        onClick();
        const result = await new Promise<
          LabelingSwingItemAddMutation$data["updateTmpImage"]
        >((resolve) => {
          addCommit({
            variables: {
              input: {
                id,
                swingFrameId,
                ballTrajectory: ball,
              },
            },
            onCompleted: ({ updateTmpImage }) => {
              resolve(updateTmpImage);
            },
          });
        });
        setMessage(
          result.__typename === "UserError"
            ? result.message
            : swingFrameId !== null
              ? "ラベル付けました"
              : "削除しました"
        );
        onRelease();
      },
      [id, addCommit, processing, onClick, onRelease]
    );

    return (
      <>
        <Stack
          direction="row"
          flexWrap="wrap"
          justifyContent="flex-start"
          spacing={2}
        >
          <Box flex={1} sx={{ height: `${boxHeight}px`, minWidth: 300 }}>
            <Image key={id} src={signedUrl} />
          </Box>
          <Box flex={1}>
            <Stack alignItems="center" direction="row" justifyContent="center">
              <Box flex={1}>
                <Text>ID:{decodeId(id)}</Text>
              </Box>
              {selectedRemove ? (
                <Button
                  onClick={() => onSelectRemove(id)}
                  size="small"
                  startIcon={
                    <Icon color={Colors.blue} name="reload" size={16} />
                  }
                  sx={{
                    color: Colors.blue,
                  }}
                  variant="text"
                >
                  削除対象から外す
                </Button>
              ) : (
                <Button
                  onClick={() => onSelectRemove(id)}
                  size="small"
                  startIcon={
                    <Icon color={Colors.orange} name="trash" size={16} />
                  }
                  sx={{
                    color: Colors.orange,
                  }}
                  variant="text"
                >
                  削除対象にする
                </Button>
              )}
            </Stack>
            <Stack direction="column" spacing={1}>
              <SwingPositionClassificationChips
                disabled={!editable}
                onSelect={handleLabeling}
                selectedId={swingClassification?.swingFrame.id ?? null}
                swingPositions={targetPositions}
              />
              <Divider />
              <FormControlLabel
                control={
                  <Switch
                    checked={ballTrajectory === true}
                    disabled={!editable}
                    onChange={() =>
                      handleLabeling(undefined, ballTrajectory !== true)
                    }
                    value="checked"
                  />
                }
                label="ボール弾道がある"
              />
              <Divider />
            </Stack>
            <Spacer height={8} />
            <Divider />
            <Spacer height={24} />
            <Box>
              <Button
                onClick={() => onSelectRemoves(id, "before")}
                variant="outlined"
              >
                これ以前の画像をまとめて削除対象にする
              </Button>
              <Spacer height={8} />
              <Button
                color="warning"
                onClick={() => onSelectRemoves(id, "after")}
                variant="outlined"
              >
                これ以降の画像をまとめて削除対象にする
              </Button>
            </Box>
          </Box>
        </Stack>
        {processing && (
          <Loading color={Colors.white} mask maskPosition="absolute" />
        )}
        <Snackbar
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={() => setMessage(null)}
              size="small"
            >
              <Icon color={Colors.white} name="close" size={24} />
            </IconButton>
          }
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          autoHideDuration={2000}
          message={message}
          onClose={() => setMessage(null)}
          open={message !== null}
        />
      </>
    );
  }
);

export default LabelingSwingItem;
