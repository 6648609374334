/**
 * @generated SignedSource<<7b77938f7737ac8926bfe74673f6d940>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MovieMetainfoAngle = "back" | "front";
export type MovieMetainfoDirection = "landscape" | "portrait" | "square";
export type MovieMetainfoGender = "female" | "male" | "unknown";
export type MovieMetainfoHand = "left" | "right";
export type MovieMetainfoPlace = "course" | "driving_range" | "indoor";
export type MovieMetainfoSwing = "full" | "half" | "quarter";
import { FragmentRefs } from "relay-runtime";
export type SwingPositionClassificationMeta$data = {
  readonly angle: MovieMetainfoAngle | null | undefined;
  readonly club: {
    readonly id: string;
    readonly name: string;
  } | null | undefined;
  readonly direction: MovieMetainfoDirection | null | undefined;
  readonly duration: number | null | undefined;
  readonly gender: MovieMetainfoGender | null | undefined;
  readonly hand: MovieMetainfoHand | null | undefined;
  readonly height: number | null | undefined;
  readonly id: string;
  readonly place: MovieMetainfoPlace | null | undefined;
  readonly sourceUrl: string;
  readonly swing: MovieMetainfoSwing | null | undefined;
  readonly trimmingEnd: number | null | undefined;
  readonly trimmingStart: number | null | undefined;
  readonly width: number | null | undefined;
  readonly " $fragmentType": "SwingPositionClassificationMeta";
};
export type SwingPositionClassificationMeta$key = {
  readonly " $data"?: SwingPositionClassificationMeta$data;
  readonly " $fragmentSpreads": FragmentRefs<"SwingPositionClassificationMeta">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SwingPositionClassificationMeta",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sourceUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Club",
      "kind": "LinkedField",
      "name": "club",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "trimmingStart",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "trimmingEnd",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "duration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "width",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "height",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "angle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "direction",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gender",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hand",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "place",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "swing",
      "storageKey": null
    }
  ],
  "type": "MovieMetainfo",
  "abstractKey": null
};
})();

(node as any).hash = "71750afe9a4842db3948742fbe126c23";

export default node;
