import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { usePaginationFragment } from "react-relay";
import { ArticlesPagination$key } from "@generated/ArticlesPagination.graphql";
import OffsetPagination from "@components/atoms/OffsetPagination";
import { Link } from "react-router-dom";
import { decodeId } from "@lib/utils/convertId";
import Spacer from "@components/atoms/Spacer";
import { ArticleCategory } from "@constants/App";

const query = graphql`
  fragment ArticlesPagination on Query
  @refetchable(queryName: "ArticlesPaginationQuery")
  @argumentDefinitions(after: { type: "String" }, first: { type: "Int" }) {
    articles(first: $first, after: $after)
      @connection(key: "ArticlesList__articles") {
      edges {
        node {
          id
          title
          category
          thumbnail {
            signedUrl
          }
        }
      }
      currentPage
      totalCount
      limit
    }
  }
`;

export default function Articles({
  articlesFragment,
}: {
  articlesFragment: ArticlesPagination$key;
}) {
  const { data } = usePaginationFragment(query, articlesFragment);
  const { edges, currentPage, limit, totalCount } = data.articles;
  return (
    <>
      <Table aria-label="simple table" sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell component="th">ID</TableCell>
            <TableCell component="th">カテゴリー</TableCell>
            <TableCell component="th">タイトル</TableCell>
            <TableCell component="th">詳細</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {edges.map(({ node }) => (
            <TableRow key={node.id}>
              <TableCell>{decodeId(node.id)}</TableCell>
              <TableCell
                sx={{
                  whiteSpace: "nowrap",
                }}
              >
                {ArticleCategory[node.category]}
              </TableCell>
              <TableCell>{node.title}</TableCell>
              <TableCell
                sx={{
                  whiteSpace: "nowrap",
                }}
              >
                <Link to={`/articles/${node.id}`}>詳細</Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Spacer height={8} />
      <OffsetPagination
        currentPage={currentPage}
        limit={limit}
        path="articles"
        totalCount={totalCount}
      />
    </>
  );
}
