import { useCallback, useState } from "react";
import {
  Stack,
  Box,
  Button,
  Divider,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@mui/material";
import {
  Form,
  SelectField,
  TextField,
  ErrorText,
} from "@components/molecules/TextInput";
import { object, string, mixed } from "@lib/utils/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { MovieMetainfosSearchQuery$key } from "@generated/MovieMetainfosSearchQuery.graphql";
import { SwingFrameAngle, MovieMetainfoSwing } from "@constants/App";
import { getKeys, isNil } from "@lib/utils/commons";
import Icon from "@components/atoms/Icon";
import Spacer from "@components/atoms/Spacer";

const angles = getKeys(SwingFrameAngle).map((key) => ({
  value: key,
  label: SwingFrameAngle[key],
}));

const swings = getKeys(MovieMetainfoSwing).map((key) => ({
  value: key,
  label: MovieMetainfoSwing[key],
}));

const query = graphql`
  fragment MovieMetainfosSearchQuery on Query {
    clubs {
      id
      name
    }
    members {
      id
      name
    }
  }
`;
type Angle = "back" | "front";
type Swing = "full" | "half" | "quarter";
export type SearchInput = {
  ids?: string;
  clubId?: string;
  userId?: string;
  reviewerId?: string;
  angle?: Angle;
  swing?: Swing;
};
type SearchParams = "angle" | "clubId" | "userId" | "swing" | "reviewerId";
type Option = {
  label: string;
  column: SearchParams;
};

export default function MovieMetainfosSearch({
  queryFragment,
  onSearch,
}: {
  queryFragment: MovieMetainfosSearchQuery$key;
  onSearch: (options: SearchInput) => void;
}) {
  const { clubs, members } = useFragment(query, queryFragment);
  const [options, setOptions] = useState<Option[]>([]);
  const [modal, setModal] = useState<boolean>(false);
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isValid },
  } = useForm<SearchInput>({
    mode: "all",
    resolver: yupResolver(
      object().shape({
        ids: string(),
        clubId: string(),
        userId: string(),
        reviewerId: string(),
        angle: mixed<Angle>().oneOf(
          getKeys(SwingFrameAngle),
          "選択してください"
        ),
        swing: mixed<Swing>().oneOf(
          getKeys(MovieMetainfoSwing),
          "選択してください"
        ),
      })
    ),
  });
  const handleClear = useCallback(() => {
    reset();
    setOptions([]);
    setModal(false);
    onSearch({});
  }, [onSearch, reset]);
  const handleSearch = useCallback(async () => {
    await handleSubmit(async (input: SearchInput) => {
      const club = clubs.find((row) => row.id === input.clubId);
      const user = members.find((row) => row.id === input.userId);
      const reviewer = members.find((row) => row.id === input.reviewerId);
      const newOptions: Option[] = [];
      if (!isNil(club)) {
        newOptions.push({
          label: club.name,
          column: "clubId",
        });
      }
      if (!isNil(user)) {
        newOptions.push({
          label: user.name,
          column: "userId",
        });
      }
      if (!isNil(reviewer)) {
        newOptions.push({
          label: reviewer.name,
          column: "reviewerId",
        });
      }
      if (!isNil(input.angle)) {
        newOptions.push({
          label: SwingFrameAngle[input.angle],
          column: "angle",
        });
      }
      if (!isNil(input.swing)) {
        newOptions.push({
          label: MovieMetainfoSwing[input.swing],
          column: "swing",
        });
      }
      setModal(false);
      setOptions(newOptions);
      onSearch(input);
    })();
  }, [clubs, members, handleSubmit, onSearch]);
  return (
    <>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="flex-end"
        spacing={2}
      >
        {options.length > 0 && (
          <Stack
            direction="row"
            flex={1}
            justifyContent="flex-start"
            spacing={1}
          >
            {options.map((option) => (
              <Chip
                key={option.column}
                label={option.label}
                onDelete={() => {
                  setValue(option.column, undefined);
                  handleSearch();
                }}
              />
            ))}
          </Stack>
        )}
        <IconButton onClick={() => setModal(true)}>
          <Icon name="search" size={32} />
        </IconButton>
      </Stack>
      <Divider />
      <Spacer height={24} />
      {modal && (
        <Dialog fullWidth onClose={() => setModal(false)} open scroll="paper">
          <DialogTitle>検索</DialogTitle>
          <Box
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <IconButton aria-label="close" onClick={() => setModal(false)}>
              <Icon name="close" size={24} />
            </IconButton>
          </Box>
          <Form>
            <DialogContent>
              <Stack
                direction="row"
                flexWrap="wrap"
                justifyContent="flex-start"
                sx={{ padding: 2 }}
              >
                <Stack flex={1}>
                  <SelectField
                    control={control}
                    label="クラブ"
                    name="clubId"
                    options={clubs.map((row) => ({
                      label: row.name,
                      value: row.id,
                    }))}
                  />
                  {!isValid && errors.clubId !== undefined && (
                    <ErrorText error={errors.clubId.message} />
                  )}
                  <Spacer height={24} />
                  <SelectField
                    control={control}
                    label="スイング"
                    name="swing"
                    options={swings}
                  />
                  {!isValid && errors.swing !== undefined && (
                    <ErrorText error={errors.swing.message} />
                  )}
                  <Spacer height={24} />
                  <SelectField
                    control={control}
                    label="カメラアングル"
                    name="angle"
                    options={angles}
                  />
                  {!isValid && errors.angle !== undefined && (
                    <ErrorText error={errors.angle.message} />
                  )}
                  <Spacer height={24} />
                </Stack>
                <Stack flex={1}>
                  <SelectField
                    control={control}
                    label="作業者"
                    name="userId"
                    options={members.map((row) => ({
                      label: row.name,
                      value: row.id,
                    }))}
                  />
                  {!isValid && errors.userId !== undefined && (
                    <ErrorText error={errors.userId.message} />
                  )}
                  <Spacer height={24} />
                  <SelectField
                    control={control}
                    label="レビュアー"
                    name="reviewerId"
                    options={members.map((row) => ({
                      label: row.name,
                      value: row.id,
                    }))}
                  />
                  {!isValid && errors.reviewerId !== undefined && (
                    <ErrorText error={errors.reviewerId.message} />
                  )}
                  <Spacer height={24} />
                  <TextField
                    control={control}
                    name="ids"
                    placeholder="IDを入力(カンマ区切り)"
                  />
                  {!isValid && errors.ids !== undefined && (
                    <ErrorText error={errors.ids.message} />
                  )}
                </Stack>
              </Stack>
              <Spacer height={24} />
              <Divider />
              <Spacer height={24} />
              <Stack direction="row" justifyContent="center" spacing={2}>
                <Button onClick={handleSearch} variant="contained">
                  検索する
                </Button>
                <Button onClick={handleClear} variant="outlined">
                  クリアする
                </Button>
              </Stack>
            </DialogContent>
          </Form>
        </Dialog>
      )}
    </>
  );
}
