import React, { useMemo } from "react";
import { Divider, Stack } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { SwingFramesView$key } from "@generated/SwingFramesView.graphql";
import Text from "@components/atoms/Text";
import Thumbnail from "@components/atoms/Thumbnail";
import { SwingFrameAngle } from "@constants/App";

const query = graphql`
  fragment SwingFramesView on Query
  @argumentDefinitions(angle: { type: "SwingFrameAngle!" }) {
    swingFrames(angle: $angle) {
      id
      name
      angle
      description
      thumbnail {
        signedUrl
      }
    }
  }
`;

export default function SwingFramesView({
  definitionsFragment,
}: {
  definitionsFragment: SwingFramesView$key;
}) {
  const { swingFrames } = useFragment(query, definitionsFragment);
  const title = useMemo(() => {
    const { angle } = swingFrames[0];
    return SwingFrameAngle[angle];
  }, [swingFrames]);
  return (
    <Stack direction="column" padding={1} spacing={2}>
      <Text align="center" bold variant="h1">
        {title}
      </Text>
      <Divider />
      <Stack direction="column" spacing={2}>
        {swingFrames.map((swingFrame) => (
          <React.Fragment key={swingFrame.id}>
            <Stack
              direction="row"
              spacing={2}
              sx={{ paddingLeft: 2, paddingRight: 2 }}
            >
              <Stack>
                <Thumbnail size={300} url={swingFrame.thumbnail?.signedUrl} />
              </Stack>
              <Stack
                alignItems="flex-start"
                direction="column"
                flex={1}
                justifyContent="flex-start"
                spacing={2}
              >
                <Text bold variant="h2">
                  {swingFrame.name}
                </Text>
                <Text>{swingFrame.description}</Text>
              </Stack>
            </Stack>
            <Divider />
          </React.Fragment>
        ))}
      </Stack>
    </Stack>
  );
}
