import { useState, useCallback } from "react";
import { TableCell, Button } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import { UserInvitesItem$key } from "@generated/UserInvitesItem.graphql";
import {
  UserInvitesItemMutation,
  UserInvitesItemMutation$data,
} from "@generated/UserInvitesItemMutation.graphql";
import usePreventDoubleClick from "@lib/hooks/usePreventDoubleClick";
import Loading from "@components/atoms/Loading";
import Message from "@components/molecules/Message";
import { dateFormat } from "@lib/utils/date";
import useMessage from "@lib/hooks/useMessage";
import Colors from "@constants/Colors";
import { UserType } from "@constants/App";

const query = graphql`
  fragment UserInvitesItem on UserInvite {
    id
    email
    role
    expiredAt
  }
`;

// 削除

const mutation = graphql`
  mutation UserInvitesItemMutation($input: RemoveUserInviteMutationInput!) {
    removeUserInvite(input: $input) {
      message
      success
    }
  }
`;

export default function UserInvitesItem({
  inviteFragment,
}: {
  inviteFragment: UserInvitesItem$key;
}) {
  const [, setMessage] = useMessage();
  const { id, email, role, expiredAt } = useFragment(query, inviteFragment);
  const [commit] = useMutation<UserInvitesItemMutation>(mutation);
  const { processing, onClick, onRelease } = usePreventDoubleClick();
  const [confirm, setConfirm] = useState<boolean>(false);

  const onRemove = useCallback(async () => {
    if (processing) {
      return;
    }
    onClick();
    setConfirm(false);
    const result = await new Promise<
      UserInvitesItemMutation$data["removeUserInvite"]
    >((resolve) => {
      commit({
        variables: {
          input: {
            id,
          },
        },
        onCompleted: ({ removeUserInvite }) => {
          resolve(removeUserInvite);
        },
      });
    });
    if (result.success === false) {
      setMessage({
        mode: "error",
        title: "削除できませんでした",
      });
    } else {
      setMessage({
        mode: "toast",
        title: "削除しました",
      });
      window.location.href = "/user_invites";
    }
    onRelease();
  }, [commit, id, onClick, onRelease, processing, setMessage]);
  return (
    <>
      <TableCell>{id}</TableCell>
      <TableCell>{email}</TableCell>
      <TableCell>{UserType[role]}</TableCell>
      <TableCell>{dateFormat(expiredAt, "YYYY年M月D日")}</TableCell>
      <TableCell>
        <Button onClick={() => setConfirm(true)}>削除</Button>
      </TableCell>
      <Message
        closeText="キャンセル"
        confirmText="削除する"
        message="この操作は取り消せません。よろしいですか？"
        onClose={() => setConfirm(false)}
        onConfirm={onRemove}
        title="ユーザー招待を削除しますか？"
        visible={confirm}
      />
      {processing && <Loading color={Colors.white} mask />}
    </>
  );
}
