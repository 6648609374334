/**
 * @generated SignedSource<<6748226b462409a2985976402b0cc448>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SwingSceneCreateScreenQuery$variables = Record<PropertyKey, never>;
export type SwingSceneCreateScreenQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SwingSceneCreate">;
};
export type SwingSceneCreateScreenQuery = {
  response: SwingSceneCreateScreenQuery$data;
  variables: SwingSceneCreateScreenQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SwingSceneCreateScreenQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "SwingSceneCreate"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SwingSceneCreateScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SwingFrame",
        "kind": "LinkedField",
        "name": "swingFrames",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "angle",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7fc2e9ee307e0c15ef2be06da69de764",
    "id": null,
    "metadata": {},
    "name": "SwingSceneCreateScreenQuery",
    "operationKind": "query",
    "text": "query SwingSceneCreateScreenQuery {\n  ...SwingSceneCreate\n}\n\nfragment SwingSceneCreate on Query {\n  ...SwingSceneInputSwingFrames\n}\n\nfragment SwingSceneInputSwingFrames on Query {\n  swingFrames {\n    id\n    name\n    angle\n  }\n}\n"
  }
};

(node as any).hash = "40a7993d3e4bc303ed5517011084780f";

export default node;
