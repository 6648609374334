import { useEffect, Suspense } from "react";
import ContentLayout from "@navigation/ContentLayout";
import Comment from "@components/templates/Comment";
import graphql from "babel-plugin-relay/macro";
import {
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import { isNil } from "@lib/utils/commons";
import Loading from "@components/atoms/Loading";
import { CommentScreenQuery } from "@generated/CommentScreenQuery.graphql";
import { PageLimit } from "@constants/App";
import useQueryId from "@lib/hooks/useQueryId";
import useReadComment from "@lib/hooks/useReadComment";

const query = graphql`
  query CommentScreenQuery($id: ID!, $first: Int!) {
    comment(id: $id) {
      id
      ...CommentContentPagination @arguments(first: $first)
    }
    admin {
      ...CommentAdmin
    }
    ...CommentQuery
  }
`;

function ScreenContent({
  queryReference,
}: {
  queryReference: PreloadedQuery<CommentScreenQuery>;
}): JSX.Element {
  const data = usePreloadedQuery<CommentScreenQuery>(query, queryReference);
  const { admin, comment } = data;
  return (
    <Comment
      adminFragment={admin}
      commentsFragment={comment}
      masterFragment={data}
    />
  );
}

export default function CommentScreen(): JSX.Element {
  const id = useQueryId();
  const { readComment } = useReadComment();
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<CommentScreenQuery>(query);
  useEffect(() => {
    loadQuery({ id, first: PageLimit });
    return () => {
      disposeQuery();
    };
  }, [id, loadQuery, disposeQuery]);
  useEffect(() => {
    if (!isNil(id)) {
      readComment(id);
    }
  }, [id, readComment]);
  return (
    <ContentLayout
      breadcrumbs={[{ label: "コメント", path: "/comments" }]}
      title="スレッド"
    >
      <Suspense fallback={<Loading />}>
        {!isNil(queryReference) && (
          <ScreenContent queryReference={queryReference} />
        )}
      </Suspense>
    </ContentLayout>
  );
}
