import { useCallback } from "react";
import graphql from "babel-plugin-relay/macro";
import { Stack, Button } from "@mui/material";
import { useMutation } from "react-relay";
import { object, string, mixed } from "@lib/utils/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Spacer from "@components/atoms/Spacer";
import {
  Form,
  TextField,
  SelectField,
  ErrorText,
} from "@components/molecules/TextInput";
import useMessage from "@lib/hooks/useMessage";
import { resolveError } from "@lib/utils/error";
import {
  UserInvitesCreateMutation,
  UserInvitesCreateMutation$data,
  UserType,
} from "@generated/UserInvitesCreateMutation.graphql";
import { UserType as roles } from "@constants/App";
import { getKeys } from "@lib/utils/commons";

const mutation = graphql`
  mutation UserInvitesCreateMutation($input: CreateUserInviteMutationInput!) {
    createUserInvite(input: $input) {
      __typename
      ... on UserInvite {
        id
        email
        role
        expiredAt
      }
      ... on UserError {
        message
      }
    }
  }
`;

type Input = {
  email: string;
  role: UserType;
};

const RoleOptions = getKeys(roles).map((key) => ({
  label: roles[key],
  value: key,
}));

export default function UserInvitesCreate() {
  const [commit] = useMutation<UserInvitesCreateMutation>(mutation);
  const [, setMessage] = useMessage();

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<Input>({
    defaultValues: {
      email: "",
      role: "Annotater",
    },
    mode: "all",
    resolver: yupResolver(
      object().shape({
        email: string().required("選択してください"),
        role: mixed<UserType>()
          .oneOf(getKeys(roles), "選択してください")
          .required("選択してくださいしてください"),
      })
    ),
  });
  const handleSave = useCallback(async () => {
    await handleSubmit(
      async ({ email: inputEmail, role: inputRole }: Input) => {
        try {
          const result = await new Promise<
            UserInvitesCreateMutation$data["createUserInvite"]
          >((resolve) => {
            commit({
              variables: {
                input: {
                  email: inputEmail,
                  role: inputRole,
                },
              },
              onCompleted({ createUserInvite }) {
                resolve(createUserInvite);
              },
            });
          });
          if (result.__typename === "UserError") {
            setMessage({
              title: "保存できませんでした",
              subline: result.message,
              mode: "error",
            });
          } else {
            setMessage({
              title: "保存しました",
              mode: "toast",
            });
            window.location.href = "/user_invites";
          }
        } catch (e: unknown) {
          setMessage({
            title: "保存できませんでした",
            subline:
              resolveError(e).message ?? "ログイン情報が正しくありません",
            mode: "error",
          });
        }
      }
    )();
  }, [commit, handleSubmit, setMessage]);

  return (
    <Form>
      <Stack direction="column" justifyContent="flex-start">
        <TextField control={control} label="Email" name="email" type="email" />
        {!isValid && errors.email !== undefined && (
          <ErrorText error={errors.email.message} />
        )}
        <Spacer height={24} />
        <SelectField
          control={control}
          label="権限"
          name="role"
          options={RoleOptions}
        />
        {!isValid && errors.role !== undefined && (
          <ErrorText error={errors.role.message} />
        )}
        <Spacer height={24} />

        <Stack alignItems="flex-start" direction="row">
          <Button disabled={!isValid} onClick={handleSave} variant="outlined">
            招待メールを送信する
          </Button>
        </Stack>
      </Stack>
    </Form>
  );
}
