import React, { useMemo } from "react";
import { Box, Tabs, Tab, Stack, Badge } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Spacer from "@components/atoms/Spacer";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { MovieInReviewData$key } from "@generated/MovieInReviewData.graphql";
import { MovieInReviewAdmin$key } from "@generated/MovieInReviewAdmin.graphql";
import { MovieInReviewQuery$key } from "@generated/MovieInReviewQuery.graphql";
import MovieInReviewLabeling from "@components/organisms/MovieInReview/MovieInReviewLabeling";
import MovieInReviewAnnotation from "@components/organisms/MovieInReview/MovieInReviewAnnotation";

const query = graphql`
  fragment MovieInReviewData on MovieMetainfo {
    id
    ...MovieInReviewAnnotation
    ...MovieInReviewLabeling
    movieMetainfoJobs {
      id
      type
      status
      reviewer {
        id
      }
    }
  }
`;

const adminQuery = graphql`
  fragment MovieInReviewAdmin on User {
    id
    ...MovieInReviewLabelingAdmin
    ...MovieInReviewAnnotationAdmin
  }
`;

const masterQuery = graphql`
  fragment MovieInReviewQuery on Query {
    ...MovieInReviewLabelingQuery
  }
`;

export type TabType = "detection" | "labels";

export default function MovieInReview({
  movieMetainfoFragment,
  adminFragment,
  masterFragment,
  initialTab,
}: {
  movieMetainfoFragment: MovieInReviewData$key;
  adminFragment: MovieInReviewAdmin$key;
  masterFragment: MovieInReviewQuery$key;
  initialTab: TabType;
}): JSX.Element {
  const navigate = useNavigate();
  const admin = useFragment<MovieInReviewAdmin$key>(adminQuery, adminFragment);
  const movieMetainfo = useFragment<MovieInReviewData$key>(
    query,
    movieMetainfoFragment
  );
  const { id, movieMetainfoJobs } = movieMetainfo;
  const inReviews = useMemo(() => {
    const filtered = movieMetainfoJobs.filter(
      (job) => job.status === "in_review" && job.reviewer?.id === admin.id
    );
    return filtered.map((inReview) => inReview.type);
  }, [movieMetainfoJobs, admin]);
  const master = useFragment<MovieInReviewQuery$key>(
    masterQuery,
    masterFragment
  );
  const handleChange = (
    event: React.SyntheticEvent,
    newValue: "labels" | "detection"
  ) => {
    navigate(`/movie_in_reviews/${id}/${newValue}`);
    event.preventDefault();
  };
  return (
    <>
      <Stack direction="row">
        <Stack flex={1}>
          <Tabs onChange={handleChange} value={initialTab}>
            <Tab
              label={
                <Badge
                  badgeContent={
                    inReviews.includes("LabelingSwing") === true ? 1 : 0
                  }
                  color="warning"
                  variant="dot"
                >
                  ラベリング
                </Badge>
              }
              value="labels"
            />
            <Tab
              label={
                <Badge
                  badgeContent={
                    inReviews.includes("AnnotateObject") === true ? 1 : 0
                  }
                  color="warning"
                  variant="dot"
                >
                  物体検知
                </Badge>
              }
              value="detection"
            />
          </Tabs>
        </Stack>
      </Stack>
      <Spacer height={16} />
      <Box>
        {initialTab === "labels" ? (
          <MovieInReviewLabeling
            adminFragment={admin}
            masterFragment={master}
            movieMetainfoFragment={movieMetainfo}
          />
        ) : (
          <MovieInReviewAnnotation
            adminFragment={admin}
            movieMetainfoFragment={movieMetainfo}
          />
        )}
      </Box>
    </>
  );
}
