/**
 * @generated SignedSource<<d6515be89836fd5ff00d9e59ec840397>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MovieInReviewsAdmin$data = {
  readonly " $fragmentSpreads": FragmentRefs<"MovieInReviewsCardAdmin">;
  readonly " $fragmentType": "MovieInReviewsAdmin";
};
export type MovieInReviewsAdmin$key = {
  readonly " $data"?: MovieInReviewsAdmin$data;
  readonly " $fragmentSpreads": FragmentRefs<"MovieInReviewsAdmin">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MovieInReviewsAdmin",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MovieInReviewsCardAdmin"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "3e172ec2fa924c325c886893556443bd";

export default node;
