/**
 * @generated SignedSource<<5d1e8fa6ebfd2ec99377fc839000ca13>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MovieJobStatus = "closing" | "done" | "generating" | "ignored" | "in_working" | "waiting";
import { FragmentRefs } from "relay-runtime";
export type LabelingSwingGenerate$data = {
  readonly generated: boolean;
  readonly id: string;
  readonly status: MovieJobStatus;
  readonly " $fragmentType": "LabelingSwingGenerate";
};
export type LabelingSwingGenerate$key = {
  readonly " $data"?: LabelingSwingGenerate$data;
  readonly " $fragmentSpreads": FragmentRefs<"LabelingSwingGenerate">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LabelingSwingGenerate",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "generated",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "MovieJob",
  "abstractKey": null
};

(node as any).hash = "d1821b2c10d11244cfe7a1d71be36e79";

export default node;
