import { Stack, List, ListItem, Divider } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { DrillData$key } from "@generated/DrillData.graphql";
import DrillMain from "@components/organisms/Drill/DrillMain";
import DrillStep from "@components/organisms/Drill/DrillStep";
import DrillPoint from "@components/organisms/Drill/DrillPoint";
import Text from "@components/atoms/Text";
import React from "react";

const query = graphql`
  fragment DrillData on Drill {
    ...DrillMainData
    drillPoints {
      id
      ...DrillPointData
    }
    drillSteps {
      id
      ...DrillStepData
    }
  }
`;

export default function Drill({
  drillFragment,
}: {
  drillFragment: DrillData$key;
}): JSX.Element {
  const drill = useFragment<DrillData$key>(query, drillFragment);

  return (
    <Stack direction="column" spacing={2}>
      <DrillMain drillFragment={drill} />
      <Text bold variant="h6">
        練習手順
      </Text>
      <List disablePadding>
        {drill.drillSteps.map((drillStep, index) => (
          <React.Fragment key={drillStep.id}>
            <Divider />
            <ListItem>
              <DrillStep drillStepFragment={drillStep} step={index + 1} />
            </ListItem>
          </React.Fragment>
        ))}
      </List>

      {drill.drillPoints.map((drillPoint) => (
        <React.Fragment key={drillPoint.id}>
          <Divider />
          <DrillPoint drillPointFragment={drillPoint} />
        </React.Fragment>
      ))}
    </Stack>
  );
}
