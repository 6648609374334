import { TableCell } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { ProgressObject$key } from "@generated/ProgressObject.graphql";
import { Link } from "react-router-dom";
import { ObjectDetectionObjectType } from "@constants/App";

const query = graphql`
  fragment ProgressObject on ProgressAnnotation {
    annotatedCount
    name
  }
`;

export default function ProgressObject({
  progressFragment,
}: {
  progressFragment: ProgressObject$key;
}) {
  const { annotatedCount, name } = useFragment<ProgressObject$key>(
    query,
    progressFragment
  );
  return (
    <>
      <TableCell>{ObjectDetectionObjectType[name]}</TableCell>
      <TableCell>{annotatedCount}件</TableCell>
      <TableCell>
        <Link to={`/object_detections/${name}`}>詳細</Link>
      </TableCell>
    </>
  );
}
