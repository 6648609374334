import { Stack, Chip } from "@mui/material";
import Text from "@components/atoms/Text";
import Thumbnail from "@components/atoms/Thumbnail";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { ArticleMainData$key } from "@generated/ArticleMainData.graphql";
import { ArticleCategory } from "@constants/App";

const query = graphql`
  fragment ArticleMainData on Article {
    category
    content
    status
    thumbnail {
      signedUrl
    }
    title
  }
`;

export default function ArticleMain({
  articleFragment,
}: {
  articleFragment: ArticleMainData$key;
}) {
  const { category, content, thumbnail, title } =
    useFragment<ArticleMainData$key>(query, articleFragment);
  return (
    <Stack direction="column" flex={1} spacing={1}>
      <Text bold variant="h2">
        {title}
      </Text>
      <Stack direction="row">
        <Chip
          color="primary"
          label={ArticleCategory[category]}
          variant="outlined"
        />
      </Stack>
      <Text>{content}</Text>
      <Thumbnail size={500} url={thumbnail?.signedUrl} />
    </Stack>
  );
}
