/**
 * @generated SignedSource<<00edd32c6ef1c2e3c6c8d95b4350b340>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SwingFrameSwingPositions$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SwingFrameInputSwingPositions">;
  readonly " $fragmentType": "SwingFrameSwingPositions";
};
export type SwingFrameSwingPositions$key = {
  readonly " $data"?: SwingFrameSwingPositions$data;
  readonly " $fragmentSpreads": FragmentRefs<"SwingFrameSwingPositions">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SwingFrameSwingPositions",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SwingFrameInputSwingPositions"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "8423e7d85d58486c54b6cbb27da59df1";

export default node;
