// Note: ラベリングのrenderパフォーマンスが重いので、FragmentCollocationを停止する
import { useCallback, useMemo, useState } from "react";
import { Box, Chip, Menu, ListItemButton, ListItemText } from "@mui/material";
import { isNil } from "@lib/utils/commons";
import Icon from "@components/atoms/Icon";
import { CameraAngle } from "@constants/App";

export type SwingListItem = {
  id: string;
  name: string;
  swingFrames: {
    id: string;
    angle: CameraAngle;
    name: string;
  }[];
};

export default function SwingPositionClassificationChip({
  disabled = false,
  swingPosition,
  selectedId,
  openSwingPositionId,
  onOpenMenu,
  onSelect,
}: {
  disabled?: boolean;
  swingPosition: SwingListItem;
  selectedId: string | null;
  openSwingPositionId: string | null;
  onOpenMenu: (id: string | null) => void;
  onSelect: (id: string | null) => void;
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { swingFrames } = swingPosition;
  const selected = useMemo(() => {
    if (selectedId !== null) {
      const selectedSwingFrame = swingFrames.find(
        (row) => row.id === selectedId
      );
      return !isNil(selectedSwingFrame);
    }
    return false;
  }, [selectedId, swingFrames]);
  const displayName = useMemo(() => {
    if (selectedId !== null) {
      const selectedSwingFrame = swingPosition.swingFrames.find(
        (row) => row.id === selectedId
      );
      if (!isNil(selectedSwingFrame)) {
        return selectedSwingFrame.name;
      }
    }
    return swingPosition.name;
  }, [selectedId, swingPosition]);
  const onOpen = useCallback(
    (id: string, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      onOpenMenu(id);
      setAnchorEl(event.currentTarget);
    },
    [onOpenMenu, setAnchorEl]
  );
  const onClose = useCallback(() => {
    onOpenMenu(null);
    setAnchorEl(null);
  }, [onOpenMenu, setAnchorEl]);

  return (
    <Box key={swingPosition.id} sx={{ padding: "2px" }}>
      <Chip
        deleteIcon={
          swingFrames.length > 1 ? (
            <Icon name="dropdown" size={24} />
          ) : undefined
        }
        disabled={disabled}
        label={displayName}
        onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          if (swingFrames.length === 1) {
            onSelect(
              selectedId === swingFrames[0].id ? null : swingFrames[0].id
            );
          } else {
            onOpen(swingPosition.id, event);
          }
        }}
        onDelete={
          swingFrames.length > 1
            ? (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                onOpen(swingPosition.id, event);
              }
            : undefined
        }
        variant={selected ? "filled" : "outlined"}
      />
      <Menu
        anchorEl={anchorEl}
        onClose={onClose}
        open={anchorEl !== null && openSwingPositionId === swingPosition.id}
      >
        {swingFrames.map((swingFrame) => (
          <ListItemButton
            key={swingFrame.id}
            onClick={() => {
              onSelect(selectedId === swingFrame.id ? null : swingFrame.id);
              onClose();
            }}
          >
            <ListItemText>{swingFrame.name}</ListItemText>
          </ListItemButton>
        ))}
      </Menu>
    </Box>
  );
}
