import { Stack, Chip } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { LessonTemplateDrill$key } from "@generated/LessonTemplateDrill.graphql";
import Text from "@components/atoms/Text";
import { decodeId } from "@lib/utils/convertId";
import { Link } from "react-router-dom";

const query = graphql`
  fragment LessonTemplateDrill on Drill {
    id
    title
    swingPosition {
      name
    }
  }
`;

export default function LessonTemplateDrill({
  drillFragment,
}: {
  drillFragment: LessonTemplateDrill$key;
}): JSX.Element {
  const data = useFragment<LessonTemplateDrill$key>(query, drillFragment);
  return (
    <Stack alignItems="center" direction="row" spacing={1}>
      <Text>ID:{decodeId(data.id)}</Text>
      <Chip label={data.swingPosition.name} size="small" variant="outlined" />
      <Link to={`/drills/${data.id}`}>{data.title}</Link>
    </Stack>
  );
}
