/**
 * @generated SignedSource<<119cf37917b525d7d9e941a0c2e4e2d1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MovieMetainfoAngle = "back" | "front";
export type MovieMetainfoDirection = "landscape" | "portrait" | "square";
export type MovieMetainfoGender = "female" | "male" | "unknown";
export type MovieMetainfoHand = "left" | "right";
export type MovieMetainfoPlace = "course" | "driving_range" | "indoor";
export type MovieMetainfoSwing = "full" | "half" | "quarter";
export type UpdateMovieMetainfoMutationInput = {
  angle: MovieMetainfoAngle;
  clientMutationId?: string | null | undefined;
  clubId: string;
  direction: MovieMetainfoDirection;
  duration: number;
  gender: MovieMetainfoGender;
  hand: MovieMetainfoHand;
  height: number;
  id: string;
  place: MovieMetainfoPlace;
  swing: MovieMetainfoSwing;
  trimmingEnd: number;
  trimmingStart: number;
  width: number;
};
export type SwingPositionClassificationMetaMutation$variables = {
  input: UpdateMovieMetainfoMutationInput;
};
export type SwingPositionClassificationMetaMutation$data = {
  readonly updateMovieMetainfo: {
    readonly __typename: "MovieMetainfo";
    readonly angle: MovieMetainfoAngle | null | undefined;
    readonly club: {
      readonly id: string;
      readonly name: string;
    } | null | undefined;
    readonly direction: MovieMetainfoDirection | null | undefined;
    readonly duration: number | null | undefined;
    readonly gender: MovieMetainfoGender | null | undefined;
    readonly hand: MovieMetainfoHand | null | undefined;
    readonly height: number | null | undefined;
    readonly id: string;
    readonly place: MovieMetainfoPlace | null | undefined;
    readonly swing: MovieMetainfoSwing | null | undefined;
    readonly trimmingEnd: number | null | undefined;
    readonly trimmingStart: number | null | undefined;
    readonly width: number | null | undefined;
  } | {
    readonly __typename: "UserError";
    readonly message: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type SwingPositionClassificationMetaMutation = {
  response: SwingPositionClassificationMetaMutation$data;
  variables: SwingPositionClassificationMetaMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "updateMovieMetainfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Club",
            "kind": "LinkedField",
            "name": "club",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "trimmingStart",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "trimmingEnd",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "duration",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "width",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "height",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "angle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "direction",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "gender",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hand",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "place",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "swing",
            "storageKey": null
          }
        ],
        "type": "MovieMetainfo",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "type": "UserError",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SwingPositionClassificationMetaMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SwingPositionClassificationMetaMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "c6e577317b71f862da12ac113b89b933",
    "id": null,
    "metadata": {},
    "name": "SwingPositionClassificationMetaMutation",
    "operationKind": "mutation",
    "text": "mutation SwingPositionClassificationMetaMutation(\n  $input: UpdateMovieMetainfoMutationInput!\n) {\n  updateMovieMetainfo(input: $input) {\n    __typename\n    ... on MovieMetainfo {\n      id\n      club {\n        id\n        name\n      }\n      trimmingStart\n      trimmingEnd\n      duration\n      width\n      height\n      angle\n      direction\n      gender\n      hand\n      place\n      swing\n    }\n    ... on UserError {\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "efb2ec4e786713bfa4626f19e36ad7e0";

export default node;
