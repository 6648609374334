import graphql from "babel-plugin-relay/macro";
import { commitMutation, Environment } from "react-relay/hooks";
import {
  refreshTokenMutation,
  refreshTokenMutation$data,
} from "@generated/refreshTokenMutation.graphql";
import { SecureStoreManager } from "@lib/utils/secureStoreManager";

const refreshTokenQuery = graphql`
  mutation refreshTokenMutation($input: RefreshTokenMutationInput!) {
    refreshToken(input: $input) {
      accessToken
      refreshToken
    }
  }
`;

export default async function refreshToken(
  relayEnvironment: Environment
): Promise<refreshTokenMutation$data["refreshToken"]> {
  const { accessToken: oldToken, refreshToken: oldRefreshToken } =
    SecureStoreManager.getAccessTokenSet();
  if (oldToken === null || oldRefreshToken === null) {
    throw new Error("ログインが切れました");
  }
  const input = {
    refreshToken: oldRefreshToken,
  };

  const { refreshToken: result } = await new Promise<refreshTokenMutation$data>(
    (resolve, reject) =>
      /* eslint-disable no-promise-executor-return */
      commitMutation<refreshTokenMutation>(relayEnvironment, {
        mutation: refreshTokenQuery,
        variables: {
          input,
        },
        onCompleted: (res) => resolve(res),
        onError: (err) => reject(err),
      })
  );
  SecureStoreManager.registerAccessTokenSet(
    result.accessToken,
    result.refreshToken
  );

  return result;
}
