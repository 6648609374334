/**
 * @generated SignedSource<<fca1d8512393dd705e6c1dcd8b48813c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ObjectDetectionObjectType = "ball" | "head" | "shaft";
export type CreateCommentMutationInput = {
  clientMutationId?: string | null | undefined;
  content: string;
  movieClipId?: string | null | undefined;
  movieMetainfoId: string;
  objectDetectionId?: string | null | undefined;
};
export type CommentPostMutation$variables = {
  connections: ReadonlyArray<string>;
  input: CreateCommentMutationInput;
};
export type CommentPostMutation$data = {
  readonly createComment: {
    readonly __typename: "CommentItem";
    readonly comment: {
      readonly commentUsers: ReadonlyArray<{
        readonly id: string;
        readonly lastReadAt: any | null | undefined;
        readonly unreadCount: number;
        readonly user: {
          readonly avatar: {
            readonly signedUrl: string | null | undefined;
          } | null | undefined;
          readonly id: string;
          readonly name: string;
        };
      }>;
      readonly contentCount: number;
      readonly id: string;
      readonly lastPostedAt: any;
      readonly movieMetainfo: {
        readonly id: string;
        readonly thumbnail: string | null | undefined;
      };
    };
    readonly commentContentEdge: {
      readonly cursor: string;
      readonly node: {
        readonly comment: {
          readonly id: string;
        };
        readonly content: string;
        readonly createdAt: any;
        readonly id: string;
        readonly movieClip: {
          readonly id: string;
          readonly swingImage: {
            readonly signedUrl: string | null | undefined;
          };
        } | null | undefined;
        readonly objectDetection: {
          readonly id: string;
          readonly objectType: ObjectDetectionObjectType;
          readonly xmax: number;
          readonly xmin: number;
          readonly ymax: number;
          readonly ymin: number;
        } | null | undefined;
        readonly user: {
          readonly avatar: {
            readonly signedUrl: string | null | undefined;
          } | null | undefined;
          readonly id: string;
          readonly name: string;
        };
      };
    };
  } | {
    readonly __typename: "UserError";
    readonly message: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type CommentPostMutation = {
  response: CommentPostMutation$data;
  variables: CommentPostMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contentCount",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastPostedAt",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "MovieMetainfo",
  "kind": "LinkedField",
  "name": "movieMetainfo",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnail",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastReadAt",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unreadCount",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "signedUrl",
  "storageKey": null
},
v12 = [
  (v11/*: any*/)
],
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    (v10/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Avatar",
      "kind": "LinkedField",
      "name": "avatar",
      "plural": false,
      "selections": (v12/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "content",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "Comment",
  "kind": "LinkedField",
  "name": "comment",
  "plural": false,
  "selections": [
    (v4/*: any*/)
  ],
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "ObjectDetection",
  "kind": "LinkedField",
  "name": "objectDetection",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "objectType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "xmin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ymin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "xmax",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ymax",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v19 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "type": "UserError",
  "abstractKey": null
},
v20 = [
  (v11/*: any*/),
  (v4/*: any*/)
],
v21 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    (v10/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Avatar",
      "kind": "LinkedField",
      "name": "avatar",
      "plural": false,
      "selections": (v20/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CommentPostMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "createComment",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Comment",
                "kind": "LinkedField",
                "name": "comment",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CommentUser",
                    "kind": "LinkedField",
                    "name": "commentUsers",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v13/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CommentContentEdge",
                "kind": "LinkedField",
                "name": "commentContentEdge",
                "plural": false,
                "selections": [
                  (v14/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CommentContent",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v13/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "MovieClip",
                        "kind": "LinkedField",
                        "name": "movieClip",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SwingImage",
                            "kind": "LinkedField",
                            "name": "swingImage",
                            "plural": false,
                            "selections": (v12/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v18/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "CommentItem",
            "abstractKey": null
          },
          (v19/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CommentPostMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "createComment",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Comment",
                "kind": "LinkedField",
                "name": "comment",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CommentUser",
                    "kind": "LinkedField",
                    "name": "commentUsers",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v21/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CommentContentEdge",
                "kind": "LinkedField",
                "name": "commentContentEdge",
                "plural": false,
                "selections": [
                  (v14/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CommentContent",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v21/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "MovieClip",
                        "kind": "LinkedField",
                        "name": "movieClip",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SwingImage",
                            "kind": "LinkedField",
                            "name": "swingImage",
                            "plural": false,
                            "selections": (v20/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v18/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "appendEdge",
                "key": "",
                "kind": "LinkedHandle",
                "name": "commentContentEdge",
                "handleArgs": [
                  {
                    "kind": "Variable",
                    "name": "connections",
                    "variableName": "connections"
                  }
                ]
              }
            ],
            "type": "CommentItem",
            "abstractKey": null
          },
          (v19/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "60eb123fd01a90095777466fcaa823c0",
    "id": null,
    "metadata": {},
    "name": "CommentPostMutation",
    "operationKind": "mutation",
    "text": "mutation CommentPostMutation(\n  $input: CreateCommentMutationInput!\n) {\n  createComment(input: $input) {\n    __typename\n    ... on CommentItem {\n      comment {\n        id\n        contentCount\n        lastPostedAt\n        movieMetainfo {\n          id\n          thumbnail\n        }\n        commentUsers {\n          id\n          lastReadAt\n          unreadCount\n          user {\n            id\n            name\n            avatar {\n              signedUrl\n              id\n            }\n          }\n        }\n      }\n      commentContentEdge {\n        cursor\n        node {\n          id\n          content\n          createdAt\n          comment {\n            id\n          }\n          user {\n            id\n            name\n            avatar {\n              signedUrl\n              id\n            }\n          }\n          movieClip {\n            id\n            swingImage {\n              signedUrl\n              id\n            }\n          }\n          objectDetection {\n            id\n            objectType\n            xmin\n            ymin\n            xmax\n            ymax\n          }\n        }\n      }\n    }\n    ... on UserError {\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "623527f4b28407d8339df2f76f3f1ad3";

export default node;
