import { useCallback, useState, useMemo } from "react";
import {
  IconButton,
  Badge,
  Dialog,
  DialogContent,
  Stack,
  Button,
  Box,
  Chip,
} from "@mui/material";
import { object, string } from "@lib/utils/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import graphql from "babel-plugin-relay/macro";
import { useMutation, useFragment } from "react-relay";
import {
  Form,
  SelectField,
  TextField,
  ErrorText,
} from "@components/molecules/TextInput";
import { useNavigate, useLocation } from "react-router-dom";
import { SwingPositionClassificationChat$key } from "@generated/SwingPositionClassificationChat.graphql";
import {
  SwingPositionClassificationChatMutation,
  SwingPositionClassificationChatMutation$data,
} from "@generated/SwingPositionClassificationChatMutation.graphql";
import Icon from "@components/atoms/Icon";
import { isNil } from "@lib/utils/commons";
import useMessage from "@lib/hooks/useMessage";
import usePreventDoubleClick from "@lib/hooks/usePreventDoubleClick";
import Spacer from "@components/atoms/Spacer";
import { decodeId } from "@lib/utils/convertId";
import { FrequentComments } from "@constants/App";

const query = graphql`
  fragment SwingPositionClassificationChat on MovieMetainfo {
    id
    comment {
      id
      contentCount
    }
    movieClips {
      id
    }
  }
`;

const mutation = graphql`
  mutation SwingPositionClassificationChatMutation(
    $input: CreateCommentMutationInput!
  ) {
    createComment(input: $input) {
      __typename
      ... on CommentItem {
        comment {
          id
        }
      }
      ... on UserError {
        message
      }
    }
  }
`;

type Input = {
  movieClipId: string | null;
  content: string;
};

export default function SwingPositionClassificationChat({
  movieMetainfoFragment,
}: {
  movieMetainfoFragment: SwingPositionClassificationChat$key;
}) {
  const { id, comment, movieClips } =
    useFragment<SwingPositionClassificationChat$key>(
      query,
      movieMetainfoFragment
    );
  const [, setMessage] = useMessage();
  const navigate = useNavigate();
  const location = useLocation();
  const { processing, onClick, onRelease } = usePreventDoubleClick();
  const [commit] =
    useMutation<SwingPositionClassificationChatMutation>(mutation);
  const [open, setOpen] = useState<boolean>(false);
  const handleNav = useCallback(() => {
    if (!isNil(comment)) {
      navigate(`/comments/modal/${comment.id}`, {
        state: { background: location },
      });
    } else {
      setOpen(true);
    }
  }, [comment, navigate, location]);
  const clips = useMemo(
    () =>
      movieClips.map((row) => {
        const decode = decodeId(row.id);
        if (decode === null) {
          throw new Error("Data Error");
        }
        return {
          value: decode,
          label: `ID: ${decode}`,
        };
      }),
    [movieClips]
  );
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm<Input>({
    defaultValues: {
      movieClipId: null,
      content: "",
    },
    mode: "all",
    resolver: yupResolver(
      object().shape({
        movieClipId: string().required("入力してください").nullable(),
        content: string()
          .default("")
          .trim()
          .min(1, "入力してください")
          .required("入力してください"),
      })
    ),
  });
  const selectTemplate = useCallback(
    (word: string) => {
      const currentText = getValues("content");
      const words = [word, currentText];
      setValue("content", words.join("\n"), { shouldValidate: true });
    },
    [getValues, setValue]
  );
  const handleCreate = useCallback(async () => {
    await handleSubmit(
      async ({ movieClipId: inputId, content: inputContent }: Input) => {
        if (processing) {
          return;
        }
        onClick();
        const result = await new Promise<
          SwingPositionClassificationChatMutation$data["createComment"]
        >((resolve) => {
          commit({
            variables: {
              input: {
                movieMetainfoId: id,
                content: inputContent,
                movieClipId: inputId,
              },
            },
            onCompleted: ({ createComment }) => {
              resolve(createComment);
            },
          });
        });
        setValue("movieClipId", null);
        setValue("content", "");
        onRelease();
        if (result.__typename === "CommentItem") {
          setOpen(false);
          navigate(`/comments/modal/${result.comment.id}`, {
            state: { background: location },
          });
        } else {
          setMessage({
            mode: "error",
            title:
              result.__typename === "UserError"
                ? result.message
                : "投稿できませんでした",
          });
        }
      }
    )();
  }, [
    commit,
    id,
    location,
    navigate,
    setMessage,
    setValue,
    processing,
    onClick,
    onRelease,
    handleSubmit,
  ]);
  return (
    <>
      <IconButton
        onClick={handleNav}
        sx={{
          position: "fixed",
          right: 8,
          bottom: 8,
        }}
      >
        <Badge badgeContent={comment?.contentCount ?? 0} color="error">
          <Icon name="chat" size={48} />
        </Badge>
      </IconButton>
      <Dialog
        fullWidth
        onClose={() => setOpen(false)}
        open={open}
        scroll="paper"
      >
        <Stack direction="row" justifyContent="flex-end">
          <IconButton aria-label="close" onClick={() => setOpen(false)}>
            <Icon name="close" size={24} />
          </IconButton>
        </Stack>
        <DialogContent>
          <Form>
            <Stack direction="column" justifyContent="flex-start" spacing={2}>
              <SelectField
                control={control}
                label="対象の画像ID"
                name="movieClipId"
                options={clips}
              />
              {!isValid && errors.movieClipId !== undefined && (
                <ErrorText error={errors.movieClipId.message} />
              )}

              <Spacer height={24} />
              <TextField
                control={control}
                name="content"
                numberOfLines={3}
                placeholder="コメントを入力"
              />
              {!isValid && errors.content !== undefined && (
                <ErrorText error={errors.content.message} />
              )}
              <Stack
                alignItems="flex-start"
                direction="row"
                flexWrap="wrap"
                justifyContent="flex-start"
              >
                {FrequentComments.map((row) => (
                  <Box key={row} sx={{ padding: "2px" }}>
                    <Chip
                      label={row}
                      onClick={() => selectTemplate(row)}
                      size="small"
                      variant="outlined"
                    />
                  </Box>
                ))}
              </Stack>
              <Stack alignItems="flex-end" direction="row">
                <Button
                  disabled={!isValid}
                  onClick={handleCreate}
                  variant="contained"
                >
                  投稿する
                </Button>
              </Stack>
              <Spacer height={18} />
            </Stack>
          </Form>
        </DialogContent>
      </Dialog>
    </>
  );
}
