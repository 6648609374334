/**
 * @generated SignedSource<<d3b3d29e8bdd187b2362553e04ffcbd5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ArticleCategory = "lesson" | "motivation" | "pickup" | "rule" | "theory";
export type ArticleStatus = "draft" | "publish";
import { FragmentRefs } from "relay-runtime";
export type ArticleMainData$data = {
  readonly category: ArticleCategory;
  readonly content: string | null | undefined;
  readonly status: ArticleStatus;
  readonly thumbnail: {
    readonly signedUrl: string | null | undefined;
  } | null | undefined;
  readonly title: string | null | undefined;
  readonly " $fragmentType": "ArticleMainData";
};
export type ArticleMainData$key = {
  readonly " $data"?: ArticleMainData$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleMainData">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArticleMainData",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "category",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Thumbnail",
      "kind": "LinkedField",
      "name": "thumbnail",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "signedUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "type": "Article",
  "abstractKey": null
};

(node as any).hash = "46af496411392245357aaf0cc84d59d4";

export default node;
