/**
 * @generated SignedSource<<11a1ab6dbd946335dd7addcc824af2d8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MovieMetainfoJobType = "AnnotateObject" | "LabelingSwing";
import { FragmentRefs } from "relay-runtime";
export type MovieMetainfoDetection$data = {
  readonly id: string;
  readonly movieClips: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"ObjectDetectionItemRow">;
  }>;
  readonly movieMetainfoJobs: ReadonlyArray<{
    readonly id: string;
    readonly type: MovieMetainfoJobType;
    readonly " $fragmentSpreads": FragmentRefs<"MovieMetainfoRemandButton" | "ObjectDetectionItemSummery">;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"ObjectDetectionChat" | "ObjectDetectionItemRowDirection">;
  readonly " $fragmentType": "MovieMetainfoDetection";
};
export type MovieMetainfoDetection$key = {
  readonly " $data"?: MovieMetainfoDetection$data;
  readonly " $fragmentSpreads": FragmentRefs<"MovieMetainfoDetection">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MovieMetainfoDetection",
  "selections": [
    (v0/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ObjectDetectionItemRowDirection"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ObjectDetectionChat"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MovieClip",
      "kind": "LinkedField",
      "name": "movieClips",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ObjectDetectionItemRow"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MovieMetainfoJob",
      "kind": "LinkedField",
      "name": "movieMetainfoJobs",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ObjectDetectionItemSummery"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "MovieMetainfoRemandButton"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "MovieMetainfo",
  "abstractKey": null
};
})();

(node as any).hash = "ea4ebb63ede61031d23257239dae05df";

export default node;
