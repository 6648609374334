export const AppVersion = "1.0.3";
export const DrawerWidth = 224;
export const HeaderHeight = 72;

export const ArticleCategory = {
  lesson: "症状",
  motivation: "モチベーション",
  theory: "スイング理論",
  pickup: "特集",
  rule: "マナー・ルール",
};

export const DrillLevel = {
  easy: "基礎があれば簡単",
  hard: "プロの指導が必須",
  normal: "手順動画を見ればできる",
  very_easy: "未経験・初心者でもできる",
  very_hard: "プロの指導が数回必須",
};

export const DrillPeriod = {
  long: "8時間",
  medium: "4時間",
  shrot: "2時間",
  very_long: "12時間",
  very_short: "1時間",
};

export const DrillPlace = {
  ground: "練習場",
  house: "自宅",
};

export const DrillPointPointType = {
  goal: "ゴール",
  notice: "注意点",
};

export const DrillSideEffect = {
  dangerous: "習得するまでフォームを崩す可能性がある（ラウンド前は要注意）",
  few: "練習中、打感が乱れても、気になる程ではない",
  nothing: "練習中、打感が悪くなる可能性はほぼない",
  noticing: "練習中、稀に打感が悪くなることがある",
  warning: "練習中、一時的に打感が悪くなる可能性がある",
};

export const DrillStatus = {
  draft: "下書き",
  publish: "公開中",
};

export const MovieMetainfoAngle = {
  front: "正面",
  back: "背面",
};

export const MovieMetainfoDirection = {
  portrait: "縦型",
  landscape: "横型",
  square: "正方形",
};
export const MovieMetainfoGender = {
  male: "男性",
  female: "女性",
  unknown: "不明",
};
export const MovieMetainfoHand = {
  right: "右利き",
  left: "左利き",
};
export const MovieMetainfoPlace = {
  driving_range: "打ちっぱなし",
  indoor: "インドア",
  course: "コース",
};

export const MovieMetainfoSwing = {
  full: "フルスイング",
  half: "ハーフスイング",
  quarter: "クォータースイング",
};

export const MovieMetainfosStatus = {
  labeling: "ラベリング中",
  in_review: "レビュー中",
  approved: "承認済み",
  remanding: "差戻し中",
  remanded: "差戻し済み",
} as const;

export const MovieMetainfoJobStatus = {
  waiting: "待機中",
  generating: "フォルダ生成中",
  in_working: "作業中",
  in_review: "レビュー中",
  approved: "承認済み",
  remanded: "差戻し済み",
} as const;

export const MovieMetainfoJobType = {
  LabelingSwing: "ラベリング",
  AnnotateObject: "アノテーション",
} as const;

export const ObjectDetectionObjectType = {
  ball: "ボール",
  head: "クラブヘッド",
  shaft: "シャフト",
} as const;

export const NameKanaPattern = /^[\u30A0-\u30FF]+$/;

export const SwingFrameAngle = {
  front: "正面",
  back: "背面",
} as const;

export const LessonTemplateFormFormType = {
  incorrect: "間違ったフォーム",
  correct: "正しいフォーム",
} as const;

export const UserType = {
  Admin: "管理者",
  Annotater: "アノテーター",
} as const;

export const MovieJobStatus = {
  waiting: "画像生成待ち",
  generating: "画像生成中",
  in_working: "作業中",
  closing: "クローズ中",
  done: "完了済み",
  ignored: "除外済み",
} as const;

export const FrequentComments = [
  "全般レビューお願いします",
  "このフレームでこのラベルは早いですか？",
  "このフレームでこのラベルは遅いですか？",
  "予備フラグの許容範囲ですか？",
];

export const MaxClipCount = 50;
export const PageLimit = 50;
export const PageLimitLarge = 100;
export const PageAnchorName = "anchor";
export type CameraAngle = "front" | "back";
export const MinmunLabelingCount = 7;
export const MinmunAnnotationCount = 7;
export const LastNoticedAt = "lastNoticedAt";
export const LastNoticeInterval = 5;

export const PasswordPattern =
  /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)(?=.*?[!"#$%&'()*+,-/:;<=>?@[\]^_`{.])[a-zA-Z\d!"#$%&'()*+,-/:;<=>?@[\]^_`{.]{8,20}$/i;
