import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
} from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { SwingFrames$key } from "@generated/SwingFrames.graphql";
import { Link } from "react-router-dom";
import { SwingFrameAngle } from "@constants/App";
import { decodeId } from "@lib/utils/convertId";
import SwingFramesList from "@components/organisms/SwingFrames/SwingFramesList";

const query = graphql`
  fragment SwingFrames on Query {
    swingFrames {
      id
      name
      angle
    }
  }
`;

export default function SwingFrames({
  definitionsFragment,
}: {
  definitionsFragment: SwingFrames$key;
}) {
  const { swingFrames } = useFragment(query, definitionsFragment);
  return (
    <Stack direction="column" spacing={2}>
      <SwingFramesList />
      <Table aria-label="simple table" sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell component="th">ID</TableCell>
            <TableCell component="th">カメラアングル</TableCell>
            <TableCell component="th">名称</TableCell>
            <TableCell component="th">詳細</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {swingFrames.map((swingFrame) => (
            <TableRow key={swingFrame.id}>
              <TableCell>{decodeId(swingFrame.id)}</TableCell>
              <TableCell>{SwingFrameAngle[swingFrame.angle]}</TableCell>
              <TableCell>{swingFrame.name}</TableCell>
              <TableCell>
                <Link to={`/swing_frames/${swingFrame.id}`}>詳細</Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Stack>
  );
}
