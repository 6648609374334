/**
 * @generated SignedSource<<451a9148f5b60c27a589498e5a27f669>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ObjectDetectionObjectType = "ball" | "head" | "shaft";
import { FragmentRefs } from "relay-runtime";
export type ObjectDetectionItemRow$data = {
  readonly height: number | null | undefined;
  readonly id: string;
  readonly objectDetections: ReadonlyArray<{
    readonly commentContents: ReadonlyArray<{
      readonly content: string;
      readonly createdAt: any;
      readonly id: string;
      readonly user: {
        readonly avatar: {
          readonly signedUrl: string | null | undefined;
        } | null | undefined;
        readonly name: string;
      };
    }>;
    readonly id: string;
    readonly objectType: ObjectDetectionObjectType;
    readonly xmax: number;
    readonly xmin: number;
    readonly ymax: number;
    readonly ymin: number;
  }>;
  readonly swingImage: {
    readonly id: string;
    readonly signedUrl: string | null | undefined;
  };
  readonly width: number | null | undefined;
  readonly " $fragmentType": "ObjectDetectionItemRow";
};
export type ObjectDetectionItemRow$key = {
  readonly " $data"?: ObjectDetectionItemRow$data;
  readonly " $fragmentSpreads": FragmentRefs<"ObjectDetectionItemRow">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "signedUrl",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ObjectDetectionItemRow",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "width",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "height",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SwingImage",
      "kind": "LinkedField",
      "name": "swingImage",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ObjectDetection",
      "kind": "LinkedField",
      "name": "objectDetections",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "objectType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "xmin",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ymin",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "xmax",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ymax",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CommentContent",
          "kind": "LinkedField",
          "name": "commentContents",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "content",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "createdAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "user",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Avatar",
                  "kind": "LinkedField",
                  "name": "avatar",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "MovieClip",
  "abstractKey": null
};
})();

(node as any).hash = "5c1df2ae0beeb6a1c362ba5dcd6a62ef";

export default node;
