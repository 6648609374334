import { Stack, Box, Divider } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { ArticleSectionData$key } from "@generated/ArticleSectionData.graphql";
import ArticleContent from "@components/organisms/Article/ArticleContent";
import Text from "@components/atoms/Text";
import Spacer from "@components/atoms/Spacer";

const query = graphql`
  fragment ArticleSectionData on ArticleSection {
    id
    title
    content
    articleContents {
      id
      ...ArticleContentData
    }
  }
`;

export default function ArticleSection({
  articleSectionFragment,
}: {
  articleSectionFragment: ArticleSectionData$key;
}) {
  const data = useFragment<ArticleSectionData$key>(
    query,
    articleSectionFragment
  );
  return (
    <Stack direction="column" flex={1} spacing={1}>
      <Text bold variant="h5">
        {data.title}
      </Text>
      <Stack direction="column" flex={1}>
        {data.articleContents.map((articleContent) => (
          <Box key={articleContent.id}>
            <ArticleContent articleContentFragment={articleContent} />
          </Box>
        ))}
      </Stack>
      <Divider />
      <Spacer height={8} />
    </Stack>
  );
}
