/**
 * @generated SignedSource<<fd186aebf4b1cd3d1a75a89c204e5437>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SwingFrameAngle = "back" | "front";
import { FragmentRefs } from "relay-runtime";
export type SwingFrameData$data = {
  readonly angle: SwingFrameAngle;
  readonly description: string;
  readonly id: string;
  readonly name: string;
  readonly orderNumber: number;
  readonly swingPosition: {
    readonly id: string;
  };
  readonly thumbnail: {
    readonly id: string;
    readonly signedUrl: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"SwingFrameDisplay" | "SwingFrameRemove">;
  readonly " $fragmentType": "SwingFrameData";
};
export type SwingFrameData$key = {
  readonly " $data"?: SwingFrameData$data;
  readonly " $fragmentSpreads": FragmentRefs<"SwingFrameData">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SwingFrameData",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "angle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "orderNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Thumbnail",
      "kind": "LinkedField",
      "name": "thumbnail",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "signedUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SwingPosition",
      "kind": "LinkedField",
      "name": "swingPosition",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SwingFrameDisplay"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SwingFrameRemove"
    }
  ],
  "type": "SwingFrame",
  "abstractKey": null
};
})();

(node as any).hash = "2bce9d73114dde982207a7d0e5b83eb3";

export default node;
