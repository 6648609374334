/**
 * @generated SignedSource<<554ed85cbd0e48d98e734bfe64473b27>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CoachingData$data = {
  readonly coachingImages: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"CoachingImage">;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"CoachingMain">;
  readonly " $fragmentType": "CoachingData";
};
export type CoachingData$key = {
  readonly " $data"?: CoachingData$data;
  readonly " $fragmentSpreads": FragmentRefs<"CoachingData">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CoachingData",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CoachingMain"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CoachingImage",
      "kind": "LinkedField",
      "name": "coachingImages",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CoachingImage"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Coaching",
  "abstractKey": null
};

(node as any).hash = "7a3afa190321621637acf8505589de00";

export default node;
