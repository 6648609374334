/**
 * @generated SignedSource<<43b7904a5dfce0ca9abd2e8ee4e1f5bd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SwingFrameCreateScreenQuery$variables = Record<PropertyKey, never>;
export type SwingFrameCreateScreenQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SwingFrameCreate">;
};
export type SwingFrameCreateScreenQuery = {
  response: SwingFrameCreateScreenQuery$data;
  variables: SwingFrameCreateScreenQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SwingFrameCreateScreenQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "SwingFrameCreate"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SwingFrameCreateScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SwingPosition",
        "kind": "LinkedField",
        "name": "swingPositions",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e96524a77990c65084db81ac398d5a9f",
    "id": null,
    "metadata": {},
    "name": "SwingFrameCreateScreenQuery",
    "operationKind": "query",
    "text": "query SwingFrameCreateScreenQuery {\n  ...SwingFrameCreate\n}\n\nfragment SwingFrameCreate on Query {\n  ...SwingFrameInputSwingPositions\n}\n\nfragment SwingFrameInputSwingPositions on Query {\n  swingPositions {\n    id\n    name\n  }\n}\n"
  }
};

(node as any).hash = "552aa5216ef49e1d48fd44e7c9ccd6ac";

export default node;
