import { useCallback } from "react";
import { Card, Box, Button, Stack } from "@mui/material";
import Spacer from "@components/atoms/Spacer";
import Text from "@components/atoms/Text";
import { resolveError } from "@lib/utils/error";
import { object, string } from "@lib/utils/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import useMessage from "@lib/hooks/useMessage";
import {
  Form,
  TextField,
  PasswordField,
  ErrorText,
} from "@components/molecules/TextInput";
import styled from "styled-components";
import graphql from "babel-plugin-relay/macro";
import { useMutation } from "react-relay";
import {
  SignInMutation,
  SignInMutation$data,
} from "@generated/SignInMutation.graphql";
import { SecureStoreManager } from "@lib/utils/secureStoreManager";
import usePreventDoubleClick from "@lib/hooks/usePreventDoubleClick";

const mutation = graphql`
  mutation SignInMutation($input: SignInMutationInput!) {
    signIn(input: $input) {
      __typename
      ... on JwtToken {
        refreshToken
        accessToken
      }
      ... on UserError {
        message
      }
    }
  }
`;

type Input = {
  email: string;
  password: string;
};

export default function Login() {
  const { processing, onClick, onRelease } = usePreventDoubleClick();
  const [, setMessage] = useMessage();

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<Input>({
    defaultValues: {
      email: "",
      password: "",
    },
    mode: "all",
    resolver: yupResolver(
      object().shape({
        email: string()
          .trim()
          .required("入力してください")
          .email("Emailの形式が正しくありません"),
        password: string().trim().required("入力してください"),
      })
    ),
  });
  const [commit] = useMutation<SignInMutation>(mutation);

  const handleSignIn = useCallback(async () => {
    await handleSubmit(async ({ email, password }: Input) => {
      try {
        if (processing) {
          return;
        }

        onClick();
        const result = await new Promise<SignInMutation$data["signIn"]>(
          (resolve) => {
            commit({
              variables: {
                input: {
                  email,
                  password,
                },
              },
              onCompleted({ signIn }) {
                resolve(signIn);
              },
            });
          }
        );
        const { __typename } = result;
        if (__typename === "JwtToken") {
          const { accessToken, refreshToken } = result;
          SecureStoreManager.registerAccessTokenSet(accessToken, refreshToken);
          window.location.href = "/";
        } else if (__typename === "UserError") {
          setMessage({
            title: result.message,
            mode: "error",
          });
        } else {
          throw new Error("ログイン情報が正しくありません");
        }
      } catch (e: unknown) {
        setMessage({
          title: resolveError(e).message ?? "ログイン情報が正しくありません",
          mode: "error",
        });
      } finally {
        onRelease();
      }
    })();
  }, [commit, handleSubmit, onRelease, processing, onClick, setMessage]);

  return (
    <Container>
      <Spacer height={48} />
      <Text variant="h5">Golfieダッシュボード</Text>
      <Spacer height={24} />
      <Card sx={{ padding: 2 }}>
        <Form>
          <Stack alignItems="center">
            <Stack direction="column" spacing={3} sx={{ width: 350 }}>
              <Box>
                <TextField
                  control={control}
                  label="Email"
                  name="email"
                  required
                  type="email"
                />
                {!isValid && errors.email !== undefined && (
                  <ErrorText error={errors.email.message} />
                )}
              </Box>
              <Box>
                <PasswordField
                  control={control}
                  label="Password"
                  name="password"
                  required
                />
                {!isValid && errors.password !== undefined && (
                  <ErrorText error={errors.password.message} />
                )}
              </Box>

              <Button
                disabled={!isValid}
                onClick={handleSignIn}
                variant="contained"
              >
                ログイン
              </Button>
              <Spacer height={24} />
            </Stack>
          </Stack>
        </Form>
      </Card>
    </Container>
  );
}

const Container = styled.div`
  padding: 0 15px;
  margin: 0 auto;
  text-align: center;
`;
