import { useCallback, useMemo } from "react";
import { Stack, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import graphql from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import { MovieInReviewsCard$key } from "@generated/MovieInReviewsCard.graphql";
import { MovieInReviewsCardAdmin$key } from "@generated/MovieInReviewsCardAdmin.graphql";
import { MovieInReviewsCardMutation } from "@generated/MovieInReviewsCardMutation.graphql";
import usePreventDoubleClick from "@lib/hooks/usePreventDoubleClick";
import MovieMetainfoItemCard from "@components/molecules/MovieMetainfoItem/MovieMetainfoItemCard";

const query = graphql`
  fragment MovieInReviewsCard on MovieMetainfo {
    id
    ...MovieMetainfoItemCard
    movieMetainfoJobs {
      id
      type
      user {
        id
      }
      reviewer {
        id
      }
    }
  }
`;

const adminQuery = graphql`
  fragment MovieInReviewsCardAdmin on User {
    id
  }
`;

const mutation = graphql`
  mutation MovieInReviewsCardMutation(
    $input: UpdateMovieMetainfoJobMutationInput!
  ) {
    updateMovieMetainfoJob(input: $input) {
      __typename
      ... on MovieMetainfoJob {
        id
        type
        user {
          id
          name
          avatar {
            signedUrl
          }
        }
        reviewer {
          id
          name
        }
        appliedAt
      }
      ... on UserError {
        message
      }
    }
  }
`;

export default function MovieInReviewsCard({
  movieFragment,
  adminFragment,
}: {
  movieFragment: MovieInReviewsCard$key;
  adminFragment: MovieInReviewsCardAdmin$key;
}) {
  const { processing, onClick } = usePreventDoubleClick();
  const data = useFragment(query, movieFragment);
  const { id: adminId } = useFragment(adminQuery, adminFragment);
  const { id, movieMetainfoJobs } = data;
  const navigate = useNavigate();
  const [commit] = useMutation<MovieInReviewsCardMutation>(mutation);
  const handleAssign = useCallback(
    async (movieMetainfoJobId: string) => {
      if (processing) {
        return;
      }
      onClick();
      await new Promise<void>((resolve) => {
        commit({
          variables: {
            input: {
              id: movieMetainfoJobId,
              workflow: "review",
            },
          },
          onCompleted: () => {
            resolve();
          },
        });
      });
      navigate(`/movie_in_reviews/${id}/labels`);
    },
    [commit, id, navigate, processing, onClick]
  );
  const reviwews = useMemo(() => {
    const res = movieMetainfoJobs.filter(
      (movieMetainfoJob) =>
        movieMetainfoJob.reviewer === null &&
        movieMetainfoJob.user?.id !== adminId
    );
    return res;
  }, [movieMetainfoJobs, adminId]);
  return (
    <MovieMetainfoItemCard
      footer={
        <Stack flex={1} spacing={1}>
          {reviwews.length === 0 ? (
            <Button
              onClick={() => {
                navigate(`/movie_in_reviews/${id}/labels`);
              }}
              variant="contained"
            >
              詳細
            </Button>
          ) : (
            <Button
              onClick={() => {
                handleAssign(reviwews[0].id);
              }}
              variant="contained"
            >
              レビューする
            </Button>
          )}
        </Stack>
      }
      movieFragment={data}
    />
  );
}
