import AvatarUi from "@mui/material/Avatar";
import Icon from "@components/atoms/Icon";
import { Stack, Box } from "@mui/material";
import Colors from "@constants/Colors";

export default function Thumbnail({
  url,
  size = 500,
}: {
  url: string | null | undefined;
  size?: number;
}) {
  return url === null || url === undefined ? (
    <Stack alignItems="center" direction="row" justifyContent="center">
      <Box sx={{ padding: 1, backgroundColor: Colors.gray }}>
        <Icon name="image" size={size} />
      </Box>
    </Stack>
  ) : (
    <AvatarUi src={url} sx={{ width: size, height: size }} variant="square" />
  );
}
