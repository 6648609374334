import { atom, useRecoilState } from "recoil";

export type MessageData = {
  title: string;
  subline?: string;
  mode: "toast" | "dialog" | "error" | "confirm";
  onConfirm?: () => void;
};

const messageAtom = atom<MessageData | null>({
  key: "MESSAGE_DATA",
  default: null,
});

export default function useMessage() {
  return useRecoilState(messageAtom);
}
