import { useCallback, useState } from "react";
import { Button } from "@mui/material";
import graphql from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import Loading from "@components/atoms/Loading";
import Message from "@components/molecules/Message";
import { useNavigate } from "react-router-dom";
import useMessage from "@lib/hooks/useMessage";
import usePreventDoubleClick from "@lib/hooks/usePreventDoubleClick";
import {
  SwingSceneRemoveMutation,
  SwingSceneRemoveMutation$data,
} from "@generated/SwingSceneRemoveMutation.graphql";
import { SwingSceneRemove$key } from "@generated/SwingSceneRemove.graphql";
import Colors from "@constants/Colors";

const query = graphql`
  fragment SwingSceneRemove on SwingScene {
    id
  }
`;

const mutation = graphql`
  mutation SwingSceneRemoveMutation($input: RemoveSwingSceneMutationInput!) {
    removeSwingScene(input: $input) {
      success
    }
  }
`;

export default function SwingSceneRemove({
  frameFragment,
}: {
  frameFragment: SwingSceneRemove$key;
}) {
  const navigate = useNavigate();
  const [, setMessage] = useMessage();
  const [confirm, setConfirm] = useState<boolean>(false);
  const { id } = useFragment(query, frameFragment);
  const [commit] = useMutation<SwingSceneRemoveMutation>(mutation);
  const { processing, onClick, onRelease } = usePreventDoubleClick();
  const handleRemove = useCallback(async () => {
    if (processing) {
      return;
    }
    onClick();
    const result = await new Promise<
      SwingSceneRemoveMutation$data["removeSwingScene"]
    >((resolve) => {
      commit({
        variables: {
          input: {
            id,
          },
        },
        onCompleted: ({ removeSwingScene }) => {
          resolve(removeSwingScene);
        },
      });
    });
    if (result.success === false) {
      setMessage({
        mode: "error",
        title: "削除できませんでした",
      });
      onRelease();
    } else {
      setMessage({
        mode: "toast",
        title: "削除しました",
      });
      navigate("/swing_scenes");
    }
  }, [commit, id, setMessage, processing, onClick, onRelease, navigate]);

  return (
    <>
      <Button color="error" onClick={() => setConfirm(true)}>
        削除する
      </Button>
      <Message
        closeText="キャンセル"
        confirmText="削除する"
        message="この操作は取り消せません。よろしいですか？"
        onClose={() => setConfirm(false)}
        onConfirm={handleRemove}
        title="スイング定義を削除しますか？"
        visible={confirm}
      />
      {processing && <Loading color={Colors.white} mask />}
    </>
  );
}
