/**
 * @generated SignedSource<<f8e47b9de1a40e26ca9d33ce87bfe5f0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SwingFrameAngle = "back" | "front";
import { FragmentRefs } from "relay-runtime";
export type SwingSceneInputSwingFrames$data = {
  readonly swingFrames: ReadonlyArray<{
    readonly angle: SwingFrameAngle;
    readonly id: string;
    readonly name: string;
  }>;
  readonly " $fragmentType": "SwingSceneInputSwingFrames";
};
export type SwingSceneInputSwingFrames$key = {
  readonly " $data"?: SwingSceneInputSwingFrames$data;
  readonly " $fragmentSpreads": FragmentRefs<"SwingSceneInputSwingFrames">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SwingSceneInputSwingFrames",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SwingFrame",
      "kind": "LinkedField",
      "name": "swingFrames",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "angle",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "5a15ad490644deeb1f2126d9987b4dbc";

export default node;
