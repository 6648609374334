/**
 * @generated SignedSource<<15824a48e8d1711165710d6504f03202>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SwingPositionClassificationItemClip$data = {
  readonly ballTrajectory: boolean;
  readonly commentContents: ReadonlyArray<{
    readonly content: string;
    readonly createdAt: any;
    readonly id: string;
    readonly user: {
      readonly avatar: {
        readonly signedUrl: string | null | undefined;
      } | null | undefined;
      readonly name: string;
    };
  }>;
  readonly id: string;
  readonly swingClassification: {
    readonly id: string;
    readonly needReview: boolean;
    readonly secondary: boolean;
    readonly swingFrame: {
      readonly id: string;
      readonly name: string;
    };
  } | null | undefined;
  readonly swingImage: {
    readonly id: string;
    readonly signedUrl: string | null | undefined;
  };
  readonly " $fragmentSpreads": FragmentRefs<"SwingPositionClassificationRemove">;
  readonly " $fragmentType": "SwingPositionClassificationItemClip";
};
export type SwingPositionClassificationItemClip$key = {
  readonly " $data"?: SwingPositionClassificationItemClip$data;
  readonly " $fragmentSpreads": FragmentRefs<"SwingPositionClassificationItemClip">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "signedUrl",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SwingPositionClassificationItemClip",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ballTrajectory",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SwingImage",
      "kind": "LinkedField",
      "name": "swingImage",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SwingClassification",
      "kind": "LinkedField",
      "name": "swingClassification",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "needReview",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "secondary",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SwingFrame",
          "kind": "LinkedField",
          "name": "swingFrame",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CommentContent",
      "kind": "LinkedField",
      "name": "commentContents",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "content",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Avatar",
              "kind": "LinkedField",
              "name": "avatar",
              "plural": false,
              "selections": [
                (v1/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SwingPositionClassificationRemove"
    }
  ],
  "type": "MovieClip",
  "abstractKey": null
};
})();

(node as any).hash = "45d50bb8452f0d5d430a0ba83aa1bb99";

export default node;
