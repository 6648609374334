import { useEffect, Suspense } from "react";
import ContentLayout from "@navigation/ContentLayout";
import AnnotateObject from "@components/templates/AnnotateObject";
import graphql from "babel-plugin-relay/macro";
import {
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import { isNil } from "@lib/utils/commons";
import Loading from "@components/atoms/Loading";
import { AnnotateObjectScreenQuery } from "@generated/AnnotateObjectScreenQuery.graphql";
import useQueryId from "@lib/hooks/useQueryId";

const query = graphql`
  query AnnotateObjectScreenQuery($id: ID!) {
    movieMetainfoJob(id: $id) {
      ...AnnotateObject
    }
  }
`;

function ScreenContent({
  queryReference,
}: {
  queryReference: PreloadedQuery<AnnotateObjectScreenQuery>;
}): JSX.Element {
  const data = usePreloadedQuery<AnnotateObjectScreenQuery>(
    query,
    queryReference
  );
  const { movieMetainfoJob } = data;
  return <AnnotateObject movieMetainfoJobFragment={movieMetainfoJob} />;
}

export default function AnnotateObjectScreen(): JSX.Element {
  const id = useQueryId();
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<AnnotateObjectScreenQuery>(query);
  useEffect(() => {
    loadQuery({ id });
    return () => {
      disposeQuery();
    };
  }, [id, loadQuery, disposeQuery]);

  return (
    <ContentLayout
      breadcrumbs={[
        {
          label: "アノテーションタスク一覧",
          path: "/annotate_objects",
        },
      ]}
      title="タスク詳細"
    >
      <Suspense fallback={<Loading />}>
        {!isNil(queryReference) && (
          <ScreenContent queryReference={queryReference} />
        )}
      </Suspense>
    </ContentLayout>
  );
}
