import { useEffect, Suspense } from "react";
import ContentLayout from "@navigation/ContentLayout";
import LessonTemplates from "@components/templates/LessonTemplates";
import graphql from "babel-plugin-relay/macro";
import {
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import { isNil } from "@lib/utils/commons";
import Loading from "@components/atoms/Loading";
import { LessonTemplatesScreenQuery } from "@generated/LessonTemplatesScreenQuery.graphql";
import usePaginationOffset from "@lib/hooks/usePaginationOffset";
import { PageLimitLarge } from "@constants/App";

const query = graphql`
  query LessonTemplatesScreenQuery($first: Int!, $after: String) {
    ...LessonTemplatesPagination @arguments(first: $first, after: $after)
  }
`;

function ScreenContent({
  queryReference,
}: {
  queryReference: PreloadedQuery<LessonTemplatesScreenQuery>;
}) {
  const data = usePreloadedQuery<LessonTemplatesScreenQuery>(
    query,
    queryReference
  );
  return <LessonTemplates templatesFragment={data} />;
}

export default function LessonTemplatesScreen() {
  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<LessonTemplatesScreenQuery>(query);
  const after = usePaginationOffset();
  useEffect(() => {
    loadQuery({
      first: PageLimitLarge,
      after,
    });
    return () => {
      disposeQuery();
    };
  }, [after, loadQuery, disposeQuery]);

  return (
    <ContentLayout title="診断テンプレート一覧">
      <Suspense fallback={<Loading />}>
        {!isNil(queryReference) && (
          <ScreenContent queryReference={queryReference} />
        )}
      </Suspense>
    </ContentLayout>
  );
}
