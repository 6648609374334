import { useLocation } from "react-router-dom";

type Param = {
  [key: string]: string | undefined;
};

export default function useQueryParams(name: string | string[]): Param {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const targets = Array.isArray(name) ? name : [name];
  const result: Param = {};
  targets.forEach((target) => {
    const value = searchParams.get(target);
    result[target] = value ?? undefined;
  });
  return result;
}
