type Hint = {
  title: string;
  value: string;
};

const Working: Hint[] = [
  {
    title: "step1",
    value: "アドレス前とフィニッシュ後の余分な画像をまとめて削除する(任意)",
  },
  {
    title: "step2",
    value: "定義書に沿ってスイングポジションのラベル付けを行う",
  },
  {
    title: "step3",
    value: "インパクト後のボール軌道が分かる写真があればフォローでラベル付する",
  },
];

const Closing: Hint[] = [
  {
    title: "point1",
    value:
      "アドレス、テイクバック(終)、ダウンスイング(終)、インパクト、フォロー(始)は1つ、それ以外は2つの合計23枚を目処にラベル付けをしてください",
  },
  {
    title: "point2",
    value: "フレーム内にスイング上の課題がある場合はフラグをONにしてください",
  },
  {
    title: "point3",
    value:
      "インパクト後のボール弾道が確認できるものにはフラグをONにしてください。この時ラベルは必須ではありません",
  },
];

export { Working, Closing };
