/**
 * @generated SignedSource<<7f874d7b8d29038679c18f65fbe9220c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SwingSceneScreenQuery$variables = {
  id: string;
};
export type SwingSceneScreenQuery$data = {
  readonly swingScene: {
    readonly " $fragmentSpreads": FragmentRefs<"SwingSceneData">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"SwingSceneSwingFrames">;
};
export type SwingSceneScreenQuery = {
  response: SwingSceneScreenQuery$data;
  variables: SwingSceneScreenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "signedUrl",
    "storageKey": null
  },
  (v2/*: any*/)
],
v4 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "angle",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SwingSceneScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "swingScene",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SwingSceneData"
          }
        ],
        "storageKey": null
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "SwingSceneSwingFrames"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SwingSceneScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "swingScene",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "draw",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "correctForm",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "incorrectForm",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "point",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "view",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "orderNumber",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Thumbnail",
                "kind": "LinkedField",
                "name": "thumbnail",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Thumbnail",
                "kind": "LinkedField",
                "name": "video",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SwingFrame",
                "kind": "LinkedField",
                "name": "swingFrame",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              }
            ],
            "type": "SwingScene",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "SwingFrame",
        "kind": "LinkedField",
        "name": "swingFrames",
        "plural": true,
        "selections": (v4/*: any*/),
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "83b7e5a106737bd985ddf4279fa3feaf",
    "id": null,
    "metadata": {},
    "name": "SwingSceneScreenQuery",
    "operationKind": "query",
    "text": "query SwingSceneScreenQuery(\n  $id: ID!\n) {\n  swingScene(id: $id) {\n    __typename\n    ...SwingSceneData\n    ... on SwingScene {\n      id\n    }\n  }\n  ...SwingSceneSwingFrames\n}\n\nfragment SwingSceneData on SwingScene {\n  id\n  title\n  draw\n  correctForm\n  incorrectForm\n  point\n  view\n  orderNumber\n  thumbnail {\n    signedUrl\n    id\n  }\n  video {\n    signedUrl\n    id\n  }\n  swingFrame {\n    id\n    name\n    angle\n  }\n  ...SwingSceneDisplay\n  ...SwingSceneRemove\n}\n\nfragment SwingSceneDisplay on SwingScene {\n  id\n  title\n  draw\n  correctForm\n  incorrectForm\n  point\n  view\n  orderNumber\n  thumbnail {\n    signedUrl\n    id\n  }\n  video {\n    signedUrl\n    id\n  }\n  swingFrame {\n    name\n    angle\n    id\n  }\n}\n\nfragment SwingSceneInputSwingFrames on Query {\n  swingFrames {\n    id\n    name\n    angle\n  }\n}\n\nfragment SwingSceneRemove on SwingScene {\n  id\n}\n\nfragment SwingSceneSwingFrames on Query {\n  ...SwingSceneInputSwingFrames\n}\n"
  }
};
})();

(node as any).hash = "a9b9deabb809d1620a4ff0a8d8547553";

export default node;
